import { FC } from 'react'

import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import NotoSansBold from 'assets/fonts/NotoSansJP-Bold.otf'
import NotoSansRegular from 'assets/fonts/NotoSansJP-Regular.otf'

import {
  INSPECTION_CONTRACTEE_ERROR_COLOR,
  INSPECTION_CONTRACTOR_ERROR_COLOR,
  INSPECTION_NORMAL_COLOR,
} from 'config/styles'

import {
  InspectionItem,
  InspectionItemEvaluation,
  InspectionItemEvaluationCollection,
  InspectionItemNumberValues,
  InspectionSheet,
  Project,
} from 'interfaces/interfaces'

import { formatInspectionDifferentValue } from 'services/InspectionSheet'
import { findDiameterKeyByValue, meterToMillimeter } from 'services/Util'

Font.register({
  family: 'NotoSansRegular',
  fonts: [
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src: NotoSansRegular,
      fontWeight: 'normal',
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src: NotoSansBold,
      fontWeight: 'bold',
    },
  ],
})

export const LABEL_COLOR = '#666'
export const BORDER_COLOR = '#eee'
export const NORMAL_COLOR = 'black'

const PROFILE_GROUP_WIDTH = 300
const NAME_COLUMN_WIDTH = 125
const VALUE_COLUMN_WIDTH = 140
const RESULT_COLUMN_WIDTH = 133

// Create styles
export const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    flexDirection: 'column',
    fontFamily: 'NotoSansRegular',
    fontSize: 11,
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 16,
    textAlign: 'center',
  },
  profileGroup: {
    flexDirection: 'row',
    paddingVertical: 6,
    borderBottom: `1px solid ${BORDER_COLOR}`,
    minWidth: PROFILE_GROUP_WIDTH,
  },
  profileGroupColumn: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: 36,
  },
  profileGroupRow: {
    flexDirection: 'row',
    padding: 24,
    width: '100%',
  },
  profileGroupLabel: {
    color: LABEL_COLOR,
    minWidth: 64,
    paddingRight: 12,
  },
  criterionColumn: {
    alignItems: 'stretch',
  },
  criterionHeader: {
    backgroundColor: BORDER_COLOR,
    padding: 12,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  criterionValuesWrapper: {
    height: 64,
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  criterionValueColumn: {
    flexDirection: 'column',
    flex: 1,
    paddingVertical: 4,
  },
  criterionValueColumnHeader: {
    color: LABEL_COLOR,
    width: '100%',
    textAlign: 'center',
    paddingBottom: 5,
  },
  criterionValueColumnContentRow: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  criterionValueColumnContentColumn: {
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  divider: {
    minWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: BORDER_COLOR,
    borderLeftWidth: 1,
    height: '100%',
  },
  valueHeaderColumn: {
    alignItems: 'stretch',
    borderColor: BORDER_COLOR,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
  },
  valueHeaderColumnContentWrapper: { height: 60, alignItems: 'flex-end', paddingBottom: 6, flexDirection: 'row' },
  valueHeaderColumnContentLabelForResult: { color: LABEL_COLOR, flex: 1, alignItems: 'center', marginHorizontal: -4 },
  valueHeaderColumnContentLabel: { color: LABEL_COLOR, flex: 1, alignItems: 'center' },
  nameColumn: {
    minWidth: NAME_COLUMN_WIDTH,
    maxWidth: NAME_COLUMN_WIDTH,
    alignItems: 'stretch',
    borderColor: BORDER_COLOR,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
  },
  valuesColumn: {
    minWidth: VALUE_COLUMN_WIDTH,
    alignItems: 'stretch',
    borderColor: BORDER_COLOR,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    paddingHorizontal: 4,
    paddingVertical: 4,
    flexDirection: 'row',
  },
  valuesColumnContent: { flex: 1, textAlign: 'center' },
  resultColumn: {
    minWidth: RESULT_COLUMN_WIDTH,
    maxWidth: RESULT_COLUMN_WIDTH,
    borderColor: BORDER_COLOR,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    paddingHorizontal: 4,
    paddingVertical: 4,
    flexDirection: 'row',
  },
  resultColumnContent: {
    flex: 1,
    textAlign: 'center',
    marginHorizontal: -4,
  },
})

const MainSheetPDF: FC<{
  project?: Project
  inspectionSheet: InspectionSheet
  inspectionItems: InspectionItem[]
  itemResults: InspectionItemEvaluationCollection[]
  meanDistanceCriterionUnit: string
  coverDistanceCriterionUnit: string
  meanDistanceContracteeCriterion?: number
  meanDistanceContractorCriterion?: number
  coverDistanceContracteeCriterion?: number
  coverDistanceContractorCriterion?: number
}> = ({
  project,
  inspectionSheet,
  inspectionItems,
  itemResults,
  meanDistanceCriterionUnit,
  coverDistanceCriterionUnit,
  meanDistanceContracteeCriterion,
  meanDistanceContractorCriterion,
  coverDistanceContracteeCriterion,
  coverDistanceContractorCriterion,
}) => {
  const { t } = useTranslation(['projects'])

  const renderProfileGroup = (label: string, value?: string) => (
    <View style={styles.profileGroup}>
      <Text style={styles.profileGroupLabel}>{label}</Text>
      <Text>{value}</Text>
    </View>
  )

  const renderCriterionHeadersColumn = (
    header: string,
    COLUMN_WIDTH: number,
    contracteeCriterionValue: number | undefined,
    contractorCriterionValue: number | undefined,
    unit: string,
    criterionUnit?: string,
    isLastColumn?: boolean
  ) => (
    <View style={{ ...styles.criterionColumn, minWidth: COLUMN_WIDTH }}>
      <View style={styles.criterionHeader}>
        <Text>{header}</Text>
      </View>
      <View style={styles.criterionValuesWrapper}>
        <View style={styles.divider} />
        <View style={styles.criterionValueColumn}>
          <Text style={styles.criterionValueColumnHeader}>
            {t('main_canvas.inspection_sheet.tolerances.tolerance', { ns: 'projects' })}
          </Text>
          <View
            style={{
              ...(criterionUnit ? styles.criterionValueColumnContentRow : styles.criterionValueColumnContentColumn),
            }}
          >
            <Text>±{contracteeCriterionValue ?? ''}</Text>
            {criterionUnit && <Text>{criterionUnit}</Text>}
            {!criterionUnit && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </View>
        </View>
        <View style={styles.criterionValueColumn}>
          <Text style={styles.criterionValueColumnHeader}>
            {t('main_canvas.inspection_sheet.tolerances.tolerance_internal', { ns: 'projects' })}
          </Text>
          <View
            style={{
              ...(criterionUnit ? styles.criterionValueColumnContentRow : styles.criterionValueColumnContentColumn),
            }}
          >
            <Text>±{contractorCriterionValue ?? ''}</Text>
            {criterionUnit && <Text>{criterionUnit}</Text>}
            {!criterionUnit && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </View>
        </View>
        {isLastColumn && <View style={styles.divider} />}
      </View>
    </View>
  )

  const renderValueHeadersColumn = (COLUMN_WIDTH: number, unit: string) => (
    <View
      style={{
        ...styles.valueHeaderColumn,
        minWidth: COLUMN_WIDTH,
        borderRightWidth: 0,
      }}
    >
      <View style={styles.valueHeaderColumnContentWrapper}>
        <>
          <View style={styles.valueHeaderColumnContentLabel}>
            <Text>{t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' })}</Text>
            <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
          </View>
          <View style={styles.valueHeaderColumnContentLabel}>
            <Text>{t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' })}</Text>
            <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
          </View>
          <View style={styles.valueHeaderColumnContentLabel}>
            <Text>{t('main_canvas.inspection_sheet.values.difference', { ns: 'projects' })}</Text>
            <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
          </View>
        </>
      </View>
    </View>
  )

  const renderResultHeadersColumn = (COLUMN_WIDTH: number) => (
    <View
      style={{
        ...styles.valueHeaderColumn,
        minWidth: COLUMN_WIDTH,
        borderRightWidth: 1,
      }}
    >
      <View style={styles.valueHeaderColumnContentWrapper}>
        <>
          <View style={styles.valueHeaderColumnContentLabelForResult}>
            <Text>{t('main_canvas.inspection_sheet.result.result', { ns: 'projects' })}</Text>
            <Text style={{ fontSize: 9, marginTop: 3 }}>
              {`（ ${t('main_canvas.inspection_sheet.tolerances.tolerance', { ns: 'projects' })} ）`}
            </Text>
          </View>
          <View style={styles.valueHeaderColumnContentLabelForResult}>
            <Text>{t('main_canvas.inspection_sheet.result.result', { ns: 'projects' })}</Text>
            <Text style={{ fontSize: 9, marginTop: 3 }}>
              {`（${t('main_canvas.inspection_sheet.tolerances.tolerance_internal', { ns: 'projects' })}）`}
            </Text>
          </View>
        </>
      </View>
    </View>
  )

  const renderNameColumn = (name: string, isFirstRow?: boolean) => (
    <View
      style={{
        ...styles.nameColumn,
        borderTopWidth: isFirstRow ? 0 : 1,
        marginTop: isFirstRow ? 0 : -1,
      }}
      wrap={false}
    >
      <Text style={{ padding: 4, textAlign: 'right' }}>{name}</Text>
    </View>
  )

  const renderValuesColumn = (
    values: InspectionItemNumberValues | null,
    result: InspectionItemEvaluation,
    needScaleValueUp?: boolean,
    isFirstRow?: boolean,
    forDiameter?: boolean
  ) => {
    let specifiedValue = '-'
    let estimatedValue = '-'
    if (values !== null) {
      if (values.specified_value !== undefined && values.specified_value !== null) {
        const value = needScaleValueUp ? meterToMillimeter(values.specified_value) : values.specified_value
        specifiedValue = forDiameter ? findDiameterKeyByValue(value) : value.toString()
      }
    }
    if (values !== null) {
      if (values.estimated_value !== undefined && values.estimated_value !== null) {
        const value = needScaleValueUp ? meterToMillimeter(values.estimated_value) : values.estimated_value
        estimatedValue = forDiameter ? findDiameterKeyByValue(value) : value.toString()
      }
    }

    return (
      <View
        style={{
          ...styles.valuesColumn,
          borderTopWidth: isFirstRow ? 0 : 1,
          marginTop: isFirstRow ? 0 : -1,
        }}
        wrap={false}
      >
        <Text style={styles.valuesColumnContent}>{specifiedValue}</Text>
        <Text style={styles.valuesColumnContent}>{estimatedValue}</Text>
        <Text
          style={{
            ...styles.valuesColumnContent,
            color: result.color === INSPECTION_NORMAL_COLOR ? NORMAL_COLOR : result.color,
          }}
        >
          {formatInspectionDifferentValue(values, needScaleValueUp)}
        </Text>
      </View>
    )
  }

  const renderResultsColumn = (results: InspectionItemEvaluation | undefined, isFirstRow?: boolean) => (
    <View
      style={{
        ...styles.resultColumn,
        borderTopWidth: isFirstRow ? 0 : 1,
        marginTop: isFirstRow ? 0 : -1,
      }}
      wrap={false}
    >
      {/* contractee */}
      <Text
        style={{
          ...styles.resultColumnContent,
          color: results?.qualifiedByContractee ? NORMAL_COLOR : INSPECTION_CONTRACTEE_ERROR_COLOR,
        }}
      >
        {results?.qualifiedByContractee
          ? t('main_canvas.inspection_sheet.result.ok', { ns: 'projects' })
          : t('main_canvas.inspection_sheet.result.ng', { ns: 'projects' })}
      </Text>
      {/* contractor */}
      <Text
        style={{
          ...styles.resultColumnContent,
          color: results?.qualifiedByContractor ? NORMAL_COLOR : INSPECTION_CONTRACTOR_ERROR_COLOR,
        }}
      >
        {results?.qualifiedByContractor
          ? t('main_canvas.inspection_sheet.result.ok', { ns: 'projects' })
          : t('main_canvas.inspection_sheet.result.ng', { ns: 'projects' })}
      </Text>
    </View>
  )

  if (itemResults.length !== inspectionItems.length) {
    return null
  }

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={{ paddingTop: 16, paddingBottom: 10 }}>
          <Text style={styles.heading}>
            {t('main_canvas.inspection_sheet.rebar_inspection_sheet', { ns: 'projects' })}
          </Text>
        </View>
        <View style={styles.profileGroupRow}>
          <View style={styles.profileGroupColumn}>
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.construction_project_name', { ns: 'projects' }),
              inspectionSheet.construction_properties.construction_project_name
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.construction_type_name', { ns: 'projects' }),
              inspectionSheet.construction_properties.construction_type
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.construction_detail', { ns: 'projects' }),
              inspectionSheet.construction_properties.construction_type_detailed
            )}
          </View>
          <View style={styles.profileGroupColumn}>
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.inspection_area_name', { ns: 'projects' }),
              project?.project_name
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.creator', { ns: 'projects' }),
              inspectionSheet.creator_name
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.creation_day', { ns: 'projects' }),
              inspectionSheet.create_time_user_specified
                ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
                : ''
            )}
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'stretch' }}>
          <View style={{ minWidth: NAME_COLUMN_WIDTH }} />
          {renderCriterionHeadersColumn(
            t('main_canvas.inspection_sheet.inspection_quantity.diameter', { ns: 'projects' }),
            VALUE_COLUMN_WIDTH,
            inspectionSheet.criterion_contractee?.diameter,
            inspectionSheet.criterion_contractor.diameter,
            'mm'
          )}
          {renderCriterionHeadersColumn(
            t('main_canvas.inspection_sheet.inspection_quantity.number', { ns: 'projects' }),
            VALUE_COLUMN_WIDTH,
            inspectionSheet.criterion_contractee?.number,
            inspectionSheet.criterion_contractor.number,
            t('main_canvas.inspection_sheet.unit_rebar', { ns: 'projects' })
          )}
          {renderCriterionHeadersColumn(
            t('main_canvas.inspection_sheet.inspection_quantity.mean_spacing', { ns: 'projects' }),
            VALUE_COLUMN_WIDTH,
            meanDistanceContracteeCriterion,
            meanDistanceContractorCriterion,
            '',
            meanDistanceCriterionUnit
          )}
          {renderCriterionHeadersColumn(
            t('main_canvas.inspection_sheet.inspection_quantity.cover_thickness', { ns: 'projects' }),
            VALUE_COLUMN_WIDTH,
            coverDistanceContracteeCriterion,
            coverDistanceContractorCriterion,
            '',
            coverDistanceCriterionUnit,
            true
          )}
          <View style={{ minWidth: RESULT_COLUMN_WIDTH }} />
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ minWidth: NAME_COLUMN_WIDTH, maxWidth: NAME_COLUMN_WIDTH }} />
          {renderValueHeadersColumn(VALUE_COLUMN_WIDTH, 'mm')}
          {renderValueHeadersColumn(
            VALUE_COLUMN_WIDTH,
            t('main_canvas.inspection_sheet.unit_rebar', { ns: 'projects' })
          )}
          {renderValueHeadersColumn(VALUE_COLUMN_WIDTH, 'mm')}
          {renderValueHeadersColumn(VALUE_COLUMN_WIDTH, 'mm')}
          {renderResultHeadersColumn(RESULT_COLUMN_WIDTH)}
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              minWidth: NAME_COLUMN_WIDTH,
              maxWidth: NAME_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          >
            <Text style={{ paddingHorizontal: 4, paddingTop: 4, color: LABEL_COLOR, textAlign: 'right' }}>
              {t('main_canvas.inspection_sheet.item', { ns: 'projects' })}
            </Text>
          </View>
          <View
            style={{
              minWidth: VALUE_COLUMN_WIDTH,
              maxWidth: VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: VALUE_COLUMN_WIDTH,
              maxWidth: VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: VALUE_COLUMN_WIDTH,
              maxWidth: VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: VALUE_COLUMN_WIDTH,
              maxWidth: VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: RESULT_COLUMN_WIDTH,
              maxWidth: RESULT_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderRightWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
        </View>
        {inspectionItems.map((item, index) => (
          <View
            key={item.inspection_item_id}
            style={{
              flexDirection: 'row',
              width: '100%',
              alignItems: 'stretch',
            }}
          >
            {renderNameColumn(item.part_name, index === 0)}
            {renderValuesColumn(item.diameter, itemResults[index].diameter, true, index === 0, true)}
            {renderValuesColumn(item.number, itemResults[index].number, false, index === 0)}
            {renderValuesColumn(item.mean_distance, itemResults[index].meanDistance, true, index === 0)}
            {renderValuesColumn(item.cover_distance, itemResults[index].coverDistance, true, index === 0)}
            {renderResultsColumn(itemResults[index].overall, index === 0)}
          </View>
        ))}
      </Page>
    </Document>
  )
}

export default MainSheetPDF
