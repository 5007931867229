import { createContext, useContext } from 'react'

import { DEFAULT_IFC_REGISTER_TOOL } from 'config/constants'

import { IFCRegisterViewer } from 'interfaces/interfaces'

const initialState: IFCRegisterViewer = {
  changeTool: () => null,
  selectedTool: DEFAULT_IFC_REGISTER_TOOL,
  pcdPoints: [],
  ifcPoints: [],
}

export const IFCRegisterContext = createContext(initialState)
export const useIFCRegisterContext = () => useContext(IFCRegisterContext)
