import { FC, useContext } from 'react'

import { HStack, StackDivider } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { ToolbarCommentIcon } from 'assets/icons'

import { BlueprintContext } from 'contexts/Blueprint'

import { BLUEPRINT_TOOLS } from 'config/constants'

import ToolbarButton from '../../components/ToolbarButton'

const Toolbar: FC = () => {
  const buttonSize = isTablet ? 16 : 8
  const { t } = useTranslation(['projects'])

  const { changeTool, selectedTool } = useContext(BlueprintContext)

  return (
    <HStack overflow="hidden" h={buttonSize} spacing={3}>
      <HStack
        backgroundColor="gray.800"
        overflow="hidden"
        borderRadius="md"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
        h={buttonSize}
      >
        <ToolbarButton
          icon={<ToolbarCommentIcon width="50%" height="50%" />}
          label={t('blueprint.toolbar.comment', { ns: 'projects' })}
          testId="comment"
          toolType={BLUEPRINT_TOOLS.COMMENT}
          toolTipPlacement="bottom"
          selectedTool={selectedTool}
          changeTool={(tool: string) => changeTool(selectedTool === BLUEPRINT_TOOLS.COMMENT ? '' : tool)}
          buttonSize={buttonSize}
        />
      </HStack>
    </HStack>
  )
}

export default Toolbar
