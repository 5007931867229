import { Line3, Vector3 } from 'three'

import { EDITOR_DECIMAL_BASE, EDITOR_REQUIRED_ANCHORS } from 'config/constants'

import { AnchorPoints, PointArray, ShapeDistance } from 'interfaces/interfaces'

import { roundNumber } from './Util'

/**
 * Get the distance and the center point between two points
 * @param {AnchorPoints} anchor
 * @returns center point and distance
 */
export const calculateCenterAndDistance = (anchor: AnchorPoints): [PointArray, number] | undefined => {
  if (anchor.points.length !== 2) {
    return undefined
  }
  const line = new Line3(new Vector3(...anchor.points[0]), new Vector3(...anchor.points[1]))
  const center = line.getCenter(new Vector3())
  return [center.toArray(), roundNumber(line.distance(), EDITOR_DECIMAL_BASE)]
}

/**
 * Extract anchor points (for drawing lines) from shape distances object
 * @param {ShapeDistance} shape distances
 * @returns array of anchor points
 */
export const extractAnchorPointsListFromShapeDistances = (shapeDistances: ShapeDistance[]): AnchorPoints[] =>
  shapeDistances.map((distance) => {
    const distanceFactors = calculateCenterAndDistance({ points: distance.positions_for_distance })
    return {
      points: distance.positions_for_distance,
      center: distanceFactors?.[0],
      distance: distance.distance, // use saved value in DB instead of recomputed value to avoid possible discrepancy
    }
  })

/**
 * Generate distances and centers between points
 * @param {AnchorPoints} annotationAnchors anchors
 * @param {number} modifiedIndex in case user move the point in any anchor
 * @returns annotation anchors with distances and centers
 */
export const generateSpacerAnnotationDistances = (annotationAnchors: AnchorPoints[], modifiedIndex?: number) => {
  // Generate distance labels between points
  const newAnchors = annotationAnchors.map((anchor, anchorIndex) => {
    if (
      (modifiedIndex === undefined || modifiedIndex !== anchorIndex) &&
      anchor.centers?.length &&
      anchor.distances?.length &&
      anchor.centers.length === anchor.points.length
    ) {
      return anchor
    }
    const centers: PointArray[] = []
    const distances: number[] = []

    if (anchor.points.length > 1) {
      anchor.points.forEach((point, index) => {
        if (anchor.points.length < EDITOR_REQUIRED_ANCHORS.spacerAnnotation && index === anchor.points.length - 1) {
          return false
        }
        const nextPointIndex = index < anchor.points.length - 1 ? index + 1 : 0
        const distanceFactors = calculateCenterAndDistance({
          points: [point, anchor.points[nextPointIndex]],
        })
        if (distanceFactors) {
          centers.push(distanceFactors[0])
          distances.push(distanceFactors[1])
        }
        return true
      })
    }
    return { ...anchor, centers, distances }
  })
  return newAnchors
}
