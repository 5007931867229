import { FC, useContext } from 'react'

import { Button, Container, Heading, Image, Link, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import BackgroundImage from 'assets/imgs/maintenance-page.png'

import { ServiceStatusContext } from 'contexts/ServiceStatus'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

const MaintenancePage: FC = () => {
  const { t } = useTranslation(['maintenance_page'])
  const { serviceStatus } = useContext(ServiceStatusContext)

  const message =
    serviceStatus?.is_down.message || serviceStatus?.is_down.url_text ? (
      <>{serviceStatus?.is_down.message} </>
    ) : (
      t('maintenance_message', { ns: 'maintenance_page' })
    )
  return (
    <Container flex="1" maxW={CONTAINER_MAX_WIDTH} textAlign="center">
      <VStack>
        <Image src={BackgroundImage} py={20} />
        <Heading textAlign="center" size="xl">
          {t('maintenance_in_progress', { ns: 'maintenance_page' })}
        </Heading>
        <Heading textAlign="center" size="md">
          {message}
        </Heading>
        <Button as={Link} href={serviceStatus?.is_down.url || 'https://www.datalabs.jp/modely'} colorScheme="primary">
          {serviceStatus?.is_down.url_text || t('about_modely', { ns: 'maintenance_page' })}
        </Button>
      </VStack>
    </Container>
  )
}

export default MaintenancePage
