import { configureStore } from '@reduxjs/toolkit'
import editorReducer from 'pages/projects/editor/store/editor'
import { useDispatch } from 'react-redux'

export const storeConfig = {
  reducer: {
    editor: editorReducer,
  },
}

export const store = configureStore(storeConfig)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
