/* eslint-disable no-param-reassign */
import dayjs from 'dayjs'
import { BorderStyle, Fill, Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
import mixpanel from 'mixpanel-browser'

import { INSPECTION_CONTRACTEE_ERROR_COLOR, INSPECTION_CONTRACTOR_ERROR_COLOR } from 'config/styles'

import {
  InspectionItem,
  InspectionItemEvaluationCollection,
  InspectionSheet,
  Project,
  TextsInspectionSheet,
} from 'interfaces/interfaces'

import { formatInspectionDifferentValue, scaleUpValues } from 'services/InspectionSheet'
import { findDiameterKeyByValue } from 'services/Util'

export const BORDER_COLOR = { argb: '000000' }
export const HEADER_FILL: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'f2f2f2' },
}

/**
 * get color code from given color name.
 *
 * @param {string} colorName color name
 * @returns {("ff0000" | "ffa500" | "000000")} color code
 */
export const getColor = (colorName: string) => {
  switch (colorName) {
    case 'red':
      return 'ff0000'
    case 'orange':
      return 'ffa500'
    default:
      return '000000'
  }
}

/**
 * Create surrounding border from a range of cells in a worksheet.
 * https://github.com/exceljs/exceljs/issues/333#issuecomment-1591185638
 *
 * @param {Worksheet} worksheet worksheet object
 * @param {[number, number]} param0 start column and row
 * @param {number} param0.startCol start column
 * @param {number} param0.startRow start row
 * @param {[number, number]} param1 end column and row
 * @param {number} param1.endCol end column
 * @param {number} param1.endRow end row
 * @param {BorderStyle} [style='thin'] border style
 */
export const createOuterBorder = (
  worksheet: Worksheet,
  [startCol, startRow]: [number, number],
  [endCol, endRow]: [number, number],
  style: BorderStyle = 'thin'
) => {
  for (let i = startRow; i <= endRow; i += 1) {
    const leftBorderCell = worksheet.getCell(i, startCol)
    const rightBorderCell = worksheet.getCell(i, endCol)

    leftBorderCell.border = {
      ...leftBorderCell.border,
      left: {
        style,
        color: BORDER_COLOR,
      },
    }

    rightBorderCell.border = {
      ...rightBorderCell.border,
      right: {
        style,
        color: BORDER_COLOR,
      },
    }
  }

  for (let i = startCol; i <= endCol; i += 1) {
    const topBorderCell = worksheet.getCell(startRow, i)
    const bottomBorderCell = worksheet.getCell(endRow, i)

    topBorderCell.border = {
      ...topBorderCell.border,
      top: {
        style,
        color: BORDER_COLOR,
      },
    }

    bottomBorderCell.border = {
      ...bottomBorderCell.border,
      bottom: {
        style,
        color: BORDER_COLOR,
      },
    }
  }
}

/**
 * create a new workbook object.
 *
 * @returns {Workbook} resultant workbook
 */
const newWorkbook = (): Workbook => {
  const workbook = new Workbook()
  workbook.creator = 'DataLabs Modely'
  workbook.lastModifiedBy = 'DataLabs Modely'
  workbook.created = new Date()

  return workbook
}

/**
 * set the inspection sheet properties to the worksheet.
 *
 * @param {Worksheet} sheet worksheet object
 * @param {string} projectName project name
 * @param {InspectionSheet} inspectionSheet inspection sheet
 * @param {Record<string, string>} sheetProperty texts for the sheet properties
 */
export const setProfileGroup = (
  sheet: Worksheet,
  projectName: string,
  inspectionSheet: InspectionSheet,
  sheetProperty: Record<string, string>
) => {
  sheet.getRow(1).getCell(1).value = sheetProperty.construction_project_name
  sheet.getRow(1).getCell(2).value = inspectionSheet.construction_properties.construction_project_name || ''

  sheet.getRow(2).getCell(1).value = sheetProperty.construction_type_name
  sheet.getRow(2).getCell(2).value = inspectionSheet.construction_properties.construction_type || ''

  sheet.getRow(3).getCell(1).value = sheetProperty.construction_detail
  sheet.getRow(3).getCell(2).value = inspectionSheet.construction_properties.construction_type_detailed || ''

  sheet.getRow(4).getCell(1).value = sheetProperty.inspection_area_name
  sheet.getRow(4).getCell(2).value = projectName

  sheet.getRow(5).getCell(1).value = sheetProperty.creator
  sheet.getRow(5).getCell(2).value = inspectionSheet.creator_name || ''

  sheet.getRow(6).getCell(1).value = sheetProperty.creation_day
  sheet.getRow(6).getCell(2).value = inspectionSheet.create_time_user_specified
    ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
    : ''
}

/**
 * convert value to string. If value is null or undefined, return '-'
 *
 * @param {(number | null | undefined)} value value to be converted to string
 * @returns {string} resultant string
 */
export const getValue = (value: number | null | undefined) =>
  value !== null && value !== undefined ? value.toString() : '-'

/**
 * set criterion header to the worksheet.
 * Its width is 3 columns.
 *
 * @param {number} startRow row number to start with.
 * @param {Worksheet} sheet Worksheet object
 * @param {string} header header to be set
 * @param {number} index index of the inspection value (ex: 0 is for diameter)
 */
const setCriterionHeader = (startRow: number, sheet: Worksheet, header: string, index: number) => {
  const columnSpace = 3
  const startColumn = 2 + index * columnSpace // 1st column is for 測点又は区別
  const endColumn = startColumn + columnSpace - 1

  sheet.getRow(startRow).getCell(startColumn).value = header
  sheet.mergeCells(startRow, startColumn, startRow, endColumn)
  sheet.getRow(startRow).getCell(startColumn).fill = HEADER_FILL
  sheet.getRow(startRow).getCell(startColumn).alignment = { horizontal: 'center' }

  createOuterBorder(sheet, [startColumn, startRow], [endColumn, startRow], 'thin')
}

/**
 * set criterion data (for contractee/contractor) to the worksheet.
 * Its width is 3 columns and its height is 2 or 3 rows.
 *
 * @param {number} startRow row number to start with.
 * @param {Worksheet} sheet Worksheet object
 * @param {string[]} values 2 values to be set
 * @param {string} unit unit of the criterion
 * @param {number} index index of the inspection value (ex: 0 is for diameter)
 * @param {Record<string, string>} tolerances texts for tolerances (criteria)
 */
const setCriterionColumn = (
  startRow: number,
  sheet: Worksheet,
  values: string[],
  unit: string,
  index: number,
  tolerances: Record<string, string>
) => {
  const columnSpace = 3
  const startColumn = 2 + index * columnSpace // 1st column is for 測点又は区別
  const endColumn = startColumn + columnSpace - 1
  const [header1, header2] = [tolerances.tolerance, tolerances.tolerance_internal]
  const [value1, value2] = values

  sheet.getRow(startRow).getCell(startColumn).value = header1
  sheet.getRow(startRow + 1).getCell(startColumn).value = value1
  sheet.getRow(startRow).getCell(startColumn).alignment = { horizontal: 'center' }
  sheet.getRow(startRow + 1).getCell(startColumn).alignment = { horizontal: 'center' }

  sheet.getRow(startRow).getCell(startColumn + 1).value = header2
  sheet.getRow(startRow).getCell(startColumn + 1).alignment = { horizontal: 'center' }
  sheet.getRow(startRow + 1).getCell(startColumn + 1).value = value2
  sheet.getRow(startRow + 1).getCell(startColumn + 1).alignment = { horizontal: 'center' }

  if (unit) {
    sheet.getRow(startRow + 2).getCell(startColumn).value = `(${unit})`
    sheet.getRow(startRow + 2).getCell(startColumn).alignment = { horizontal: 'center' }
    sheet.getRow(startRow + 2).getCell(startColumn + 1).value = `(${unit})`
    sheet.getRow(startRow + 2).getCell(startColumn + 1).alignment = { horizontal: 'center' }
  }

  createOuterBorder(sheet, [startColumn, startRow], [endColumn, startRow + 2], 'thin')
}

/**
 * set criterion data to the worksheet.
 *
 * @param {Worksheet} sheet Worksheet object
 * @param {InspectionSheet} inspectionSheet Inspection Sheet
 * @param {string} meanDistanceCriterionUnit unit of mean distance criterion
 * @param {string} coverDistanceCriterionUnit unit of cover distance criterion
 * @param {(number | undefined)} meanDistanceContracteeCriterion mean distance criterion for contractee
 * @param {(number | undefined)} meanDistanceContractorCriterion mean distance criterion for contractor
 * @param {(number | undefined)} coverDistanceContracteeCriterion cover distance criterion for contractee
 * @param {(number | undefined)} coverDistanceContractorCriterion cover distance criterion for contractor
 * @param {TextsInspectionSheet} texts Texts for the inspection sheet
 */
const setCriterionData = (
  sheet: Worksheet,
  inspectionSheet: InspectionSheet,
  meanDistanceCriterionUnit: string,
  coverDistanceCriterionUnit: string,
  meanDistanceContracteeCriterion: number | undefined,
  meanDistanceContractorCriterion: number | undefined,
  coverDistanceContracteeCriterion: number | undefined,
  coverDistanceContractorCriterion: number | undefined,
  texts: TextsInspectionSheet
) => {
  const startRow = 8 // Profile rows is 6 + 1 row as space
  setCriterionHeader(startRow, sheet, texts.inspection_quantity.diameter, 0)
  setCriterionHeader(startRow, sheet, texts.inspection_quantity.number, 1)
  setCriterionHeader(startRow, sheet, texts.inspection_quantity.mean_spacing, 2)
  setCriterionHeader(startRow, sheet, texts.inspection_quantity.cover_thickness, 3)

  setCriterionColumn(
    startRow + 1,
    sheet,
    [
      getValue(inspectionSheet.criterion_contractee?.diameter),
      getValue(inspectionSheet.criterion_contractor?.diameter),
    ],
    'mm',
    0,
    texts.tolerances
  )
  setCriterionColumn(
    startRow + 1,
    sheet,
    [getValue(inspectionSheet.criterion_contractee?.number), getValue(inspectionSheet.criterion_contractor?.number)],
    texts.unit_rebar,
    1,
    texts.tolerances
  )
  setCriterionColumn(
    startRow + 1,
    sheet,
    [
      `${getValue(meanDistanceContracteeCriterion)}${meanDistanceCriterionUnit}`,
      `${getValue(meanDistanceContractorCriterion)}${meanDistanceCriterionUnit}`,
    ],
    '',
    2,
    texts.tolerances
  )
  setCriterionColumn(
    startRow + 1,
    sheet,
    [
      `${getValue(coverDistanceContracteeCriterion)}${coverDistanceCriterionUnit}`,
      `${getValue(coverDistanceContractorCriterion)}${coverDistanceCriterionUnit}`,
    ],
    '',
    3,
    texts.tolerances
  )
}

/**
 * set header values of inspection items to the worksheet.
 *
 * @param {number} startRow row number to start with.
 * @param {Worksheet} sheet Worksheet object to set values
 * @param {string[][]} headers headers to be set
 * @param {number} index index of the inspection value (ex: 0 is for diameter)
 */
const setValueHeader = (startRow: number, sheet: Worksheet, headers: string[][], index: number) => {
  const columnSpace = headers.length
  const startColumn = 2 + index * 3 // 1st column is for 測点又は区別
  const endColumn = startColumn + columnSpace - 1

  headers.forEach((header, headerIndex) => {
    const [title, unit] = header

    sheet.getRow(startRow).getCell(startColumn + headerIndex).value = title
    sheet.getRow(startRow).getCell(startColumn + headerIndex).alignment = { horizontal: 'center' }
    sheet.getRow(startRow + 1).getCell(startColumn + headerIndex).value = unit.length ? `（${unit}）` : `（-）`
    sheet.getRow(startRow + 1).getCell(startColumn + headerIndex).alignment = { horizontal: 'center' }
  })

  createOuterBorder(sheet, [startColumn, startRow], [endColumn, startRow + 1], 'thin')
}

/**
 * set values of inspection items to each inspection value (ex: diameter) the worksheet.
 *
 * @param {number} startRow row number to start with.
 * @param {Worksheet} sheet Worksheet object to set values
 * @param {string[]} values values to be set
 * @param {string[]} colors colors of the values
 * @param {number} index index of the inspection value (ex: 0 is for diameter)
 */
const setValueRow = (startRow: number, sheet: Worksheet, values: string[], colors: string[], index: number) => {
  const startColumn = 2 + index * 3 // 1st column is for 測点又は区別

  values.forEach((value, valueIndex) => {
    sheet.getRow(startRow).getCell(startColumn + valueIndex).value = value
    sheet.getRow(startRow).getCell(startColumn + valueIndex).alignment = { horizontal: 'center' }
  })
  colors.forEach((color, colorIndex) => {
    sheet.getRow(startRow).getCell(startColumn + colorIndex).font = { color: { argb: getColor(color) } }
  })

  const cell = sheet.getRow(startRow).getCell(startColumn)
  cell.border = {
    ...cell.border,
    left: {
      style: 'thin',
      color: BORDER_COLOR,
    },
  }
}

/**
 * set values of inspection items to the worksheet.
 *
 * @param {Worksheet} sheet Worksheet object
 * @param {InspectionItem[]} inspectionItems Inspection Items whose values are to be set
 * @param {InspectionItemEvaluationCollection[]} itemResults Judgment results of inspection items
 * @param {TextsInspectionSheet} texts Texts for the inspection sheet
 */
const setValues = (
  sheet: Worksheet,
  inspectionItems: InspectionItem[],
  itemResults: InspectionItemEvaluationCollection[],
  texts: TextsInspectionSheet
) => {
  const startRow = 12 // Profile rows is 6 + 1 row as space + 4 Criterion header rows

  sheet.getRow(startRow + 1).getCell(1).value = texts.item
  createOuterBorder(sheet, [1, startRow + 1], [1, startRow + 1], 'thin')

  const commonHeaders = [
    [texts.values.specification, 'mm'],
    [texts.values.actual, 'mm'],
    [texts.values.difference, 'mm'],
  ]
  setValueHeader(startRow, sheet, commonHeaders, 0)
  setValueHeader(
    startRow,
    sheet,
    [
      [texts.values.specification, texts.unit_rebar],
      [texts.values.actual, texts.unit_rebar],
      [texts.values.difference, texts.unit_rebar],
    ],
    1
  )
  setValueHeader(startRow, sheet, commonHeaders, 2)
  setValueHeader(startRow, sheet, commonHeaders, 3)
  setValueHeader(
    startRow,
    sheet,
    [
      [texts.result.result, texts.tolerances.tolerance],
      [texts.result.result, texts.tolerances.tolerance_internal],
    ],
    4
  )

  inspectionItems.forEach((item, itemIndex) => {
    const diameter = scaleUpValues(item.diameter, null, null)
    const mean_distance = scaleUpValues(item.mean_distance, null, null)
    const cover_distance = scaleUpValues(item.cover_distance, null, null)

    sheet.getRow(startRow + 2 + itemIndex).getCell(1).value = item.part_name

    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        findDiameterKeyByValue(diameter.specified_value || undefined) || '-',
        findDiameterKeyByValue(diameter.estimated_value || undefined) || '-',
        formatInspectionDifferentValue(item.diameter, true, true),
      ],
      ['black', 'black', itemResults[itemIndex].diameter.color || 'black'],
      0
    )
    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        getValue(item.number.specified_value),
        getValue(item.number.estimated_value),
        formatInspectionDifferentValue(item.number, false, true),
      ],
      ['black', 'black', itemResults[itemIndex].number.color || 'black'],
      1
    )
    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        getValue(mean_distance.specified_value),
        getValue(mean_distance.estimated_value),
        formatInspectionDifferentValue(item.mean_distance, true, true),
      ],
      ['black', 'black', itemResults[itemIndex].meanDistance.color || 'black'],
      2
    )
    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        getValue(cover_distance.specified_value),
        getValue(cover_distance.estimated_value),
        formatInspectionDifferentValue(item.cover_distance, true, true),
      ],
      ['black', 'black', itemResults[itemIndex].coverDistance.color || 'black'],
      3
    )
    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        itemResults[itemIndex].overall.qualifiedByContractee ? texts.result.ok : texts.result.ng,
        itemResults[itemIndex].overall.qualifiedByContractor ? texts.result.ok : texts.result.ng,
      ],
      [
        itemResults[itemIndex].overall.qualifiedByContractee ? 'black' : INSPECTION_CONTRACTEE_ERROR_COLOR,
        itemResults[itemIndex].overall.qualifiedByContractor ? 'black' : INSPECTION_CONTRACTOR_ERROR_COLOR,
      ],
      4
    )
  })

  if (inspectionItems.length) {
    createOuterBorder(sheet, [1, startRow + 2], [1 + 3 * 4 + 2, startRow + 2 + inspectionItems.length - 1], 'thin')
  }
}

/**
 * save the workbook object as a xlsx file with the given file name.
 *
 * @param {Workbook} workbook object to be saved as a file
 * @param {string} fileName name of the file to be saved
 * @returns {Promise<boolean>} true if the file is saved successfully
 */
export const saveFile = (workbook: Workbook, fileName: string): Promise<boolean> =>
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, fileName)
    return true
  })

/**
 * Create an XLSX exported file of an inspection sheet. Will immediately
 * forces the generated file to be saved by the user.
 *
 * @param {string} name Name of worksheet
 * @param {InspectionSheet} inspectionSheet Inspection Sheet
 * @param {InspectionItem[]} inspectionItems Inspection Items
 * @param {InspectionItemEvaluationCollection[]} itemResults flag to specify contractee/contractor
 * @param {Project | undefined} project inspection area
 * @param {string} meanDistanceCriterionUnit unit of mean distance criterion
 * @param {string} coverDistanceCriterionUnit unit of cover distance criterion
 * @param {number | undefined} meanDistanceContracteeCriterion mean distance criterion for contractee
 * @param {number | undefined} meanDistanceContractorCriterion mean distance criterion for contractor
 * @param {number | undefined} coverDistanceContracteeCriterion cover distance criterion for contractee
 * @param {number | undefined} coverDistanceContractorCriterion cover distance criterion for contractor
 * @param {TextsInspectionSheet} texts Texts for the inspection sheet
 * @returns {Promise<boolean>} true if the file is saved successfully
 */
export const generateXLSX = async (
  name: string,
  inspectionSheet: InspectionSheet,
  inspectionItems: InspectionItem[],
  itemResults: InspectionItemEvaluationCollection[],
  project: Project | undefined,
  meanDistanceCriterionUnit: string,
  coverDistanceCriterionUnit: string,
  meanDistanceContracteeCriterion: number | undefined,
  meanDistanceContractorCriterion: number | undefined,
  coverDistanceContracteeCriterion: number | undefined,
  coverDistanceContractorCriterion: number | undefined,
  texts: TextsInspectionSheet
): Promise<boolean> => {
  const workbook = newWorkbook()
  const sheet = workbook.addWorksheet(name, { pageSetup: { orientation: 'landscape', paperSize: 9 } })

  setProfileGroup(sheet, project?.project_name || '', inspectionSheet, texts.sheet_property)
  setCriterionData(
    sheet,
    inspectionSheet,
    meanDistanceCriterionUnit,
    coverDistanceCriterionUnit,
    meanDistanceContracteeCriterion,
    meanDistanceContractorCriterion,
    coverDistanceContracteeCriterion,
    coverDistanceContractorCriterion,
    texts
  )
  setValues(sheet, inspectionItems, itemResults, texts)

  // Re-size the column to fix the text
  sheet.columns.forEach((column) => {
    let maxLength = 0
    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
    }
    column.width = maxLength < 10 ? 10 : maxLength + 2
  })

  await saveFile(workbook, name)

  // track event to mixpanel
  mixpanel.track('Export inspection sheet', {
    'Inspection area ID': project?.project_id,
    'File format': 'xlsx',
    'Criterion unit (mean distance)': meanDistanceCriterionUnit,
    'Criterion unit (cover distance)': coverDistanceCriterionUnit,
  })

  return true
}
