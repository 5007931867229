// eslint-disable-next-line no-restricted-imports
import axios from 'axios'

export const ERROR_PROCESS_MESSAGE: Record<string, { key: string; name: string; messages: Record<number, string> }> = {
  ACTIVATE_ADDITIONAL_FEATURE: { key: 'ACTIVATE_ADDITIONAL_FEATURE', name: '有償機能利用', messages: {} },
  ADD_BLUEPRINT: { key: 'ADD_BLUEPRINT', name: '設計図面追加', messages: {} },
  ADD_IFC: { key: 'ADD_IFC', name: 'IFCファイル追加', messages: {} },
  CREATE_COMMENT: {
    key: 'CREATE_COMMENT',
    name: 'コメント作成',
    messages: {
      40001:
        'アップロードされた画像の拡張子はサポートされていません。\n.jpg, .jpeg, .png, .svg拡張子の画像で再度試してください。',
      40007: 'プロジェクト内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  CREATE_COMMENT_REPLY: {
    key: 'CREATE_COMMENT_REPLY',
    name: 'コメント返信作成',
    messages: {
      40007: 'プロジェクト内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  CREATE_MASK_REGION: {
    key: 'CREATE_MASK_REGION',
    name: '注目領域作成',
    messages: {},
  },
  CREATE_PROJECT: {
    key: 'CREATE_PROJECT',
    name: '検査箇所作成',
    messages: {
      40002: '点群ファイルが壊れています。',
      40004:
        '点群の物理的な大きさが限界値を超えています。\nトリミングなどを行い、点群の大きさを下げて再度試してください。',
      40006: '点群の座標値が大きすぎます。\ncentering処理を行い、再度試してください。',
      40081: '点の数が少なすぎます。',
      42201: 'ファイル名が不正です。\nスペースや@などの特殊文字を取り除いて、再度試してください。',
    },
  },
  CREATE_PROJECT_GROUP: {
    key: 'CREATE_PROJECT_GROUP',
    name: 'プロジェクト作成',
    messages: {},
  },
  CREATE_SHAPE_GROUP: {
    key: 'CREATE_SHAPE_GROUP',
    name: '種別作成',
    messages: {},
  },
  CREATE_USER_INFO: { key: 'CREATE_USER_INFO', name: 'ユーザー情報作成', messages: {} },
  DELETE_BLUEPRINT: { key: 'DELETE_BLUEPRINT', name: '設計図面削除', messages: {} },
  DELETE_COMMENT: { key: 'DELETE_COMMENT', name: 'コメント削除', messages: {} },
  DELETE_COMMENT_REPLY: { key: 'DELETE_COMMENT_REPLY', name: 'コメント返信削除', messages: {} },
  DELETE_DISTANCE: { key: 'DELETE_DISTANCE', name: '距離削除', messages: {} },
  DELETE_IFC: { key: 'DELETE_IFC', name: 'IFCファイル削除', messages: {} },
  DELETE_INVITED_USER_FROM_PROJECT_GROUP: {
    key: 'DELETE_INVITED_USER_FROM_PROJECT_GROUP',
    name: '関係者招待の削除',
    messages: {},
  },
  DELETE_MASK_REGION: {
    key: 'DELETE_MASK_REGION',
    name: '注目領域削除',
    messages: {},
  },
  DELETE_OVERLAP: {
    key: 'DELETE_OVERLAP',
    name: '継手長削除',
    messages: {},
  },
  DELETE_PROJECT: { key: 'DELETE_PROJECT', name: '検査箇所削除', messages: {} },
  DELETE_PROJECT_GROUP: { key: 'DELETE_PROJECT_GROUP', name: 'プロジェクト削除', messages: {} },
  DELETE_SHAPES: { key: 'DELETE_SHAPES', name: '鉄筋削除', messages: {} },
  DELETE_SHAPE_GROUP: { key: 'DELETE_SHAPE_GROUP', name: '種別削除', messages: {} },
  DETECT_SHAPES: {
    key: 'DETECT_SHAPES',
    name: '鉄筋検出',
    messages: {
      40081: '点の数が少なすぎます。',
    },
  },
  DOWNLOAD_CAD_FILE: {
    key: 'DOWNLOAD_CAD_FILE',
    name: 'CADファイル生成',
    messages: {
      42201: 'モデルの数が上限を超えています。不要なものを削除してやり直してください。',
    },
  },
  DOWNLOAD_XML_FILE: {
    key: 'DOWNLOAD_XML_FILE',
    name: 'XMLファイル生成',
    messages: {},
  },
  EVALUATE_COVER_DISTANCE: {
    key: 'EVALUATE_COVER_DISTANCE',
    name: '最小かぶり評価',
    messages: {
      40081: '鉄筋が型枠と干渉しています。',
    },
  },
  EVALUATE_MEAN_DISTANCE: {
    key: 'EVALUATE_MEAN_DISTANCE',
    name: '平均間隔評価',
    messages: {
      40081: '鉄筋の位置・向きが揃っていません。鉄筋の選択が適切であることを確認してください。',
    },
  },
  EVALUATE_OVERLAP: {
    key: 'EVALUATE_OVERLAP',
    name: '継手長計算',
    messages: {
      40081: '鉄筋の向きが揃っていません。鉄筋の選択が適切であることを確認してください。',
      40082: '鉄筋が遠すぎます。鉄筋の選択が適切であることを確認してください。',
      40083: '鉄筋が重なっていません。鉄筋の選択が適切であることを確認してください。',
    },
  },
  EVALUATE_SPACER: {
    key: 'EVALUATE_SPACER',
    name: 'スペーサー格子計算',
    messages: {
      40081: 'この4点からは長方形もひし形も作成できません。適切な4点を選択してやり直してください。',
    },
  },
  GET_ACCESS_TOKEN: { key: 'GET_ACCESS_TOKEN', name: 'ユーザー情報の確認', messages: {} },
  GET_BILLING_RECORDS: {
    key: 'GET_BILLING_RECORDS',
    name: '請求記録取得',
    messages: {},
  },
  GET_BLUEPRINTS: { key: 'GET_BLUEPRINTS', name: '設計図面取得', messages: {} },
  GET_COMMENT_REPLIES: { key: 'GET_COMMENT_REPLIES', name: 'コメント返信取得', messages: {} },
  GET_COMMENTS: { key: 'GET_COMMENTS', name: 'コメント取得', messages: {} },
  GET_DISTANCES: { key: 'GET_DISTANCES', name: '距離取得', messages: {} },
  GET_DOWN_SAMPLED_FILE: { key: 'GET_DOWN_SAMPLED_FILE', name: '点群データ取得', messages: {} },
  GET_IFC_FILES: { key: 'GET_IFC_FILES', name: 'IFCファイル取得', messages: {} },
  GET_INSPECTION_SHEET: { key: 'GET_INSPECTION_SHEET', name: '帳票取得', messages: {} },
  GET_MASK_REGION: {
    key: 'GET_MASK_REGION',
    name: '注目領域取得',
    messages: {},
  },
  GET_OVERLAPS: { key: 'GET_OVERLAPS', name: '継手長取得', messages: {} },
  GET_PROJECT_GROUPS: { key: 'GET_PROJECT_GROUPS', name: 'プロジェクト取得', messages: {} },
  GET_SERVICE_STATUS: { key: 'GET_SERVICE_STATUS', name: 'サービス状況取得', messages: {} },
  GET_SHAPES: { key: 'GET_SHAPES', name: '鉄筋取得', messages: {} },
  GET_SHAPE_GROUPS: { key: 'GET_SHAPE_GROUPS', name: '種別取得', messages: {} },
  GET_SUBSCRIPTIONS: { key: 'GET_SUBSCRIPTIONS', name: '利用状況取得', messages: {} },
  GET_USER: {
    key: 'GET_USER',
    name: 'ユーザ取得',
    messages: {
      40401: '40401', // Because the message style is quite complex, manually handle it in the component
    },
  },
  INVITE_USER: {
    key: 'INVITE_USER',
    name: '関係者招待',
    messages: {
      40004: '招待したユーザは既にModelyのアカウントを持っています。\nプロジェクトへの招待を行うことができます。',
    },
  },
  INVITE_USER_TO_PROJECT_GROUP: {
    key: 'INVITE_USER_TO_PROJECT_GROUP',
    name: '関係者招待',
    messages: {
      40002: '自分自身を招待する事はできません。',
      40006: '指定されたユーザはすでに招待されています。',
      40402: '招待されたユーザのアカウントが作成されていません。\nまずアカウント作成の招待を行ってください。',
    },
  },
  IMPORT_XML: {
    key: 'IMPORT_XML',
    name: 'XMLファイルアップロード',
    messages: {},
  },
  MODIFY_INSPECTION_SHEET: { key: 'MODIFY_INSPECTION_SHEET', name: '帳票処理', messages: {} },
  REGISTER_USER: { key: 'REGISTER_USER', name: '新規登録', messages: {} },
  SAVE_DISTANCES: { key: 'SAVE_DISTANCES', name: '距離保存', messages: {} },
  SAVE_OVERLAPS: { key: 'SAVE_OVERLAPS', name: '継手長保存', messages: {} },
  SAVE_SHAPES: { key: 'SAVE_SHAPES', name: '鉄筋保存', messages: {} },
  UPDATE_COMMENT: {
    key: 'UPDATE_COMMENT',
    name: 'コメント変更',
    messages: {
      40007: 'プロジェクト内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  UPDATE_COMMENT_REPLY: {
    key: 'UPDATE_COMMENT_REPLY',
    name: 'コメント返信変更',
    messages: {
      40007: 'プロジェクト内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  UPDATE_DISTANCE: { key: 'UPDATE_DISTANCE', name: '距離変更', messages: {} },
  UPDATE_PROJECT_GROUP: { key: 'UPDATE_PROJECT_GROUP', name: 'プロジェクト変更', messages: {} },
  UPDATE_SHAPE_GROUP: { key: 'UPDATE_SHAPE_GROUP', name: '種別変更', messages: {} },
  UPDATE_USER: { key: 'UPDATE_USER', name: 'ユーザー情報変更', messages: {} },
  UPLOAD_FILE: { key: 'UPLOAD_FILE', name: 'ファイルアップロード', messages: {} },
} as const

export const ERROR_PROCESS: Record<keyof typeof ERROR_PROCESS_MESSAGE, keyof typeof ERROR_PROCESS_MESSAGE> =
  Object.keys(ERROR_PROCESS_MESSAGE).reduce((processes, key) => ({ ...processes, [key]: key }), {})

const COMMON_MESSAGE = 'エラーが発生しました。\n時間をおいて、再度実行してください。'

export const getCommonErrorMessage = (processName: keyof typeof ERROR_PROCESS): string =>
  `${ERROR_PROCESS_MESSAGE[processName].name}でエラーが発生しました。\n時間をおいて、再度実行してください。`

const AXIOS_ERROR_CODE: Record<string, string> = {
  ERR_BAD_OPTION_VALUE: '00',
  ERR_BAD_OPTION: '01',
  ECONNABORTED: '02',
  ETIMEDOUT: '03',
  ERR_NETWORK: '04',
  ERR_FR_TOO_MANY_REDIRECTS: '05',
  ERR_DEPRECATED: '06',
  ERR_BAD_RESPONSE: '07',
  ERR_BAD_REQUEST: '08',
  ERR_CANCELED: '09',
  ERR_NOT_SUPPORT: '10',
  ERR_INVALID_URL: '11',
}

//*
/**
 * axiosやs3などのtry-catchされたエラーのハンドリングを実施する
 * @param {Error} err Errorオブジェクト
 * @param {string} processName 処理名
 */
export const processErrorHandler = (
  err: unknown,
  processName: keyof typeof ERROR_PROCESS,
  showErrorModal: (message: string) => void
) => {
  //* エラーの種類によって、エラーメッセージを設定
  let alertMessage = `不明な${COMMON_MESSAGE}`
  if (axios.isAxiosError(err)) {
    if (err.response) {
      alertMessage = getCommonErrorMessage(processName)

      const statusCode = err.response.status
      if (statusCode === 400) alertMessage = '不正なリクエストです。'
      if (statusCode === 403) alertMessage = '不正なリクエストです。'
      if (statusCode === 404) alertMessage = 'データが存在しません。'

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const responseData: {
        status_id: number
        status_message?: string
      } = err.response.data

      let statusId: number | undefined = responseData?.status_id
      if (responseData?.status_message && responseData.status_message.includes('status_id')) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          statusId = JSON.parse(responseData.status_message)?.status_id
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }

      // Detailed message
      let messages = ''
      if (statusId === 50503) {
        messages = 'ただいま、メンテナンス中です。メンテナンス終了までしばらくお待ちください。'
      } else if (statusId === 40302) {
        alertMessage = 'この検査箇所へのアクセス権限がありません。'
      } else if (statusId) {
        messages = ERROR_PROCESS_MESSAGE[processName].messages[statusId] || ''
      }

      if (messages) {
        alertMessage = messages
      }
    } else if (err.code) {
      // Network errors
      const errorCode = AXIOS_ERROR_CODE[err.code] || ''
      if (errorCode) {
        alertMessage = `ネットワークエラーが発生しました。\nネットワーク環境が良い場所に移動して再度試してください。\n\n（エラーコード：${errorCode}）`
      }
    }
  }

  //* エラーを表示
  showErrorModal(alertMessage)
}
