import { FC, useContext, useState } from 'react'

import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { ChromePicker, ColorResult } from 'react-color'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { ResetIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import {
  COOKIE_EXPIRE,
  EDITOR_BACKGROUND_COOKIE_NAME,
  EDITOR_COLLAPSE_TYPES,
  EDITOR_POINT_SIZE_INTERVAL,
  getPrecision,
} from 'config/constants'
import { EDITOR_DEFAULT_BACKGROUND, INFO_PANEL_PADDING, INPUT_GROUP_STYLES } from 'config/styles'

import { setDiameterLabelVisible } from '../store/editor'
import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'
import RangeInput from './components/RangeInput'

const SettingPanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()
  const {
    isPointCloudInvisible,
    updatePointCloudVisibility,
    pointSize,
    changePointSize,
    shapesDistancesVisible,
    updateShapesDistancesVisibility,
  } = useContext(EditorContext)

  const [cookies, setCookie] = useCookies([EDITOR_BACKGROUND_COOKIE_NAME])
  const backgroundColor =
    (cookies as Record<string, string>)[EDITOR_BACKGROUND_COOKIE_NAME] || EDITOR_DEFAULT_BACKGROUND

  const dispatch = useAppDispatch()
  const diameterLabelVisible = useSelector((state: RootState) => state.editor.diameterLabelVisible)

  const [isManualEditingPointSize, setIsManualEditingPointSize] = useState(false)

  const SettingLayers = (
    <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1}>
      <HStack pl={2} pr={1} spacing={0} w="100%">
        {!isManualEditingPointSize && (
          <Text flex={1} whiteSpace="nowrap">
            {t('main_canvas.panels.setting.point_size', { ns: 'projects' })}
          </Text>
        )}
        {pointSize !== undefined && (
          <Box {...INPUT_GROUP_STYLES} height={5}>
            <RangeInput
              isSmall
              key="pointSize"
              min={EDITOR_POINT_SIZE_INTERVAL}
              step={EDITOR_POINT_SIZE_INTERVAL}
              onManualEdit={setIsManualEditingPointSize}
              precision={getPrecision(EDITOR_POINT_SIZE_INTERVAL)}
              value={pointSize}
              updateValue={(newValue) => {
                if (newValue) {
                  changePointSize(newValue)
                }
              }}
            />
          </Box>
        )}
        <IconButton
          aria-label="reset"
          fontSize="sm"
          icon={<ResetIcon />}
          onClick={(e) => {
            e.stopPropagation()
            changePointSize(undefined)
          }}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>
      <HStack pl={2} pr={1} spacing={0} w="100%">
        <Text mr={9}> {t('main_canvas.panels.setting.background_color', { ns: 'projects' })}</Text>
        <Spacer />
        <Popover matchWidth>
          <PopoverTrigger>
            <Button
              variant="outline"
              maxWidth={6}
              minWidth={6}
              width={6}
              height={5}
              p={0}
              backgroundColor={backgroundColor}
              borderColor="secondary.700"
              _hover={{ bg: backgroundColor }}
              _focus={{ bg: backgroundColor }}
              _active={{ bg: backgroundColor }}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="auto">
              <ChromePicker
                disableAlpha
                color={backgroundColor}
                onChangeComplete={(color: ColorResult) =>
                  setCookie(EDITOR_BACKGROUND_COOKIE_NAME, color.hex, { expires: COOKIE_EXPIRE })
                }
              />
            </PopoverContent>
          </Portal>
        </Popover>
        <IconButton
          aria-label="reset"
          fontSize="sm"
          icon={<ResetIcon />}
          onClick={(e) => {
            e.stopPropagation()
            setCookie(EDITOR_BACKGROUND_COOKIE_NAME, EDITOR_DEFAULT_BACKGROUND, { expires: COOKIE_EXPIRE })
          }}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>
      <LayerItem
        invisible={isPointCloudInvisible}
        label={t('main_canvas.panels.setting.pcd', { ns: 'projects' })}
        updateVisibility={updatePointCloudVisibility}
      />
      <LayerItem
        invisible={!diameterLabelVisible}
        label={t('main_canvas.panels.setting.show_diameter', { ns: 'projects' })}
        updateVisibility={(invisible) => {
          dispatch(setDiameterLabelVisible(!invisible))
          // track with mixpanel
          mixpanel.track('Change visibility of diameter', {
            'Inspection area ID': project_id,
            'Visibility (new)': !invisible,
            'Visibility (old)': invisible,
          })
        }}
      />
      <LayerItem
        invisible={!shapesDistancesVisible}
        label={t('main_canvas.panels.setting.show_spacing', { ns: 'projects' })}
        updateVisibility={(invisible) => updateShapesDistancesVisibility(!invisible)}
      />
    </VStack>
  )

  return (
    <Box backgroundColor="gray.800" borderBottomLeftRadius="md" w="100%">
      <CollapsePanel
        title={t('main_canvas.panels.setting.title', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.settings}
      >
        {SettingLayers}
      </CollapsePanel>
    </Box>
  )
}

export default SettingPanel
