import { FC, useContext } from 'react'

import { Button, Container, Flex, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import BackgroundImage from 'assets/imgs/landing-page.png'

import { UserContext } from 'contexts/Users'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

const LandingPage: FC = () => {
  const { userLoaded } = useContext(UserContext)
  const { t } = useTranslation(['landing_page'])

  return (
    <VStack h="100%">
      <Navbar isInverted />

      <Container flex="1" maxW={CONTAINER_MAX_WIDTH}>
        <Flex pos="relative" h="100%">
          <Image src={BackgroundImage} pos="absolute" right="0" top="5%" w="80%" />
          <VStack flex="1" pos="relative" align="left" color="secondary.600">
            <VStack w={{ sm: '50%', xl: '55%' }} flex="1" pt="10%" align="left">
              <Heading as="h2" size="3xl" lineHeight={1.2}>
                <Trans i18nKey="heading" ns="landing_page" />
              </Heading>
              <HStack>
                <Button as={RouterLink} to={userLoaded ? '/dashboard' : '/register'} colorScheme="primary" mr="6">
                  {t('get_started', { ns: 'landing_page' })}
                </Button>
              </HStack>
            </VStack>
            <Text fontSize="xs" py="12">
              Copyright 2022
            </Text>
          </VStack>
        </Flex>
      </Container>
    </VStack>
  )
}

export default LandingPage
