import { useState } from 'react'

import { useFrame } from '@react-three/fiber'
import { Camera, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import { EDITOR_BASE_LINE_THICKNESS } from 'config/constants'

export default function useMeshOutline(arcballControls: ArcballControls | null) {
  const [outlineThickness, setOutlineThickness] = useState(EDITOR_BASE_LINE_THICKNESS)

  useFrame(() => {
    setOutlineThickness(
      Math.max(
        arcballControls
          ? // eslint-disable-next-line dot-notation
            (arcballControls['target'] as Vector3).distanceTo((arcballControls['camera'] as Camera).position) *
              EDITOR_BASE_LINE_THICKNESS
          : EDITOR_BASE_LINE_THICKNESS,
        EDITOR_BASE_LINE_THICKNESS
      )
    )
  })

  return { outlineThickness }
}
