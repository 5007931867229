import { FC } from 'react'

import { Button, Flex, HStack, IconButton, Square, StackDivider, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { BackIcon, CubeIcon, GridIcon, UniteIcon } from 'assets/icons'

import Toolbar from '../blueprint/toolbar/Toolbar'

export const NAV_TYPES = {
  EDITOR: 'editor',
  BLUEPRINT: 'blueprint',
  IFC_REGISTER: 'ifc-register',
}

const TopNav: FC<{ type: typeof NAV_TYPES[keyof typeof NAV_TYPES]; isOwner: boolean }> = ({ type, isOwner }) => {
  const buttonSize = isTablet ? 16 : 8
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()
  if (!project_id) {
    return null
  }

  return (
    <HStack left={1} top={1} position="absolute" h={buttonSize}>
      {isTablet ? (
        <IconButton
          aria-label="back"
          as={RouterLink}
          colorScheme="secondary"
          icon={<BackIcon size="50%" />}
          to="/dashboard"
          w={buttonSize}
          variant="toolbar"
          h="100%"
        />
      ) : (
        <Button
          as={RouterLink}
          colorScheme="secondary"
          leftIcon={<BackIcon />}
          to="/dashboard"
          size="xs"
          variant="toolbar"
          h="100%"
        >
          {t('components.top_nav.project', { ns: 'projects' })}
        </Button>
      )}
      <HStack
        spacing={0}
        backgroundColor="gray.800"
        overflow="hidden"
        borderRadius="md"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        h={buttonSize}
      >
        <Tooltip
          hasArrow
          placement="bottom"
          label={t('components.top_nav.move_to_3d_scene', { ns: 'projects' })}
          p={2}
          fontSize="xs"
          fontWeight="normal"
          isDisabled={isTablet}
        >
          <Flex>
            <Square
              as={RouterLink}
              position="relative"
              color="white"
              fontSize={isTablet ? 'xl' : 'md'}
              size={buttonSize}
              to={`/projects/${project_id}`}
              backgroundColor={type === NAV_TYPES.EDITOR ? 'gray.600' : 'transparent'}
              data-testid="move-main-canvas"
            >
              <CubeIcon size="50%" />
            </Square>
          </Flex>
        </Tooltip>
        <Tooltip
          hasArrow
          placement="bottom"
          label={t('components.top_nav.move_to_blueprint', { ns: 'projects' })}
          p={2}
          fontSize="xs"
          fontWeight="normal"
          isDisabled={isTablet}
        >
          <Flex>
            <Square
              as={RouterLink}
              position="relative"
              color="white"
              fontSize={isTablet ? 'xl' : 'md'}
              size={buttonSize}
              to={`/projects/${project_id}/blueprint`}
              backgroundColor={type === NAV_TYPES.BLUEPRINT ? 'gray.600' : 'transparent'}
              data-testid="move-blueprint"
            >
              <GridIcon size="50%" />
            </Square>
          </Flex>
        </Tooltip>
        {isOwner && (
          <Tooltip
            hasArrow
            placement="bottom"
            label={t('components.top_nav.move_to_ifc_register', { ns: 'projects' })}
            p={2}
            fontSize="xs"
            fontWeight="normal"
            isDisabled={isTablet}
          >
            <Flex>
              <Square
                as={RouterLink}
                position="relative"
                color="white"
                fontSize={isTablet ? 'xl' : 'md'}
                size={buttonSize}
                to={`/projects/${project_id}/ifc-register`}
                backgroundColor={type === NAV_TYPES.IFC_REGISTER ? 'gray.600' : 'transparent'}
                data-testid="move-ifc-register"
              >
                <UniteIcon size="50%" />
              </Square>
            </Flex>
          </Tooltip>
        )}
      </HStack>
      {type === NAV_TYPES.BLUEPRINT && <Toolbar />}
    </HStack>
  )
}

export default TopNav
