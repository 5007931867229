import { FC, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import CylindersOnArcImage from 'assets/imgs/cylinders_on_arc.png'
import CylindersOnAxisImage from 'assets/imgs/cylinders_on_axis.png'
import ToriOnAxisImage from 'assets/imgs/tori_on_axis.png'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_SHAPES_SITUATIONS, EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'

import { Cylinder, InspectionShapeIds, Plane, Shapes, ShapesDistances, Torus } from 'interfaces/interfaces'

import { getCoverDistanceAndIndividualDistance, getMeanDistanceAndIndividualDistance } from 'services/InspectionSheet'

const SITUATIONS_IMAGES = {
  [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC]: CylindersOnArcImage,
  [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS]: CylindersOnAxisImage,
  [EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS]: ToriOnAxisImage,
}

const SituationsModal: FC<{
  shapes: Shapes
  isOpen: boolean
  onClose: () => void
  onConfirm: (
    situation: string,
    meanDistance: number,
    diameter: number,
    tekkinNumber: number,
    shapeIds: InspectionShapeIds,
    coverDistance?: number,
    distances?: ShapesDistances,
    permutationMap?: number[]
  ) => void
}> = ({ shapes, isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation(['projects'])
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { selectedShapeIds } = useContext(EditorContext)

  const [isLoading, setIsLoading] = useState(false)
  const [situationShapes, setSituationShapes] = useState<{ [key: string]: Cylinder[] | Torus[] }>()
  const [situationShapeIds, setSituationShapeIds] = useState<{ [key: string]: InspectionShapeIds }>()
  const [situationPlanes, setSituationPlanes] = useState<Plane[]>([])
  const [isSituationNotClear, setIsSituationNotClear] = useState(false)

  useEffect(() => {
    const filteredSelectedShapeIds = selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) || []
    const cylinders = shapes.cylinders.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
    const cylinderIds = cylinders.map((s) => s.shape_id)
    const tori = shapes.tori.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
    const toriIds = tori.map((s) => s.shape_id)
    const planes = shapes.planes.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
    const planeIds = planes.map((s) => s.shape_id)

    setSituationShapes({
      [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC]: cylinders,
      [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS]: cylinders,
      [EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS]: tori,
    })
    setSituationShapeIds({
      [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC]: {
        cylinders: cylinderIds,
        planes: planeIds,
        tori: [],
      },
      [EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS]: {
        cylinders: cylinderIds,
        planes: planeIds,
        tori: [],
      },
      [EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS]: {
        cylinders: [],
        planes: planeIds,
        tori: toriIds,
      },
    })

    setSituationPlanes(planes)
    setIsSituationNotClear((!!cylinders.length && !!tori.length) || (!!planes.length && !!tori.length))
  }, [shapes, selectedShapeIds])

  const confirmSituation = async (situation: string) => {
    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    if (!situationShapes?.[situation].length) {
      showErrorModal(t('main_canvas.inspection_sheet.situation_modal.message_invalid_number', { ns: 'projects' }))
      return false
    }

    const diameters = situationShapes?.[situation].map((s) => {
      if ('diameter' in s) {
        return s.diameter
      }
      return s.minor_diameter
    })

    const meanDistance = await getMeanDistanceAndIndividualDistance(
      token,
      situation,
      situationShapes[situation],
      showErrorModal
    )
    const coverDistance =
      (situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC ||
        situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS) &&
      situationPlanes.length === 1
        ? await getCoverDistanceAndIndividualDistance(
            token,
            situationShapes[situation] as Cylinder[],
            situationPlanes[0],
            showErrorModal
          )
        : undefined

    setIsLoading(false)

    if (!meanDistance || coverDistance === null || !situationShapeIds?.[situation]) {
      return false
    }

    const shapesDistances: ShapesDistances = INITIAL_SHAPE_STATE()
    if (
      situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC ||
      situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS
    ) {
      shapesDistances.cylinders = meanDistance.individual_distance
    } else if (situation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS) {
      shapesDistances.tori = meanDistance.individual_distance
    }
    if (coverDistance !== undefined) {
      shapesDistances.planes = coverDistance.individual_distance
    }

    onConfirm(
      situation,
      meanDistance.mean_distance,
      diameters[0],
      situationShapes[situation].length,
      situationShapeIds[situation],
      coverDistance?.cover_distance,
      shapesDistances,
      meanDistance.permutation_map
    )
    return true
  }

  const isSituationShapesInvalid = (situation: string) =>
    !situationShapes || situationShapes[situation].length < 2 || situationPlanes.length > 1

  const renderSituationButton = (situation: string) => {
    const texts: Record<string, string> = t('main_canvas.inspection_sheet.situation_modal', {
      ns: 'projects',
      returnObjects: true,
    })
    if (situationShapes?.[situation].length === 0) {
      return null
    }
    return (
      <Box position="relative" key={situation}>
        <Button
          onClick={() => confirmSituation(situation)}
          variant="outlinePrimary"
          color="secondary"
          borderRadius={30}
          height={180}
          disabled={isLoading || isSituationShapesInvalid(situation)}
        >
          <VStack>
            <Image src={SITUATIONS_IMAGES[situation]} height={130} />
            <Text>{texts[situation]}</Text>
          </VStack>
        </Button>
        {isSituationShapesInvalid(situation) && (
          <Center position="absolute" w="100%" h="100%" top={0} left={0} p={5} backgroundColor="whiteAlpha.700">
            {situationShapes && situationShapes[situation].length < 2 && (
              <Text fontWeight="bold" textAlign="center">
                {t('main_canvas.inspection_sheet.situation_modal.message_one_rebar', { ns: 'projects' })}
              </Text>
            )}
            {situationShapes && situationShapes[situation].length >= 2 && situationPlanes.length > 1 && (
              <Text fontWeight="bold">
                {t('main_canvas.inspection_sheet.situation_modal.message_multiple_planes', { ns: 'projects' })}
              </Text>
            )}
          </Center>
        )}
      </Box>
    )
  }

  return (
    <Modal closeOnOverlayClick={!isLoading} isOpen={isOpen} onClose={() => onClose()} trapFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody position="relative">
          {!isSituationNotClear && (
            <VStack height="100%" spacing={6} paddingTop={6}>
              {!situationShapes ||
                (!Object.keys(situationShapes).some((s) => situationShapes[s].length) && (
                  <Text fontWeight="bold" textAlign="center">
                    {t('main_canvas.inspection_sheet.situation_modal.message_no_rebars', { ns: 'projects' })}
                  </Text>
                ))}
              {Object.values(EDITOR_SHAPES_SITUATIONS).map((situation) => renderSituationButton(situation))}
            </VStack>
          )}
          {isLoading && (
            <Center position="absolute" width="100%" height="100%" top={0} left={0} backgroundColor="whiteAlpha.50">
              <Spinner w="64px" h="64px" thickness="4px" speed="0.65s" size="xl" colorScheme="primary" />
            </Center>
          )}
          {isSituationNotClear && (
            <Center p={10}>
              <Text fontWeight="bold" textAlign="center">
                {t('main_canvas.inspection_sheet.situation_modal.message_invalid_selection', { ns: 'projects' })}
              </Text>
            </Center>
          )}
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onClose()}>
            {t('main_canvas.inspection_sheet.situation_modal.cancel', { ns: 'projects' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SituationsModal
