import { FC, useEffect, useState } from 'react'

import { CylinderGeometry, Mesh, MeshPhongMaterial } from 'three'

const standardMaterial = new MeshPhongMaterial({
  color: 'yellow',
  transparent: true,
  opacity: 0.4,
  depthTest: false,
  depthWrite: false,
})

const DummyCylinderMesh: FC<{
  diameter: number
  length: number
}> = ({ diameter, length }) => {
  const [mesh, setMesh] = useState<Mesh>(new Mesh())

  useEffect(() => {
    const cylinderMesh = new Mesh()

    const geo = new CylinderGeometry(diameter / 2, diameter / 2, length, 64, 1, false, 0, Math.PI * 2)
    cylinderMesh.geometry = geo

    setMesh(cylinderMesh)
  }, [diameter, length])

  return (
    <mesh quaternion={mesh.quaternion} position={mesh.position} geometry={mesh.geometry} material={standardMaterial} />
  )
}

export default DummyCylinderMesh
