import { InputProps } from '@chakra-ui/react'

export const NAV_BAR_HEIGHT = 60
export const CYLINDER_PANEL_WIDTH = 250

export const TOAST_CONFIG = {
  title: '',
  status: 'success',
  duration: 3000,
  isClosable: true,
  position: 'top',
} as const

// default breakpoints
//   sm:  30em
//   md:  48em
//   lg:  62em
//   xl:  80em
//   2xl: 96em
export const CONTAINER_MAX_WIDTH = { lg: '58em', xl: '74em', '2xl': '89em' }

export const INFO_PANEL_PADDING = 2 // * 4px
export const INFO_PANEL_WIDTH = 40 // * 4px
export const LAYER_ITEM_STYLES = {
  borderColor: 'transparent',
  borderWidth: '1px',
  paddingLeft: INFO_PANEL_PADDING,
  paddingRight: INFO_PANEL_PADDING - 1.5,
  _hover: {
    borderColor: 'whiteAlpha.50',
  },
}
export const INPUT_GROUP_STYLES = {
  backgroundColor: 'gray.700',
  borderColor: 'whiteAlpha.200',
  borderRadius: 'md',
  borderWidth: '1px',
}

export const EDITOR_POINT_COLOR = 'red'
export const EDITOR_NEUTRAL_POINT_COLOR = 'gray'
export const EDITOR_FRAME_COLORS = {
  comments: '#2699FB',
  cylinders: 'red',
  tori: '#00FF00',
  planes: 'blue',
  distance: 'red',
  overlap: '#A94094',
  detectedCylindersDistance: 'red',
  detectedToriDistance: 'green',
  detectedPlanesDistance: 'blue',
  cuboid: 'red', // actually color is based on cylinder or torus
  pcdTrim: 'white',
  spacerAnnotation: '#FFbb00',
}
export const EDITOR_LABEL_BACKGROUND = {
  cylinders: '#FF6666',
  tori: '#44FF44',
}

export const EDITOR_ACTION_BUTTON_MIN_WIDTH = 106
export const EDITOR_ACTION_BUTTON_MAX_WIDTH = 282
export const EDITOR_DEFAULT_BACKGROUND = '#000'

export const INSPECTION_CONTRACTEE_ERROR_COLOR = 'red'
export const INSPECTION_CONTRACTOR_ERROR_COLOR = 'orange'
export const INSPECTION_NORMAL_COLOR = 'secondary'

export const BLUEPRINT_INPUT_STYLES: InputProps = {
  backgroundColor: 'transparent',
  borderColor: 'whiteAlpha.200',
  borderRadius: 'md',
  height: 6,
  px: 2,
  size: 'sm',
  textAlign: 'center',
  width: '0',
}

const NOTIFICATION_BOX_COMMON_STYLES = {
  target: '_blank',
  size: 'sm',
  fontSize: 'xs',
  outline: 'none',
  _focus: { boxShadow: 'none' },
}
export const NOTIFICATION_STYLES = {
  info: {
    box: { bgGradient: 'linear(to-r, cyan.700, purple.500)' },
    text: { color: 'whiteAlpha.900', fontWeight: 'semibold', align: 'center' as const },
    button: {
      bgColor: 'blackAlpha.300',
      _hover: { bgColor: 'blackAlpha.300', textDecoration: 'none' },
      _active: { bgColor: 'blackAlpha.300' },
      ...NOTIFICATION_BOX_COMMON_STYLES,
    },
  },
  warning: {
    box: { bgGradient: 'linear(to-r, yellow.400, orange.300)' },
    text: { color: '', fontWeight: 'semibold', align: 'center' as const },
    button: {
      bgColor: 'whiteAlpha.700',
      _hover: { bgColor: 'whiteAlpha.700', textDecoration: 'none' },
      _active: { bgColor: 'whiteAlpha.700' },
      ...NOTIFICATION_BOX_COMMON_STYLES,
    },
  },
  error: {
    box: { bgColor: 'red.600' },
    text: { color: 'whiteAlpha.900', fontWeight: 'semibold', align: 'center' as const },
    button: {
      bgColor: 'blackAlpha.300',
      _hover: { bgColor: 'blackAlpha.300', textDecoration: 'none' },
      _active: { bgColor: 'blackAlpha.300' },
      ...NOTIFICATION_BOX_COMMON_STYLES,
    },
  },
}
