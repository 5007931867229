/**
 * This module provides functions to select points on a mesh or point cloud using raycasting.
 */
import { ThreeEvent } from '@react-three/fiber'
import { Box3, Camera, Float32BufferAttribute, Intersection, Object3D, Points, PointsMaterial, Raycaster } from 'three'

import { FlatPosition, PointArray } from 'interfaces/interfaces'

import { roundNumber } from './Util'

type ClickedElement = { x: number; y: number; width: number; height: number } // the coordinate values can be large
type PositionScreenRatio = { x: number; y: number } // the coordinate values are between -1 and 1

/**
 * Creates a raycaster with a threshold for ray size
 * (precision of the raycaster when intersecting point objects).
 * @param {number} [threshold=0.001] - The size of the ray.
 * @return {Raycaster} A raycaster instance with specified size.
 */
const createRayCaster = (threshold = 0.001): Raycaster => {
  const raycaster = new Raycaster()
  if (raycaster.params.Points) {
    raycaster.params.Points.threshold = threshold
  }
  return raycaster
}

/**
 * Converts 2D coordinates on the screen to relative positions within the canvas.
 * @param {FlatPosition} positionScreen - The x,y coordinates from the top left of the screen
 * of the clicked point.
 * @param {ClickedElement} clickedElement - The top left x,y coordinates, width,
 * and height of the clicked element.
 * ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
 * @return {PositionScreenRatio} The converted coordinates relative to the canvas center.
 */
const getClickCoords = (positionScreen: FlatPosition, clickedElement: ClickedElement): PositionScreenRatio => {
  //* Get the relative position from the canvas of the click location
  const relativeX = positionScreen.x - clickedElement.x
  const relativeY = positionScreen.y - clickedElement.y

  //* Convert the click coordinates to relative positions within the canvas,
  // with the center of the canvas as (0, 0) and a range of - 1 to 1.
  return {
    x: (relativeX / clickedElement.width) * 2 - 1,
    y: -(relativeY / clickedElement.height) * 2 + 1,
  }
}

/**
 * Creates an array representing the selected point's coordinates, rounded to six decimal places.
 * If the point index is invalid (negative), it returns undefined.
 *
 * @param {Points} points - The Points object from which to extract the point's coordinates.
 * @param {number} pointIndex - The index of the point in the Points object's geometry attribute.
 * @return {PointArray | undefined} An array of the selected point's coordinates ([x, y, z]), each rounded to six decimal places,
 * or undefined if the point index is invalid.
 */
const createSelectedPoint = (points: Points, pointIndex: number): PointArray | undefined => {
  if (pointIndex < 0) return undefined
  const position: Float32BufferAttribute = points.geometry.getAttribute('position') as Float32BufferAttribute

  return [
    roundNumber(position.getX(pointIndex), '0.000001'),
    roundNumber(position.getY(pointIndex), '0.000001'),
    roundNumber(position.getZ(pointIndex), '0.000001'),
  ]
}

/**
 * Calculates the intersections of a ray with objects in the scene.
 * This function casts a ray from the camera through the mouse click position and determines
 * which objects in the scene are intersected by this ray.
 *
 * @param {HTMLElement |null| undefined} container - The HTML container element that holds the canvas.
 * Used to calculate correct mouse or touch position.
 * @param {Camera} camera - The camera used to define the ray's origin.
 * @param {Object3D<THREE.Event> | Points | undefined} object - An object
 * to compute intersection point with the ray.
 * @param event - The mouse event that triggers the raycasting.
 * @param {number} [rayThreshold] - The thickness of the ray. Optional.
 * @return {Intersection[] | undefined} An array of intersection objects,
 * each containing information about an intersected object, such as the point of intersection,
 * the distance from the ray's origin, and the intersected object itself.
 * The items are sorted by distance from the camera, closest first.
 */
const getIntersects = (
  container: HTMLElement | null | undefined,
  camera: Camera | null | undefined,
  object: Object3D<THREE.Event> | Points | undefined,
  event: {
    reactMouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>
    reactTouchEvent?: React.TouchEvent<HTMLDivElement>
    threeMouseEvent?: ThreeEvent<PointerEvent>
    threeTouchEvent?: ThreeEvent<TouchEvent>
  },
  rayThreshold?: number
): Intersection[] | undefined => {
  if (!container || !camera || !object) {
    return undefined
  }

  const targetElem = container.getBoundingClientRect()
  const { x, y, width, height } = targetElem
  const coords = getClickCoords(
    {
      x:
        event.reactMouseEvent?.clientX ||
        event.reactTouchEvent?.touches[0].clientX ||
        event.threeMouseEvent?.sourceEvent.clientX ||
        event.threeTouchEvent?.sourceEvent.touches[0].clientX ||
        0,
      y:
        event.reactMouseEvent?.clientY ||
        event.reactTouchEvent?.touches[0].clientY ||
        event.threeMouseEvent?.sourceEvent.clientY ||
        event.threeTouchEvent?.sourceEvent.touches[0].clientY ||
        0,
    },
    {
      x,
      y,
      width,
      height,
    }
  )

  //* Generate ray and determine click position
  const rayCaster = createRayCaster(rayThreshold)

  //* Extend the ray from the camera to the point
  rayCaster.setFromCamera(coords, camera)

  //* Detect the point cloud intersected by the ray
  const intersects = rayCaster.intersectObject(object)

  if (!intersects.length) {
    return undefined
  }

  return intersects
}

/**
 * Retrieves the coordinates of the point selected by the user on a mesh surface.
 *
 * @param {HTMLElement} container - The HTML container element that holds the canvas.
 * Used to calculate correct mouse or touch position.
 * @param {Camera | null | undefined} camera - The camera used to view the mesh,
 * necessary for raycasting.
 * @param {ThreeEvent<PointerEvent> | undefined} mouseEvent - The mouse event that triggers the selection.
 * Optional if touchEvent is provided.
 * @param {ThreeEvent<TouchEvent> | undefined} touchEvent - The touch event that triggers the selection.
 * Optional if mouseEvent is provided.
 * @return {PointArray | undefined} The 3D coordinates of the selected point on the mesh surface as an array [x, y, z],
 * or undefined if no point is selected.
 */
export const getSelectedPointOnMesh = (
  container: HTMLElement,
  camera?: Camera | null,
  mouseEvent?: ThreeEvent<PointerEvent>,
  touchEvent?: ThreeEvent<TouchEvent>
): PointArray | undefined => {
  const intersects = getIntersects(container, camera, mouseEvent?.eventObject || touchEvent?.eventObject, {
    threeMouseEvent: mouseEvent,
    threeTouchEvent: touchEvent,
  })

  if (!intersects?.length || intersects[0].point === undefined) {
    return undefined
  }

  return intersects[0].point.toArray()
}

/**
 * Judges whether a point is within a mask.
 *
 * @param {THREE.Vector3} point - The point to check if it is within the mask.
 * @param {{
 *     modelMatrix: THREE.Matrix4
 *     min: THREE.Vector3
 *     max: THREE.Vector3
 *     unsaved: boolean
 *     visible: boolean
 *   }} mask - The mask object containing the model matrix, min, max, unsaved, and visible properties.
 * @returns {boolean} True if the point is within the mask, false otherwise.
 */
function isWithinMask(
  point: THREE.Vector3,
  mask: {
    modelMatrix: THREE.Matrix4
    min: THREE.Vector3
    max: THREE.Vector3
    unsaved: boolean
    visible: boolean
  }
) {
  const localPoint = point.clone().applyMatrix4(mask.modelMatrix)
  const box = new Box3(mask.min, mask.max)
  return box.containsPoint(localPoint)
}

/**
 * judges whether a point is selectable based on the masks and the visibility of the points outside the masks.
 *
 * @param {THREE.Vector3} point - The point to check if it is selectable.
 * @param {{ modelMatrix: THREE.Matrix4; min: THREE.Vector3; max: THREE.Vector3; unsaved: boolean; visible: boolean }[]} maskModelMatrices
 * - Array of mask objects containing the model matrix, min, max, unsaved, and visible properties.
 * @param {boolean} outsideMasksIsVisible - Flag indicating whether the points outside the masks (including the hidden ones) are visible.
 * @returns {boolean} True if the point is selectable, false otherwise.
 */
function isSelectable(
  point: THREE.Vector3,
  maskModelMatrices: {
    modelMatrix: THREE.Matrix4
    min: THREE.Vector3
    max: THREE.Vector3
    unsaved: boolean
    visible: boolean
  }[],
  outsideMasksIsVisible: boolean
): boolean {
  // if there are no masks, the point is always selectable
  if (!maskModelMatrices?.length) return true

  // Check if the point is within any of the saved masked areas
  const MasksContainingPoint = maskModelMatrices.filter((mask) => isWithinMask(point, mask) && !mask.unsaved)
  // if the point is not within any of the saved masks, judge with the value of outsideMasksIsVisible
  if (MasksContainingPoint.length === 0) return outsideMasksIsVisible

  // Check if any of the masks containing the point is visible
  return MasksContainingPoint.some((mask) => mask.visible)
}

/**
 * Retrieves the coordinates of the point selected by the user on a Point Cloud (PCD).
 *
 * @param {HTMLElement | null} container - The HTML container element that holds the canvas,
 * used to calculate correct mouse or touch position.
 * @param {Camera | null | undefined} camera - The camera used to view the point cloud,
 * necessary for raycasting.
 * @param {Points | undefined} pointCloud - The point cloud object on which the point selection is being made.
 * @param {{ modelMatrix: THREE.Matrix4; min: THREE.Vector3; max: THREE.Vector3; unsaved: boolean; visible: boolean}[]} [maskModelMatrices]
 * - Optional array of model matrices for masked areas, used when mask is applied.
 * @param {React.MouseEvent<HTMLDivElement, MouseEvent> | undefined} mouseEvent - The mouse event that triggers the selection.
 * Optional if touchEvent is provided.
 * @param {React.TouchEvent<HTMLDivElement> | undefined} touchEvent - The touch event that triggers the selection.
 * Optional if mouseEvent is provided.
 * @param {boolean} [outsideMasksIsVisible=true]
 * - Flag indicating whether the points outside the masks (including the hidden ones) are visible.
 * @return {PointArray | undefined} The 3D coordinates of the selected point on the point cloud surface as an array [x, y, z],
 * or undefined if no point is selected or if the point cloud is invisible.
 */
export const getSelectedPointOnPCD = (
  container: HTMLElement | null,
  camera: Camera | null | undefined,
  pointCloud: Points | undefined,
  maskModelMatrices?: {
    modelMatrix: THREE.Matrix4
    min: THREE.Vector3
    max: THREE.Vector3
    unsaved: boolean
    visible: boolean
  }[],
  mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  touchEvent?: React.TouchEvent<HTMLDivElement>,
  outsideMasksIsVisible = true
): PointArray | undefined => {
  const copyPoints: Points | undefined = pointCloud?.clone()
  if (!copyPoints) return undefined

  const material = copyPoints.material as PointsMaterial

  // get all the intersection points
  const intersects = getIntersects(
    container,
    camera,
    copyPoints,
    {
      reactMouseEvent: mouseEvent,
      reactTouchEvent: touchEvent,
    },
    material.size
  )

  if (!intersects?.length) {
    return undefined
  }

  const intersectedPoint = intersects.find(
    (point) => point.index !== undefined && isSelectable(point.point, maskModelMatrices || [], outsideMasksIsVisible)
  )

  if (!intersectedPoint?.index) {
    return undefined
  }

  return createSelectedPoint(copyPoints, intersectedPoint.index)
}

// export private method for test (not for production)
export const exportedForTesting = {
  createRayCaster,
  getClickCoords,
  createSelectedPoint,
  getIntersects,
  isWithinMask,
  isSelectable,
}
