import React, { FC } from 'react'

import { Box, Link, Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { Instruction } from '../../../interfaces/interfaces'

function getHighlightedText(text: string) {
  const parts = text.split(/(「.*?」)/)
  return parts.map((part, index) => (
    // items order will not be changed, so we can use index as the key
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      {part.toLowerCase().startsWith('「') && part.toLowerCase().endsWith('」') ? (
        <b style={{ backgroundColor: 'var(--chakra-colors-secondary-700)', color: 'white' }}>{part}</b>
      ) : (
        part
      )}
    </React.Fragment>
  ))
}
const InstructionBarContent: FC<{ instruction?: Instruction }> = ({ instruction }) => {
  const { t } = useTranslation(['projects'])

  if (!instruction) {
    return null
  }

  return (
    <Box
      p={1}
      left={isTablet ? 1 : '50%'}
      transform={isTablet ? '' : 'translateX(-50%)'}
      position="absolute"
      bottom={isTablet ? 14 : 1}
      maxW={isTablet ? '293px' : '60vw'}
      borderRadius={4}
      backgroundColor="secondary.800"
      color="secondary.300"
      textAlign="center"
      zIndex={999}
    >
      <Text>
        <span>{getHighlightedText(instruction.text)}</span>
        <span style={{ display: 'inline-block' }}>
          {t('main_canvas.instructions.text')}
          <Link
            isExternal
            variant="underline"
            href={instruction.url}
            onClick={() => {
              // track with mixpanel
              // As it is external link, a new window will pop up.
              // So, we can track with track() without using track_links().
              // Ref: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-website-links
              mixpanel.track('Move to modely help center', {
                'Moved from': 'instruction text in main canvas page',
                'Target URL': instruction.url,
              })
            }}
          >
            help center
          </Link>
        </span>
      </Text>
    </Box>
  )
}

export default InstructionBarContent
