import { FC, useContext, useRef } from 'react'

import { Flex, Square, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import { EditorContext } from 'contexts/Editor'

const SubToolbarButton: FC<{
  icon: React.ReactElement
  label: string
  toolType: string
  dataTestId?: string
}> = ({ icon, label, toolType, dataTestId }) => {
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const { selectedSubTool, changeSubTool } = useContext(EditorContext)

  return (
    <Tooltip hasArrow placement="bottom" label={label} p={2} fontSize="xs" fontWeight="normal" isDisabled={isTablet}>
      <Flex>
        <Square
          ref={buttonRef}
          position="relative"
          backgroundColor={selectedSubTool === toolType ? 'gray.600' : 'transparent'}
          color="white"
          fontSize="xl"
          size={isTablet ? 12 : 8}
          onClick={() => {
            changeSubTool(toolType)
          }}
          data-testid={dataTestId}
        >
          {icon}
        </Square>
      </Flex>
    </Tooltip>
  )
}

export default SubToolbarButton
