import { FC } from 'react'

import { Heading } from '@chakra-ui/react'

const PageHeading: FC = ({ children }) => (
  <Heading as="h1" size="md" py="7" fontWeight="semibold">
    {children}
  </Heading>
)

export default PageHeading
