import { FC, RefObject, useContext, useEffect, useState } from 'react'

import { BackSide, BoxGeometry, Camera, Matrix4, Mesh, MeshBasicMaterial } from 'three'
import { ArcballControls } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import useMeshOutline from 'hooks/MeshOutline'
import useShape from 'hooks/Shape'

import { Plane } from 'interfaces/interfaces'

//* 鉄筋マテリアル
const standardMaterial = new MeshBasicMaterial({ color: '#0077ff' })
const hoveredMaterial = new MeshBasicMaterial({ color: 'yellow' })
const selectedMaterial = new MeshBasicMaterial({
  color: 'white',
  side: BackSide,
  polygonOffset: true,
  polygonOffsetFactor: 10,
})

export const PlaneMesh: FC<{
  cameraRef: RefObject<Camera> | null
  plane: Plane
  invisible?: boolean
  arcballControls: ArcballControls | null
}> = ({ cameraRef, plane, invisible, arcballControls }) => {
  const { selectedShapeIds } = useContext(EditorContext)
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut, onPointerMove, isFocus } = useShape(plane, cameraRef)
  const { outlineThickness } = useMeshOutline(arcballControls)
  const [mesh, setMesh] = useState<Mesh>(new Mesh())
  const [outlineMesh, setOutlineMesh] = useState<Mesh>(new Mesh())

  useEffect(() => {
    const { length_1, length_2, transformation } = plane
    if (transformation.length !== 16) {
      return
    }
    const matrix = new Matrix4().set(
      transformation[0],
      transformation[1],
      transformation[2],
      transformation[3],
      transformation[4],
      transformation[5],
      transformation[6],
      transformation[7],
      transformation[8],
      transformation[9],
      transformation[10],
      transformation[11],
      transformation[12],
      transformation[13],
      transformation[14],
      transformation[15]
    )

    const planeMesh = new Mesh()
    planeMesh.applyMatrix4(matrix)

    //* 形状設定
    const geo = new BoxGeometry(length_1, length_2, 0.001)
    geo.translate(length_1 / 2, length_2 / 2, 0)
    planeMesh.geometry = geo

    //* state保存
    setMesh(planeMesh)

    // used for outline effect
    const outlinePlaneMesh = planeMesh.clone()
    setOutlineMesh(outlinePlaneMesh)
  }, [plane])

  useEffect(() => {
    if (!outlineMesh || !selectedShapeIds.includes(plane.shape_id)) {
      return
    }
    // used for outline effect
    const { length_1, length_2 } = plane
    const outlineGeo = new BoxGeometry(length_1 + outlineThickness * 2, length_2 + outlineThickness * 2, 0)
    outlineGeo.translate(length_1 / 2, length_2 / 2, 0)
    outlineMesh.geometry = outlineGeo
  }, [outlineMesh, outlineThickness, plane, selectedShapeIds])

  if (!plane || invisible) return null

  return (
    <>
      <mesh
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerMove={onPointerMove}
        quaternion={mesh.quaternion}
        position={mesh.position}
        geometry={mesh.geometry}
        material={isFocus ? hoveredMaterial : standardMaterial}
      />
      {selectedShapeIds.includes(plane.shape_id) && (
        <mesh
          quaternion={outlineMesh.quaternion}
          position={outlineMesh.position}
          geometry={outlineMesh.geometry}
          material={selectedMaterial}
        />
      )}
    </>
  )
}

// Handle typing for props that is not required
PlaneMesh.defaultProps = {
  invisible: undefined,
}
