import { FC, useContext, useState } from 'react'

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import {
  EDITOR_COLLAPSE_TYPES,
  EDITOR_SHAPE_KEYS,
  EDITOR_SHAPE_TEMP_ID_PREFIX,
  MAX_EDITOR_LAYERS,
} from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { Cylinder, Plane, Shape, ShapeGroup, ShapeKey, Torus } from 'interfaces/interfaces'

import { checkGroupingCondition } from 'services/ShapeGroups'
import { findDiameterKeyByValue, meterToMillimeter, shapesWithKeyShouldShow, zeroPad } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

import { shapeDetectedSelected } from '../../store/editor'
import CollapsePanel from '../components/CollapsePanel'
import LayerItem from '../components/LayerItem'
import LayerItemDivider from '../components/LayerItemDivider'
import CreateShapeGroupModal from './CreateShapeGroupModal'

const GroupLayers: FC<{
  label: string
  invisible: boolean
  disabled: boolean
  selected: boolean
  childLayers: React.ReactNode
  childLevel?: number
  tagColor?: string
  updateVisibility: (invisible: boolean) => void
  onClick: () => void
  onEdit?: () => void
}> = ({
  label,
  invisible,
  disabled,
  selected,
  childLayers,
  childLevel,
  tagColor,
  updateVisibility,
  onClick,
  onEdit,
}) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <LayerItem
        invisible={invisible}
        collapsible
        collapsed={collapsed}
        updateExpansion={setCollapsed}
        disabled={disabled}
        selected={selected}
        label={label}
        updateVisibility={updateVisibility}
        onClick={onClick}
        onEdit={onEdit}
        childLevel={childLevel}
        tagColor={tagColor}
      />
      {!collapsed && childLayers}
    </>
  )
}

const ShapePanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const {
    selectedShapeIds,
    shapes,
    shapeGroups,
    isLayerModifying,
    project,
    selectedSpacerInspectionItem,
    deleteShapes,
    updateShapeStatus,
    updateShapeGroupStatus,
    updateAllShapesStatus,
    updateAllSelectedShapesStatus,
    changeSelectedShapeIds,
    changeHoveredShapeId,
  } = useContext(EditorContext)
  const { userType } = useContext(UserContext)
  const { projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { showModal } = useContext(GlobalModalContext)

  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.detected)
  )

  const isOwner = projectGroups.some((proj) => proj.project_group_id === project?.project_group_id)
  const isInvited = invitedProjectGroups.some((proj) => proj.project_group_id === project?.project_group_id)

  const permissions = decideActionPermission(isOwner, isInvited).MAIN_CANVAS
  const isAllowedModify = permissions.MODIFY.includes(userType)
  const isUpdatingShapeGroup = shapeGroups.some((group) => group.selected)

  const [isCreateShapeGroupModalOpen, setIsCreateShapeGroupModalOpen] = useState(false)
  const [selectedShapeGroup, setSelectedShapeGroup] = useState<ShapeGroup>()

  const updateLayerVisibility = (invisible: boolean, index: number, shapeKey: ShapeKey) => {
    updateShapeStatus({ invisible }, index, shapeKey)

    // track with mixpanel
    mixpanel.track('Change visibility of shape objects', {
      'Inspection area ID': project?.project_id,
      Granuarity: 'individual',
      'Visibility (new)': !invisible,
      'Visibility (old)': invisible,
      'Shape type': shapeKey,
    })
  }
  const deleteLayer = (index: number, shapeKey: ShapeKey) => {
    deleteShapes(false, index, shapeKey)
  }

  const selectedIds = selectedShapeIds.filter(
    (id) =>
      shapes.cylinders.some((c) => c.shape_id === id) ||
      shapes.planes.some((c) => c.shape_id === id) ||
      shapes.tori.some((c) => c.shape_id === id)
  )
  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length
  const isSomeCylindersVisible = shapes[EDITOR_SHAPE_KEYS.CYLINDERS].some((status) => !status.invisible)
  const isSomeToriVisible = shapes[EDITOR_SHAPE_KEYS.TORI].some((status) => !status.invisible)
  const isSomePlanesVisible = shapes[EDITOR_SHAPE_KEYS.PLANES].some((status) => !status.invisible)
  const minHeight =
    40 +
    33 *
      ((shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.CYLINDERS) ? 1 : 0) +
        (shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.TORI) ? 1 : 0) +
        (shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.PLANES) ? 1 : 0))

  if (
    !shapes[EDITOR_SHAPE_KEYS.CYLINDERS]?.length &&
    !shapes[EDITOR_SHAPE_KEYS.TORI]?.length &&
    !shapes[EDITOR_SHAPE_KEYS.PLANES]?.length
  ) {
    return null
  }

  if (selectedSpacerInspectionItem) {
    return null
  }

  const getLabel = (index: number, shapeKey: ShapeKey, isTemporary: boolean) => {
    let prefix =
      shapeKey === EDITOR_SHAPE_KEYS.PLANES
        ? t('main_canvas.panels.shape.formwork', { ns: 'projects' })
        : t('main_canvas.panels.shape.rebar', { ns: 'projects' })
    if (isTemporary) prefix = '! '
    // note that we put a whitespace between the text and the index
    return `${prefix} ${zeroPad(index + 1, zeroPlaces)}`
  }

  const getDiameter = (shape: Cylinder | Torus | Plane, shapeKey: ShapeKey) => {
    let diameter = 0
    if (shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS) {
      diameter = (shape as Cylinder).diameter
    }
    if (shapeKey === EDITOR_SHAPE_KEYS.TORI) {
      diameter = (shape as Torus).minor_diameter
    }
    if (!diameter) {
      return ''
    }
    return `(${findDiameterKeyByValue(meterToMillimeter(diameter))})`
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderShapeGroupLayer = (
    shapeKey: ShapeKey,
    childLevel: number,
    shape: Cylinder | Torus | Plane,
    index: number,
    groupColor?: string
  ) => (
    <LayerItem
      disabled={isLayerModifying || !isAllowedModify}
      selected={selectedShapeIds.includes(shape.shape_id)}
      deleteLayer={() => deleteLayer(index, shapeKey)}
      invisible={shape.invisible}
      key={getLabel(index, shapeKey, shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))}
      label={
        <HStack fontSize={10} alignItems="baseline" spacing={1}>
          <Text>{getLabel(index, shapeKey, shape.shape_id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))}</Text>
          <Text>{getDiameter(shape, shapeKey)}</Text>
        </HStack>
      }
      updateVisibility={(invisible) => updateLayerVisibility(invisible, index, shapeKey)}
      onMouseOver={() => changeHoveredShapeId(shape.shape_id)}
      onMouseOut={() => changeHoveredShapeId('')}
      onClick={() => {
        const newShapeIds = [...selectedShapeIds]
        const idIndex = newShapeIds.indexOf(shape.shape_id)
        if (idIndex >= 0) {
          newShapeIds.splice(idIndex, 1)
        } else {
          newShapeIds.push(shape.shape_id)
        }

        changeSelectedShapeIds(newShapeIds)
        dispatch(shapeDetectedSelected())
      }}
      childLevel={childLevel}
      groupColor={groupColor}
    />
  )

  const RenderShapeGroups = (shapeKey: ShapeKey, groups: ShapeGroup[]) => {
    const layers: React.ReactNode[][] = Array.from({ length: groups.length + 1 }, () => new Array<React.ReactNode>())
    const allGroupedShapeIds = groups.reduce(
      (ids: string[], group) => ids.concat(group.cylinder_ids || []).concat(group.torus_ids || []),
      []
    )

    shapes[shapeKey].forEach((shape, index) => {
      if (shape.deleted) {
        return
      }
      if (allGroupedShapeIds.includes(shape.shape_id)) {
        // Grouped layers
        const groupIndex = groups.findIndex(
          (group) => group.cylinder_ids?.includes(shape.shape_id) || group.torus_ids?.includes(shape.shape_id)
        )
        layers[groupIndex].push(RenderShapeGroupLayer(shapeKey, 2, shape, index, groups[groupIndex].color_palette_code))
      } else {
        // Ungrouped layers
        layers[layers.length - 1].push(RenderShapeGroupLayer(shapeKey, 1, shape, index))
      }
    })

    return layers
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderShapeLayers = (shapeKey: ShapeKey, label: string, isSomeShapesVisible: boolean, groups: ShapeGroup[]) => {
    if (!shapesWithKeyShouldShow(shapes, shapeKey)) {
      return null
    }

    const allShapeIds = shapes[shapeKey].map((shape) => shape.shape_id)
    const groupLayers = RenderShapeGroups(shapeKey, groups)
    const childLayers = groupLayers.map((layers, index) => {
      const group = groups[index]
      if (index < groupLayers.length - 1) {
        const groupShapes = (shapes[shapeKey] as Shape[]).filter(
          (shape) => group.cylinder_ids?.includes(shape.shape_id) || group.torus_ids?.includes(shape.shape_id)
        )
        return (
          <GroupLayers
            label={group.grouping_shape_type_name}
            invisible={!groupShapes.some((shape) => !shape.invisible)}
            disabled={isLayerModifying || !isAllowedModify}
            selected={!groupShapes.some((shape) => !selectedShapeIds.includes(shape.shape_id))}
            childLayers={layers}
            childLevel={1}
            tagColor={group.color_palette_code}
            updateVisibility={(invisible) => {
              updateShapeGroupStatus({ invisible }, group.grouping_shape_type_id || '', shapeKey)
            }}
            onClick={() => {
              // if some of the shapes are not selected, select all the shapes
              if (groupShapes.some((shape) => !selectedShapeIds.includes(shape.shape_id))) {
                changeSelectedShapeIds(
                  Array.from(new Set([...selectedShapeIds, ...groupShapes.map((shape) => shape.shape_id)]))
                )
              } else {
                // if all of the shapes are selected, unselect all the shapes
                changeSelectedShapeIds(
                  selectedShapeIds.filter((id) => !groupShapes.find((shape) => shape.shape_id === id))
                )
              }
              dispatch(shapeDetectedSelected())
            }}
            onEdit={() => {
              setSelectedShapeGroup(group)
              setIsCreateShapeGroupModalOpen(true)
            }}
          />
        )
      }

      return layers
    })

    return (
      <GroupLayers
        label={label}
        invisible={!isSomeShapesVisible}
        disabled={isLayerModifying || !isAllowedModify}
        selected={!!allShapeIds.length && !allShapeIds.some((id) => !selectedShapeIds.includes(id))}
        childLayers={childLayers}
        updateVisibility={(invisible) => updateAllShapesStatus({ invisible }, shapeKey)}
        onClick={() => {
          // if some of the shapes are not selected, select all the shapes
          if (shapes[shapeKey].some((shape) => !selectedShapeIds.includes(shape.shape_id))) {
            changeSelectedShapeIds(Array.from(new Set([...selectedShapeIds, ...allShapeIds])))
          } else {
            // if all of the shapes are selected, unselect all the shapes
            changeSelectedShapeIds(selectedShapeIds.filter((id) => !allShapeIds.includes(id)))
          }
          dispatch(shapeDetectedSelected())
        }}
      />
    )
  }

  const isSomeSelectedShapesVisible = () => {
    if (
      shapes[EDITOR_SHAPE_KEYS.CYLINDERS].length &&
      selectedShapeIds.some((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.CYLINDERS].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex < 0) {
          return false
        }
        return !shapes[EDITOR_SHAPE_KEYS.CYLINDERS][shapeIndex].invisible
      })
    ) {
      return true
    }

    if (
      shapes[EDITOR_SHAPE_KEYS.TORI].length &&
      selectedShapeIds.some((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.TORI].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex < 0) {
          return false
        }
        return !shapes[EDITOR_SHAPE_KEYS.TORI][shapeIndex].invisible
      })
    ) {
      return true
    }

    if (
      shapes[EDITOR_SHAPE_KEYS.PLANES].length &&
      selectedShapeIds.some((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.PLANES].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex < 0) {
          return false
        }
        return !shapes[EDITOR_SHAPE_KEYS.PLANES][shapeIndex].invisible
      })
    ) {
      return true
    }

    return false
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? minHeight : 0}
    >
      <CollapsePanel
        title={t('main_canvas.panels.shape.detected_shapes', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.detected}
      >
        <VStack
          w="100%"
          spacing={0}
          pb={INFO_PANEL_PADDING - 1}
          divider={<LayerItemDivider />}
          overflowX="hidden"
          overflowY="auto"
        >
          {RenderShapeLayers(
            EDITOR_SHAPE_KEYS.CYLINDERS,
            `${t('main_canvas.panels.shape.rebar', { ns: 'projects' })} (${
              shapes[EDITOR_SHAPE_KEYS.CYLINDERS].length
            })`,
            isSomeCylindersVisible,
            shapeGroups.filter((group) => group.cylinder_ids?.length)
          )}
          {RenderShapeLayers(
            EDITOR_SHAPE_KEYS.TORI,
            `${t('main_canvas.panels.shape.hoop', { ns: 'projects' })} (${shapes[EDITOR_SHAPE_KEYS.TORI].length})`,
            isSomeToriVisible,
            shapeGroups.filter((group) => group.torus_ids?.length)
          )}
          {RenderShapeLayers(
            EDITOR_SHAPE_KEYS.PLANES,
            `${t('main_canvas.panels.shape.formwork', { ns: 'projects' })} (${
              shapes[EDITOR_SHAPE_KEYS.PLANES].length
            })`,
            isSomePlanesVisible,
            []
          )}
          {!!selectedIds.length && (
            <Box fontWeight="bold" w="100%">
              <LayerItem
                onClick={() => changeSelectedShapeIds([])}
                disabled={isLayerModifying || !isAllowedModify}
                invisible={!isSomeSelectedShapesVisible()}
                label={`${t('main_canvas.panels.shape.rebar', { ns: 'projects' })}(${selectedIds.length})`}
                updateVisibility={(invisible) => updateAllSelectedShapesStatus({ invisible })}
                deleteLayer={() => deleteShapes(true)}
                onGroup={
                  isUpdatingShapeGroup
                    ? undefined
                    : () =>
                        setIsCreateShapeGroupModalOpen(
                          !!checkGroupingCondition(shapes, shapeGroups, selectedShapeIds, showModal)
                        )
                }
              />
            </Box>
          )}
        </VStack>
      </CollapsePanel>
      <CreateShapeGroupModal
        isOpen={isCreateShapeGroupModalOpen}
        selectedShapeGroup={selectedShapeGroup}
        onClose={() => {
          setIsCreateShapeGroupModalOpen(false)
          setSelectedShapeGroup(undefined)
        }}
      />
    </Flex>
  )
}

export default ShapePanel
