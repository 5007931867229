import axios from 'extends/axios'

import { API_GATEWAY_URL } from 'config/environments'

import { AnchorItem, PointArray } from 'interfaces/interfaces'

import { calculateCenterAndDistance } from './Editor'
import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

interface DistanceResponse {
  manual_measurement_id: string
  positions_for_distance: PointArray[]
}

const parseDistance = (distance: DistanceResponse) => {
  const distanceFactors = calculateCenterAndDistance({ points: distance.positions_for_distance })
  return {
    id: distance.manual_measurement_id,
    points: distance.positions_for_distance,
    center: distanceFactors?.[0],
    distance: distanceFactors?.[1],
  }
}

/**
 * Get all distance object(s) of particular project.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {AnchorItem[] | null}
 */
export const getDistances = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<AnchorItem[] | null> => {
  const distances = await axios
    .get<{ results: DistanceResponse[] }>(`${PROJECTS_API_URL}/${project_id}/manual-measurements`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results.map((distance) => parseDistance(distance)))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_DISTANCES, showErrorModal)
      return null
    })

  return distances
}

/**
 * Add new distance object.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {AnchorItem} distance temporary distance object
 * @param {function} showErrorModal show error modal function
 * @returns {AnchorItem | null}
 */
export const saveDistance = async (
  access_token: string,
  project_id: string,
  distance: AnchorItem,
  showErrorModal: (message: string) => void
): Promise<AnchorItem | null> => {
  const savedDistance = await axios
    .post<DistanceResponse>(
      `${PROJECTS_API_URL}/${project_id}/manual-measurements`,
      {
        positions_for_distance: [...distance.points],
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => (response.data.manual_measurement_id ? parseDistance(response.data) : null))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.SAVE_DISTANCES, showErrorModal)
      return null
    })

  return savedDistance
}

/**
 * Update distance object.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {AnchorItem} distance updated distance object
 * @param {function} showErrorModal show error modal function
 * @returns {AnchorItem | null}
 */
export const updateDistance = async (
  access_token: string,
  project_id: string,
  distance: AnchorItem,
  showErrorModal: (message: string) => void
): Promise<AnchorItem | null> => {
  const updatedDistance = await axios
    .patch<DistanceResponse>(
      `${PROJECTS_API_URL}/${project_id}/manual-measurements/${distance.id || ''}`,
      {
        positions_for_distance: [...distance.points],
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => (response.data.manual_measurement_id ? parseDistance(response.data) : null))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_DISTANCE, showErrorModal)
      return null
    })

  return updatedDistance
}

/**
 * Delete a particular distance object.
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} manual_measurement_id distance id
 * @return {boolean}
 */
export const deleteDistance = async (
  access_token: string,
  project_id: string,
  manual_measurement_id: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECTS_API_URL}/${project_id}/manual-measurements/${manual_measurement_id}`, {
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_DISTANCE, showErrorModal)
      return false
    })
  return result
}
