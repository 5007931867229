import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container, Progress, Text, useToast } from '@chakra-ui/react'
import bytes from 'bytes'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { AddIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import {
  PRICING_PLANS,
  SUBSCRIPTION_ALERT_REMAINING_DAYS,
  UPLOAD_LIMIT_BYTE,
  UPLOAD_LIMIT_GIGABYTE,
  USER_TYPES,
} from 'config/constants'
import { CONTAINER_MAX_WIDTH, TOAST_CONFIG } from 'config/styles'

import { Subscription } from 'interfaces/interfaces'

import { getUserSubscription } from 'services/Usage'
import { getRemainingDays } from 'services/Util'
import { checkSize, decideActionPermission } from 'services/Validation'

import ProjectsTable from './ProjectsTable'
import CreateProjectGroupModal from './components/CreateProjectGroupModal'

const remainingDays = getRemainingDays()

const Dashboard: FC = () => {
  const { t } = useTranslation(['dashboard'])
  const toast = useToast()
  const { search } = useLocation()
  const navigate = useNavigate()

  const parameters = new URLSearchParams(search)
  const user_id = parameters.get('user_id')
  const user_email = parameters.get('user_email')

  const { user } = useAuth0()
  const { getProjectGroups, total_size, projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { userType, subscriptionId, getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  const isAllowedCreateProjectGroup = decideActionPermission().PROJECT_DASHBOARD.CREATE_PROJECT_GROUP.includes(userType)
  const isAllowedActivatePremiumFeatures = userType === USER_TYPES.BASE_USER

  const [isOpeningCreateProjectGroupModal, setIsOpeningCreateProjectGroupModal] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(null)

  const fetchProjectGroups = useCallback(async () => {
    if (user_id && userType === USER_TYPES.ADMIN) {
      const projectResult = await getProjectGroups(user_id)
      if (projectResult) {
        setTotalSize(projectResult.totalSize)
      }
    } else {
      setTotalSize(total_size)
      // set total size/project number to user info in mixpanel
      mixpanel.people.set({
        'Total size (GB)': total_size / 1024 ** 3,
        'Project number (owned)': projectGroups.length,
        'Project number (invited)': invitedProjectGroups.length,
      })
    }
    // Should not watch for projectGroups and invitedProjectGroups
    // Because they are object type so that cannot compare by states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, userType, total_size])

  useEffect(() => {
    void fetchProjectGroups()
  }, [fetchProjectGroups, total_size, user_id])

  useEffect(() => {
    void (async () => {
      if (!user?.sub || !subscriptionId) {
        return
      }

      const token = await getAccessToken()
      if (!token) {
        return
      }

      const subscription = await getUserSubscription(token, user.sub, subscriptionId, showErrorModal)
      setUserSubscription(subscription)
    })()
  }, [user, subscriptionId, getAccessToken, showErrorModal])

  const openCreateProjectGroupModal = () => {
    //* アップロードされた合計ファイルサイズが10GB以上の場合、エラー
    if (!checkSize(totalSize, UPLOAD_LIMIT_BYTE)) {
      showErrorModal(
        t('exceed_available_file_storage_quota', {
          ns: 'dashboard',
          upload_limit_gigabyte: UPLOAD_LIMIT_GIGABYTE,
        })
      )
      return false
    }

    setIsOpeningCreateProjectGroupModal(true)
    return true
  }

  const onProjectGroupCreated = async (result?: boolean) => {
    setIsOpeningCreateProjectGroupModal(false)

    if (result) {
      toast({
        ...TOAST_CONFIG,
        title: t('project_created', {
          ns: 'dashboard',
        }),
      })
      await getProjectGroups()
    }
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>
          {t('project', {
            ns: 'dashboard',
          })}
          {userType === USER_TYPES.ADMIN && user_email ? `(${user_email || ''})` : ''}
        </PageHeading>
        <Box w={{ sm: '100%', md: '50%' }}>
          <Progress value={Math.round((totalSize / UPLOAD_LIMIT_BYTE) * 100)} size="xs" colorScheme="secondary" />
          <Text fontSize="sm" fontWeight="semibold" mt={1.5}>
            {bytes(totalSize, {
              decimalPlaces: 1,
              unit: 'GB',
            })}{' '}
            / {UPLOAD_LIMIT_GIGABYTE}GB
          </Text>
        </Box>
        {(() => {
          if (!userType) {
            return null
          }

          // if it is permitted to create a project group, show the button
          if (isAllowedCreateProjectGroup) {
            return (
              <Box mt={10} mb={7}>
                <Button
                  variant="outlinePrimary"
                  size="md"
                  onClick={openCreateProjectGroupModal}
                  rightIcon={<AddIcon />}
                >
                  {t('create_project', {
                    ns: 'dashboard',
                  })}
                </Button>
                {remainingDays <= SUBSCRIPTION_ALERT_REMAINING_DAYS &&
                  userSubscription?.pricing_system === PRICING_PLANS.PAY_ON_DEMAND && (
                    <Text color="orange" mt={1} fontSize="xs">
                      {t('subscription_expires_soon_message', {
                        ns: 'dashboard',
                      })}
                    </Text>
                  )}
              </Box>
            )
          }

          // if it is permitted to activate premium features, show the button
          if (isAllowedActivatePremiumFeatures) {
            return (
              <Button variant="outlinePrimary" size="md" mt={10} mb={7} onClick={() => navigate('/usage')}>
                {t('get_started_with_premium_feature', {
                  ns: 'dashboard',
                })}
              </Button>
            )
          }

          // otherwise, show nothing
          return null
        })()}
        {!isAllowedCreateProjectGroup && <Box height={10} />}
        <ProjectsTable />
      </Container>
      <CreateProjectGroupModal isOpen={isOpeningCreateProjectGroupModal} onConfirm={onProjectGroupCreated} />
    </>
  )
}

export default Dashboard
