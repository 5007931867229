import { FC } from 'react'

import { Html } from '@react-three/drei'
import { Vector3 } from 'three'

import { CameraFocusPointIcon } from 'assets/icons'

/**
 * Component for a camera focus point rendered in 3D space
 */
const CameraFocusPoint: FC<{
  position: Vector3
}> = ({ position }) => (
  <Html
    position={position}
    style={{
      transform: 'translate(-50%, -50%)',
      width: '20px',
      height: '20px',
      position: 'relative',
    }}
    zIndexRange={[0, 0]}
    className="unprintable"
  >
    <CameraFocusPointIcon />
  </Html>
)

export default CameraFocusPoint
