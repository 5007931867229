import { createContext, useContext } from 'react'

import { DEFAULT_BLUEPRINT_TOOL } from 'config/constants'

import { BlueprintViewer } from 'interfaces/interfaces'

const initialState: BlueprintViewer = {
  changeTool: () => null,
  selectedTool: DEFAULT_BLUEPRINT_TOOL,
}

export const BlueprintContext = createContext(initialState)
export const useBlueprintContext = () => useContext(BlueprintContext)
