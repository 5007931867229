import { FC, useContext, useEffect, useState } from 'react'

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'

import { ChevronDownIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { DIAMETERS } from 'config/constants'
import { INFO_PANEL_PADDING, INPUT_GROUP_STYLES } from 'config/styles'

import { findDiameterKeyByValue } from 'services/Util'

const DiameterPanel: FC<{ value?: number }> = ({ value }) => {
  const { baseDiameter, changeBaseDiameter, updateSelectedPointDiameter } = useContext(EditorContext)

  const [localValue, setLocalValue] = useState<number>()

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const updateValue = (newValue: number) => {
    if (!newValue) {
      return
    }

    updateSelectedPointDiameter(newValue)

    if (baseDiameter !== newValue) {
      changeBaseDiameter(newValue)
    }
  }

  return (
    <Box px={INFO_PANEL_PADDING}>
      <Menu variant="toolbar">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          textAlign="left"
          fontWeight="normal"
          fontSize="xs"
          paddingX={2}
          height={7}
          width="100%"
          variant="toolbar"
          {...INPUT_GROUP_STYLES}
        >
          {localValue ? `${findDiameterKeyByValue(localValue)}（${localValue || ''}）` : ''}
        </MenuButton>
        <Portal>
          <MenuList>
            {Object.keys(DIAMETERS).map((d) => (
              <MenuItem
                key={d}
                backgroundColor={localValue === DIAMETERS[d] ? 'secondary.600' : 'transparent'}
                onClick={() => updateValue(DIAMETERS[d])}
                fontSize="xs"
              >
                {d}（{DIAMETERS[d]}）
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

// Handle typing for props that is not required
DiameterPanel.defaultProps = {
  value: undefined,
}

export default DiameterPanel
