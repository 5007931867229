import axios from 'extends/axios'

import { EDITOR_SHAPE_TEMP_ID_PREFIX, MODAL_TYPES } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { ModalProps, ShapeGroup, Shapes } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

/**
 * Get all shape groups
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {ShapeGroup[] | null}
 */
export const getShapeGroups = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<ShapeGroup[] | null> => {
  const shapeGroups = await axios
    .get<{ results: ShapeGroup[] }>(`${PROJECTS_API_URL}/${project_id}/grouping-shape-types`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_SHAPE_GROUPS, showErrorModal)
      return null
    })

  return shapeGroups
}

/**
 * Create a shape group
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {ShapeGroup} shapeGroup shape group
 * @param {function} showErrorModal show error modal function
 * @returns {ShapeGroup | null}
 */
export const createShapeGroup = async (
  access_token: string,
  project_id: string,
  shapeGroup: ShapeGroup,
  showErrorModal: (message: string) => void
): Promise<ShapeGroup | null> => {
  const shapeIds = shapeGroup.cylinder_ids?.length
    ? { cylinder_ids: shapeGroup.cylinder_ids }
    : { torus_ids: shapeGroup.torus_ids }

  const shapeGroups = await axios
    .post<ShapeGroup>(
      `${PROJECTS_API_URL}/${project_id}/grouping-shape-types`,
      {
        grouping_shape_type_name: shapeGroup.grouping_shape_type_name,
        color_palette_code: shapeGroup.color_palette_code,
        ...shapeIds,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_SHAPE_GROUP, showErrorModal)
      return null
    })

  return shapeGroups
}

/**
 * Update a shape group
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {ShapeGroup} shapeGroup shape group with updated values
 * @param {function} showErrorModal show error modal function
 * @returns {ShapeGroup | null}
 */
export const updateShapeGroup = async (
  access_token: string,
  project_id: string,
  shapeGroup: ShapeGroup,
  showErrorModal: (message: string) => void
): Promise<ShapeGroup | null> => {
  const shapeIds = shapeGroup.cylinder_ids?.length
    ? { cylinder_ids: shapeGroup.cylinder_ids }
    : { torus_ids: shapeGroup.torus_ids }

  const shapeGroups = await axios
    .patch<ShapeGroup>(
      `${PROJECTS_API_URL}/${project_id}/grouping-shape-types/${shapeGroup.grouping_shape_type_id || ''}`,
      {
        grouping_shape_type_name: shapeGroup.grouping_shape_type_name,
        color_palette_code: shapeGroup.color_palette_code,
        ...shapeIds,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_SHAPE_GROUP, showErrorModal)
      return null
    })

  return shapeGroups
}

/**
 * Delete a shape group
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {ShapeGroup} shapeGroup shape group
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteShapeGroup = async (
  access_token: string,
  project_id: string,
  shapeGroup: ShapeGroup,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete<ShapeGroup>(
      `${PROJECTS_API_URL}/${project_id}/grouping-shape-types/${shapeGroup.grouping_shape_type_id || ''}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_SHAPE_GROUP, showErrorModal)
      return false
    })

  return result
}

/**
 * check whether the selected shapes are valid as source of a new shape group (type).
 * If it is valid, return the selected shapes.
 * Otherwise, show the alert modal and return null.
 *
 * @param {Shapes} shapes existing shapes
 * @param {ShapeGroup[]} shapeGroups existing shape groups (types)
 * @param {string[]} selectedShapeIds IDs of selected shapes
 * @param {(props: ModalProps) => void} showModal modal function
 * @returns {{ cylinders: Cylinder[]; tori: Torus[]; } | null} selected shapes or null
 */
export const checkGroupingCondition = (
  shapes: Shapes,
  shapeGroups: ShapeGroup[],
  selectedShapeIds: string[],
  showModal: (props: ModalProps) => void
) => {
  const filteredSelectedShapeIds = selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) || []
  const selectedCylinders = shapes.cylinders.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const selectedPlanes = shapes.planes.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
  const selectedTori = shapes.tori.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))

  // User select planes with no cylinders/tori.
  if (selectedPlanes.length) {
    showModal({
      body: '型枠から種別を作成することはできません。',
      confirmText: '了解',
      modalType: MODAL_TYPES.ALERT,
    })
    return null
  }

  // There are multiple shape types such as cylinders and tori in the selected shapes.
  if (selectedCylinders.length && selectedTori.length) {
    showModal({
      body: '通常の鉄筋とフープ筋など、異なる種類の鉄筋を1つの種別に含める事はできません。',
      confirmText: '了解',
      modalType: MODAL_TYPES.ALERT,
    })
    return null
  }

  const allGroupedShapeIds = shapeGroups.reduce(
    (ids: string[], group) => ids.concat(group.cylinder_ids || []).concat(group.torus_ids || []),
    []
  )
  // The selected shapes already belong to another type
  if (
    selectedCylinders.some((shape) => allGroupedShapeIds.includes(shape.shape_id)) ||
    selectedTori.some((shape) => allGroupedShapeIds.includes(shape.shape_id))
  ) {
    showModal({
      body: '選択した鉄筋は既に別の種別に使われています。',
      confirmText: '了解',
      modalType: MODAL_TYPES.ALERT,
    })
    return null
  }

  // The selected shapes contain different diameter
  if (
    (selectedCylinders.length && selectedCylinders.some((shape) => shape.diameter !== selectedCylinders[0].diameter)) ||
    (selectedTori.length && selectedTori.some((shape) => shape.minor_diameter !== selectedTori[0].minor_diameter))
  ) {
    showModal({
      body: '選択した鉄筋の径は同じである必要があります。',
      confirmText: '了解',
      modalType: MODAL_TYPES.ALERT,
    })
    return null
  }

  return {
    cylinders: selectedCylinders,
    tori: selectedTori,
  }
}
