import { FC, useContext } from 'react'

import { Flex, Text, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, MAX_EDITOR_LAYERS, MODAL_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { Cuboid } from 'interfaces/interfaces'

import { deleteMaskingRegion } from 'services/MaskingRegion'
import { zeroPad } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'

const MaskingRegionsPanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const {
    maskRegions,
    project,
    isLayerModifying,
    maskRegionsOutsideVisible,
    changeMaskRegions,
    changeMaskRegionsOutsideVisible,
  } = useContext(EditorContext)
  const { userType, getAccessToken } = useContext(UserContext)
  const { projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.maskRegion)
  )

  const isOwner = projectGroups.some((proj) => proj.project_group_id === project?.project_group_id)
  const isInvited = invitedProjectGroups.some((proj) => proj.project_group_id === project?.project_group_id)
  const permissions = decideActionPermission(isOwner, isInvited).MAIN_CANVAS
  const isAllowedModify = permissions.MODIFY.includes(userType)

  const updateLayerVisibility = (visibility: boolean, cuboid: Cuboid) => {
    changeMaskRegions(
      maskRegions.map((region) =>
        region.masking_region_id === cuboid.masking_region_id
          ? {
              ...cuboid,
              invisible: visibility,
            }
          : region
      )
    )

    // track with mixpanel
    mixpanel.track('Change visibility of masked points', {
      'Inspection area ID': project?.project_id,
      Granuarity: 'individual',
      'Visibility (new)': !visibility,
      'Visibility (old)': visibility,
      'Mask region number': 1,
    })
  }

  const deleteLayer = (region: Cuboid) => {
    showModal({
      title: t('main_canvas.modals.delete_mask_region.title', { ns: 'projects' }),
      body: <Text> {t('main_canvas.modals.delete_mask_region.text', { ns: 'projects' })}</Text>,
      confirmText: t('main_canvas.modals.delete_mask_region.confirm', { ns: 'projects' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const deleteResult = await deleteMaskingRegion(
            token,
            project?.project_id || '',
            region.masking_region_id,
            showErrorModal
          )
          if (!deleteResult) {
            return false
          }

          const newMaskRegions = maskRegions.filter((m) => m.masking_region_id !== region.masking_region_id)
          changeMaskRegions(newMaskRegions)

          // track with mixpanel
          mixpanel.track('Delete mask region', {
            'Number of mask regions (deleted masks)': 1,
            'Number of mask regions (after deletion)': newMaskRegions.length,
            'Inspection area ID': project?.project_id,
          })
          return true
        })()
        return true
      },
    })
  }

  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

  if (!maskRegions?.filter((m) => !m.deleted).length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? 20 : 10}
      data-testid="masking-regions-panel"
    >
      <CollapsePanel
        title={t('main_canvas.panels.mask_region.title', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.maskRegion}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={!maskRegionsOutsideVisible}
            label={t('main_canvas.panels.mask_region.show_outside_points', { ns: 'projects' })}
            updateVisibility={(visible) => changeMaskRegionsOutsideVisible(!visible)}
          />
          {maskRegions.map((region, index) => (
            <LayerItem
              disabled={isLayerModifying}
              selected={false}
              invisible={region.invisible}
              key={`cuboid-listitem-${region.masking_region_id}}`}
              // note that we put a whitespace between the text and the index
              label={`${t('main_canvas.panels.mask_region.mask_region', { ns: 'projects' })}

              ${zeroPad(index + 1, zeroPlaces)}`}
              updateVisibility={(invisible) => updateLayerVisibility(invisible, region)}
              deleteLayer={isAllowedModify ? () => deleteLayer(region) : undefined}
              childLevel={1}
            />
          ))}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default MaskingRegionsPanel
