import * as chakraUiIcons from '@chakra-ui/icons'
import { AiOutlineFolderOpen } from 'react-icons/ai'
import {
  BiArrowBack,
  BiBookmarkPlus,
  BiCamera,
  BiCaretLeft,
  BiCaretRight,
  BiCaretRightCircle,
  BiCheck,
  BiCheckCircle,
  BiChevronDown,
  BiChevronLeft,
  BiChevronUp,
  BiCrosshair,
  BiCube,
  BiDotsHorizontalRounded,
  BiDownload,
  BiErrorCircle,
  BiExport,
  BiGrid,
  BiHelpCircle,
  BiHide,
  BiImageAdd,
  BiImport,
  BiListUl,
  BiMenu,
  BiMinus,
  BiPencil,
  BiPlus,
  BiRefresh,
  BiReset,
  BiShow,
  BiTargetLock,
  BiTransfer,
  BiTrash,
  BiUndo,
  BiUnite,
  BiX,
} from 'react-icons/bi'
import { BsFillCircleFill, BsPencil, BsScissors, BsTranslate } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdOutlineCancel } from 'react-icons/md'
import { RiAlertFill } from 'react-icons/ri'

import { ReactComponent as CameraFocusPoint } from './icons/camera-focus-point.svg'
import { ReactComponent as Comment } from './icons/comment.svg'
import { ReactComponent as CubeBack } from './icons/cube-back.svg'
import { ReactComponent as CubeDown } from './icons/cube-down.svg'
import { ReactComponent as CubeFront } from './icons/cube-front.svg'
import { ReactComponent as CubeLeft } from './icons/cube-left.svg'
import { ReactComponent as CubeRight } from './icons/cube-right.svg'
import { ReactComponent as CubeUp } from './icons/cube-up.svg'
import { ReactComponent as Cuboid } from './icons/cuboid.svg'
import { ReactComponent as LogoInverted } from './icons/logo-inverted.svg'
import { ReactComponent as Logo } from './icons/logo.svg'
import { ReactComponent as Move } from './icons/move.svg'
import { ReactComponent as ToolbarAxis1 } from './icons/toolbar-axis-1.svg'
import { ReactComponent as ToolbarAxis2 } from './icons/toolbar-axis-2.svg'
import { ReactComponent as ToolbarAxis3 } from './icons/toolbar-axis-3.svg'
import { ReactComponent as ToolbarClipping } from './icons/toolbar-clipping.svg'
import { ReactComponent as ToolbarCommentCuboid } from './icons/toolbar-comment-cuboid.svg'
import { ReactComponent as ToolbarComment } from './icons/toolbar-comment.svg'
import { ReactComponent as ToolbarCylinderCuboid } from './icons/toolbar-cylinder-cuboid.svg'
import { ReactComponent as ToolbarCylinderOnArc } from './icons/toolbar-cylinder-on-arc.svg'
import { ReactComponent as ToolbarCylinderOnAxis } from './icons/toolbar-cylinder-on-axis.svg'
import { ReactComponent as ToolbarCylinder } from './icons/toolbar-cylinder.svg'
import { ReactComponent as ToolbarDistance } from './icons/toolbar-distance.svg'
import { ReactComponent as ToolbarMove } from './icons/toolbar-move.svg'
import { ReactComponent as ToolbarPlaneVirtual } from './icons/toolbar-plane-virtual.svg'
import { ReactComponent as ToolbarPlane } from './icons/toolbar-plane.svg'
import { ReactComponent as ToolbarRotate } from './icons/toolbar-rotate.svg'
import { ReactComponent as ToolbarScale } from './icons/toolbar-scale.svg'
import { ReactComponent as ToolbarSpacerAnnotation } from './icons/toolbar-spacer.svg'
import { ReactComponent as ToolbarTorusCuboid } from './icons/toolbar-torus-cuboid.svg'
import { ReactComponent as ToolbarTorus } from './icons/toolbar-torus.svg'
import { ReactComponent as ToolbarTranslate } from './icons/toolbar-translate.svg'

export const {
  ChevronRightIcon, //* >矢印
  ChevronDownIcon,
  CloseIcon, //* 閉じる
} = chakraUiIcons

export const AddIcon = BiPlus
export const AddImageIcon = BiImageAdd
export const AlertFillIcon = RiAlertFill
export const BackIcon = BiArrowBack
export const BigChevronDownIcon = FaChevronDown
export const BigChevronUpIcon = FaChevronUp
export const BookmarkPlusIcon = BiBookmarkPlus
export const CameraFocusPointIcon = CameraFocusPoint
export const CameraIcon = BiCamera
export const CancelIcon = MdOutlineCancel
export const CaretLeftIcon = BiCaretLeft
export const CaretRightIcon = BiCaretRight
export const CheckCircleIcon = BiCheckCircle
export const ChevronLeftIcon = BiChevronLeft
export const CircleFillIcon = BsFillCircleFill
export const CollapseIcon = BiChevronUp
export const CommentDisplayIcon = Comment
export const CommentMoveIcon = Move
export const CubeBackIcon = CubeBack
export const CubeDownIcon = CubeDown
export const CubeFrontIcon = CubeFront
export const CubeIcon = BiCube
export const CubeLeftIcon = CubeLeft
export const CubeRightIcon = CubeRight
export const CubeUpIcon = CubeUp
export const CuboidIcon = Cuboid
export const DeleteIcon = BiTrash
export const DiameterIcon = BiCrosshair
export const DownloadIcon = BiDownload
export const ErrorIcon = BiErrorCircle
export const ExpandIcon = BiChevronDown
export const ExportIcon = BiExport
export const FolderIcon = AiOutlineFolderOpen
export const GridIcon = BiGrid
export const HDotsIcon = BiDotsHorizontalRounded
export const HelpCircleIcon = BiHelpCircle
export const HideIcon = BiHide
export const ImportIcon = BiImport
export const InputEditorCancelIcon = BiX
export const InputEditorConfirmIcon = BiCheck
export const InputEditorEditIcon = BiPencil
export const ListIcon = BiListUl
export const LogoDark = Logo
export const LogoLight = LogoInverted
export const MenuIcon = BiMenu
export const MinusIcon = BiMinus
export const OverlapIcon = BiTransfer
export const PencilIcon = BsPencil
export const PlayIcon = BiCaretRightCircle
export const RefreshIcon = BiRefresh
export const ResetIcon = BiReset
export const ShowIcon = BiShow
export const TargetLockIcon = BiTargetLock
export const ToolbarAxis1Icon = ToolbarAxis1
export const ToolbarAxis2Icon = ToolbarAxis2
export const ToolbarAxis3Icon = ToolbarAxis3
export const ToolbarClippingIcon = ToolbarClipping
export const ToolbarCommentCuboidIcon = ToolbarCommentCuboid
export const ToolbarCommentIcon = ToolbarComment
export const ToolbarCylinderCuboidIcon = ToolbarCylinderCuboid
export const ToolbarCylinderIcon = ToolbarCylinder
export const ToolbarCylinderOnArcIcon = ToolbarCylinderOnArc
export const ToolbarCylinderOnAxisIcon = ToolbarCylinderOnAxis
export const ToolbarDistanceIcon = ToolbarDistance
export const ToolbarMoveIcon = ToolbarMove
export const ToolbarPlaneIcon = ToolbarPlane
export const ToolbarPlaneVirtualIcon = ToolbarPlaneVirtual
export const ToolbarRotateIcon = ToolbarRotate
export const ToolbarScaleIcon = ToolbarScale
export const ToolbarSpacerAnnotationIcon = ToolbarSpacerAnnotation
export const ToolbarTorusCuboidIcon = ToolbarTorusCuboid
export const ToolbarTorusIcon = ToolbarTorus
export const ToolbarTranslateIcon = ToolbarTranslate
export const ToolbarTrimPCDCuboidIcon = BsScissors
export const TranslateIcon = BsTranslate
export const UndoIcon = BiUndo
export const UniteIcon = BiUnite
