import { FC, useContext, useEffect, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/app'

import { OverlapIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'

import { Overlap } from 'interfaces/interfaces'

import { calculateCenterAndDistance } from 'services/Editor'
import { evaluateOverlap } from 'services/Overlaps'
import { compareSortedArrays } from 'services/Util'

import { overlapComputationStarted } from '../store/editor'

const ComputeOverlapButton: FC<{ setOverlaps: (overlaps: Overlap[]) => void }> = ({ setOverlaps }) => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const { shapes, selectedShapeIds, overlaps, project } = useContext(EditorContext)

  const selectedCylinders = shapes.cylinders.filter((cylinder) => selectedShapeIds.includes(cylinder.shape_id))

  const [isLoading, setIsLoading] = useState(false)
  const [buttonClassName, setButtonClassName] = useState('')

  useEffect(() => {
    if (buttonClassName) {
      setTimeout(() => {
        setButtonClassName('')
        setOverlaps(
          overlaps.map((overlap) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { isHighlighting, ...newOverlap } = overlap
            return newOverlap
          })
        )
      }, 1200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonClassName])

  // This function returns existing overlap length objects whose cylinder pair is identical to the selected 2 cylinders.
  const getExistedOverlap = () => {
    if (!overlaps.length) {
      return false
    }

    const selectedCylinderIds = selectedCylinders.map((c) => c.shape_id)
    const existedOverlap = overlaps.find((o) => compareSortedArrays(o.cylinder_shape_ids || [], selectedCylinderIds))

    return existedOverlap || null
  }

  const onClick = async () => {
    if (selectedCylinders.length !== 2) {
      return false
    }
    dispatch(overlapComputationStarted())

    const existedOverlap = getExistedOverlap()

    // Do not proceed if overlay already exists
    if (existedOverlap) {
      setButtonClassName('animation-blinking-primary')

      const index = overlaps.findIndex((o) => o.overlap_length_id === existedOverlap.overlap_length_id)
      if (index >= 0) {
        const newOverlaps = [...overlaps]
        newOverlaps[index].isHighlighting = true
        setOverlaps(newOverlaps)
      }
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const overlap = await evaluateOverlap(token, selectedCylinders, showErrorModal)
    if (overlap) {
      setOverlaps([
        ...overlaps,
        {
          ...overlap,
          overlap_length_id: `${EDITOR_SHAPE_TEMP_ID_PREFIX}01`,
          center: calculateCenterAndDistance({ points: overlap.positions_for_distance })?.[0],
          cylinder_shape_ids: selectedCylinders.map((cylinder) => cylinder.shape_id),
        },
      ])

      // track with mixpanel
      mixpanel.track('Compute overlap length', {
        'Inspection area ID': project?.project_id,
      })
    }

    setIsLoading(false)
    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<OverlapIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={onClick}
      isLoading={isLoading}
      disabled={isLoading}
      spinnerPlacement="end"
      loadingText={t('main_canvas.action_buttons.computing', { ns: 'projects' })}
      isFullWidth
      justifyContent="space-between"
      className={buttonClassName}
    >
      {t('main_canvas.action_buttons.compute_overlap', { ns: 'projects' })}
    </Button>
  )
}

export default ComputeOverlapButton
