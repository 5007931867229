import { FC } from 'react'

import { Center, Divider } from '@chakra-ui/react'

const LayerItemDivider: FC = () => (
  <Center h={2} w="100%">
    <Divider variant="layer" />
  </Center>
)

export default LayerItemDivider
