import { FC } from 'react'

import { Box, Center, Flex, Link, Tooltip } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { HelpCircleIcon } from 'assets/icons'

const HelpButton: FC = () => {
  const { t } = useTranslation(['projects'])
  const urlHelpCenter = t('main_canvas.help_button.url', { ns: 'projects' })

  return (
    <Box>
      <Tooltip
        hasArrow
        placement="right"
        label={t('main_canvas.help_button.text', { ns: 'projects' })}
        p={2}
        fontSize="xs"
        fontWeight="normal"
        isDisabled={isTablet}
      >
        <Flex>
          <Link
            href={urlHelpCenter}
            isExternal
            variant="toolbar"
            left={1}
            width={isTablet ? 16 : 8}
            height={isTablet ? 16 : 8}
            borderRadius="md"
            onClick={() => {
              // track with mixpanel
              // As it is external link, a new window will pop up.
              // So, we can track with track() without using track_links().
              // Ref: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-website-links
              mixpanel.track('Move to modely help center', {
                'Moved from': 'toolbar in main canvas page',
                'Target URL': urlHelpCenter,
              })
            }}
          >
            <Center width="100%" height="100%">
              <HelpCircleIcon size={isTablet ? 28 : 14} />
            </Center>
          </Link>
        </Flex>
      </Tooltip>
    </Box>
  )
}

export default HelpButton
