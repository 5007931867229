import { FC, useContext } from 'react'

import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, EDITOR_MEASURE_KEYS, MAX_EDITOR_LAYERS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { zeroPad } from 'services/Util'

import { distanceSelected } from '../store/editor'
import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'

const DistancePanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const {
    selectedPoint,
    distanceAnchors,
    isLayerModifying,
    updateDistanceAnchorStatus,
    updateAllDistanceAnchorsStatus,
    changeSelectedPoint,
    deleteDistances,
  } = useContext(EditorContext)
  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.distance)
  )

  const { project_id } = useParams<{ project_id: string }>()

  const updateLayerVisibility = (invisible: boolean, index: number) => {
    updateDistanceAnchorStatus({ invisible }, index)

    // track with mixpanel
    mixpanel.track('Change visibility of distance', {
      'Inspection area ID': project_id,
      Granuarity: 'individual',
      'Visibility (new)': !invisible,
      'Visibility (old)': invisible,
      'Distance number': 1,
    })
  }

  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length
  const isSomeDistanceAnchorsVisible = distanceAnchors.some((anchor) => !anchor.invisible)

  if (!distanceAnchors?.filter((d) => !d.deleted).length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? 20 : 10}
    >
      <CollapsePanel
        title={t('main_canvas.panels.distance.title', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.distance}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={!isSomeDistanceAnchorsVisible}
            label={t('main_canvas.panels.distance.all_distances', { ns: 'projects' })}
            updateVisibility={(invisible) => updateAllDistanceAnchorsStatus({ invisible })}
          />
          {distanceAnchors.map(
            (anchor, anchorIndex) =>
              !anchor.deleted && (
                <LayerItem
                  disabled={isLayerModifying}
                  selected={selectedPoint?.anchorIndex === anchorIndex}
                  deleteLayer={() => deleteDistances(anchorIndex)}
                  invisible={anchor.invisible}
                  key={`${t('main_canvas.panels.distance.distance', { ns: 'projects' })} ${zeroPad(
                    anchorIndex + 1,
                    zeroPlaces
                  )}`}
                  // note that we put a whitespace between the text and the index
                  label={
                    <HStack fontSize={10} alignItems="baseline" spacing={1}>
                      <Text>
                        {!anchor.id || anchor.modified ? '! ' : ''}
                        {t('main_canvas.panels.distance.distance', { ns: 'projects' })} {anchor.distance || 0}
                      </Text>
                    </HStack>
                  }
                  updateVisibility={(invisible) => updateLayerVisibility(invisible, anchorIndex)}
                  onClick={() => {
                    let pointIndex = 0
                    if (selectedPoint?.anchorIndex === anchorIndex) {
                      pointIndex = selectedPoint.pointIndex + 1
                      if (pointIndex >= anchor.points.length) {
                        pointIndex = 0
                      }
                    }
                    changeSelectedPoint({ anchorIndex, pointIndex, shapeKey: EDITOR_MEASURE_KEYS.DISTANCE })
                    dispatch(distanceSelected())
                  }}
                  childLevel={1}
                />
              )
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default DistancePanel
