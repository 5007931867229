import { FC, RefObject, useContext, useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { BackSide, Camera, CylinderGeometry, Euler, Matrix4, Mesh, MeshBasicMaterial } from 'three'
import { ArcballControls } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import useMeshOutline from 'hooks/MeshOutline'
import useShape from 'hooks/Shape'

import { EDITOR_LABEL_BACKGROUND } from 'config/styles'

import { Cylinder } from 'interfaces/interfaces'

import { findDiameterKeyByValue, meterToMillimeter } from 'services/Util'

//* 鉄筋ジオメトリ
const createCylinderGeometry = (diameter: number, length: number) =>
  new CylinderGeometry(diameter / 2, diameter / 2, length, 64, 1, false, 0, Math.PI * 2)
//* 鉄筋マテリアル
const defaultMaterial = new MeshBasicMaterial({ color: 'red' })
const hoveredMaterial = new MeshBasicMaterial({ color: 'yellow' })
const selectedMaterial = new MeshBasicMaterial({ color: 'white', side: BackSide })
//* 検出された鉄筋はZ軸方向が上となるため、X軸を90度回転させるオイラー角を定義
const cylinderEuler = new Euler(Math.PI / 2, 0, 0)

export const CylinderMesh: FC<{
  cameraRef: RefObject<Camera> | null
  cylinder: Cylinder
  invisible?: boolean
  arcballControls: ArcballControls | null
}> = ({ cameraRef, cylinder, invisible, arcballControls }) => {
  const { selectedShapeIds, shapeGroups } = useContext(EditorContext)
  const diameterLabelVisible = useSelector((state: RootState) => state.editor.diameterLabelVisible)
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut, onPointerMove, isFocus } = useShape(
    cylinder,
    cameraRef
  )
  const { outlineThickness } = useMeshOutline(arcballControls)
  const [mesh, setMesh] = useState<Mesh>(new Mesh())
  const [outlineMesh, setOutlineMesh] = useState<Mesh>(new Mesh())

  const shapeGroup = shapeGroups.find((group) => group.cylinder_ids?.includes(cylinder.shape_id))
  const standardMaterial = shapeGroup
    ? new MeshBasicMaterial({ color: shapeGroup.color_palette_code })
    : defaultMaterial

  useEffect(() => {
    const { diameter, length, transformation } = cylinder
    if (transformation.length !== 16) {
      return
    }
    const matrix = new Matrix4().set(
      transformation[0],
      transformation[1],
      transformation[2],
      transformation[3],
      transformation[4],
      transformation[5],
      transformation[6],
      transformation[7],
      transformation[8],
      transformation[9],
      transformation[10],
      transformation[11],
      transformation[12],
      transformation[13],
      transformation[14],
      transformation[15]
    )

    //* X軸をZ軸方向に90度回転させ、鉄筋回転を適用したmeshを生成
    const cylinderMesh = new Mesh()
    cylinderMesh.quaternion.setFromEuler(cylinderEuler)
    cylinderMesh.applyMatrix4(matrix)

    //* 円柱の形状設定
    const geo = createCylinderGeometry(diameter, length)
    geo.translate(0, length / 2, 0)
    cylinderMesh.geometry = geo

    //* state保存
    setMesh(cylinderMesh)

    // used for outline effect
    const outlineCylinderMesh = cylinderMesh.clone()
    setOutlineMesh(outlineCylinderMesh)
  }, [cylinder])

  useEffect(() => {
    if (!outlineMesh || !selectedShapeIds.includes(cylinder.shape_id)) {
      return
    }
    // used for outline effect
    const { diameter, length } = cylinder
    const outlineGeo = createCylinderGeometry(diameter + outlineThickness, length + outlineThickness)
    outlineGeo.translate(0, length / 2, 0)
    outlineMesh.geometry = outlineGeo
  }, [cylinder, outlineMesh, outlineThickness, selectedShapeIds])

  const distanceLabel = () => {
    if (!diameterLabelVisible) {
      return null
    }
    return (
      <Html position={0} style={{ transform: 'translateX(-100%)' }} zIndexRange={[1, 9]}>
        <Box backgroundColor={EDITOR_LABEL_BACKGROUND.cylinders} px={2} fontSize="80%" fontWeight="bold" color="black">
          {findDiameterKeyByValue(meterToMillimeter(cylinder.diameter))}
        </Box>
      </Html>
    )
  }

  if (!cylinder || invisible) return null

  return (
    <>
      <mesh
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerMove={onPointerMove}
        quaternion={mesh.quaternion}
        position={mesh.position}
        geometry={mesh.geometry}
        material={isFocus ? hoveredMaterial : standardMaterial}
      >
        {distanceLabel()}
      </mesh>
      {selectedShapeIds.includes(cylinder.shape_id) && (
        <mesh
          quaternion={outlineMesh.quaternion}
          position={outlineMesh.position}
          geometry={outlineMesh.geometry}
          material={selectedMaterial}
        />
      )}
    </>
  )
}

// Handle typing for props that is not required
CylinderMesh.defaultProps = {
  invisible: undefined,
}
