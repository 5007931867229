/* eslint-disable no-param-reassign */
import { Workbook, Worksheet } from 'exceljs'
import mixpanel from 'mixpanel-browser'

import { INSPECTION_CONTRACTEE_ERROR_COLOR } from 'config/styles'

import { InspectionSheet, Project, SpacerInspectionItem, TextsInspectionSheet } from 'interfaces/interfaces'

import { formatInspectionDifferentValue } from 'services/InspectionSheet'
import { evaluateInspectionItemValue } from 'services/SpacerInspectionSheet'

import { BORDER_COLOR, HEADER_FILL, createOuterBorder, getColor, getValue, saveFile, setProfileGroup } from './XLSX'

const newWorkbook = (): Workbook => {
  const workbook = new Workbook()
  workbook.creator = 'DataLabs Modely'
  workbook.lastModifiedBy = 'DataLabs Modely'
  workbook.created = new Date()

  return workbook
}

const setGroupHeader = (
  startRow: number,
  sheet: Worksheet,
  header: string,
  columnSpace: number,
  startColumn: number
) => {
  const endColumn = startColumn + columnSpace - 1

  sheet.getRow(startRow).getCell(startColumn).value = header
  sheet.mergeCells(startRow, startColumn, startRow, endColumn)
  sheet.getRow(startRow).getCell(startColumn).fill = HEADER_FILL
  sheet.getRow(startRow).getCell(startColumn).alignment = { horizontal: 'center' }

  createOuterBorder(sheet, [startColumn, startRow], [endColumn, startRow], 'thin')
}

const setGroupHeaders = (sheet: Worksheet, textsSpacerSheet: Record<string, string>) => {
  const startRow = 8 // Profile rows is 6 + 1 row as space
  setGroupHeader(startRow, sheet, textsSpacerSheet.surface_density, 3, 2)
  setGroupHeader(startRow, sheet, textsSpacerSheet.target_area, 1, 5)
  setGroupHeader(startRow, sheet, textsSpacerSheet.spacer_number, 3, 6)
}

const setValueHeader = (startRow: number, sheet: Worksheet, headers: string[][], startColumn: number) => {
  const columnSpace = headers.length
  const endColumn = startColumn + columnSpace - 1

  headers.forEach((header, headerIndex) => {
    const [title, unit, singleTitle] = header

    sheet.getRow(startRow).getCell(startColumn + headerIndex).value = title
    sheet.getRow(startRow).getCell(startColumn + headerIndex).alignment = { horizontal: 'center' }
    sheet.getRow(startRow + 1).getCell(startColumn + headerIndex).value =
      singleTitle || (unit.length ? `（${unit}）` : `（-）`)
    sheet.getRow(startRow + 1).getCell(startColumn + headerIndex).alignment = { horizontal: 'center' }
  })

  createOuterBorder(sheet, [startColumn, startRow], [endColumn, startRow + 1], 'thin')
}
const setValueRow = (startRow: number, sheet: Worksheet, values: string[], colors: string[], startColumn: number) => {
  values.forEach((value, valueIndex) => {
    sheet.getRow(startRow).getCell(startColumn + valueIndex).value = value
    sheet.getRow(startRow).getCell(startColumn + valueIndex).alignment = { horizontal: 'center' }
  })
  colors.forEach((color, colorIndex) => {
    sheet.getRow(startRow).getCell(startColumn + colorIndex).font = { color: { argb: getColor(color) } }
  })

  const cell = sheet.getRow(startRow).getCell(startColumn)
  cell.border = {
    ...cell.border,
    left: {
      style: 'thin',
      color: BORDER_COLOR,
    },
  }
}

const setValues = (
  sheet: Worksheet,
  inspectionItems: SpacerInspectionItem[],
  itemResults: boolean[],
  texts: TextsInspectionSheet
) => {
  const startRow = 9 // Profile rows is 6 + 1 row as space + 1 row group header

  sheet.getRow(startRow + 1).getCell(1).value = texts.spacer_sheet_property.item
  createOuterBorder(sheet, [1, startRow + 1], [1, startRow + 1], 'thin')

  setValueHeader(
    startRow,
    sheet,
    [
      [texts.values.specification, texts.spacer_sheet_property.unit_surface_density],
      [texts.values.actual, texts.spacer_sheet_property.unit_surface_density],
      [texts.values.difference, texts.spacer_sheet_property.unit_surface_density],
    ],
    2
  )
  setValueHeader(startRow, sheet, [['', 'm^2']], 5)
  setValueHeader(
    startRow,
    sheet,
    [
      [texts.values.specification, texts.spacer_sheet_property.unit_number],
      [`${texts.values.actual} (${texts.spacer_sheet_property.estimate})`, texts.spacer_sheet_property.unit_number],
      [`${texts.values.actual} (${texts.spacer_sheet_property.user_input})`, texts.spacer_sheet_property.unit_number],
    ],
    6
  )
  setValueHeader(startRow, sheet, [['', '', texts.result.result]], 9)

  inspectionItems.forEach((item, itemIndex) => {
    sheet.getRow(startRow + 2 + itemIndex).getCell(1).value = item.part_name

    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        getValue(item.number_of_spacers_per_meter_square.specified_value),
        getValue(item.number_of_spacers_per_meter_square.estimated_value),
        formatInspectionDifferentValue(item.number_of_spacers_per_meter_square),
      ],
      ['black', 'black', evaluateInspectionItemValue(item.number_of_spacers_per_meter_square, texts.values).color],
      2
    )
    setValueRow(startRow + 2 + itemIndex, sheet, [getValue(item.target_area)], ['black'], 5)
    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [
        getValue(item.number_of_deployed_spacers.specified_value),
        getValue(item.number_of_deployed_spacers.estimated_value_by_surface_density),
        getValue(item.number_of_deployed_spacers.estimated_value_by_user_input),
      ],
      ['black', 'black', 'black'],
      6
    )
    setValueRow(
      startRow + 2 + itemIndex,
      sheet,
      [itemResults[itemIndex] ? texts.result.ok : texts.result.ng],
      [itemResults[itemIndex] ? 'black' : INSPECTION_CONTRACTEE_ERROR_COLOR],
      9
    )
  })

  if (inspectionItems.length) {
    createOuterBorder(sheet, [1, startRow + 2], [1 + 3 + 1 + 3 + 1, startRow + 2 + inspectionItems.length - 1], 'thin')
  }
}

/**
 * Create an XLSX exported file of an inspection sheet. Will immediately
 * forces the generated file to be saved by the user.
 *
 * @param name Name of worksheet
 * @param inspectionSheet Inspection Sheet
 * @param inspectionItems Inspection Items
 * @param itemResults flag to specify contractee/contractor
 * @param project Inspection area
 * @param texts Texts for the inspection sheet
 * @returns
 */
export const generateXLSX = async (
  name: string,
  inspectionSheet: InspectionSheet,
  inspectionItems: SpacerInspectionItem[],
  itemResults: boolean[],
  project: Project | undefined,
  texts: TextsInspectionSheet
): Promise<boolean> => {
  const workbook = newWorkbook()
  const sheet = workbook.addWorksheet(name, { pageSetup: { orientation: 'landscape', paperSize: 9 } })

  setProfileGroup(sheet, project?.project_name || '', inspectionSheet, texts.sheet_property)
  setGroupHeaders(sheet, texts.spacer_sheet_property)
  setValues(sheet, inspectionItems, itemResults, texts)

  // Re-size the column to fix the text
  sheet.columns.forEach((column) => {
    let maxLength = 0
    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
    }
    column.width = maxLength < 10 ? 10 : maxLength + 2
  })

  await saveFile(workbook, name)

  // track event to mixpanel
  mixpanel.track('Export spacer inspection sheet', {
    'Inspection area ID': project?.project_id,
    'File format': 'xlsx',
  })

  return true
}
