import { useState } from 'react'

import { useFrame } from '@react-three/fiber'
import { Camera, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

/**
 * sets the scale of 3D objects based on the distance between the camera and the target
 *
 * @export
 * @param {(ArcballControls | null | undefined)} arcballControls arcballControls instance
 * @returns {{ scale: number }} scale of 3D objects
 */
export default function usePointScale(arcballControls: ArcballControls | null | undefined) {
  const [scale, setScale] = useState(1)

  useFrame(() => {
    setScale(
      Math.max(
        arcballControls
          ? // eslint-disable-next-line dot-notation
            (arcballControls['target'] as Vector3).distanceTo((arcballControls['camera'] as Camera).position) * 2
          : 1,
        1
      )
    )
  })

  return { scale }
}
