import { FC } from 'react'

import { Box, Button, Collapse, Flex } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { BigChevronDownIcon as ChevronDownIcon, BigChevronUpIcon as ChevronUpIcon } from 'assets/icons'

import { panelToggled } from '../../store/editor'

const CollapsePanel: FC<{ title: string; type: string }> = ({ children, title, type }) => {
  const dispatch = useAppDispatch()
  const expandedPanels = useSelector((state: RootState) => state.editor.expandedPanels)
  const isOpen = expandedPanels.includes(type)

  return (
    <Flex w="100%" maxH="100%" flex={1} flexDirection="column">
      <Button
        onClick={() => dispatch(panelToggled(type))}
        w="100%"
        variant="layer"
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        paddingInlineStart={0}
      >
        {title}
      </Button>
      <Box flex={1} overflowX="hidden" overflowY="auto" className="collapse-content">
        <Collapse in={isOpen} animateOpacity>
          {children}
        </Collapse>
      </Box>
    </Flex>
  )
}

export default CollapsePanel
