import { EDITOR_SHAPES_SITUATIONS } from 'config/constants'

import { InspectionItem, ShapeDistance, ShapesDistances } from 'interfaces/interfaces'

/**
 * Get all of the distances between shapes in all the inspection items.
 * @param {InspectionItem[]} inspectionItems
 * @returns {ShapesDistances}
 */
const getShapeDistancesFromInspectionItem = (inspectionItems: InspectionItem[]): ShapesDistances => {
  const distances = {
    cylinders: new Array<ShapeDistance>(),
    planes: new Array<ShapeDistance>(),
    tori: new Array<ShapeDistance>(),
  }
  inspectionItems.forEach((item) => {
    distances.planes = distances.planes.concat(item.cylinders_and_plane_individual_distance || [])
    distances.cylinders = distances.cylinders.concat(
      item.situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC ||
        item.situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS
        ? item.aligned_shapes_individual_distance || []
        : []
    )
    distances.tori = distances.tori.concat(
      item.situation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS ? item.aligned_shapes_individual_distance || [] : []
    )
  })

  return distances
}

export { getShapeDistancesFromInspectionItem }
