import { FC, useContext, useState } from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { AddIcon, HDotsIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { deleteInvitedUserFromProjectGroup } from 'services/Projects'
import { decideActionPermission } from 'services/Validation'

import InviteUserModal from './components/InviteUserModal'

const InvitedUsersTable: FC = () => {
  const { t } = useTranslation(['dashboard'])
  const { project_group_id } = useParams<{ project_group_id: string }>()
  const { projectGroups, invitedProjectGroups, getProjectGroups } = useContext(ProjectsContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken, userType } = useContext(UserContext)

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const ownProjectGroup = projectGroups.find((proj) => proj.project_group_id === project_group_id)
  const invitedProjectGroup = invitedProjectGroups.find((proj) => proj.project_group_id === project_group_id)
  const projectGroup = invitedProjectGroup || ownProjectGroup
  const invitedUsers = { ...(ownProjectGroup?.shared_user || {}), ...(invitedProjectGroup?.shared_user || {}) }

  const isAllowedInvite = decideActionPermission(
    !!ownProjectGroup,
    !!invitedProjectGroup
  ).PROJECT_DASHBOARD.INVITE_USER_TO_PROJECT.includes(userType)

  const confirmInvitationDelete = (userId: string) => {
    if (!project_group_id) {
      return false
    }

    showModal({
      body: (
        <>
          <Text>{t('invited_users.delete_warning', { ns: 'dashboard' })}</Text>
          <Text mt="1" fontWeight="semibold">
            {invitedUsers[userId]}
          </Text>
        </>
      ),
      confirmText: t('invited_users.delete', { ns: 'dashboard' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const deleteInvitationApi = async () => {
          setIsLoading(true)
          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            return false
          }

          const deleteResult = await deleteInvitedUserFromProjectGroup(token, project_group_id, userId, showErrorModal)
          if (!deleteResult) {
            setIsLoading(false)
            return false
          }

          await getProjectGroups()
          setIsLoading(false)
          return true
        }

        void deleteInvitationApi()
        return true
      },
      title: t('invited_users.confirm_delete_invitee', { ns: 'dashboard' }),
    })
    return true
  }

  if (!project_group_id || !projectGroup) {
    return null
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <Breadcrumb pt={8}>
          <BreadcrumbItem>
            <Link as={RouterLink} to="/dashboard" variant="neutral">
              {t('invited_users.project', { ns: 'dashboard' })}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>{invitedProjectGroup?.project_group_name || ownProjectGroup?.project_group_name}</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading>{t('invited_users.list_of_invitees', { ns: 'dashboard' })}</PageHeading>
        {isAllowedInvite && (
          <Button
            variant="outlinePrimary"
            size="md"
            mb={7}
            onClick={() => setIsInviteModalOpen(true)}
            rightIcon={<AddIcon />}
            aria-label="invite"
          >
            {t('invited_users.invite_user', { ns: 'dashboard' })}
          </Button>
        )}
        <TableContainer mb={10}>
          <Table w="100%" variant="simple" size="sm">
            <Thead fontSize="sm">
              <Tr>
                <Th>{t('invited_users.email', { ns: 'dashboard' })}</Th>
                <Th w="1%" />
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(invitedUsers).map((userId: string) => (
                <Tr key={userId}>
                  <Td>{invitedUsers[userId]}</Td>
                  <Td>
                    <Menu autoSelect={false}>
                      <MenuButton
                        disabled={!ownProjectGroup || isLoading}
                        variant="ghost"
                        as={IconButton}
                        aria-label="Actions"
                        fontSize="lg"
                        icon={<HDotsIcon />}
                      />
                      <Portal>
                        <MenuList>
                          {!!ownProjectGroup && (
                            <MenuItem aria-label={`delete.${userId}`} onClick={() => confirmInvitationDelete(userId)}>
                              {t('invited_users.delete', { ns: 'dashboard' })}
                            </MenuItem>
                          )}
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <InviteUserModal
          isOpen={isInviteModalOpen}
          invitingProjectGroup={projectGroup}
          onConfirm={() => setIsInviteModalOpen(false)}
        />
      </Container>
    </>
  )
}

export default InvitedUsersTable
