import { FC, useCallback, useContext, useEffect, useState } from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { AddIcon, HDotsIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import useIFCFiles from 'hooks/IFCFiles'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { IFCFile } from 'interfaces/project'

import { getIFCFiles } from 'services/IFC'
import { decideActionPermission } from 'services/Validation'

import UploadIFCModal from './components/UploadIFCModal'

const IFCFilesTable: FC = () => {
  const { t } = useTranslation(['dashboard'])
  const { project_group_id } = useParams<{ project_group_id: string }>()
  const { projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken, userType } = useContext(UserContext)

  const [files, setFiles] = useState<IFCFile[]>([])
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const ownProjectGroup = projectGroups.find((proj) => proj.project_group_id === project_group_id)
  const invitedProjectGroup = invitedProjectGroups.find((proj) => proj.project_group_id === project_group_id)
  const projectGroup = invitedProjectGroup || ownProjectGroup

  const isAllowedModify = decideActionPermission(
    !!ownProjectGroup,
    !!invitedProjectGroup
  ).PROJECT_DASHBOARD.MODIFY.includes(userType)

  const fetchData = useCallback(async () => {
    if (!projectGroup?.project_group_id) {
      return Promise.resolve()
    }

    const token = await getAccessToken()
    if (!token) return Promise.resolve()

    setIsLoading(true)

    const results = await getIFCFiles(token, projectGroup.project_group_id, showErrorModal)
    if (results) setFiles(results)

    setIsLoading(false)

    return Promise.resolve()
  }, [getAccessToken, projectGroup, showErrorModal])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  const { confirmFileDelete } = useIFCFiles(
    project_group_id,
    undefined,
    isAllowedModify,
    files,
    setIsLoading,
    fetchData
  )

  if (!project_group_id || !projectGroup) {
    return null
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <Breadcrumb pt={8}>
          <BreadcrumbItem>
            <Link as={RouterLink} to="/dashboard" variant="neutral">
              {t('ifc_files.project', { ns: 'dashboard' })}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>{projectGroup?.project_group_name}</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading>{t('ifc_files.list_of_ifc_files', { ns: 'dashboard' })}</PageHeading>
        {isAllowedModify && (
          <Button
            variant="outlinePrimary"
            size="md"
            mb={7}
            onClick={() => setIsUploadModalOpen(true)}
            rightIcon={<AddIcon />}
            aria-label="invite"
          >
            {t('ifc_files.upload_ifc_file', { ns: 'dashboard' })}
          </Button>
        )}
        <TableContainer mb={10}>
          <Table w="100%" variant="simple" size="sm">
            <Thead fontSize="sm">
              <Tr>
                <Th>{t('ifc_files.file', { ns: 'dashboard' })}</Th>
                <Th w="1%" />
              </Tr>
            </Thead>
            <Tbody>
              {files.map((file) => (
                <Tr key={file.ifc_file_id}>
                  <Td>{file.original_filename}</Td>
                  <Td>
                    <Menu autoSelect={false}>
                      <MenuButton
                        disabled={!isAllowedModify || isLoading}
                        variant="ghost"
                        as={IconButton}
                        aria-label="Actions"
                        fontSize="lg"
                        icon={<HDotsIcon />}
                      />
                      <Portal>
                        <MenuList>
                          {!!isAllowedModify && (
                            <MenuItem aria-label={`delete.${file.ifc_file_id}`} onClick={() => confirmFileDelete(file)}>
                              {t('ifc_files.delete', { ns: 'dashboard' })}
                            </MenuItem>
                          )}
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <UploadIFCModal
          isOpen={isUploadModalOpen}
          onConfirm={async (result) => {
            setIsUploadModalOpen(false)

            if (result) {
              await fetchData()
            }
          }}
          projectGroup={projectGroup}
        />
      </Container>
    </>
  )
}

export default IFCFilesTable
