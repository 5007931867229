import { Box3, Camera, Euler, Line3, Matrix3, Matrix4, Matrix4Tuple, Plane, Points, Quaternion, Vector3 } from 'three'

import {
  AUTO_DETECT_DUMMY_AMOUNT_ON_ARC,
  AUTO_DETECT_DUMMY_AMOUNT_ON_AXIS,
  EDITOR_CUBOID_DIRECTIONS,
  EDITOR_SHAPES_SITUATIONS,
} from 'config/constants'

import { Cuboid, CuboidDirection, PointArray, Plane as Rectangle } from 'interfaces/interfaces'

/**
 * Set the visibility of a cloned Points object
 * @param {boolean} isVisible whether the Points object is visible or not
 * @return {Points} cloned Points object with the visibility set
 */
export const setPointsVisible = (points: Points, isVisible: boolean): Points => {
  const clonedPoints = points.clone()
  clonedPoints.visible = isVisible
  return clonedPoints
}

/**
 * compute the distance between the point and the camera object.
 * If camera is null, return 0.
 *
 * @param {Vector3} position 3D coordinates of the point
 * @param {(Camera | null)} camera camera object
 * @returns {number} distance between the point and the camera
 */
export const getCameraDistance = (position: Vector3, camera: Camera | null): number => {
  if (!camera) {
    return 0
  }

  const distance = camera.position.clone().distanceTo(position)
  return distance
}

/**
 * Estimate the center and radius of a circle from 3 points,
 * which are assumed to be on the arc of the circle.
 *
 * @param {Vector3[]} points 3D coordinates of 3 points
 * @returns {[Vector3, number]} center coordinate and radius
 */
export const findCircle = (points: Vector3[]): [Vector3, number] => {
  // https://gamedev.stackexchange.com/a/60631
  const ac = points[2].clone().sub(points[0])
  const ab = points[1].clone().sub(points[0])
  const abXac = ab.clone().cross(ac)

  // this is the vector from point a to the circum sphere center
  const toCircumSphereCenter = abXac
    .clone()
    .cross(ab)
    .multiplyScalar(ac.length() ** 2)
    .add(
      ac
        .clone()
        .cross(abXac)
        .multiplyScalar(ab.length() ** 2)
    )
    .divideScalar(2 * abXac.length() ** 2)

  // The 3 space coords of the circum sphere center then:
  return [
    points[0].clone().add(toCircumSphereCenter), // now this is the actual 3space location
    toCircumSphereCenter.length(),
  ]
}

/**
 * Fix the third point (C -> D) to make it place on the normal vector of the line between point 1 (A) and point 2 (B)
 * ref: https://docs.google.com/presentation/d/1-_qYSQdIpZ6SeF9G_5n0scfgPz6bTrJhJIVS4wJttcY/edit#slide=id.g1a91a669376_0_0
 * @param {Vector3[]} points input 3 points
 * @returns {Vector3[]} corrected 3 points. The first 2 points are the same as the input while the last point is corrected.
 */
export const fixVertexOnNormal = (points: Vector3[]): Vector3[] => {
  if (points.length !== 3) {
    return points
  }

  const directionAB = points[1].clone().sub(points[0]).normalize()
  const directionNormal = points[2].clone().sub(points[1]).cross(directionAB).normalize()
  const directionAD = directionAB.cross(directionNormal).normalize()
  const distance = new Line3(points[0], points[1])
    .closestPointToPoint(points[2], false, new Vector3())
    .distanceTo(points[2])
  const newPoint = points[1].clone().add(directionAD.multiplyScalar(distance))

  return [points[0], points[1], newPoint]
}

/**
 * Find the last point of a Parallelogram from previous 3 points.
 *
 * @param {Vector3[]} points input 3 points
 * @returns {Vector3} corrected last point
 */
export const findMissingVertexParallelogram = (points: Vector3[]): Vector3 => {
  if (points.length !== 3) {
    return new Vector3()
  }

  // Find the center point between point_0 and point_2
  const centerPoint = new Line3(points[0].clone(), points[2].clone()).getCenter(new Vector3())
  // Missing point is the point go from point_1 across the center point,
  // with 2 times distance from point_1 to the center point
  const missingCrossVector = centerPoint.sub(points[1])
  const missingCrossLength = missingCrossVector.length()
  const missingCenterVector = missingCrossVector.normalize().multiplyScalar(missingCrossLength * 2)
  const missingCenterPoint = points[1].clone().add(missingCenterVector)

  return missingCenterPoint
}

/**
 * convert array of PointArray to array of Vector3
 *
 * @param {PointArray[]} points array of PointArray
 * @returns {Vector3[]} array of Vector3
 */
export const pointsToVector3s = (points: PointArray[]): Vector3[] => points.map((point) => new Vector3(...point))

/**
 * compute a point that is moved up from the target point along the normal vector of the plane
 *
 * @param {Vector3} target target point
 * @param {Vector3} planeNormal normal vector of the plane
 * @param {number} distance distance to move up
 * @returns {Vector3} resultant point
 */
const movePointUp = (target: Vector3, planeNormal: Vector3, distance: number): Vector3 =>
  new Vector3().addVectors(target.clone(), planeNormal.clone().multiplyScalar(distance))

/**
 * creates a cuboid properties from 3 points
 * Its 3 edges are parallel to:
 * width: points[0] -> points[1],
 * depth: points[1] -> points[2],
 * height: points[3] -> plane(points[0], points[1], points[2])
 *
 * @param {Vector3[]} points 3 points to create a cuboid from them
 * @param {number} minSize minimum size of the 3 dimensions of the cuboid
 * @returns {{ width: number; depth: number; height: number; pose: Matrix4; }}
 */
export const getCuboidFromPoints = (points: Vector3[], minSize: number) => {
  const bottomPlane = new Plane().setFromCoplanarPoints(points[0], points[1], points[2])
  const bottomPlaneNormal = bottomPlane.normal.normalize()

  const width = Math.max(minSize, Math.abs(points[0].distanceTo(points[1])))
  const depth = Math.max(minSize, Math.abs(points[1].distanceTo(points[2])))
  const height = bottomPlane.distanceToPoint(points[3])
  const absHeight = Math.max(minSize, Math.abs(height))

  const center = new Line3(points[0], points[2]).getCenter(new Vector3())
  const position = movePointUp(center, bottomPlaneNormal, height / 2)
  const rotationMatrix = new Matrix4().makeBasis(
    points[0].clone().sub(points[1]).normalize(),
    new Plane().setFromCoplanarPoints(points[0], points[1], points[2]).normal.normalize(),
    points[2].clone().sub(points[1]).normalize()
  )

  const pose = rotationMatrix.clone().setPosition(position)

  return {
    width,
    depth,
    height: absHeight,
    pose,
  }
}

/**
 * creates a plane object from 3 points
 *
 * @param {Vector3[]} points 3 points to create a plane from them
 * @param {string} id ID to include in the resultant plane object
 * @returns {Rectangle} resultant plane object
 */
export const getPlaneFromPoints = (points: Vector3[], id: string): Rectangle => {
  const transformMatrix = new Matrix4()
    .makeBasis(
      points[0].clone().sub(points[1]).normalize(),
      points[2].clone().sub(points[1]).normalize(),
      points[0].clone().sub(points[1]).cross(points[2].clone().sub(points[1])).normalize()
    )
    .invert()
    .toArray() as Matrix4Tuple
  transformMatrix[3] = points[1].x
  transformMatrix[7] = points[1].y
  transformMatrix[11] = points[1].z

  return {
    shape_id: id,
    length_1: points[0].distanceTo(points[1]),
    length_2: points[1].distanceTo(points[2]),
    transformation: transformMatrix,
  } as Rectangle
}

/**
 * returns transform matrix from the source frame to the target frame
 * from the pair of 3 points in each frame
 *
 * @param {Vector3[]} pointsSource 3 points used as a source coordinate system
 * @param {Vector3[]} pointsTarget 3 points used as a target coordinate system
 * @returns {Matrix4} transform matrix from the source frame to the target frame
 */
export const computeTransformFromPairedPoints = (pointsSource: Vector3[], pointsTarget: Vector3[]): Matrix4 => {
  // set orientation/translation of the coordinate system made of the 3 source points
  const verticesRectangleSource = fixVertexOnNormal(pointsSource)
  const transformSource = new Matrix4().makeBasis(
    verticesRectangleSource[2].clone().sub(verticesRectangleSource[1]).normalize(),
    verticesRectangleSource[1].clone().sub(verticesRectangleSource[0]).normalize(),
    verticesRectangleSource[2]
      .clone()
      .sub(verticesRectangleSource[1])
      .cross(verticesRectangleSource[1].clone().sub(verticesRectangleSource[0]))
      .normalize()
  )
  transformSource.setPosition(verticesRectangleSource[0])

  // set orientation/translation of the coordinate system made of the 3 target points
  const verticesRectangleTarget = fixVertexOnNormal(pointsTarget)
  const transformTarget = new Matrix4().makeBasis(
    verticesRectangleTarget[2].clone().sub(verticesRectangleTarget[1]).normalize(),
    verticesRectangleTarget[1].clone().sub(verticesRectangleTarget[0]).normalize(),
    verticesRectangleTarget[2]
      .clone()
      .sub(verticesRectangleTarget[1])
      .cross(verticesRectangleTarget[1].clone().sub(verticesRectangleTarget[0]))
      .normalize()
  )
  transformTarget.setPosition(verticesRectangleTarget[0])

  // return the transformation matrix that maps the source coordinate system to the target coordinate system
  return transformTarget.multiply(transformSource.clone().invert())
}

/**
 * Judge whether the registration result is valid
 * by checking the error between the transformed source points and target points
 *
 * @param {Vector3[]} pointsSource points in the source frame
 * @param {Vector3[]} pointsTarget points in the target frame
 * @param {Matrix4} transform transformation matrix from the source frame to the target frame
 * @param {number} threshold threshold to determine if the registration is successful
 * @throws {Error} when the number of points in the source and target are different
 * @returns {boolean} whether the registration is valid or not
 */
export const isValidRegistration = (
  pointsSource: Vector3[],
  pointsTarget: Vector3[],
  transform: Matrix4,
  threshold: number
): boolean => {
  if (pointsSource.length !== pointsTarget.length) {
    throw new Error('The number of the source and the target points are different.')
  }

  const transformedSourcePoints = pointsSource.map((point) => point.clone().applyMatrix4(transform))
  for (let i = 0; i < pointsTarget.length; i += 1) {
    if (transformedSourcePoints[i].distanceTo(pointsTarget[i]) > threshold) {
      return false
    }
  }
  return true
}

/**
 * converts a cuboid object whose axis is set to be z direction
 * to a new one whose axis is set to be the specified direction (x/y)
 *
 * @param {Cuboid} cuboid cuboid object to be converted
 * @param {?CuboidDirection} [cuboidDirection] direction to set the axis (x/y/z)
 * @returns {Cuboid} converted cuboid object
 */
export const rotateCuboid = (cuboid: Cuboid, cuboidDirection?: CuboidDirection): Cuboid => {
  const newCuboid = {
    masking_region_id: cuboid.masking_region_id,
    center: [...cuboid.center] as PointArray,
    extent: [...cuboid.extent] as PointArray,
    rotation: cuboid.rotation.clone(),
  }
  if (cuboidDirection === EDITOR_CUBOID_DIRECTIONS.X) {
    newCuboid.extent = [newCuboid.extent[1], newCuboid.extent[2], newCuboid.extent[0]]
    const rotationArray = newCuboid.rotation.toArray()
    newCuboid.rotation = new Matrix3().fromArray([
      rotationArray[3], // y
      rotationArray[4], // y
      rotationArray[5], // y
      rotationArray[6], // z
      rotationArray[7], // z
      rotationArray[8], // z
      rotationArray[0], // x
      rotationArray[1], // x
      rotationArray[2], // x
    ])
  } else if (cuboidDirection === EDITOR_CUBOID_DIRECTIONS.Y) {
    newCuboid.extent = [newCuboid.extent[2], newCuboid.extent[0], newCuboid.extent[1]]
    const rotationArray = newCuboid.rotation.toArray()
    newCuboid.rotation = new Matrix3().fromArray([
      rotationArray[6], // z
      rotationArray[7], // z
      rotationArray[8], // z
      rotationArray[0], // x
      rotationArray[1], // x
      rotationArray[2], // x
      rotationArray[3], // y
      rotationArray[4], // y
      rotationArray[5], // y
    ])
  }
  return newCuboid
}

/**
 * compute 7 vertices of a cuboid object
 *
 * @param {Cuboid} cuboid cuboid object
 * @returns {Vector3[]} 7 vertices of the cuboid
 */
export const getVerticesFromCuboid = (cuboid: Cuboid): Vector3[] => {
  const centerVector = new Vector3(cuboid.center[0], cuboid.center[1], cuboid.center[2])
  const vertex0 = centerVector
    .clone()
    .add(
      new Vector3(-cuboid.extent[0] / 2, -cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )
  const vertex1 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, -cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )
  const vertex2 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, -cuboid.extent[1] / 2, cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )
  const vertex3 = centerVector
    .clone()
    .add(
      new Vector3(-cuboid.extent[0] / 2, -cuboid.extent[1] / 2, cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )
  const vertex4 = centerVector
    .clone()
    .add(
      new Vector3(-cuboid.extent[0] / 2, cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )
  const vertex5 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, cuboid.extent[1] / 2, -cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )
  const vertex6 = centerVector
    .clone()
    .add(
      new Vector3(cuboid.extent[0] / 2, cuboid.extent[1] / 2, cuboid.extent[2] / 2).applyMatrix3(
        cuboid.rotation.clone()
      )
    )

  return [vertex0, vertex1, vertex2, vertex3, vertex4, vertex5, vertex6]
}

/**
 * Generate model matrix (world space) for a cuboid so we can calculate each
 * fragment location in the cuboid's local space.
 * Min and max values are also calculated for each cuboid to determine
 * if the fragment is inside the cuboid.
 *
 * @param {Cuboid} cuboid Cuboid to generate data
 * @returns {{ modelMatrix: Matrix4; min: Vector3; max: Vector3; unsaved: boolean, visible: boolean }}
 * - The resultant model matrix and related properties
 */
export const generateCuboidUniforms = (
  cuboid: Cuboid
): { modelMatrix: Matrix4; min: Vector3; max: Vector3; unsaved: boolean; visible: boolean } => {
  const { center, rotation, extent } = cuboid

  const boundingBox = new Box3(
    new Vector3(-extent[0] / 2, -extent[1] / 2, -extent[2] / 2),
    new Vector3(extent[0] / 2, extent[1] / 2, extent[2] / 2)
  )

  // Calculate the model matrix
  const modelMatrix = new Matrix4()
  modelMatrix
    .compose(
      new Vector3(...center),
      new Quaternion().setFromRotationMatrix(new Matrix4().setFromMatrix3(rotation)),
      new Vector3(1, 1, 1)
    )
    .invert()

  return {
    modelMatrix,
    min: boundingBox.min,
    max: boundingBox.max,
    unsaved: false,
    visible: !cuboid.invisible,
  }
}

/**
 * computes the middle point between two points
 *
 * @param {Vector3} startPoint start point
 * @param {Vector3} endPoint end point
 * @returns {Vector3} middle point
 */
export const getMidPoint = (startPoint: Vector3, endPoint: Vector3): Vector3 => {
  const mid = new Vector3()
  mid.addVectors(startPoint, endPoint)
  mid.multiplyScalar(0.5)
  return mid
}

/**
 * gets the vertices of a rhombus at default pose
 *
 * @param {number} length length of edges of rhombus
 * @param {number} angle angle of a corner of rhombus
 * @returns {PointArray[]} vertices of rhombus
 */
export const getVerticesFromRhombus = (length: number, angle: number): PointArray[] => {
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)
  const point1: PointArray = [0, 0, 0]
  const point2: PointArray = [length * cos, length * sin, 0]
  const point3: PointArray = [length * (1 + cos), length * sin, 0]
  const point4: PointArray = [length, 0, 0]
  return [point1, point2, point3, point4]
}

/**
 * gets the vertices of a rectangle plane at default pose
 *
 * @param {number} length_1 length of an edge of the rectangle
 * @param {number} length_2 length of another edge of the rectangle
 * @returns {PointArray[]} vertices of rectangle plane
 */
export const getVerticesFromPlane = (length_1: number, length_2: number): PointArray[] => {
  const point1: PointArray = [0, 0, 0]
  const point2: PointArray = [length_1, 0, 0]
  const point3: PointArray = [length_1, length_2, 0]
  const point4: PointArray = [0, length_2, 0]
  return [point1, point2, point3, point4]
}

/**
 * Generates an array of numbers distributed evenly between -length/2 and length/2
 *
 * @param {number} length length of the array
 * @returns {number[]} resultant array
 */
const generateFlatPoints = (length: number): number[] =>
  new Array(AUTO_DETECT_DUMMY_AMOUNT_ON_AXIS)
    .fill(1)
    .map((_, index) => (index * length) / (AUTO_DETECT_DUMMY_AMOUNT_ON_AXIS - 1) - length / 2)

/**
 * Generates an array of points distributed evenly on a circle with a given radius
 *
 * @param {number} radius radius of the circle
 * @returns {number[][]} resultant array of 2D positions
 */
const generateRoundPoints = (radius: number): number[][] =>
  new Array(AUTO_DETECT_DUMMY_AMOUNT_ON_ARC)
    .fill(1)
    .map((_, index) => [
      Math.cos((Math.PI * 2 * index) / AUTO_DETECT_DUMMY_AMOUNT_ON_ARC) * radius,
      Math.sin((Math.PI * 2 * index) / AUTO_DETECT_DUMMY_AMOUNT_ON_ARC) * radius,
    ])

/**
 * generates data needed to create dummy shapes aligned in a cuboid
 *
 * @param {(Cuboid | undefined)} editorCuboid cuboid
 * @param {string} autoDetectSituation situation for shape alignment
 * @param {number} convertedDiameter diameter of cylinders aligned on a circle arc
 * @param {(string | undefined)} cuboidDirection alignment axis
 * @returns {{ dummyLength: number; dummyRotation: Euler; dummyPositions: number[][]; }} data needed to create dummy shapes
 */
export const generateDummyFactors = (
  editorCuboid: Cuboid | undefined,
  autoDetectSituation: string,
  convertedDiameter: number,
  cuboidDirection: string | undefined
): { dummyLength: number; dummyRotation: Euler; dummyPositions: number[][] } => {
  const editorCuboidWidth = editorCuboid?.extent[0] || 0
  const editorCuboidDepth = editorCuboid?.extent[1] || 0
  const editorCuboidHeight = editorCuboid?.extent[2] || 0

  // initialize variables
  let compareSides: number[] = []
  let mainSide = 0
  let getRoundPoint: (points: number[]) => number[] = () => [0, 0, 0]
  let getFlatPoint: (point: number) => number[] = () => [0, 0, 0]
  let dummyRotation = new Euler()
  let dummyLength = 0

  const angle = Math.PI / 2

  // set variables based on alignment axis
  if (cuboidDirection === 'x') {
    compareSides = [editorCuboidDepth, editorCuboidHeight]
    mainSide = editorCuboidWidth
    getRoundPoint = ([y, z]) => [0, y, z]
    getFlatPoint = (x) => [x, 0, 0]
  } else if (cuboidDirection === 'y') {
    compareSides = [editorCuboidWidth, editorCuboidHeight]
    mainSide = editorCuboidDepth
    getRoundPoint = ([x, z]) => [x, 0, z]
    getFlatPoint = (y) => [0, y, 0]
  } else if (cuboidDirection === 'z') {
    compareSides = [editorCuboidWidth, editorCuboidDepth]
    mainSide = editorCuboidHeight
    getRoundPoint = ([x, y]) => [x, y, 0]
    getFlatPoint = (z) => [0, 0, z]
  }

  // compute dummy length
  if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS) {
    dummyLength = Math.max(...compareSides)
  } else if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC) {
    dummyLength = mainSide
  } else if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS) {
    dummyLength = Math.min(...compareSides)
  }

  // compute dummy rotation
  if (cuboidDirection === 'x') {
    dummyRotation = compareSides[0] > compareSides[1] ? new Euler() : new Euler(angle, 0, 0)
    if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC) {
      dummyRotation = new Euler(0, 0, angle)
    } else if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS) {
      dummyRotation = new Euler(0, angle, 0)
    }
  } else if (cuboidDirection === 'y') {
    dummyRotation = compareSides[0] > compareSides[1] ? new Euler(0, 0, angle) : new Euler(angle, 0, 0)
    if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC) {
      dummyRotation = new Euler(0, angle, 0)
    } else if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS) {
      dummyRotation = new Euler(angle, 0, 0)
    }
  } else if (cuboidDirection === 'z') {
    dummyRotation = compareSides[0] > compareSides[1] ? new Euler(0, 0, angle) : new Euler()
    if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC) {
      dummyRotation = new Euler(angle, 0, 0)
    } else if (autoDetectSituation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS) {
      dummyRotation = new Euler(0, 0, angle)
    }
  }

  // compute dummy positions
  const dummyPositions =
    autoDetectSituation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC
      ? generateRoundPoints((Math.min(...compareSides) - convertedDiameter) / 2).map(getRoundPoint)
      : generateFlatPoints(mainSide).map(getFlatPoint)

  return { dummyLength, dummyRotation, dummyPositions }
}
