import { useCallback, useContext, useEffect, useState } from 'react'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { Comment, Cuboid, ModalProps, Position, RectangleInBlueprint } from 'interfaces/interfaces'

import { deleteComment, editComment, getComments } from 'services/Comments'

const useComment = (
  project_id: string | undefined,
  selectedComment: Comment | undefined,
  setSelectedComment: (comment?: Comment) => void,
  resetCommentStates: () => void,
  showModal: (props: ModalProps) => void
) => {
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  const [isLoading, setIsLoading] = useState(false)
  const [comments, setComments] = useState<Comment[]>([])

  const fetchComments = useCallback(async () => {
    if (!project_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const retrievedComments = await getComments(token, project_id, showErrorModal)

    if (retrievedComments) {
      if (selectedComment) {
        setSelectedComment(retrievedComments.find((comment) => comment.thread_id === selectedComment.thread_id))
      }
      setComments(retrievedComments)
    } else {
      setComments([])
      setSelectedComment(undefined)
    }

    setIsLoading(false)
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id, getAccessToken, showErrorModal, selectedComment])

  const onUpdateComment = async (comment: Comment) => {
    if (!project_id || !comment.thread_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    if (
      await editComment(
        token,
        project_id,
        comment.thread_id,
        comment.author_name,
        comment.thread_body,
        comment.cartesian_position,
        comment.cuboid_position,
        comment.blueprint_position,
        comment.blueprint_id,
        showErrorModal
      )
    ) {
      await fetchComments()
      setIsLoading(false)
      setSelectedComment(undefined)
      resetCommentStates()
      return true
    }

    setSelectedComment(undefined)
    resetCommentStates()
    setIsLoading(false)
    return false
  }

  const onConfirmMoveBlueprintPosition = async (blueprint_id: string, blueprint_position: RectangleInBlueprint) => {
    if (!selectedComment) {
      return false
    }

    const newComment = { ...selectedComment, blueprint_position, blueprint_id }
    return onUpdateComment(newComment)
  }

  const onConfirmMoveCartesianPosition = async (cartesian_position: Position) => {
    if (!selectedComment) {
      return false
    }

    const newComment = { ...selectedComment, cartesian_position, cuboid_position: undefined }
    return onUpdateComment(newComment)
  }

  const onConfirmMoveCuboid = async (cuboid_position: Cuboid) => {
    if (!selectedComment) {
      return false
    }

    const newComment = { ...selectedComment, cuboid_position, cartesian_position: undefined }
    return onUpdateComment(newComment)
  }

  useEffect(() => {
    void (() => fetchComments())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id])

  const openDeleteCommentConfirmModal = () => {
    if (!project_id || !selectedComment) {
      return
    }

    showModal({
      body: '一度削除してしまうと、元に戻せません。',
      confirmText: '削除',
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          if (!selectedComment?.thread_id) {
            return false
          }

          setIsLoading(true)
          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            setSelectedComment(undefined)
            resetCommentStates()
            return false
          }

          if (await deleteComment(token, project_id, selectedComment.thread_id, showErrorModal)) {
            await fetchComments()
            setIsLoading(false)
            setSelectedComment(undefined)
            resetCommentStates()
            return true
          }

          setSelectedComment(undefined)
          resetCommentStates()
          setIsLoading(false)
          return false
        })()
        return true
      },
      title: '確認',
    })
  }

  return {
    comments,
    isLoading,
    fetchComments,
    openDeleteCommentConfirmModal,
    onConfirmMoveBlueprintPosition,
    onConfirmMoveCartesianPosition,
    onConfirmMoveCuboid,
  }
}

export default useComment
