import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { Container, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import { NavLink as RouterNavLink } from 'react-router-dom'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { USER_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { UserProfile } from 'interfaces/user'

import { getSubscriptionStatus, getUserSubscription } from 'services/Usage'
import { getUserList } from 'services/Users'

import { Subscription } from '../../interfaces/usage'

const getSubscriptionsByUserIds = async (
  users: UserProfile[],
  token: string,
  showErrorModal: (message: string) => void
) => {
  const subscriptionsWithUserProfile = Promise.all(
    users.map(async (user) => {
      const { user_id, subscription_id } = user

      if (!subscription_id) {
        return { user_id, user, subscription: null }
      }

      const subscription = await getUserSubscription(token, user_id, subscription_id, showErrorModal)
      return { user_id, user, subscription }
    })
  )

  return subscriptionsWithUserProfile
}

const generateUserIdsToSubscriptionStatus = (
  subscriptions: {
    user_id: string
    user: UserProfile
    subscription: Subscription | null
  }[]
) => {
  const subscriptionStatus = subscriptions.reduce((acc: Record<string, string>, { user_id, user, subscription }) => {
    acc[user_id] = getSubscriptionStatus(user, subscription)
    return acc
  }, {})

  return subscriptionStatus
}

const UserList: FC = () => {
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken, userType } = useContext(UserContext)

  const [users, setUsers] = useState<UserProfile[]>([])
  const [userIdsToSubscriptionStatus, setUserIdsToSubscriptionStatus] = useState<Record<string, string>>({})

  const fetchData = useCallback(async () => {
    const token = await getAccessToken()
    if (!token) return Promise.resolve()

    const result = await getUserList(token, showErrorModal)
    if (result) {
      setUsers(result)

      const userIdsToSubscriptions = await getSubscriptionsByUserIds(result, token, showErrorModal)
      const lookupTable = generateUserIdsToSubscriptionStatus(userIdsToSubscriptions)
      setUserIdsToSubscriptionStatus(lookupTable)
    }

    return Promise.resolve()
  }, [getAccessToken, showErrorModal])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  if (userType !== USER_TYPES.ADMIN) {
    return null
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>ユーザリスト</PageHeading>
        <TableContainer mb={10} whiteSpace="normal" wordBreak="break-all">
          <Table w="100%" variant="simple" size="sm">
            <Thead fontSize="sm">
              <Tr>
                <Th>User</Th>
                <Th textAlign="right" whiteSpace="nowrap">
                  Hubspot ID
                </Th>
                <Th textAlign="right">氏名</Th>
                <Th textAlign="right">契約状況</Th>
                <Th textAlign="right">会社名</Th>
                <Th textAlign="right">現場</Th>
                <Th textAlign="right">所属部署</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.hubspot_properties.email}>
                  <Td>
                    <Link
                      as={RouterNavLink}
                      variant="underline"
                      to={`/dashboard?user_id=${user.user_id}&user_email=${user.hubspot_properties.email}`}
                    >
                      {user.hubspot_properties.email}
                    </Link>
                  </Td>
                  <Td textAlign="right" whiteSpace="nowrap">
                    {user.hubspot_id}
                  </Td>
                  <Td textAlign="right" whiteSpace="nowrap">
                    {user.hubspot_properties.last_name} {user.hubspot_properties.first_name}
                  </Td>
                  <Td textAlign="right">{userIdsToSubscriptionStatus[user.user_id]}</Td>
                  <Td textAlign="right" whiteSpace="nowrap">
                    {user.hubspot_properties.company}
                  </Td>
                  <Td textAlign="right">{user.hubspot_properties.dealnames.join('/') || 'なし'}</Td>
                  <Td textAlign="right">{user.hubspot_properties.department}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  )
}

export default UserList
