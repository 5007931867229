import { FC, useContext, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { AnchorItem } from 'interfaces/interfaces'

import { saveDistance, updateDistance } from 'services/Distances'

const SaveDistanceButton: FC<{
  setDistances: (distances: AnchorItem[]) => void
  setIsLayerModifying: (modifying: boolean) => void
}> = ({ setDistances, setIsLayerModifying }) => {
  const { t } = useTranslation(['projects'])
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const { project, distanceAnchors, isToolProcessing } = useContext(EditorContext)

  const [isLoading, setIsLoading] = useState(false)

  const filteredDistances = distanceAnchors.filter(
    (distance) => (!distance.id || distance.modified) && !distance.deleted
  )

  if (!project || !filteredDistances.length) {
    return null
  }

  const onSave = async () => {
    setIsLoading(true)
    setIsLayerModifying(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      setIsLayerModifying(false)
      return false
    }

    const savedDistances = await Promise.all(
      filteredDistances.map((distance) =>
        distance.id
          ? updateDistance(token, project.project_id, distance, showErrorModal)
          : saveDistance(token, project.project_id, distance, showErrorModal)
      )
    )

    // track with mixpanel
    mixpanel.track('Save or Update distance object', {
      'Inspection area ID': project.project_id,
      'Number of target distance objects': savedDistances.length,
    })

    // Remove all temporary distances
    const newDistances = distanceAnchors.filter((distance) => !!distance.id && !distance.modified)

    savedDistances.forEach((distance, index) => {
      // If successful saved, push the new distance
      // else, keep the old one
      newDistances.push(distance || filteredDistances[index])
    })

    setDistances(newDistances)
    setIsLoading(false)
    setIsLayerModifying(false)
    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={onSave}
      isLoading={isLoading}
      disabled={isLoading || isToolProcessing}
      spinnerPlacement="end"
      loadingText={t('main_canvas.action_buttons.saving', { ns: 'projects' })}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.save', { ns: 'projects' })}
    </Button>
  )
}

export default SaveDistanceButton
