import { FC, useEffect, useState } from 'react'

import { Mesh, MeshPhongMaterial, TorusGeometry } from 'three'

const standardMaterial = new MeshPhongMaterial({
  color: 'yellow',
  transparent: true,
  opacity: 0.4,
  depthTest: false,
  depthWrite: false,
})

const DummyTorusMesh: FC<{
  major_diameter: number
  minor_diameter: number
}> = ({ major_diameter, minor_diameter }) => {
  const [mesh, setMesh] = useState<Mesh>(new Mesh())

  useEffect(() => {
    const torusMesh = new Mesh()

    const geo = new TorusGeometry(major_diameter / 2, minor_diameter / 2, 10, 60)
    torusMesh.geometry = geo

    setMesh(torusMesh)
  }, [major_diameter, minor_diameter])

  return (
    <mesh quaternion={mesh.quaternion} position={mesh.position} geometry={mesh.geometry} material={standardMaterial} />
  )
}

export default DummyTorusMesh
