import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { IFCRegisterContext } from 'contexts/IFCRegister'

import { IFC_REGISTER_TOOLS } from 'config/constants'

import { ActionInstruction, Instruction } from 'interfaces/interfaces'

import InstructionBarContent from '../components/InstructionBarContent'

const InstructionBar: FC = () => {
  const { t } = useTranslation(['projects'])
  const { selectedTool, pcdPoints, ifcPoints } = useContext(IFCRegisterContext)
  const [instruction, setInstruction] = useState<Instruction>()

  const handleRegister = useCallback(
    (intro: ActionInstruction) => {
      if (!pcdPoints.length && !ifcPoints.length) {
        setInstruction(intro.before_action)
      } else if (pcdPoints.length === 3 && ifcPoints.length === 3) {
        setInstruction(undefined)
      } else {
        setInstruction(intro.after_action)
      }
    },
    [pcdPoints, ifcPoints]
  )

  useEffect(() => {
    const texts: ActionInstruction = t('ifc_registration.instructions', {
      returnObjects: true,
    })

    switch (selectedTool) {
      case IFC_REGISTER_TOOLS.REGISTER:
        handleRegister(texts)
        break
      default:
    }
  }, [handleRegister, selectedTool, t])

  return <InstructionBarContent instruction={instruction} />
}

export default InstructionBar
