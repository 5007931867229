import { FC, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Square,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import UploadIFCModal from 'pages/dashboard/components/UploadIFCModal'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddIcon, ChevronDownIcon, DeleteIcon } from 'assets/icons'

import useIFCFiles from 'hooks/IFCFiles'

import { IFCFile, ProjectGroup } from 'interfaces/interfaces'

const FileListButton: FC<{
  projectGroup?: ProjectGroup
  ifcFiles: IFCFile[]
  selectedIFCFile: IFCFile | null
  setSelectedIFCFile: (file: IFCFile) => void
  onFileChanged: () => Promise<void>
  isAllowedModify: boolean
  backgroundColor: string
}> = ({
  projectGroup,
  ifcFiles,
  selectedIFCFile,
  setSelectedIFCFile,
  onFileChanged,
  isAllowedModify,
  backgroundColor,
}) => {
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()

  const [isUploadFileOpen, setIsUploadFileOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { confirmFileDelete } = useIFCFiles(
    projectGroup?.project_group_id,
    project_id,
    isAllowedModify,
    ifcFiles,
    setIsLoading,
    onFileChanged
  )

  if (isLoading) {
    return null
  }

  return (
    <Box position="absolute" backgroundColor={backgroundColor} top={0} left={0} right={0} bottom={0} zIndex={999}>
      <HStack position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" spacing={1}>
        <Menu variant="toolbar">
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            backgroundColor="gray.800"
            fontWeight="normal"
            color="white"
            size="sm"
            _hover={{ bg: 'gray.600' }}
            _expanded={{ bg: 'gray.600' }}
            _focus={{ bg: 'gray.600', boxShadow: 'outline' }}
            _active={{ bg: 'gray.600' }}
            disabled={isLoading}
          >
            <Text isTruncated>
              {selectedIFCFile?.original_filename ||
                t('ifc_registration.file_list.choose_ifc_file', { ns: 'projects' })}
            </Text>
          </MenuButton>
          <MenuList minW={0}>
            {ifcFiles.map((ifcFile) => (
              <MenuItem
                disabled={isLoading}
                key={ifcFile.ifc_file_id}
                backgroundColor={ifcFile.ifc_file_id === selectedIFCFile?.ifc_file_id ? 'whiteAlpha.100' : 'gray.700'}
                onClick={() => {
                  setSelectedIFCFile(ifcFile)
                }}
              >
                {ifcFile.original_filename}
                {isAllowedModify && (
                  <>
                    <Spacer />
                    <Square
                      position="relative"
                      color="white"
                      fontSize="md"
                      size={8}
                      ml={4}
                      backgroundColor="whiteAlpha.100"
                      _hover={{ bg: 'whiteAlpha.400' }}
                      borderRadius="md"
                      overflow="hidden"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (!isLoading) {
                          confirmFileDelete(ifcFile)
                        }
                      }}
                      aria-label="delete-ifcFile"
                    >
                      <DeleteIcon />
                    </Square>
                  </>
                )}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        {isAllowedModify && !isLoading && (
          <Tooltip
            hasArrow
            placement="bottom"
            label={t('ifc_registration.file_list.tooltip_upload_ifc', { ns: 'projects' })}
            p={2}
            fontSize="xs"
            fontWeight="normal"
            isDisabled={isTablet}
          >
            <Flex>
              <Square
                position="relative"
                color="white"
                fontSize="md"
                size={8}
                backgroundColor="gray.800"
                _hover={{ bg: 'gray.600' }}
                borderRadius="md"
                overflow="hidden"
                cursor="pointer"
                onClick={() => {
                  setIsUploadFileOpen(true)
                }}
                data-testid="add-ifc-file"
              >
                <AddIcon />
              </Square>
            </Flex>
          </Tooltip>
        )}
        <UploadIFCModal
          isOpen={isUploadFileOpen}
          projectGroup={projectGroup}
          onConfirm={(result) => {
            setIsUploadFileOpen(false)
            if (result) {
              void onFileChanged()
            }
          }}
        />
      </HStack>
    </Box>
  )
}

export default FileListButton
