import { FC, useContext, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { Cuboid, CuboidAnchor } from 'interfaces/interfaces'

import { createMaskingRegion } from 'services/MaskingRegion'

import { maskRegionCreated } from '../store/editor'

const SaveMaskRegionButton: FC<{
  setCuboidAnchor: (anchors?: CuboidAnchor) => void
  setCuboid: (cuboid?: Cuboid) => void
  isToolProcessing: boolean
}> = ({ setCuboidAnchor, setCuboid, isToolProcessing }) => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const { project, cuboid, maskRegions, changeMaskRegions } = useContext(EditorContext)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  if (!project) {
    return null
  }

  const saveMaskRegion = async () => {
    if (!cuboid) {
      return false
    }

    setIsLoading(true)
    setLoadingText(t('main_canvas.action_buttons.saving', { ns: 'projects' }))

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const newMaskRegion = await createMaskingRegion(token, project.project_id, cuboid, showErrorModal)

    if (!newMaskRegion) {
      return false
    }

    const newMaskRegions = [...maskRegions, newMaskRegion]
    changeMaskRegions(newMaskRegions)

    // track with mixpanel
    mixpanel.track('Create mask region', {
      'Number of mask regions (new masks)': 1,
      'Number of mask regions (after creation)': newMaskRegions.length,
      'Inspection area ID': project.project_id,
    })

    setCuboidAnchor(undefined)
    setCuboid(undefined)
    setIsLoading(false)
    dispatch(maskRegionCreated())
    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={saveMaskRegion}
      isLoading={isLoading}
      disabled={isToolProcessing || isLoading}
      spinnerPlacement="end"
      loadingText={loadingText}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.save', { ns: 'projects' })}
    </Button>
  )
}

export default SaveMaskRegionButton
