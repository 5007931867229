import { FC, useEffect } from 'react'

import { useFrame } from '@react-three/fiber'
import TWEEN from '@tweenjs/tween.js'
import { Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

/**
 * animate the camera center moving to target position
 * Refs:
 * https://sbcode.net/react-three-fiber/house/
 * https://github.com/sole/tween.js/blob/master/docs/user_guide.md
 *
 * @param {{ target: any; arcballControls: any; }} param0 input parameters
 * @param {Vector3} param0.target target position
 * @param {ArcballControls | null} param0.arcballControls arcballControls instance
 * @returns {null?} null is returned when the action is completed
 */
const CameraAnimator: FC<{ target: Vector3; arcballControls: ArcballControls | null }> = ({
  target,
  arcballControls,
}) => {
  useEffect(() => {
    if (!arcballControls) return

    arcballControls.saveState()
    // eslint-disable-next-line dot-notation
    new TWEEN.Tween(arcballControls['target'])
      .to(
        {
          x: target.x,
          y: target.y,
          z: target.z,
        },
        200
      )
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
      .onComplete(() => {
        arcballControls.reset()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target])

  useFrame(() => {
    TWEEN.update()
  })

  return null
}

export default CameraAnimator
