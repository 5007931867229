import './index.css'

import React, { Suspense } from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from 'store/app'

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'config/environments'
import theme from 'config/theme'

import App from './App'
import './i18n'

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    audience={AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
  >
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <Suspense fallback="...is loading">
            <App />
          </Suspense>
        </Provider>
      </ChakraProvider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
)
