import dayjs from 'dayjs'
import axios from 'extends/axios'
import { Matrix4Tuple } from 'three'

import { EDITOR_EXTRA_SHAPE_KEYS, EDITOR_SHAPE_KEYS, EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import { Plane, PointArray, Rhombus, Shape, SpacerAnnotation } from 'interfaces/interfaces'

import { generateSpacerAnnotationDistances } from './Editor'
import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { getVerticesFromPlane, getVerticesFromRhombus } from './Points'

export const parsePlane = (plane: Plane): SpacerAnnotation => {
  const points = getVerticesFromPlane(plane.length_1, plane.length_2)
  const distanceFactors = generateSpacerAnnotationDistances([{ points }])
  const area = plane.length_1 * plane.length_2

  return { ...plane, ...distanceFactors[0], area, shape_type: EDITOR_SHAPE_KEYS.PLANES }
}
export const parseRhombus = (rhombus: Rhombus): SpacerAnnotation => {
  const points = getVerticesFromRhombus(rhombus.length, rhombus.angle)
  const distanceFactors = generateSpacerAnnotationDistances([{ points }])
  const area = rhombus.length ** 2 * Math.sin(rhombus.angle)

  return { ...rhombus, ...distanceFactors[0], area, shape_type: EDITOR_EXTRA_SHAPE_KEYS.RHOMBI }
}

/**
 * Get spacer shape
 * @param {string} access_token access token
 * @param {PointArray[]} points 4 points of spacers
 * @param {function} showErrorModal show error modal function
 * @returns {SpacerAnnotation | null}
 */
export const evaluateSpacer = async (
  access_token: string,
  points: PointArray[],
  showErrorModal: (message: string) => void
): Promise<SpacerAnnotation | null> => {
  const spacer = await axios
    .post<
      Shape & {
        parameters_rhombus: { length: number; angle: number }
        parameters_plane: { length_1: number; length_2: number }
      }
    >(
      `${API_GATEWAY_URL}/evaluate-unit-cell`,
      {
        points,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => {
      const transformation = Object.values(response.data.transformation) as Matrix4Tuple
      if (response.data.shape_type === EDITOR_SHAPE_KEYS.PLANES) {
        return parsePlane({
          ...response.data,
          ...response.data.parameters_plane,
          shape_id: `${EDITOR_SHAPE_TEMP_ID_PREFIX}_plane_${dayjs().unix()}}`,
          transformation,
        })
      }
      return parseRhombus({
        ...response.data,
        ...response.data.parameters_rhombus,
        shape_id: `${EDITOR_SHAPE_TEMP_ID_PREFIX}_rhombus_${dayjs().unix()}}`,
        transformation,
      })
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.EVALUATE_SPACER, showErrorModal)
      return null
    })

  return spacer
}
