import { FC, useContext, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'

import { EDITOR_REQUIRED_ANCHORS, EDITOR_SHAPE_TEMP_ID_PREFIX, EDITOR_TOOLS } from 'config/constants'

import { Anchors, Plane, Shapes } from 'interfaces/interfaces'

import { fixVertexOnNormal, getPlaneFromPoints, pointsToVector3s } from 'services/Points'

import { virtualPlaneCreated } from '../store/editor'

const ConvertVirtualPlaneButton: FC<{
  setShapes: (shapes: Shapes) => void
  setAnchors: (anchor: Anchors) => void
  isToolProcessing: boolean
}> = ({ setShapes, setAnchors, isToolProcessing }) => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { anchors, project, shapes, selectedTool } = useContext(EditorContext)
  const [isLoading, setIsLoading] = useState(false)

  const filteredPlaneAnchors = anchors.planes.filter((anchor) => !anchor.deleted)

  const convert = () => {
    const newShapes = { ...shapes }

    const planes: Plane[] = []
    filteredPlaneAnchors.forEach((anchor, index) => {
      const points = pointsToVector3s(anchor.points)

      if (points.length !== EDITOR_REQUIRED_ANCHORS.planes) {
        return
      }

      const correctedPoints = fixVertexOnNormal(points)
      planes.push(
        getPlaneFromPoints(correctedPoints, `${EDITOR_SHAPE_TEMP_ID_PREFIX}_virtual_${newShapes.planes.length + index}`)
      )
    })
    newShapes.planes = [...newShapes.planes, ...planes]

    setShapes(newShapes)

    // track with mixpanel
    mixpanel.track('Create virtual plane', {
      'Inspection area ID': project?.project_id,
      'Shape type': 'planes',
      'Number of shapes': planes.length,
    })

    setAnchors(INITIAL_SHAPE_STATE())
    setIsLoading(false)
    dispatch(virtualPlaneCreated())
  }

  if (!project) {
    return null
  }

  if (!isLoading && (selectedTool !== EDITOR_TOOLS.PLANE_VIRTUAL || !filteredPlaneAnchors.length)) {
    return null
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={convert}
      isLoading={isLoading}
      disabled={isToolProcessing || isLoading}
      spinnerPlacement="end"
      loadingText={t('main_canvas.action_buttons.creating', { ns: 'projects' })}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.create_virtual_formwork', { ns: 'projects' })}
    </Button>
  )
}

export default ConvertVirtualPlaneButton
