import { FC, useContext, useState } from 'react'

import { Button } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { AnchorPoints, SpacerAnnotation } from 'interfaces/interfaces'

import { evaluateSpacer } from 'services/Spacers'

import { spacerGridFormationFinished } from '../store/editor'

const ConvertSpacerAnnotationButton: FC<{
  setSpacerAnnotations: (spacers: SpacerAnnotation[]) => void
  setSpacerAnnotationAnchors: (spacers: AnchorPoints[]) => void
  isToolProcessing: boolean
}> = ({ setSpacerAnnotations, setSpacerAnnotationAnchors, isToolProcessing }) => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { getAccessToken } = useContext(UserContext)
  const { project, spacerAnnotations, spacerAnnotationAnchors } = useContext(EditorContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const [isLoading, setIsLoading] = useState(false)

  if (!project || !spacerAnnotationAnchors?.filter((a) => !a.deleted).length) {
    return null
  }

  const convertSpacerAnnotation = async () => {
    if (!spacerAnnotationAnchors) {
      return false
    }

    const filteredAnchors = spacerAnnotationAnchors.filter((a) => !a.deleted)
    if (!filteredAnchors.length) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const result = await evaluateSpacer(token, filteredAnchors[0].points, showErrorModal)

    if (!result) {
      setIsLoading(false)
      return false
    }

    // add detected shape to spacers list
    setSpacerAnnotations([...spacerAnnotations, result])

    // track with mixpanel
    mixpanel.track('Finish forming spacer unit cell', {
      'Inspection area ID': project?.project_id,
      'Shape type': result.shape_type || '',
    })

    setIsLoading(false)
    setSpacerAnnotationAnchors([])
    dispatch(spacerGridFormationFinished())
    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={convertSpacerAnnotation}
      isLoading={isLoading}
      disabled={isToolProcessing || isLoading}
      spinnerPlacement="end"
      loadingText={t('main_canvas.action_buttons.detecting', { ns: 'projects' })}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.detect', { ns: 'projects' })}
    </Button>
  )
}

export default ConvertSpacerAnnotationButton
