import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { Button, HStack, useToast } from '@chakra-ui/react'
import UpdateProjectModal from 'pages/dashboard/components/CreateProjectModal'
import SituationsModal from 'pages/projects/editor/inspectionSheet/SituationsModal'
import ValuesModal from 'pages/projects/editor/inspectionSheet/ValuesModal'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddIcon, ExportIcon, InputEditorCancelIcon, InputEditorConfirmIcon, ListIcon, RefreshIcon } from 'assets/icons'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { EDITOR_SHAPE_TEMP_ID_PREFIX, INSPECTION_SHEET_TYPES, MODAL_TYPES } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'

import {
  InspectionItem,
  InspectionShapeIds,
  InspectionSheet,
  Project,
  ShapesDistances,
  SpacerInspectionItem,
} from 'interfaces/interfaces'

import { getInspectionItems, getInspectionSheets } from 'services/InspectionSheet'
import { getShapeDistancesFromInspectionItem } from 'services/ShapeDistances'
import { getInspectionItems as getSpacerInspectionItems } from 'services/SpacerInspectionSheet'
import { sanitizeFilename } from 'services/Util'
import { decideActionPermission } from 'services/Validation'
import { generateXLSX } from 'services/exports/XLSX_ShapesDistances'

import InspectionTypeModal, { INSPECTION_TYPE } from '../inspectionSheet/InspectionTypeModal'
import MainSheetWrapperModal, { MODAL_TYPE } from '../inspectionSheet/MainSheetWrapper'
import SpacerValuesModal from '../inspectionSheet/SpacerValuesModal'
import Blackboard from '../mainCanvas/Blackboard/Blackboard'

const InspectionButton: FC<{
  setShapesPreviewDistances: (distances?: ShapesDistances) => void
  setShapesDistances: (distances: ShapesDistances) => void
}> = ({ setShapesPreviewDistances, setShapesDistances }) => {
  const toast = useToast()
  const { project_id } = useParams<{ project_id: string }>()
  const { t } = useTranslation('projects')
  const {
    shapes,
    spacerAnnotations,
    shapesPreviewDistances,
    shapesDistancesVisible,
    shapesDistances,
    selectedShapeIds,
    updateSelectedShapeIds,
    project,
    selectedInspectionItem,
    selectedSpacerInspectionItem,
    changeSelectedInspectionItem,
    changeSelectedSpacerInspectionItem,
    isAllActionsDisabled,
    changeIsAllActionsDisabled,
  } = useContext(EditorContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken, userType } = useContext(UserContext)
  const { getProjectGroups, invitedProjectGroups, projectGroups } = useContext(ProjectsContext)

  const ownProjectGroup = projectGroups.find((group) => group.project_group_id === project?.project_group_id)
  const invitedProjectGroup = invitedProjectGroups.find((group) => group.project_group_id === project?.project_group_id)
  const filteredSelectedShapeIds = selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)) || []
  const projectGroup = ownProjectGroup || invitedProjectGroup

  const permissions = decideActionPermission(!!ownProjectGroup, !!invitedProjectGroup).INSPECTION_SHEET
  const isAllowedDownload = permissions.DOWNLOAD.includes(userType)
  const isAllowedModify = permissions.MODIFY.includes(userType)

  const [inspectionSheet, setInspectionSheet] = useState<InspectionSheet>()
  const [spacerInspectionSheet, setSpacerInspectionSheet] = useState<InspectionSheet>()
  const [inspectionItems, setInspectionItems] = useState<InspectionItem[]>([])
  const [spacerInspectionItems, setSpacerInspectionItems] = useState<SpacerInspectionItem[]>([])
  const [blackboardInspectionSheet, setBlackboardInspectionSheet] = useState<InspectionSheet>()

  const [isLoading, setIsLoading] = useState(false)
  const [isSituationsModalOpen, setIsSituationsModalOpen] = useState(false)
  const [isValuesModalOpen, setIsValuesModalOpen] = useState(false)
  const [mainSheetModalOpenType, setMainSheetModalOpenType] = useState<string>()
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false)
  const [isSpacerValuesModalOpen, setIsSpacerValuesModalOpen] = useState(false)

  const [valuesUpdatingInspectionItem, setValuesUpdatingInspectionItem] = useState<InspectionItem>()
  const [valuesUpdatingSpacerInspectionItem, setValuesUpdatingSpacerInspectionItem] = useState<SpacerInspectionItem>()

  const [situation, setSituation] = useState('')
  const [meanDistance, setMeanDistance] = useState<number>()
  const [coverDistance, setCoverDistance] = useState<number>()
  const [diameter, setDiameter] = useState<number>()
  const [tekkinNumber, setTekkinNumber] = useState<number>(0)
  const [shapeIds, setShapeIds] = useState<InspectionShapeIds>()

  const [updatingFileProject, setUpdatingFileProject] = useState<Project>()

  const [shapeDistancePermutationMap, setShapeDistancePermutationMap] = useState<number[]>()

  const fetchInspectionSheet = useCallback(async () => {
    if (!project_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const sheets = await getInspectionSheets(token, project_id, showErrorModal)

    if (!sheets.length || sheets.some((sheet) => !sheet.inspection_sheet_id)) {
      showErrorModal(t('main_canvas.inspection_sheet.message_no_sheet', { ns: 'projects' }))
      setIsLoading(false)
      return false
    }

    const rebarSheet = sheets.find((s) => s.sheet_type === INSPECTION_SHEET_TYPES.REBAR)
    const spacerSheet = sheets.find((s) => s.sheet_type === INSPECTION_SHEET_TYPES.SPACER)
    setInspectionSheet(rebarSheet)
    setSpacerInspectionSheet(spacerSheet)

    const items = await getInspectionItems(token, project_id, rebarSheet?.inspection_sheet_id || '', showErrorModal)
    setInspectionItems(items)
    const spacerItems = await getSpacerInspectionItems(
      token,
      project_id,
      spacerSheet?.inspection_sheet_id || '',
      showErrorModal
    )
    setSpacerInspectionItems(spacerItems)

    setIsLoading(false)
    return items
  }, [project_id, getAccessToken, showErrorModal, t])

  useEffect(() => {
    if (mainSheetModalOpenType !== undefined) {
      void (() => fetchInspectionSheet())()
    }
  }, [fetchInspectionSheet, mainSheetModalOpenType])

  useEffect(() => {
    if (!project?.down_sampled_file.name) {
      setMainSheetModalOpenType(MODAL_TYPE.REBARS)
    }
  }, [project])

  useEffect(() => {
    // Disable all of the actions (toolbar, information panel, action buttons) when opening the inspection sheet
    // except for the case when user selecting an inspection item.
    // Selecting an inspection item status can be consider as status of existing of any selected shapes,
    // it's a tricky way but it's not meaningful to do any actions while selecting an inspection item which does not have any shapes.
    changeIsAllActionsDisabled(mainSheetModalOpenType !== undefined && !selectedShapeIds.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSheetModalOpenType, selectedShapeIds])

  useEffect(() => {
    if ((!inspectionSheet || !spacerInspectionSheet) && (isValuesModalOpen || isSpacerValuesModalOpen)) {
      void (() => fetchInspectionSheet())()
    }
  }, [fetchInspectionSheet, isValuesModalOpen, isSpacerValuesModalOpen, inspectionSheet, spacerInspectionSheet])

  useEffect(() => {
    void (async () => {
      if (shapesDistancesVisible) {
        // If there is no inspection items yet, fetch the data
        // The inspection items could be an empty array, so we need to check inspection sheet instead
        const items = inspectionSheet ? inspectionItems : (await fetchInspectionSheet()) || []
        setShapesDistances(getShapeDistancesFromInspectionItem(items))
      } else {
        setShapesDistances(INITIAL_SHAPE_STATE())
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapesDistancesVisible])

  const onClick = () => {
    if (isAllowedModify && filteredSelectedShapeIds.length) {
      const cylinders = shapes.cylinders.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
      const tori = shapes.tori.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))
      const spacers = spacerAnnotations.filter((s) => filteredSelectedShapeIds.includes(s.shape_id))

      // if user has selected both rebars and spacers, let user choose only one of those types
      if ((cylinders.length || tori.length) && spacers.length) {
        setIsTypeModalOpen(true)
        return false
      }

      // spacers
      if (spacers.length) {
        if (spacers.length > 1) {
          showModal({
            body: t('main_canvas.inspection_sheet.error_modal.message_multiple_cells', { ns: 'projects' }),
            modalType: MODAL_TYPES.ALERT,
            confirmText: t('main_canvas.inspection_sheet.error_modal.confirm', { ns: 'projects' }),
          })
          return false
        }

        setValuesUpdatingSpacerInspectionItem(undefined)
        setIsSpacerValuesModalOpen(true)
        return true
      }

      // rebars
      if (
        (cylinders.length && cylinders.some((cylinder) => cylinder.diameter !== cylinders[0].diameter)) ||
        (tori.length && tori.some((torus) => torus.minor_diameter !== tori[0].minor_diameter))
      ) {
        showModal({
          body: t('main_canvas.inspection_sheet.error_modal.message_different_diameters', { ns: 'projects' }),
          modalType: MODAL_TYPES.ALERT,
          confirmText: t('main_canvas.inspection_sheet.error_modal.confirm', { ns: 'projects' }),
        })
      } else {
        setIsSituationsModalOpen(true)
      }
    } else {
      setMainSheetModalOpenType('')
    }

    return true
  }

  const resetState = () => {
    setSituation('')
    setTekkinNumber(0)
    setDiameter(undefined)
    setCoverDistance(undefined)
    setShapeIds(undefined)
    setMeanDistance(undefined)
    setShapesPreviewDistances(undefined)
    setShapeDistancePermutationMap(undefined)
  }

  const onItemAdded = () => {
    resetState()
    setIsValuesModalOpen(true)
    setMainSheetModalOpenType(undefined)
    setValuesUpdatingInspectionItem(undefined)
    setValuesUpdatingSpacerInspectionItem(undefined)
    changeSelectedInspectionItem(undefined)
  }

  const onSituationConfirm = (
    evaluatedSituation: string,
    evaluatedMeanDistance: number,
    evaluatedDiameter: number,
    evaluatedTekkinNumber: number,
    evaluatedShapeIds: InspectionShapeIds,
    evaluatedCoverDistance?: number,
    evaluatedShapesDistances?: ShapesDistances,
    evaluatedPermutationMap?: number[]
  ) => {
    setIsSituationsModalOpen(false)

    setSituation(evaluatedSituation || '')
    setTekkinNumber(evaluatedTekkinNumber || 0)
    setDiameter(evaluatedDiameter)
    setCoverDistance(evaluatedCoverDistance)
    setShapeIds(evaluatedShapeIds)
    setMeanDistance(evaluatedMeanDistance)
    setShapesPreviewDistances(evaluatedShapesDistances)
    setShapeDistancePermutationMap(evaluatedPermutationMap)

    setValuesUpdatingInspectionItem(undefined)

    setIsValuesModalOpen(!evaluatedShapesDistances)
  }

  const onValuesConfirm = (result?: boolean) => {
    setIsValuesModalOpen(false)
    changeSelectedInspectionItem(undefined)
    setShapesPreviewDistances(undefined)

    if (result) {
      void (() => fetchInspectionSheet())()
    }

    setMainSheetModalOpenType(
      !project?.down_sampled_file.name || !!selectedInspectionItem || !!valuesUpdatingInspectionItem || !!result
        ? MODAL_TYPE.REBARS
        : undefined
    )
  }

  const onUpdateShapes = () => {
    const item = valuesUpdatingInspectionItem
    changeSelectedInspectionItem(item)
    if (item) {
      onItemSelected(item)
    }
    setValuesUpdatingInspectionItem(undefined)
    setIsValuesModalOpen(false)
  }

  const onUpdateSpacerShapes = () => {
    const item = valuesUpdatingSpacerInspectionItem
    changeSelectedSpacerInspectionItem(item)
    if (item) {
      onSpacerItemSelected(item)
    }
    setValuesUpdatingSpacerInspectionItem(undefined)
    setIsSpacerValuesModalOpen(false)
  }

  const onItemSelected = (item: InspectionItem | null) => {
    if (!item) {
      updateSelectedShapeIds([])
    } else {
      const newSelectedShapeIds = [
        ...(item.shape_ids?.cylinders || []),
        ...(item.shape_ids?.planes || []),
        ...(item.shape_ids?.tori || []),
      ]

      updateSelectedShapeIds(newSelectedShapeIds)
    }

    setValuesUpdatingInspectionItem(item || undefined)
  }

  const onSpacerItemSelected = (item: SpacerInspectionItem | null) => {
    if (!item) {
      updateSelectedShapeIds([])
    } else {
      const newSelectedShapeIds = [...(item.shape_ids?.planes || []), ...(item.shape_ids?.rhombi || [])]

      updateSelectedShapeIds(newSelectedShapeIds)
    }
  }

  const openUpdateProjectModal = () => {
    setUpdatingFileProject(project)
  }

  const onProjectUpdated = async (result?: boolean) => {
    setUpdatingFileProject(undefined)
    if (result) {
      await getProjectGroups()
      toast({
        ...TOAST_CONFIG,
        title: t('main_canvas.action_buttons.inspection_area_updated', { ns: 'projects' }),
      })
    }
  }

  const onTypeConfirmed = (type?: string) => {
    if (type === INSPECTION_TYPE.SPACERS) {
      setValuesUpdatingSpacerInspectionItem(undefined)
      setIsSpacerValuesModalOpen(true)
    }
    if (type === INSPECTION_TYPE.REBARS) {
      setIsSituationsModalOpen(true)
    }

    setIsTypeModalOpen(false)
  }

  const onSpacerValuesConfirmed = (result?: boolean) => {
    setIsSpacerValuesModalOpen(false)
    changeSelectedSpacerInspectionItem(undefined)

    if (result) {
      void (() => fetchInspectionSheet())()
    }

    setMainSheetModalOpenType(
      !project?.down_sampled_file.name ||
        !!selectedSpacerInspectionItem ||
        !!valuesUpdatingSpacerInspectionItem ||
        !!result
        ? MODAL_TYPE.SPACERS
        : undefined
    )
  }

  const onGenerateXLSX = async () => {
    if (!valuesUpdatingInspectionItem || !inspectionSheet) {
      return
    }

    const filename = sanitizeFilename(
      `${valuesUpdatingInspectionItem.part_name}_${project?.project_name || ''}_${t(
        'main_canvas.action_buttons.spacing',
        { ns: 'projects' }
      )}.xlsx`
    )
    await generateXLSX(
      filename,
      inspectionSheet,
      valuesUpdatingInspectionItem,
      shapesDistances.distances,
      project,
      t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true })
    )
  }

  return (
    <>
      {!isAllActionsDisabled && (
        <HStack w="100%">
          {isAllowedModify &&
            !shapesPreviewDistances &&
            ((selectedInspectionItem && !isValuesModalOpen) ||
              (selectedSpacerInspectionItem && !isSpacerValuesModalOpen)) && (
              <>
                <Button
                  colorScheme="secondary"
                  size={isTablet ? 'lg' : 'sm'}
                  maxWidth={12}
                  fontSize="md"
                  px={1}
                  variant="toolbar"
                  onClick={() => {
                    if (selectedInspectionItem) {
                      onValuesConfirm()
                    }
                    if (selectedSpacerInspectionItem) {
                      onSpacerValuesConfirmed()
                    }
                  }}
                >
                  <InputEditorCancelIcon size="60%" />
                </Button>
                <Button
                  colorScheme="secondary"
                  rightIcon={<RefreshIcon size={isTablet ? 20 : 12} />}
                  size={isTablet ? 'lg' : 'sm'}
                  fontSize={isTablet ? 'lg' : 'xs'}
                  variant="toolbar"
                  onClick={onClick}
                  spinnerPlacement="end"
                  flex={1}
                  justifyContent="space-between"
                  disabled={!filteredSelectedShapeIds.length}
                >
                  {t('main_canvas.action_buttons.update_inspection_item', { ns: 'projects' })}
                </Button>
              </>
            )}
          {(!isAllowedModify ||
            ((!selectedInspectionItem || isValuesModalOpen) &&
              (!selectedSpacerInspectionItem || isSpacerValuesModalOpen))) &&
            !shapesPreviewDistances && (
              <Button
                colorScheme="secondary"
                rightIcon={isAllowedModify && filteredSelectedShapeIds.length ? <AddIcon /> : <ListIcon />}
                size={isTablet ? 'lg' : 'sm'}
                fontSize={isTablet ? 'lg' : 'xs'}
                variant="toolbar"
                onClick={onClick}
                spinnerPlacement="end"
                isFullWidth
                justifyContent="space-between"
                isLoading={isLoading}
                loadingText={
                  isAllowedModify && filteredSelectedShapeIds.length
                    ? t('main_canvas.action_buttons.add_inspection_item', { ns: 'projects' })
                    : t('main_canvas.action_buttons.inspection_sheet', { ns: 'projects' })
                }
              >
                {isAllowedModify && filteredSelectedShapeIds.length
                  ? t('main_canvas.action_buttons.add_inspection_item', { ns: 'projects' })
                  : t('main_canvas.action_buttons.inspection_sheet', { ns: 'projects' })}
              </Button>
            )}
          {isAllowedModify && shapesPreviewDistances && (
            <>
              <Button
                colorScheme="secondary"
                size={isTablet ? 'lg' : 'sm'}
                maxWidth={12}
                fontSize="md"
                px={1}
                variant="toolbar"
                onClick={() => {
                  onValuesConfirm()
                }}
              >
                <InputEditorCancelIcon size="60%" />
              </Button>
              <Button
                colorScheme="secondary"
                rightIcon={<InputEditorConfirmIcon size={isTablet ? 20 : 12} />}
                size={isTablet ? 'lg' : 'sm'}
                fontSize={isTablet ? 'lg' : 'xs'}
                variant="toolbar"
                onClick={() => {
                  setIsValuesModalOpen(true)
                }}
                spinnerPlacement="end"
                flex={1}
                justifyContent="space-between"
                disabled={!filteredSelectedShapeIds.length}
              >
                {selectedInspectionItem
                  ? t('main_canvas.action_buttons.update_specification', { ns: 'projects' })
                  : t('main_canvas.action_buttons.add_specification', { ns: 'projects' })}
              </Button>
            </>
          )}
        </HStack>
      )}
      {!isAllActionsDisabled &&
        !!inspectionSheet &&
        !!valuesUpdatingInspectionItem &&
        (!!shapesDistances.distances.cylinders.length ||
          !!shapesDistances.distances.planes.length ||
          !!shapesDistances.distances.tori.length) &&
        !shapesPreviewDistances && (
          <Button
            colorScheme="secondary"
            rightIcon={<ExportIcon />}
            size={isTablet ? 'lg' : 'sm'}
            fontSize={isTablet ? 'lg' : 'xs'}
            variant="toolbar"
            onClick={onGenerateXLSX}
            spinnerPlacement="end"
            isFullWidth
            justifyContent="space-between"
            isLoading={isLoading}
            loadingText={t('main_canvas.action_buttons.exporting', { ns: 'projects' })}
          >
            {t('main_canvas.action_buttons.export_spacing', { ns: 'projects' })}
          </Button>
        )}
      <SituationsModal
        shapes={shapes}
        isOpen={isSituationsModalOpen}
        onClose={() => setIsSituationsModalOpen(false)}
        onConfirm={onSituationConfirm}
      />
      {!!inspectionSheet && !!spacerInspectionSheet && (
        <>
          <ValuesModal
            inspectionSheet={inspectionSheet}
            inspectionItems={inspectionItems}
            valuesUpdatingInspectionItem={valuesUpdatingInspectionItem}
            situation={situation}
            evaluatedTekkinNumber={tekkinNumber}
            evaluatedDiameter={diameter}
            evaluatedMeanDistance={meanDistance}
            evaluatedCoverDistance={coverDistance}
            evaluatedShapesDistances={shapesPreviewDistances}
            evaluatedPermutationMap={shapeDistancePermutationMap}
            isOpen={isValuesModalOpen}
            onConfirm={onValuesConfirm}
            shapeIds={shapeIds}
            onUpdateShapes={onUpdateShapes}
          />
          <MainSheetWrapperModal
            inspectionSheet={inspectionSheet}
            inspectionItems={inspectionItems}
            spacerInspectionSheet={spacerInspectionSheet}
            spacerInspectionItems={spacerInspectionItems}
            openType={mainSheetModalOpenType}
            isLoadingSheet={isLoading}
            isAllowedDownload={isAllowedDownload}
            isAllowedModify={isAllowedModify}
            canClose={!!project?.down_sampled_file.name}
            onClose={() => {
              if (project?.down_sampled_file.name) {
                setMainSheetModalOpenType(undefined)
                updateSelectedShapeIds([])
              }
            }}
            onOpenItem={(item: InspectionItem) => {
              resetState()
              setValuesUpdatingInspectionItem(item)
              changeSelectedInspectionItem(undefined)
              setMainSheetModalOpenType(undefined)
              setIsValuesModalOpen(true)
            }}
            onOpenSpacerItem={(item: SpacerInspectionItem) => {
              resetState()
              setValuesUpdatingSpacerInspectionItem(item)
              changeSelectedSpacerInspectionItem(undefined)
              setMainSheetModalOpenType(undefined)
              setIsSpacerValuesModalOpen(true)
            }}
            onItemRefetch={(item: InspectionItem) => {
              setValuesUpdatingInspectionItem(undefined)
              changeSelectedInspectionItem(item)
              setMainSheetModalOpenType(undefined)
              onItemSelected(item)
            }}
            onSpacerItemRefetch={(item: SpacerInspectionItem) => {
              setValuesUpdatingSpacerInspectionItem(undefined)
              changeSelectedSpacerInspectionItem(item)
              setMainSheetModalOpenType(undefined)
              onSpacerItemSelected(item)
            }}
            onSheetUpdated={() => {
              void (() => fetchInspectionSheet())()
            }}
            onItemSelected={onItemSelected}
            onSpacerItemSelected={onSpacerItemSelected}
            onItemAdded={onItemAdded}
            openUpdateProjectModal={openUpdateProjectModal}
            setShapesDistances={setShapesDistances}
            onOpenBlackboard={() => {
              setBlackboardInspectionSheet(inspectionSheet)
              setMainSheetModalOpenType(undefined)
            }}
          />
          <SpacerValuesModal
            isOpen={isSpacerValuesModalOpen}
            onConfirm={onSpacerValuesConfirmed}
            inspectionSheet={spacerInspectionSheet}
            valuesUpdatingInspectionItem={valuesUpdatingSpacerInspectionItem}
            onUpdateShapes={onUpdateSpacerShapes}
          />
        </>
      )}
      {projectGroup && (
        <UpdateProjectModal
          isOpen={!!updatingFileProject}
          onConfirm={onProjectUpdated}
          selectedProject={updatingFileProject}
          projectGroup={projectGroup}
        />
      )}
      <InspectionTypeModal onConfirm={onTypeConfirmed} isOpen={isTypeModalOpen} />
      <Blackboard
        onClose={() => {
          setBlackboardInspectionSheet(undefined)
          setMainSheetModalOpenType('')
        }}
        inspectionSheet={blackboardInspectionSheet}
      />
    </>
  )
}

export default InspectionButton
