import { FC, useContext, useEffect, useState } from 'react'

import { Button, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/app'

import { CheckCircleIcon } from 'assets/icons'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import {
  EDITOR_SHAPE_KEYS,
  EDITOR_SHAPE_TEMP_ID_PREFIX,
  EDITOR_TOOLS,
  JOBS_WATCHING_INTERVAL,
  MODAL_TYPES,
} from 'config/constants'

import {
  AnchorPoints,
  Anchors,
  Cuboid,
  CuboidAnchor,
  Cylinder,
  CylinderDetectionResult,
  Plane,
  PlaneDetectionResult,
  ShapeKey,
  Shapes,
  ShapesDistances,
  Torus,
  TorusDetectionResult,
} from 'interfaces/interfaces'

import { getMeanDistanceAndIndividualDistance } from 'services/InspectionSheet'
import { autoDetectShapes, checkJobStatusForShapeDetection, detectShapes } from 'services/Projects'

import { shapeDetectionFinished } from '../store/editor'

// TODO: Remove these converters when cylinder detection result is the same with shape
const getParametersByShapeType = (
  shapeType: ShapeKey,
  result: CylinderDetectionResult | TorusDetectionResult | PlaneDetectionResult
) => {
  if (shapeType === EDITOR_SHAPE_KEYS.CYLINDERS) {
    const cylinder = result as CylinderDetectionResult
    return { diameter: cylinder.parameters_cylinder?.diameter || 0, length: cylinder.parameters_cylinder?.length || 0 }
  }
  if (shapeType === EDITOR_SHAPE_KEYS.TORI) {
    const torus = result as TorusDetectionResult
    return {
      minor_diameter: torus.parameters_torus?.minor_diameter || 0,
      major_diameter: torus.parameters_torus?.major_diameter || 0,
    }
  }

  if (shapeType === EDITOR_SHAPE_KEYS.PLANES) {
    const plane = result as PlaneDetectionResult
    return {
      length_1: plane.parameters_plane?.length_1 || 0,
      length_2: plane.parameters_plane?.length_2 || 0,
    }
  }
  return {}
}
const convertDetectionResultToShapes = (
  shapeType: ShapeKey,
  result: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[]
) =>
  result.map((c, index) => ({
    shape_id: `${EDITOR_SHAPE_TEMP_ID_PREFIX}_${shapeType}_${dayjs().unix()}_${index}`,
    transformation: c.registration_result ? Object.values(c.registration_result.transformation) : [],
    ...getParametersByShapeType(shapeType, c),
  }))

const migrateErrorAnchors = (
  errorAnchors: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[],
  anchorPoints: AnchorPoints[]
) => {
  const results: AnchorPoints[] = []
  errorAnchors.forEach((errorAnchor) => {
    const existAnchor = anchorPoints.find(
      (anchor) =>
        anchor.points.length === errorAnchor.positions?.length &&
        !anchor.points.some(
          (point, index) =>
            !errorAnchor.positions ||
            point.length !== 3 ||
            point[0] !== errorAnchor.positions[index].x ||
            point[1] !== errorAnchor.positions[index].y ||
            point[2] !== errorAnchor.positions[index].z
        )
    )
    if (existAnchor) {
      existAnchor.error_id = errorAnchor.error_id
      results.push(existAnchor)
    }
  })
  return results
}

const ConvertButton: FC<{
  setShapes: (shapes: Shapes) => void
  setShapesDistances: (shapesDistances: { shapes: Shapes; distances: ShapesDistances; situation: string }) => void
  setAnchors: (anchor: Anchors) => void
  setCuboidAnchor: (anchors?: CuboidAnchor) => void
  setCuboid: (cuboid?: Cuboid) => void
  isToolProcessing: boolean
}> = ({ setShapes, setShapesDistances, setAnchors, setCuboidAnchor, setCuboid, isToolProcessing }) => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { getAccessToken } = useContext(UserContext)
  const {
    autoDetectSituation,
    anchors,
    project,
    shapes,
    changeSelectedPoint,
    baseDiameter,
    cuboid,
    cuboidAnchor,
    cuboidDirection,
    selectedTool,
    maskRegions,
    maskRegionsOutsideVisible,
    changeIsJobRunning,
  } = useContext(EditorContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const [runningJobToken, setRunningJobToken] = useState<{ type: 'auto' | 'manual'; token: string }>()
  const [isCheckingJob, setIsCheckingJob] = useState(false)

  // ------------ Jobs watching ------------ //
  useEffect(() => {
    const interval = setInterval(() => {
      void (async () => {
        if (runningJobToken && !isCheckingJob) {
          setIsCheckingJob(true)

          const token = await getAccessToken()
          if (!token) {
            changeIsJobRunning(false)
            setIsCheckingJob(false)
            setIsLoading(false)
            return false
          }

          const errorCylinderAnchors: CylinderDetectionResult[] = []
          const errorPlaneAnchors: PlaneDetectionResult[] = []
          const errorTorusAnchors: TorusDetectionResult[] = []

          const jobResult = await checkJobStatusForShapeDetection(token, runningJobToken.token, showErrorModal)
          // if job has been completed
          if (jobResult !== null) {
            setRunningJobToken(undefined)
            const shapeType = jobResult.shape_type

            // if job has been succeeded, add the shapes to render. Or else, just remove the watcher
            if (shapeType) {
              const newShapes = { ...shapes }

              if (jobResult.shape_type === EDITOR_SHAPE_KEYS.CYLINDERS) {
                const results = jobResult.list_shapes as CylinderDetectionResult[]
                errorCylinderAnchors.push(...(results.filter((shape) => !!shape.error_id) || []))
                const detectedShapes = convertDetectionResultToShapes(
                  shapeType,
                  results.filter((shape) => !shape.error_id)
                ) as Cylinder[]
                newShapes.cylinders = [...newShapes.cylinders, ...detectedShapes]

                // draw individual shape distances
                if (runningJobToken.type === 'auto') {
                  const distances = await getMeanDistanceAndIndividualDistance(
                    token,
                    autoDetectSituation,
                    detectedShapes,
                    showErrorModal
                  )
                  setShapesDistances({
                    shapes: {
                      planes: [],
                      cylinders: [...detectedShapes],
                      tori: [],
                    },
                    distances: {
                      planes: [],
                      cylinders: distances?.individual_distance || [],
                      tori: [],
                    },
                    situation: autoDetectSituation,
                  })
                }
              } else if (jobResult.shape_type === EDITOR_SHAPE_KEYS.PLANES) {
                const results = jobResult.list_shapes as PlaneDetectionResult[]
                errorPlaneAnchors.push(...(results.filter((shape) => !!shape.error_id) || []))
                const detectedShapes = convertDetectionResultToShapes(
                  shapeType,
                  results.filter((shape) => !shape.error_id)
                ) as Plane[]
                newShapes.planes = [...newShapes.planes, ...detectedShapes]
              } else if (jobResult.shape_type === EDITOR_SHAPE_KEYS.TORI) {
                const results = jobResult.list_shapes as TorusDetectionResult[]
                errorTorusAnchors.push(...(results.filter((shape) => !!shape.error_id) || []))
                const detectedShapes = convertDetectionResultToShapes(
                  shapeType,
                  results.filter((shape) => !shape.error_id)
                ) as Torus[]
                newShapes.tori = [...newShapes.tori, ...detectedShapes]

                // draw individual shape distances
                if (runningJobToken.type === 'auto') {
                  const distances = await getMeanDistanceAndIndividualDistance(
                    token,
                    autoDetectSituation,
                    detectedShapes,
                    showErrorModal
                  )
                  setShapesDistances({
                    shapes: {
                      planes: [],
                      cylinders: [],
                      tori: [...detectedShapes],
                    },
                    distances: {
                      planes: [],
                      cylinders: [],
                      tori: distances?.individual_distance || [],
                    },
                    situation: autoDetectSituation,
                  })
                }
              }

              setShapes(newShapes)
            }

            const totalErrorShapes = errorCylinderAnchors.length + errorPlaneAnchors.length + errorTorusAnchors.length

            // track with mixpanel
            mixpanel.track('Finish detecting shapes', {
              'Inspection area ID': project?.project_id,
              'Job token': runningJobToken.token,
              'Shape type': shapeType,
              'Number of shapes (success)': (jobResult.list_shapes?.length || 0) - totalErrorShapes,
              'Number of shapes (error)': totalErrorShapes,
            })

            const shapeLabel =
              shapeType === EDITOR_SHAPE_KEYS.PLANES
                ? t('main_canvas.panels.shape.formwork', { ns: 'projects' })
                : t('main_canvas.panels.shape.rebar', { ns: 'projects' })
            // if job has failed or no shape has been detected, show a message
            if (!shapeType || !jobResult.list_shapes?.length) {
              showModal({
                body: t('main_canvas.action_buttons.message_not_detected', { ns: 'projects', shapeLabel }),
                modalType: MODAL_TYPES.ALERT,
                title: t('main_canvas.action_buttons.title_detection', { ns: 'projects', shapeLabel }),
                confirmText: t('main_canvas.action_buttons.confirm', { ns: 'projects' }),
              })
              // if there are some shapes failed to detected, show a message
            } else if (totalErrorShapes) {
              showModal({
                body: (
                  <Text>
                    {t('main_canvas.action_buttons.not_detected', {
                      ns: 'projects',
                      shapeLabel,
                      count: totalErrorShapes,
                    })}
                  </Text>
                ),
                modalType: MODAL_TYPES.ALERT,
                title: t('main_canvas.action_buttons.title_detection', { ns: 'projects', shapeLabel }),
                confirmText: t('main_canvas.action_buttons.confirm', { ns: 'projects' }),
              })
            }

            const newAnchors: Anchors = INITIAL_SHAPE_STATE()

            // put back anchors to show error messages
            newAnchors.cylinders.push(...migrateErrorAnchors(errorCylinderAnchors, anchors.cylinders))
            newAnchors.planes.push(...migrateErrorAnchors(errorPlaneAnchors, anchors.planes))
            newAnchors.tori.push(...migrateErrorAnchors(errorTorusAnchors, anchors.tori))

            setAnchors(newAnchors)
            setCuboidAnchor(undefined)
            setCuboid(undefined)
            setIsLoading(false)
            setIsCheckingJob(false)
            changeIsJobRunning(false)
            dispatch(shapeDetectionFinished(totalErrorShapes))
          }
        }
        return true
      })()
    }, JOBS_WATCHING_INTERVAL)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningJobToken])

  if (!project) {
    return null
  }

  const filteredCylinderAnchors = anchors.cylinders.filter((anchor) => !anchor.deleted)
  const filteredPlaneAnchors = anchors.planes.filter((anchor) => !anchor.deleted)
  const filteredTorusAnchors = anchors.tori.filter((anchor) => !anchor.deleted)
  if (
    !isLoading &&
    selectedTool !== EDITOR_TOOLS.TORUS_CUBOID &&
    selectedTool !== EDITOR_TOOLS.CYLINDER_CUBOID &&
    !filteredCylinderAnchors.length &&
    !filteredPlaneAnchors.length &&
    !filteredTorusAnchors.length
  ) {
    return null
  }
  if (
    !isLoading &&
    (selectedTool === EDITOR_TOOLS.TORUS_CUBOID || selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID) &&
    !cuboid
  ) {
    return null
  }
  if (!isLoading && selectedTool !== EDITOR_TOOLS.PLANE && filteredPlaneAnchors.length) {
    return null
  }

  // ------------ Manual detection ------------ //
  const manualConvertShapes = async () => {
    setIsLoading(true)
    setLoadingText(t('main_canvas.action_buttons.detecting', { ns: 'projects' }))
    changeIsJobRunning(true)

    const token = await getAccessToken()
    if (!token) {
      changeIsJobRunning(false)
      return false
    }

    if (filteredCylinderAnchors.length) {
      await manualConvert(token, EDITOR_SHAPE_KEYS.CYLINDERS)
    } else if (filteredPlaneAnchors.length) {
      await manualConvert(token, EDITOR_SHAPE_KEYS.PLANES)
    } else if (filteredTorusAnchors.length) {
      await manualConvert(token, EDITOR_SHAPE_KEYS.TORI)
    }

    changeSelectedPoint(undefined)
    return true
  }

  const manualConvert = async (token: string, shapeKey: ShapeKey): Promise<boolean> => {
    if (!project.down_sampled_file.path) {
      return false
    }

    const jobToken = await detectShapes(
      token,
      project.down_sampled_file.path,
      shapeKey,
      anchors,
      maskRegions,
      maskRegionsOutsideVisible,
      showErrorModal
    )

    // track with mixpanel
    mixpanel.track('Start detecting shapes (manual)', {
      'Inspection area ID': project.project_id,
      'Job token': jobToken,
      'Shape type': shapeKey,
      'Number of anchors': anchors[shapeKey].length,
    })

    if (!jobToken) {
      return false
    }

    // add this job token to the watching list
    setRunningJobToken({ type: 'manual', token: jobToken })
    return true
  }

  // ------------ Automatic detection ------------ //
  const autoConvertShapes = async (shapeKey: ShapeKey, token: string) => {
    if (!project.down_sampled_file.path || !cuboid || !baseDiameter) {
      return false
    }

    const jobToken = await autoDetectShapes(
      shapeKey,
      token,
      project.down_sampled_file.path,
      autoDetectSituation,
      cuboidAnchor?.diameter || baseDiameter,
      cuboid,
      maskRegions,
      maskRegionsOutsideVisible,
      showErrorModal,
      cuboidDirection
    )

    // track with mixpanel
    mixpanel.track('Start detecting shapes (cuboid)', {
      'Inspection area ID': project.project_id,
      'Job token': jobToken,
      'Shape type': shapeKey,
      Situation: autoDetectSituation,
    })

    if (!jobToken) {
      return false
    }

    // add this job token to the watching list
    setRunningJobToken({ type: 'auto', token: jobToken })
    return true
  }

  // ------------ Modal actions ------------ //
  const convert = () => {
    if (selectedTool === EDITOR_TOOLS.TORUS_CUBOID) {
      void onAutoDetect(EDITOR_SHAPE_KEYS.TORI)
    } else if (selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID) {
      void onAutoDetect(EDITOR_SHAPE_KEYS.CYLINDERS)
    } else {
      void manualConvertShapes()
    }
  }

  const onAutoDetect = async (shapeKey: ShapeKey) => {
    setIsLoading(true)
    setLoadingText(t('main_canvas.action_buttons.detecting', { ns: 'projects' }))
    changeIsJobRunning(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      changeIsJobRunning(false)
      return false
    }

    await autoConvertShapes(shapeKey, token)

    changeSelectedPoint(undefined)
    return true
  }

  return (
    <Button
      colorScheme="secondary"
      rightIcon={<CheckCircleIcon width="100%" />}
      size={isTablet ? 'lg' : 'sm'}
      fontSize={isTablet ? 'lg' : 'xs'}
      variant="toolbar"
      onClick={convert}
      isLoading={isLoading}
      disabled={isToolProcessing || isLoading || (!baseDiameter && selectedTool !== EDITOR_TOOLS.PLANE)}
      spinnerPlacement="end"
      loadingText={loadingText}
      isFullWidth
      justifyContent="space-between"
    >
      {t('main_canvas.action_buttons.detect', { ns: 'projects' })}
    </Button>
  )
}

export default ConvertButton
