import { FC, useEffect, useState } from 'react'

import { HStack, IconButton, Input, Text } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import { AddIcon, MinusIcon } from 'assets/icons'

import { BLUEPRINT_MAX_SCALE, BLUEPRINT_MIN_SCALE, BLUEPRINT_SCALE_STEP } from 'config/constants'
import { BLUEPRINT_INPUT_STYLES } from 'config/styles'

const ActionsPanel: FC<{
  scale: number
  currentPage: number
  totalPages: number
  setScale: (scale: number) => void
  setPage: (page: number) => void
}> = ({ scale, currentPage, totalPages, setScale, setPage }) => {
  const [localScale, setLocalScale] = useState(scale)
  const [localPage, setLocalPage] = useState(currentPage)

  useEffect(() => {
    setLocalPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    setLocalScale(scale)
  }, [scale])

  const onPageConfirm = (value: number) => {
    setPage(value)
  }

  const onScaleConfirm = (value: number) => {
    const newScale = Math.min(Math.max(value, BLUEPRINT_MIN_SCALE), BLUEPRINT_MAX_SCALE)
    setLocalScale(newScale)
    setScale(newScale)
  }

  const onScaleChange = (amount: number) => {
    setLocalScale(localScale + amount)
    onScaleConfirm(localScale + amount)
  }

  const onScaleUpdate = (value: string) => {
    const newValue = value.replace('%', '')
    if (/^\d+$/.test(newValue)) {
      setLocalScale(Number(newValue))
    }
  }

  return (
    <HStack right={1} position="absolute" spacing={1} top={isTablet ? 10 : 1} h={8} color="white">
      <HStack backgroundColor="secondary.800" p={1} pr={2} borderRadius="md">
        <Input
          {...BLUEPRINT_INPUT_STYLES}
          value={localPage}
          minW={10}
          type="number"
          min={1}
          max={totalPages}
          onChange={(e) => setLocalPage(Math.min(Math.max(Number(e.target.value), 1), totalPages))}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onPageConfirm(localPage)
            }
          }}
          onBlur={() => {
            onPageConfirm(localPage)
          }}
        />
        <Text color="secondary.400"> / {totalPages}</Text>
      </HStack>
      <HStack backgroundColor="secondary.800" p={1} borderRadius="md" spacing={1}>
        <IconButton
          aria-label="minus-scale"
          fontSize="sm"
          icon={<MinusIcon />}
          onClick={() => onScaleChange(-BLUEPRINT_SCALE_STEP)}
          size="xs"
          variant="toolbarIcon"
        />
        <Input
          {...BLUEPRINT_INPUT_STYLES}
          value={`${localScale}%`}
          minW={14}
          onChange={(e) => {
            onScaleUpdate(e.target.value)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onScaleConfirm(localScale)
            }
          }}
          onBlur={() => {
            onScaleConfirm(localScale)
          }}
        />
        <IconButton
          aria-label="plus-scale"
          fontSize="sm"
          icon={<AddIcon />}
          onClick={() => onScaleChange(BLUEPRINT_SCALE_STEP)}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>
    </HStack>
  )
}

export default ActionsPanel
