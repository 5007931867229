import { FC, useContext, useEffect, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { TOAST_CONFIG } from 'config/styles'

import { updateProjectGroupName, updateProjectName } from 'services/Projects'

/**
 * Modal component for updating the name of a project or project group.
 *
 * @component
 * @param {boolean} isOpen - Determines whether the modal is open or not.
 * @param {() => void} onConfirm - Callback function to be called when the update is confirmed.
 * @param {string} currentName - The current name of the project or project group.
 * @param {string} targetId - The ID of the project or project group.
 * @param {'project' | 'projectGroup'} targetType - The type of the target (either 'project' or 'projectGroup').
 * @returns {JSX.Element} The rendered modal component.
 */
const UpdateProjectNameModal: FC<{
  isOpen: boolean
  onConfirm: () => void
  currentName: string
  targetId: string
  targetType: 'project' | 'projectGroup'
}> = ({ isOpen, onConfirm, currentName, targetId, targetType }) => {
  const { t } = useTranslation(['dashboard'])
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { getProjectGroups } = useContext(ProjectsContext)

  const toast = useToast()

  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setName(currentName)
  }, [currentName])

  const onChangeName = async () => {
    if (!currentName || !name) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      onConfirm()
      return false
    }

    if (targetType === 'projectGroup') {
      const updatedProject = await updateProjectGroupName(token, targetId, name, showErrorModal)
      if (updatedProject) {
        toast({
          ...TOAST_CONFIG,
          title: t('components.update_project_name_modal.project_name_changed', {
            ns: 'dashboard',
          }),
        })

        // track with mixpanel
        mixpanel.track('Rename project', {
          'Project ID': targetId,
          'Project Name (new)': name,
        })
        await getProjectGroups()
      }
    } else {
      const updatedProject = await updateProjectName(token, targetId, name, showErrorModal)
      if (updatedProject) {
        toast({
          ...TOAST_CONFIG,
          title: t('components.update_project_name_modal.inspection_area_name_changed', {
            ns: 'dashboard',
          }),
        })

        // track with mixpanel
        mixpanel.track('Rename inspection area', {
          'Inspection area ID': targetId,
          'Inspection area Name (new)': name,
        })

        // Refetch project groups also refetch projects inside expanding project groups
        await getProjectGroups()
      }
    }

    setIsLoading(false)
    onConfirm()
    return true
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal closeOnOverlayClick isOpen onClose={() => onConfirm()} trapFocus={false} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('components.update_project_name_modal.change_target_name', {
            ns: 'dashboard',
            target:
              targetType === 'projectGroup'
                ? t('components.update_project_name_modal.project', {
                    ns: 'dashboard',
                  })
                : t('components.update_project_name_modal.inspection_area', {
                    ns: 'dashboard',
                  }),
          })}{' '}
        </ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody position="relative">
          <FormControl>
            <FormLabel>
              {' '}
              {t('components.update_project_name_modal.current', {
                ns: 'dashboard',
              })}
            </FormLabel>
            <Text aria-label="previous_name" fontSize="md">
              {currentName}
            </Text>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel htmlFor="project_name">
              {' '}
              {t('components.update_project_name_modal.after_change', {
                ns: 'dashboard',
              })}
            </FormLabel>
            <Input
              placeholder={t('components.update_project_name_modal.provide_target_name', {
                ns: 'dashboard',
                target:
                  targetType === 'projectGroup'
                    ? t('components.update_project_name_modal.project', {
                        ns: 'dashboard',
                      })
                    : t('components.update_project_name_modal.inspection_area', {
                        ns: 'dashboard',
                      }),
              })}
              id="project_name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value.trimStart())}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('components.update_project_name_modal.cancel', {
              ns: 'dashboard',
            })}
          </Button>
          <Button
            disabled={isLoading || !name}
            colorScheme="primary"
            me={3}
            py={2}
            minW="100px"
            onClick={() => onChangeName()}
          >
            {t('components.update_project_name_modal.change', {
              ns: 'dashboard',
            })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateProjectNameModal
