import { FC, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, HStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useInView } from 'react-intersection-observer'
import { Document, Page, pdfjs } from 'react-pdf'
import { useParams } from 'react-router-dom'

import { GlobalModalContext } from 'contexts/GlobalModal'

import useComment from 'hooks/Comment'

import { BLUEPRINT_COMMENT_AREA_MIN_SIZE, BLUEPRINT_TOOLS } from 'config/constants'

import { Comment, CommentImage, CommentUploadedImage, FlatPosition, RectangleInBlueprint } from 'interfaces/interfaces'

import { scaleBlueprintRectangle } from 'services/Util'

import CommentPopup from '../components/CommentPopup'
import CommentFrame from './CommentFrame'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const DocumentPage: FC<{
  pageNumber: number
  scale: number
  toggleVisible: (visible: boolean) => void
}> = ({ pageNumber, scale, toggleVisible }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    onChange: () => {
      toggleVisible(inView)
    },
  })

  const [renderedScale, setRenderedScale] = useState<number>(1)

  const isLoading = renderedScale !== scale

  return (
    <Box ref={ref} className="blueprint-page">
      {isLoading && renderedScale && (
        <Page
          key={`${pageNumber}.${renderedScale}`}
          pageNumber={pageNumber}
          scale={renderedScale}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      )}
      <Page
        key={`${pageNumber}.${scale}`}
        pageNumber={pageNumber}
        scale={scale}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        onRenderSuccess={() => {
          setRenderedScale(scale)
        }}
      />
    </Box>
  )
}

const MainDocument: FC<{
  blueprintId: string
  url: string
  totalPages: number
  scale: number
  selectedTool: string
  setTotalPages: (total: number) => void
  toggleVisiblePage: (page: number, visible: boolean) => void
  isMovingComment: boolean
  setIsMovingComment: (moving: boolean) => void
}> = ({
  blueprintId,
  url,
  totalPages,
  scale,
  selectedTool,
  setTotalPages,
  toggleVisiblePage,
  isMovingComment,
  setIsMovingComment,
}) => {
  const { project_id } = useParams<{ project_id: string }>()
  const { user } = useAuth0()
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // Comment
  const [selectedComment, setSelectedComment] = useState<Comment>()
  const [selectedCommentBoundingPosition, setSelectedCommentBoundingPosition] = useState<RectangleInBlueprint>()
  const [commentName, setCommentName] = useState('')
  const [commentBody, setCommentBody] = useState('')
  const [commentPopupPosition, setCommentPopupPosition] = useState<RectangleInBlueprint>()
  const [isOpeningCommentPopup, setIsOpeningCommentPopup] = useState(false)
  const [movingCommentAnchorIndex, setMovingCommentAnchorIndex] = useState(-1)
  const [movingCommentPositions, setMovingCommentPositions] = useState<RectangleInBlueprint>()
  const [movingCommentStartPosition, setMovingCommentStartPosition] = useState<FlatPosition>()
  const [commentImages, setCommentImages] = useState<CommentImage[]>([])
  const [commentDeletingImageIndexes, setCommentDeletingImageIndexes] = useState<number[]>([])
  const [commentEditingCaptions, setCommentEditingCaptions] = useState<string[]>([])
  const [commentImageHandlingIndex, setCommentImageHandlingIndex] = useState(-1)
  const [commentOpeningImageIds, setCommentOpeningImageIds] = useState<string[]>([])
  const [commentUploadedImages, setCommentUploadedImages] = useState<CommentUploadedImage[]>([])

  const resetCommentStates = () => {
    setCommentPopupPosition(undefined)
    setSelectedComment(undefined)
    setSelectedCommentBoundingPosition(undefined)
    setIsMovingComment(false)
    setIsOpeningCommentPopup(false)
    setMovingCommentPositions(undefined)
  }

  const { comments, isLoading, fetchComments, openDeleteCommentConfirmModal, onConfirmMoveBlueprintPosition } =
    useComment(project_id, selectedComment, setSelectedComment, resetCommentStates, showModal)

  useEffect(() => {
    setCommentPopupPosition(undefined)
    setMovingCommentPositions(undefined)
    setIsMovingComment(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool])

  const toggleCommentOpeningImageId = (id: string) => {
    setCommentOpeningImageIds((states) => {
      const ids = [...states]
      if (ids.includes(id)) {
        ids.splice(ids.indexOf(id), 1)
      } else {
        ids.push(id)
      }
      return ids
    })
  }

  const onConfirmMove = async () => {
    if (movingCommentPositions) {
      await onConfirmMoveBlueprintPosition(blueprintId, movingCommentPositions)
    }

    onCancelMove()
    return true
  }

  const onCancelMove = () => {
    setIsOpeningCommentPopup(false)
    setSelectedComment(undefined)
    setMovingCommentAnchorIndex(-1)
    setMovingCommentPositions(undefined)
    setIsMovingComment(false)
  }

  const onDragMove = (index: number, start?: FlatPosition) => {
    setMovingCommentAnchorIndex(index)
    setMovingCommentStartPosition(start?.x && start.y ? getPointPosition(start.x, start.y) : undefined)
  }

  const getPointPosition = (mouseX: number, mouseY: number): FlatPosition | undefined => {
    const targetRect = document.querySelector('.react-pdf__Document')?.getBoundingClientRect()
    if (!targetRect) {
      return undefined
    }

    return { x: mouseX - targetRect.x, y: mouseY - targetRect.y }
  }

  const getMovingUnplacedCommentPosition = (): FlatPosition | undefined => {
    if (!selectedComment?.thread_id) {
      return undefined
    }

    const frame = document.getElementById(selectedComment.thread_id)
    if (!frame) {
      return undefined
    }

    const rect = frame.getBoundingClientRect()

    // plus padding: 4px
    return getPointPosition(rect.left + 4, rect.top + 4)
  }

  const onClick = (mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (selectedTool !== BLUEPRINT_TOOLS.COMMENT || isOpeningCommentPopup || !mouseEvent || isMovingComment) {
      return false
    }

    if (!commentPopupPosition?.coordinate.x) {
      const coords = getPointPosition(mouseEvent.clientX, mouseEvent.clientY)
      if (coords) {
        setCommentPopupPosition(
          scaleBlueprintRectangle(
            {
              coordinate: coords,
              extent: { width: 0, height: 0 },
            },
            (1 / scale) * 100
          )
        )
      }
    } else if (commentPopupPosition.extent.width && movingCommentAnchorIndex < 0) {
      setIsOpeningCommentPopup(true)
    }

    return true
  }

  const onMouseMove = (mouseX: number, mouseY: number) => {
    if (
      !isMovingComment &&
      (selectedTool !== BLUEPRINT_TOOLS.COMMENT ||
        !commentPopupPosition ||
        commentPopupPosition.extent.width === null ||
        isOpeningCommentPopup)
    ) {
      return false
    }

    const coords = getPointPosition(mouseX, mouseY)
    if (!coords) {
      return false
    }

    const popupPositions = isMovingComment ? movingCommentPositions : commentPopupPosition

    const unitScale = scale / 100
    const extent = {
      width: (coords?.x || 0) / unitScale - (popupPositions?.coordinate.x || 0),
      height: (coords?.y || 0) / unitScale - (popupPositions?.coordinate.y || 0),
    }

    if (!isMovingComment) {
      setCommentPopupPosition({
        coordinate: popupPositions?.coordinate || {
          x: 0,
          y: 0,
        },
        extent,
      })
    } else if (movingCommentAnchorIndex === 0 || movingCommentAnchorIndex === 1 || movingCommentAnchorIndex === 2) {
      const newPositions = popupPositions
        ? { ...popupPositions }
        : {
            coordinate: {
              x: 0,
              y: 0,
            },
            extent: { width: 0, height: 0 },
          }

      if (movingCommentAnchorIndex === 0) {
        const newWidth =
          (newPositions.extent.width || 0) * unitScale + (newPositions.coordinate.x || 0) * unitScale - (coords.x || 0)
        if (newWidth > BLUEPRINT_COMMENT_AREA_MIN_SIZE) {
          newPositions.extent.width = newWidth / unitScale
          newPositions.coordinate.x = coords.x / unitScale
        }

        const newHeight =
          (newPositions.extent.height || 0) * unitScale + (newPositions.coordinate.y || 0) * unitScale - (coords.y || 0)
        if (newHeight > BLUEPRINT_COMMENT_AREA_MIN_SIZE) {
          newPositions.extent.height = newHeight / unitScale
          newPositions.coordinate.y = coords.y / unitScale
        }
      } else if (movingCommentAnchorIndex === 1) {
        newPositions.extent = extent
      } else {
        const distanceX = (coords.x || 0) - (movingCommentStartPosition?.x || 0)
        const distanceY = (coords.y || 0) - (movingCommentStartPosition?.y || 0)

        newPositions.coordinate = {
          x: (newPositions.coordinate.x || 0) + distanceX / unitScale,
          y: (newPositions.coordinate.y || 0) + distanceY / unitScale,
        }

        setMovingCommentStartPosition(coords)
      }

      setMovingCommentPositions(newPositions)
    }

    return true
  }

  const unplacedComments = comments.filter(
    (comment) => !comment.blueprint_position && (!comment.blueprint_id || comment.blueprint_id === blueprintId)
  )

  const getCommentProperties = (comment: Comment, forUnplacedComment?: boolean) => {
    const defaultProperties = {
      position: {
        x: 0,
        y: 0,
      },
      width: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
      height: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
    }

    if (forUnplacedComment) {
      return defaultProperties
    }

    if (isMovingComment && selectedComment?.thread_id === comment.thread_id && movingCommentPositions) {
      return {
        position: movingCommentPositions.coordinate,
        width: Math.max(movingCommentPositions.extent.width, BLUEPRINT_COMMENT_AREA_MIN_SIZE),
        height: Math.max(movingCommentPositions.extent.height, BLUEPRINT_COMMENT_AREA_MIN_SIZE),
      }
    }
    if (comment.blueprint_position) {
      return {
        position: comment.blueprint_position.coordinate,
        width: comment.blueprint_position.extent.width || BLUEPRINT_COMMENT_AREA_MIN_SIZE,
        height: comment.blueprint_position.extent.height || BLUEPRINT_COMMENT_AREA_MIN_SIZE,
      }
    }
    return defaultProperties
  }

  return (
    <Document
      file={url}
      onLoadSuccess={(args) => setTotalPages(args.numPages)}
      onMouseMove={(mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!mouseEvent) return
        onMouseMove(mouseEvent.clientX, mouseEvent.clientY)
      }}
      onTouchMove={(touchEvent?: React.TouchEvent<HTMLDivElement>) => {
        if (!touchEvent) return
        onMouseMove(touchEvent.touches[0].clientX, touchEvent.touches[0].clientY)
      }}
      onClick={onClick}
      onMouseUp={() => {
        setMovingCommentAnchorIndex(-1)
      }}
    >
      {Array.from(Array(totalPages).keys()).map((index) => (
        <DocumentPage
          key={`page-${index + 1}`}
          pageNumber={index + 1}
          scale={scale / 100}
          toggleVisible={(visible) => toggleVisiblePage(index + 1, visible)}
        />
      ))}
      {!!commentPopupPosition &&
        commentPopupPosition.extent.width !== null &&
        commentPopupPosition.extent.height !== null && (
          <CommentFrame
            position={commentPopupPosition.coordinate}
            width={Math.max(commentPopupPosition.extent.width, BLUEPRINT_COMMENT_AREA_MIN_SIZE)}
            height={Math.max(commentPopupPosition.extent.height, BLUEPRINT_COMMENT_AREA_MIN_SIZE)}
            blueprintScale={scale / 100}
            forOverview
            isWarning={false}
            scale={Math.min(scale / 100, 1)}
          />
        )}
      {isOpeningCommentPopup && (
        <CommentPopup
          user={user}
          project_id={project_id}
          comment={selectedComment}
          blueprintPosition={
            selectedComment?.blueprint_position || commentPopupPosition || selectedCommentBoundingPosition
          }
          blueprintId={blueprintId}
          blueprintScale={scale / 100}
          commentBody={commentBody}
          commentName={commentName}
          disabled={isLoading}
          forEditor={false}
          updateCommentBody={setCommentBody}
          updateCommentName={setCommentName}
          closePopup={() => {
            setCommentPopupPosition(undefined)
            setIsOpeningCommentPopup(false)
            setSelectedComment(undefined)
          }}
          onCommentModified={() => fetchComments()}
          deleteComment={() => {
            openDeleteCommentConfirmModal()

            // track with mixpanel
            mixpanel.track('Delete comment', {
              'Inspection area ID': project_id,
              'Thread ID': selectedComment?.thread_id,
              Situation: 'blueprint viewer',
            })
          }}
          onMoveComment={() => {
            setIsOpeningCommentPopup(false)
            setIsMovingComment(true)
            setMovingCommentPositions(
              selectedComment?.blueprint_position
                ? {
                    coordinate: selectedComment.blueprint_position.coordinate,
                    extent: selectedComment.blueprint_position.extent,
                  }
                : {
                    coordinate: getMovingUnplacedCommentPosition() || { x: 0, y: 0 },
                    extent: {
                      width: (BLUEPRINT_COMMENT_AREA_MIN_SIZE * scale) / 100,
                      height: (BLUEPRINT_COMMENT_AREA_MIN_SIZE * scale) / 100,
                    },
                  }
            )

            // track with mixpanel
            mixpanel.track('Update region for comment', {
              'Inspection area ID': project_id,
              'Thread ID': selectedComment?.thread_id,
              Situation: 'blueprint viewer',
            })
          }}
          showErrorModal={showErrorModal}
          deletingImageIndexes={commentDeletingImageIndexes}
          editingCaptions={commentEditingCaptions}
          updateCommentImages={setCommentImages}
          openingImageIds={commentOpeningImageIds}
          handlingImageIndex={commentImageHandlingIndex}
          uploadedImages={commentUploadedImages}
          toggleOpeningImageId={toggleCommentOpeningImageId}
          updateDeletingImageIndexes={setCommentDeletingImageIndexes}
          updateEditingCaptions={setCommentEditingCaptions}
          updateUploadedImages={setCommentUploadedImages}
          updateHandlingImageIndex={setCommentImageHandlingIndex}
          commentImages={commentImages}
        />
      )}
      {comments
        .filter(
          (comment) => comment.blueprint_position && (!comment.blueprint_id || comment.blueprint_id === blueprintId)
        )
        .map((comment) => (
          <CommentFrame
            {...getCommentProperties(comment)}
            frameId={comment.thread_id}
            key={comment.thread_id}
            disabled={isLoading || (isMovingComment && selectedComment?.thread_id !== comment.thread_id)}
            isWarning={!comment.blueprint_id}
            blueprintScale={scale / 100}
            onClick={() => {
              if (!isMovingComment) {
                setSelectedComment(comment)
                setIsOpeningCommentPopup(true)
              }
            }}
            forOverview={false}
            isMoving={selectedComment?.thread_id === comment.thread_id && isMovingComment}
            onConfirmMove={onConfirmMove}
            onCancelMove={onCancelMove}
            onDragMove={onDragMove}
            scale={Math.min(scale / 100, 1)}
          />
        ))}
      <HStack
        flexWrap="wrap"
        // padding left and right = 4px
        maxW={`${
          (Math.ceil(Math.sqrt(unplacedComments.length)) * (BLUEPRINT_COMMENT_AREA_MIN_SIZE + 4 * 2) * scale) / 100
        }px`}
        spacing={0}
        position="absolute"
        top={`${(document.querySelector('.react-pdf__Page')?.clientHeight || 0) / 2}px`}
        transform="translateX(-50%) translateY(-50%)"
        left="50%"
        zIndex={2}
      >
        {unplacedComments.map((comment) => (
          <CommentFrame
            {...getCommentProperties(comment, true)}
            frameId={comment.thread_id}
            unplaced
            key={comment.thread_id}
            disabled={isLoading || (isMovingComment && selectedComment?.thread_id !== comment.thread_id)}
            isWarning={!comment.blueprint_id}
            blueprintScale={scale / 100}
            onClick={(target: HTMLDivElement) => {
              if (!isMovingComment) {
                setSelectedComment(comment)
                setIsOpeningCommentPopup(true)

                const { left, top } = target.getBoundingClientRect()
                const coordinate = getPointPosition(left, top) || { x: 0, y: 0 }
                setSelectedCommentBoundingPosition({
                  coordinate: { x: (coordinate.x / scale) * 100, y: (coordinate.y / scale) * 100 },
                  extent: {
                    width: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
                    height: BLUEPRINT_COMMENT_AREA_MIN_SIZE,
                  },
                })
              }
            }}
            forOverview={false}
            isHoldingPlace={selectedComment?.thread_id === comment.thread_id && isMovingComment}
            onConfirmMove={onConfirmMove}
            onCancelMove={onCancelMove}
            onDragMove={onDragMove}
            scale={Math.min(scale / 100, 1)}
          />
        ))}
      </HStack>
      {selectedComment && unplacedComments.includes(selectedComment) && isMovingComment && (
        <CommentFrame
          {...getCommentProperties(selectedComment)}
          isWarning={false}
          isMoving
          unplaced
          disabled={isLoading}
          blueprintScale={scale / 100}
          forOverview={false}
          onConfirmMove={onConfirmMove}
          onCancelMove={onCancelMove}
          onDragMove={onDragMove}
          scale={Math.min(scale / 100, 1)}
        />
      )}
    </Document>
  )
}

export default MainDocument
