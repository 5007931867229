import { useContext } from 'react'

import { Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { IFCFile } from 'interfaces/project'

import { deleteIFCFile } from 'services/IFC'

const useIFCFiles = (
  project_group_id: string | undefined,
  project_id: string | undefined,
  isAllowedModify: boolean,
  files: IFCFile[],
  setIsLoading: (value: boolean) => void,
  fetchData: () => Promise<void>
) => {
  const { t } = useTranslation('dashboard')

  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const confirmFileDelete = (file: IFCFile) => {
    if (!project_group_id || !isAllowedModify) {
      return false
    }

    showModal({
      body: (
        <>
          <Text>{t('ifc_files.delete_warning')}</Text>
          <Text mt="1" fontWeight="semibold">
            {file.original_filename}
          </Text>
        </>
      ),
      confirmText: t('ifc_files.delete'),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const deleteFileApi = async () => {
          setIsLoading(true)
          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            return false
          }

          const deleteResult = await deleteIFCFile(token, project_group_id, file.ifc_file_id, showErrorModal)
          if (!deleteResult) {
            setIsLoading(false)
            return false
          }
          // track event to mixpanel
          mixpanel.track('Delete ifc file', {
            'Project ID': project_group_id,
            'Inspection area ID': project_id,
            'Number of ifc files (after deleting one)': files.length - 1,
          })

          await fetchData()
          setIsLoading(false)
          return true
        }

        void deleteFileApi()
        return true
      },
      title: t('ifc_files.confirm_delete_ifc_file'),
    })
    return true
  }

  return { confirmFileDelete }
}

export default useIFCFiles
