import { FC, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { EditorContext, INITIAL_EXTRA_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_EXTRA_SHAPE_KEYS, EDITOR_SHAPE_KEYS, EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'
import { INSPECTION_CONTRACTEE_ERROR_COLOR } from 'config/styles'

import {
  InspectionItemNumberValues,
  InspectionSheet,
  SpacerInspectionItem,
  SpacerInspectionItemNumberValues,
} from 'interfaces/interfaces'

import { upsertInspectionItem } from 'services/SpacerInspectionSheet'
import { roundNumber } from 'services/Util'

const DEFAULT_NUMBER_VALUES = {
  specified_value: null,
  estimated_value: null,
}
const DEFAULT_SPACER_NUMBER_VALUES = {
  specified_value: null,
  estimated_value_by_user_input: null,
  estimated_value_by_surface_density: null,
}
const SpacerValuesModal: FC<{
  inspectionSheet: InspectionSheet
  valuesUpdatingInspectionItem?: SpacerInspectionItem
  isOpen: boolean
  onConfirm: (result?: boolean) => void
  onUpdateShapes: () => void
}> = ({ inspectionSheet, valuesUpdatingInspectionItem, isOpen, onConfirm, onUpdateShapes }) => {
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { project, selectedSpacerInspectionItem, spacerAnnotations, selectedShapeIds } = useContext(EditorContext)

  const selectedSpacer = spacerAnnotations.find((s) =>
    selectedShapeIds.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)).includes(s.shape_id)
  )

  const [isLoading, setIsLoading] = useState(false)

  const [inspectionItem, setInspectionItem] = useState<SpacerInspectionItem>()
  const [partName, setPartName] = useState<string>('')
  const [area, setArea] = useState<number>()
  const [areaString, setAreaString] = useState('')
  const [spacerPerArea, setSpacerPerArea] = useState<InspectionItemNumberValues>(DEFAULT_NUMBER_VALUES)
  const [spacerPerAreaString, setSpacerPerAreaString] = useState('')
  const [spacers, setSpacers] = useState<SpacerInspectionItemNumberValues>(DEFAULT_SPACER_NUMBER_VALUES)
  const [spacersSpecifiedString, setSpacersSpecifiedString] = useState('')
  const [spacersEstimatedString, setSpacersEstimatedString] = useState('')

  useEffect(() => {
    setInspectionItem(valuesUpdatingInspectionItem || selectedSpacerInspectionItem)
  }, [valuesUpdatingInspectionItem, selectedSpacerInspectionItem])

  useEffect(() => {
    setPartName(inspectionItem?.part_name || '')
    setArea(inspectionItem?.target_area || undefined)
    setAreaString(inspectionItem?.target_area?.toString() || '')

    const spacerSpecifiedValue = inspectionItem?.number_of_deployed_spacers.specified_value ?? null
    const spacerEstimatedValue = inspectionItem?.number_of_deployed_spacers.estimated_value_by_user_input ?? null
    const spacerEstimatedValueByDensity =
      valuesUpdatingInspectionItem?.number_of_deployed_spacers.estimated_value_by_surface_density ??
      (spacerEstimatedValue !== null &&
      inspectionItem?.target_area !== undefined &&
      inspectionItem?.target_area !== null
        ? roundNumber(spacerEstimatedValue * inspectionItem.target_area, '1')
        : null)
    setSpacers({
      specified_value: spacerSpecifiedValue,
      estimated_value_by_surface_density: spacerEstimatedValueByDensity,
      estimated_value_by_user_input: spacerEstimatedValue,
    })
    setSpacersSpecifiedString(spacerSpecifiedValue?.toString() || '')
    setSpacersEstimatedString(spacerEstimatedValue?.toString() || '')

    const spacerPerAreaSpecifiedValue = inspectionItem?.number_of_spacers_per_meter_square.specified_value ?? null
    setSpacerPerArea({
      specified_value: spacerPerAreaSpecifiedValue,
      estimated_value:
        valuesUpdatingInspectionItem?.number_of_spacers_per_meter_square.estimated_value ??
        (selectedSpacer?.area && roundNumber(1 / selectedSpacer.area, '0.1')) ??
        null,
    })
    setSpacerPerAreaString(spacerPerAreaSpecifiedValue?.toString() || '')
  }, [valuesUpdatingInspectionItem, selectedSpacerInspectionItem, inspectionItem, selectedSpacer])

  useEffect(() => {
    setIsLoading(false)
  }, [isOpen])

  const confirmValues = async () => {
    if (!project_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const shapeIds = selectedSpacer
      ? {
          planes: selectedSpacer.shape_type === EDITOR_SHAPE_KEYS.PLANES ? [selectedSpacer.shape_id] : [],
          rhombi: selectedSpacer.shape_type === EDITOR_EXTRA_SHAPE_KEYS.RHOMBI ? [selectedSpacer.shape_id] : [],
        }
      : undefined
    // Use item value when updating item. Use evaluated value when updating shapes
    const updatedShapeIds = shapeIds || valuesUpdatingInspectionItem?.shape_ids || INITIAL_EXTRA_SHAPE_STATE()

    const newItem: SpacerInspectionItem = {
      inspection_item_id: inspectionItem?.inspection_item_id,
      part_name: partName,
      number_of_spacers_per_meter_square: spacerPerArea,
      number_of_deployed_spacers: spacers,
      target_area: area ?? null,
      shape_ids: updatedShapeIds,
      // As user has acknowledged this action, there is no need to show need-update alert
      need_update: false,
    }

    const upsertResult = await upsertInspectionItem(
      token,
      project_id,
      inspectionSheet.inspection_sheet_id,
      newItem,
      showErrorModal
    )
    if (upsertResult) {
      onConfirm(true)
      // track with mixpanel
      const track_title = newItem.inspection_item_id
        ? 'Update inspection item'
        : 'Create spacer inspection item - with shapes'
      mixpanel.track(track_title, {
        'Inspection area ID': project_id,
        'Shape type': shapeIds?.planes ? 'planes' : 'rhombi',
      })

      return true
    }

    setIsLoading(false)

    return false
  }

  const renderValueInputs = (
    values: InspectionItemNumberValues | SpacerInspectionItemNumberValues | undefined,
    regionTitle: string,
    labels: string[],
    unit: string,
    setter?: (newValues: InspectionItemNumberValues) => void,
    spacerSetter?: (newValues: SpacerInspectionItemNumberValues) => void,
    stringValue?: string,
    stringSetter?: (newValue: string, valueAsNumber?: number) => void,
    estimatedStringValue?: string,
    estimatedStringSetter?: (newValue: string) => void,
    markups?: string[],
    preventDecimal?: boolean
  ) => {
    const difference =
      (values as InspectionItemNumberValues)?.estimated_value && values?.specified_value
        ? roundNumber(
            ((values as InspectionItemNumberValues)?.estimated_value || 0) - (values?.specified_value || 0),
            '0.1'
          )
        : undefined

    return (
      <VStack height="100%" spacing={1} paddingTop={6}>
        <HStack width="100%" alignItems="baseline">
          <Text fontWeight="bold" fontSize="lg" whiteSpace="nowrap">
            {regionTitle}
          </Text>
          <Divider />
        </HStack>
        <HStack justifyContent="flex-start" width="100%" paddingLeft={5} paddingTop={2} spacing={8}>
          {labels.length && (
            <FormControl flex={1}>
              <FormLabel htmlFor={regionTitle}>
                {labels[0]}
                {unit.length ? `（${unit}）` : ``}
              </FormLabel>
              <NumberInput
                min={0}
                max={9999}
                value={stringValue || ''}
                onChange={(valueAsString, valueAsNumber) => {
                  if (setter) {
                    setter({
                      specified_value: valueAsNumber,
                      estimated_value: (values as InspectionItemNumberValues)?.estimated_value,
                    })
                  }
                  if (spacerSetter) {
                    spacerSetter({
                      specified_value: valueAsNumber,
                      estimated_value_by_surface_density: (values as SpacerInspectionItemNumberValues)
                        ?.estimated_value_by_surface_density,
                      estimated_value_by_user_input: (values as SpacerInspectionItemNumberValues)
                        ?.estimated_value_by_user_input,
                    })
                  }
                  if (stringSetter) {
                    stringSetter(valueAsString.replace(preventDecimal ? '.' : '', ''), valueAsNumber)
                  }
                }}
                isRequired
              >
                <NumberInputField />
              </NumberInput>
              <Text>{markups?.[0] || ''}</Text>
            </FormControl>
          )}
          {labels.length > 1 && (
            <FormControl flex={1}>
              <FormLabel>
                {labels[1]}
                {unit.length ? `（${unit}）` : ``}
              </FormLabel>
              <VStack height={10} alignItems="flex-start" pt={2}>
                <Text fontSize="md">
                  {(values as SpacerInspectionItemNumberValues)?.estimated_value_by_surface_density ||
                    (values as InspectionItemNumberValues)?.estimated_value ||
                    '-'}
                </Text>
              </VStack>
              {!!markups?.[0] && <Text opacity={0}>{markups?.[0] || ''}</Text>}
            </FormControl>
          )}
          {labels.length > 2 && (
            <FormControl flex={1}>
              <FormLabel>
                {labels[2]}
                {unit.length ? `（${unit}）` : ``}
              </FormLabel>
              {(estimatedStringValue === undefined || !estimatedStringSetter) && (
                <VStack height={10} alignItems="flex-start" pt={2}>
                  <Text
                    fontSize="md"
                    color={difference && difference < 0 ? INSPECTION_CONTRACTEE_ERROR_COLOR : 'inherit'}
                  >
                    {difference === undefined ? '-' : difference || '±0'}
                  </Text>
                </VStack>
              )}
              {estimatedStringValue !== undefined && estimatedStringSetter && (
                <NumberInput
                  min={0}
                  max={9999}
                  value={estimatedStringValue || ''}
                  onChange={(valueAsString, valueAsNumber) => {
                    if (spacerSetter) {
                      spacerSetter({
                        specified_value: values?.specified_value ?? null,
                        estimated_value_by_surface_density: (values as SpacerInspectionItemNumberValues)
                          ?.estimated_value_by_surface_density,
                        estimated_value_by_user_input: valueAsNumber,
                      })
                    }
                    if (estimatedStringSetter) {
                      estimatedStringSetter(valueAsString.replace(preventDecimal ? '.' : '', ''))
                    }
                  }}
                  isRequired
                >
                  <NumberInputField />
                </NumberInput>
              )}
              {!!markups?.[0] && <Text opacity={0}>{markups?.[0] || ''}</Text>}
            </FormControl>
          )}
        </HStack>
      </VStack>
    )
  }

  return (
    <Modal
      closeOnOverlayClick={!isLoading}
      isOpen={isOpen}
      onClose={() => onConfirm()}
      trapFocus={false}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {/* 測定箇所 */}
          <VStack height="100%" spacing={1} paddingTop={6}>
            <HStack width="100%" alignItems="baseline">
              <Text fontWeight="bold" fontSize="lg" whiteSpace="nowrap">
                {t('main_canvas.inspection_sheet.spacer_sheet_property.item', { ns: 'projects' })}
              </Text>
              <Divider />
            </HStack>
            <HStack justifyContent="flex-start" width="100%" paddingLeft={5} paddingTop={2}>
              <FormControl flex={1}>
                <FormLabel htmlFor="partName">
                  {t('main_canvas.inspection_sheet.create_item_modal.name', { ns: 'projects' })}
                </FormLabel>
                <InputGroup size="md">
                  <Input id="partName" value={partName} onChange={(e) => setPartName(e.target.value)} />
                </InputGroup>
              </FormControl>
              <Box flex={1} />
            </HStack>
          </VStack>
          {/* 1m^2あたり個数 */}
          {renderValueInputs(
            spacerPerArea,
            t('main_canvas.inspection_sheet.spacer_sheet_property.surface_density', { ns: 'projects' }),
            [
              t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' }),
              t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
              t('main_canvas.inspection_sheet.values.difference', { ns: 'projects' }),
            ],
            t('main_canvas.inspection_sheet.spacer_sheet_property.unit_surface_density', { ns: 'projects' }),
            (values) => {
              setSpacerPerArea(values)
              setSpacers((state) => ({
                ...state,
                estimated_value_by_surface_density:
                  values.estimated_value !== null && area !== undefined
                    ? roundNumber(values.estimated_value * area, '1')
                    : null,
              }))
            },
            undefined,
            spacerPerAreaString,
            setSpacerPerAreaString,
            undefined,
            undefined,
            ['以上'],
            true
          )}
          {/* 対象面積 */}
          {renderValueInputs(
            spacerPerArea,
            t('main_canvas.inspection_sheet.spacer_sheet_property.target_area', { ns: 'projects' }),
            [''],
            'm^2',
            undefined,
            undefined,
            areaString,
            (valueAsString, valueAsNumber) => {
              setAreaString(valueAsString)
              setArea(valueAsNumber)
              setSpacers((state) => ({
                ...state,
                estimated_value_by_surface_density:
                  spacerPerArea.estimated_value !== null && valueAsNumber !== undefined
                    ? roundNumber(spacerPerArea.estimated_value * valueAsNumber, '1')
                    : null,
              }))
            }
          )}
          {/* スペーサー設置個数 */}
          {renderValueInputs(
            spacers,
            t('main_canvas.inspection_sheet.spacer_sheet_property.spacer_number', { ns: 'projects' }),
            [
              t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' }),
              `${t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' })}（${t(
                'main_canvas.inspection_sheet.spacer_sheet_property.estimate',
                { ns: 'projects' }
              )}）`,
              `${t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' })}（${t(
                'main_canvas.inspection_sheet.spacer_sheet_property.user_input',
                { ns: 'projects' }
              )}）`,
            ],
            t('main_canvas.inspection_sheet.spacer_sheet_property.unit_number', { ns: 'projects' }),
            undefined,
            setSpacers,
            spacersSpecifiedString,
            setSpacersSpecifiedString,
            spacersEstimatedString,
            setSpacersEstimatedString,
            undefined,
            true
          )}
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('main_canvas.inspection_sheet.create_item_modal.cancel', { ns: 'projects' })}
          </Button>
          {inspectionItem && project?.down_sampled_file.name && (
            <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onUpdateShapes()}>
              {t('main_canvas.inspection_sheet.create_spacer_item_modal.reselect_models', { ns: 'projects' })}
            </Button>
          )}
          <Button disabled={isLoading} me={3} py={2} minW="100px" colorScheme="primary" onClick={() => confirmValues()}>
            {inspectionItem
              ? t('main_canvas.inspection_sheet.create_item_modal.update', { ns: 'projects' })
              : t('main_canvas.inspection_sheet.create_spacer_item_modal.preview_sheet', { ns: 'projects' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SpacerValuesModal
