/* eslint-disable no-param-reassign */
import { Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'
import mixpanel from 'mixpanel-browser'

import {
  InspectionItem,
  InspectionSheet,
  Project,
  ShapeDistance,
  ShapesDistances,
  TextsInspectionSheet,
} from 'interfaces/interfaces'

import { meterToMillimeter, roundNumber } from 'services/Util'

import { setProfileGroup } from './XLSX'

const newWorkbook = (): Workbook => {
  const workbook = new Workbook()
  workbook.creator = 'DataLabs Modely'
  workbook.lastModifiedBy = 'DataLabs Modely'
  workbook.created = new Date()

  return workbook
}

const setInspectionItemData = (sheet: Worksheet, inspectionItem: InspectionItem, textItem: string) => {
  const startRow = 8 // Profile rows is 6 + 1 row as space
  sheet.getRow(startRow).getCell(1).value = textItem
  sheet.getRow(startRow).getCell(2).value = inspectionItem.part_name
}

const setValues = (sheet: Worksheet, shapesDistances: ShapesDistances, textSpacing: string) => {
  const startRow = 9 // Profile rows is 6 + 1 row as space + 1 Inspection item data row

  sheet.getRow(startRow + 1).getCell(1).value = textSpacing
  shapesDistances.cylinders.concat(shapesDistances.tori).forEach((distance: ShapeDistance, index) => {
    sheet.getRow(startRow + 1).getCell(index + 2).value = meterToMillimeter(roundNumber(distance.distance, '0.001'))
  })
}

export const saveFile = (workbook: Workbook, fileName: string): Promise<boolean> =>
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, fileName)
    return true
  })

/**
 * Create an XLSX exported file of an inspection sheet. Will immediately
 * forces the generated file to be saved by the user.
 *
 * @param name Name of worksheet
 * @param inspectionSheet Inspection Sheet
 * @param inspectionItem Inspection Item
 * @param shapesDistances All the inter-rebar distances in the inspection item
 * @param project inspection area
 * @param texts Texts for the inspection sheet
 * @returns {Promise<boolean>} True if the file was saved successfully
 */
export const generateXLSX = async (
  name: string,
  inspectionSheet: InspectionSheet,
  inspectionItem: InspectionItem,
  shapesDistances: ShapesDistances,
  project: Project | undefined,
  texts: TextsInspectionSheet
): Promise<boolean> => {
  const workbook = newWorkbook()
  const sheet = workbook.addWorksheet(name, { pageSetup: { orientation: 'landscape', paperSize: 9 } })

  setProfileGroup(sheet, project?.project_name || '', inspectionSheet, texts.sheet_property)
  setInspectionItemData(sheet, inspectionItem, texts.item)
  setValues(sheet, shapesDistances, texts.individual_spacing)

  // Re-size the column to fix the text
  sheet.columns.forEach((column) => {
    let maxLength = 0
    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
    }
    column.width = maxLength < 10 ? 10 : maxLength + 2
  })

  await saveFile(workbook, name)

  // track event to mixpanel
  mixpanel.track('Export individual inter-rebar distances', {
    'Inspection area ID': project?.project_id,
    'Inspection item ID': inspectionItem.inspection_item_id,
    'File format': 'xlsx',
    'Shape type': shapesDistances.cylinders.length ? 'cylinders' : 'tori',
    'Distance number': shapesDistances.cylinders.length || shapesDistances.tori.length,
  })

  return true
}
