import { FC } from 'react'

import { Helmet } from 'react-helmet'

import { Project, ProjectGroup } from 'interfaces/interfaces'

/**
 * returns react component for browser tab title from the project and project group
 *
 * @param {{ projectGroup: any; project: any; }} param0 - project and project group
 * @param {ProjectGroup} param0.projectGroup - project group
 * @param {Project} param0.project - project
 * @returns {*} react component for browser tab title
 */
const AppTitle: FC<{ projectGroup?: ProjectGroup | null; project?: Project | null }> = ({ projectGroup, project }) => (
  <Helmet>
    <title>
      {project?.project_name || 'Modely'}
      {projectGroup ? ` · ${projectGroup.project_group_name}` : ''}
    </title>
  </Helmet>
)

export default AppTitle
