import { FC, useEffect, useState } from 'react'

import { Button, HStack, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { InspectionItem, InspectionSheet, SpacerInspectionItem } from 'interfaces/inspection'
import { ShapesDistances } from 'interfaces/shape'

import MainSheetModal, { MODAL_WIDTH as REBARS_MODAL_WIDTH } from './MainSheetModal'
import SpacerSheetModal, { MODAL_WIDTH as SPACERS_MODAL_WIDTH } from './SpacerSheetModal'

export const MODAL_TYPE = {
  REBARS: 'rebars',
  SPACERS: 'spacers',
}
const MainSheetWrapperModal: FC<{
  canClose: boolean
  inspectionSheet: InspectionSheet
  inspectionItems: InspectionItem[]
  spacerInspectionSheet: InspectionSheet
  spacerInspectionItems: SpacerInspectionItem[]
  openType?: string
  isLoadingSheet: boolean
  isAllowedDownload: boolean
  isAllowedModify: boolean
  onOpenItem: (item: InspectionItem) => void
  onOpenSpacerItem: (item: SpacerInspectionItem) => void
  onClose: () => void
  onSheetUpdated: () => void
  onItemSelected: (item: InspectionItem | null) => void
  onSpacerItemSelected: (item: SpacerInspectionItem | null) => void
  onItemAdded: () => void
  onItemRefetch: (item: InspectionItem) => void
  onSpacerItemRefetch: (item: SpacerInspectionItem) => void
  openUpdateProjectModal: () => void
  setShapesDistances: (distances: ShapesDistances) => void
  onOpenBlackboard: () => void
}> = ({
  canClose,
  inspectionSheet,
  inspectionItems,
  spacerInspectionSheet,
  spacerInspectionItems,
  openType,
  isLoadingSheet,
  isAllowedDownload,
  isAllowedModify,
  onOpenItem,
  onOpenSpacerItem,
  onClose,
  onSheetUpdated,
  onItemSelected,
  onSpacerItemSelected,
  onItemAdded,
  onItemRefetch,
  onSpacerItemRefetch,
  openUpdateProjectModal,
  setShapesDistances,
  onOpenBlackboard,
}) => {
  const [isItemSelected, setIsItemSelected] = useState(false)
  const [openingModal, setOpeningModal] = useState(MODAL_TYPE.REBARS)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation(['projects'])

  useEffect(() => {
    if (openType) {
      setOpeningModal(openType)
    }
  }, [openType])

  const renderTabButton = (type: string, label: string) => (
    <Button
      borderTopRadius={0}
      onClick={() => setOpeningModal(type)}
      variant={openingModal === type ? 'solid' : 'outline'}
      size="sm"
    >
      {label}
    </Button>
  )
  return (
    <Modal
      closeOnOverlayClick={!isLoading && !isLoadingSheet && !isItemSelected}
      isOpen={openType !== undefined}
      onClose={() => onClose()}
      trapFocus={false}
    >
      <ModalOverlay hidden={isItemSelected} />
      <ModalContent
        maxWidth={openingModal === MODAL_TYPE.REBARS ? REBARS_MODAL_WIDTH : SPACERS_MODAL_WIDTH}
        transition="background-color 0.1s ease-in"
        backgroundColor={isItemSelected ? 'transparent' : 'white'}
        position="relative"
        pointerEvents={isItemSelected ? 'none' : 'auto'}
        shadow={0}
        containerProps={{ pointerEvents: isItemSelected ? 'none' : 'auto' }}
      >
        <HStack
          justifyContent="center"
          spacing={1}
          transition="opacity 0.1s ease-in"
          opacity={isItemSelected ? '0' : '1'}
        >
          {renderTabButton(
            MODAL_TYPE.REBARS,
            t('main_canvas.inspection_sheet.rebar_inspection_sheet', { ns: 'projects' })
          )}
          {renderTabButton(
            MODAL_TYPE.SPACERS,
            t('main_canvas.inspection_sheet.spacer_inspection_sheet', { ns: 'projects' })
          )}
        </HStack>
        {openingModal === MODAL_TYPE.REBARS && (
          <MainSheetModal
            canClose={canClose}
            inspectionSheet={inspectionSheet}
            inspectionItems={inspectionItems}
            isLoadingSheet={isLoadingSheet}
            isAllowedDownload={isAllowedDownload}
            isAllowedModify={isAllowedModify}
            isLoading={isLoading}
            onOpenItem={onOpenItem}
            onClose={onClose}
            onSheetUpdated={onSheetUpdated}
            onItemSelected={onItemSelected}
            onItemAdded={onItemAdded}
            onItemRefetch={onItemRefetch}
            openUpdateProjectModal={openUpdateProjectModal}
            setShapesDistances={setShapesDistances}
            setIsItemSelected={setIsItemSelected}
            setIsLoading={setIsLoading}
            onOpenBlackboard={onOpenBlackboard}
          />
        )}
        {openingModal === MODAL_TYPE.SPACERS && (
          <SpacerSheetModal
            canClose={canClose}
            inspectionSheet={spacerInspectionSheet}
            inspectionItems={spacerInspectionItems}
            isLoadingSheet={isLoadingSheet}
            isAllowedDownload={isAllowedDownload}
            isAllowedModify={isAllowedModify}
            isLoading={isLoading}
            onOpenItem={onOpenSpacerItem}
            onClose={onClose}
            onSheetUpdated={onSheetUpdated}
            onItemSelected={onSpacerItemSelected}
            onItemRefetch={onSpacerItemRefetch}
            openUpdateProjectModal={openUpdateProjectModal}
            setIsItemSelected={setIsItemSelected}
            setIsLoading={setIsLoading}
            onOpenBlackboard={onOpenBlackboard}
          />
        )}
      </ModalContent>
    </Modal>
  )
}

export default MainSheetWrapperModal
