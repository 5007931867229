import { FC } from 'react'

import { Flex, HStack, Square, StackDivider, Tooltip } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  CubeBackIcon,
  CubeDownIcon,
  CubeFrontIcon,
  CubeLeftIcon,
  CubeRightIcon,
  CubeUpIcon,
  TargetLockIcon,
} from 'assets/icons'

const AssistantBar: FC<{
  move: (position: string) => void
  fixedPosition: boolean
  removeCameraFocusPoint?: () => void
}> = ({ move, fixedPosition, removeCameraFocusPoint }) => {
  const { t } = useTranslation(['projects'])

  const buttonSize = isTablet ? 12 : 8

  // TODO: this react component should be moved to outside of AssistantBar to avoid nesting of react components
  /**
   * button component
   *
   * @param {string} label text for tooltip
   * @param {string} testId test id for button
   * @param {React.ReactElement} icon icon for button
   * @param {() => void} onClick callback function to be called when button is clicked
   * @param {?boolean} [isActive] whether the button is active. It affects the background color of the button
   * @returns {React.ReactElement} button component
   */
  const renderButton = (
    label: string,
    testId: string,
    icon: React.ReactElement,
    onClick: () => void,
    isActive?: boolean
  ) => (
    <Tooltip hasArrow placement="top" label={label} p={2} fontSize="xs" fontWeight="normal" isDisabled={isTablet}>
      <Flex>
        <Square
          position="relative"
          data-testid={testId}
          color="white"
          fontSize="xl"
          size={buttonSize}
          onClick={onClick}
          backgroundColor={isActive ? 'gray.600' : 'transparent'}
        >
          {icon}
        </Square>
      </Flex>
    </Tooltip>
  )

  // text for mixpanel
  let trackText = 'Move camera pose - cuboid coordinate'
  if (fixedPosition) {
    trackText = 'Move camera pose - fixed coordinate'
  }

  const { project_id } = useParams<{ project_id: string }>()

  return (
    <HStack
      left={1}
      overflow="hidden"
      position={fixedPosition ? 'absolute' : 'unset'}
      bottom={1}
      h={buttonSize}
      spacing={4}
    >
      <HStack
        backgroundColor="gray.800"
        overflow="hidden"
        borderRadius="md"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
        h={buttonSize}
      >
        {renderButton(
          fixedPosition
            ? t('main_canvas.camera_bar_world_coordinate.x_minus', { ns: 'projects' })
            : t('main_canvas.camera_bar_cuboid_coordinate.x_minus', { ns: 'projects' }),
          fixedPosition ? 'LEFT' : 'CUBE-LEFT',
          <CubeLeftIcon width="50%" height="50%" />,
          () => {
            move(fixedPosition ? 'LEFT' : 'CUBE-LEFT')

            // track with mixpanel
            mixpanel.track(trackText, {
              'Inspection area ID': project_id,
              'Camera pose': 'left',
            })
          }
        )}
        {renderButton(
          fixedPosition
            ? t('main_canvas.camera_bar_world_coordinate.x_plus', { ns: 'projects' })
            : t('main_canvas.camera_bar_cuboid_coordinate.x_plus', { ns: 'projects' }),
          fixedPosition ? 'RIGHT' : 'CUBE-RIGHT',
          <CubeRightIcon width="50%" height="50%" />,
          () => {
            move(fixedPosition ? 'RIGHT' : 'CUBE-RIGHT')
            // track with mixpanel
            mixpanel.track(trackText, {
              'Inspection area ID': project_id,
              'Camera pose': 'right',
            })
          }
        )}
        {renderButton(
          fixedPosition
            ? t('main_canvas.camera_bar_world_coordinate.y_minus', { ns: 'projects' })
            : t('main_canvas.camera_bar_cuboid_coordinate.y_minus', { ns: 'projects' }),
          fixedPosition ? 'FRONT' : 'CUBE-FRONT',
          <CubeFrontIcon width="50%" height="50%" />,
          () => {
            move(fixedPosition ? 'FRONT' : 'CUBE-FRONT')
            // track with mixpanel
            mixpanel.track(trackText, {
              'Inspection area ID': project_id,
              'Camera pose': 'front',
            })
          }
        )}
        {renderButton(
          fixedPosition
            ? t('main_canvas.camera_bar_world_coordinate.y_plus', { ns: 'projects' })
            : t('main_canvas.camera_bar_cuboid_coordinate.y_plus', { ns: 'projects' }),
          fixedPosition ? 'BACK' : 'CUBE-BACK',
          <CubeBackIcon width="50%" height="50%" />,
          () => {
            move(fixedPosition ? 'BACK' : 'CUBE-BACK')
            // track with mixpanel
            mixpanel.track(trackText, {
              'Inspection area ID': project_id,
              'Camera pose': 'back',
            })
          }
        )}
        {renderButton(
          fixedPosition
            ? t('main_canvas.camera_bar_world_coordinate.z_minus', { ns: 'projects' })
            : t('main_canvas.camera_bar_cuboid_coordinate.z_minus', { ns: 'projects' }),
          fixedPosition ? 'DOWN' : 'CUBE-DOWN',
          <CubeDownIcon width="50%" height="50%" />,
          () => {
            move(fixedPosition ? 'DOWN' : 'CUBE-DOWN')
            // track with mixpanel
            mixpanel.track(trackText, {
              'Inspection area ID': project_id,
              'Camera pose': 'down',
            })
          }
        )}
        {renderButton(
          fixedPosition
            ? t('main_canvas.camera_bar_world_coordinate.z_plus', { ns: 'projects' })
            : t('main_canvas.camera_bar_cuboid_coordinate.z_plus', { ns: 'projects' }),
          fixedPosition ? 'UP' : 'CUBE-UP',
          <CubeUpIcon width="50%" height="50%" />,
          () => {
            move(fixedPosition ? 'UP' : 'CUBE-UP')
            // track with mixpanel
            mixpanel.track(trackText, {
              'Inspection area ID': project_id,
              'Camera pose': 'up',
            })
          }
        )}
        {/* show remove camera focus button */}
        {!!removeCameraFocusPoint &&
          renderButton(
            t('main_canvas.camera_bar_remove_focus_point', { ns: 'projects' }),
            'REMOVE-FOCUS-POINT',
            <TargetLockIcon width="50%" height="50%" />,
            removeCameraFocusPoint,
            true
          )}
      </HStack>
    </HStack>
  )
}

export default AssistantBar
