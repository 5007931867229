// eslint-disable-next-line no-restricted-imports
import axios, { AxiosRequestConfig } from 'axios'

import { API_GATEWAY_URL } from 'config/environments'

import { ServiceStatus } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

/**
 * Get service status, used for displaying maintenance page
 * @param {string} access_token
 * @return {ServiceStatus | null}
 */
export const getServiceStatus = async (
  access_token: string,
  showErrorModal: (message: string) => void
): Promise<ServiceStatus | null> => {
  const config: AxiosRequestConfig = {
    responseType: 'json',
    headers: access_token ? { 'X-Authorization': `Bearer ${access_token}` } : undefined,
  }
  const status = await axios
    .get<ServiceStatus>(`${API_GATEWAY_URL}/service-status`, config)
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_SERVICE_STATUS, showErrorModal)
      return null
    })

  return status
}
