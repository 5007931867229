import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { ProjectGroup, ProjectsContextProps } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from 'services/ErrorHandler'
import * as ProjectService from 'services/Projects'

import { GlobalModalContext } from './GlobalModal'
import { UserContext } from './Users'

// ref: https://wanago.io/2020/09/28/react-context-api-hooks-typescript/
//* アプリ全体としてプロジェクト情報を管理するプロバイダーの生成
export const ProjectsContext = createContext<ProjectsContextProps>({
  projectGroups: [],
  invitedProjectGroups: [],
  total_size: 0,
  projectGroupsLoaded: false,
  getProjectGroup: () => undefined,
  getProjectGroups: async () => Promise.resolve(null),
  appendProjectGroup: () => null,
  putProjectGroup: () => null,
})

//* プロバイダーに渡すプロジェクト情報の項目や操作処理
export function useProjectsContext(): ProjectsContextProps {
  const { showErrorModal } = useContext(GlobalModalContext)
  const { userLoaded } = useContext(UserContext)

  const [projectGroups, setProjectGroups] = useState<ProjectGroup[]>([])
  const [invitedProjectGroups, setInvitedProjectGroups] = useState<ProjectGroup[]>([])
  const [total_size, setTotalSize] = useState(0)
  const [projectGroupsLoaded, setProjectGroupsLoaded] = useState(false)
  const { getAccessTokenSilently } = useAuth0()

  //* プロジェクトデータ取得
  const getProjectGroups = useCallback(
    async (
      user_id?: string
    ): Promise<{ projectGroups: ProjectGroup[]; invitedProjectGroups: ProjectGroup[]; totalSize: number } | null> => {
      const access_token = await getAccessTokenSilently().catch((err) => {
        processErrorHandler(err, ERROR_PROCESS.GET_ACCESS_TOKEN, showErrorModal)
        return null
      })

      if (!access_token) {
        return null
      }

      const [projectGroupsRes, invitedProjectGroupsRes] = await Promise.all([
        ProjectService.getProjectGroups(access_token, user_id, (message: string) => showErrorModal(message, true)),
        ProjectService.getInvitedProjectGroups(access_token, user_id, (message: string) =>
          showErrorModal(message, true)
        ),
      ])

      if (!projectGroupsRes || !invitedProjectGroupsRes) {
        return null
      }

      setTotalSize(projectGroupsRes.total_size)
      setProjectGroups(projectGroupsRes.projectGroups)
      setInvitedProjectGroups(invitedProjectGroupsRes.projectGroups)
      return {
        projectGroups: projectGroupsRes.projectGroups,
        invitedProjectGroups: invitedProjectGroupsRes.projectGroups,
        totalSize: projectGroupsRes.total_size,
      }
    },
    [getAccessTokenSilently, showErrorModal]
  )

  //* プロバイダー内のプロジェクトデータを取得
  const getProjectGroup = useCallback(
    (project_group_id: string): ProjectGroup | undefined =>
      projectGroups.find((pj) => pj.project_group_id === project_group_id),
    [projectGroups]
  )

  //* プロジェクトデータの追加
  const appendProjectGroup = useCallback(
    (projectGroup: ProjectGroup) => {
      const deepCopyProjectGroups = JSON.parse(JSON.stringify(projectGroups)) as ProjectGroup[]
      setProjectGroups([projectGroup, ...deepCopyProjectGroups])
    },
    [projectGroups]
  )

  //* プロジェクトデータの置換
  const putProjectGroup = useCallback(
    (newProjectGroup: ProjectGroup) => {
      const deepCopyProjectGroups = JSON.parse(JSON.stringify(projectGroups)) as ProjectGroup[]
      const index = projectGroups.findIndex((pj) => pj.project_group_id === newProjectGroup.project_group_id)
      deepCopyProjectGroups.splice(index, 1, newProjectGroup)

      setProjectGroups(deepCopyProjectGroups)
    },
    [projectGroups]
  )

  //* 初回ロード
  useEffect(() => {
    if (!projectGroupsLoaded && userLoaded) {
      getProjectGroups()
        .then(() => {
          setProjectGroupsLoaded(true)
        })
        .catch((err) => processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal))
    }
  }, [getProjectGroups, projectGroupsLoaded, showErrorModal, userLoaded])

  return {
    projectGroups,
    invitedProjectGroups,
    total_size,
    projectGroupsLoaded,
    getProjectGroup,
    getProjectGroups,
    appendProjectGroup,
    putProjectGroup,
  }
}
