import axios from 'extends/axios'
import { Matrix3 } from 'three'

import { API_GATEWAY_URL } from 'config/environments'

import {
  AxiosComment,
  Comment,
  CommentReply,
  CommentUploadedImage,
  Cuboid,
  Position,
  RectangleInBlueprint,
} from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`

/**
 * Get all comments
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {Comment[]}
 */
export const getComments = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<Comment[] | null> => {
  const comments = await axios
    .get<{ results: AxiosComment[] }>(`${PROJECTS_API_URL}/${project_id}/threads?compress_size_url=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) =>
      response.data.results.map((comment) => ({
        ...comment,
        blueprint_id: comment.blueprint_id === 'n/a' ? undefined : comment.blueprint_id,
        cartesian_position:
          comment.cartesian_position?.x === null ||
          comment.cartesian_position?.y === null ||
          comment.cartesian_position?.z === null
            ? undefined
            : comment.cartesian_position,
        blueprint_position:
          comment.blueprint_position?.coordinate.x === null ||
          comment.blueprint_position?.coordinate.y === null ||
          comment.blueprint_position?.extent.width === null ||
          comment.blueprint_position?.extent.height === null
            ? undefined
            : comment.blueprint_position,
        cuboid_position:
          !comment.cuboid_position ||
          comment.cuboid_position.center === null ||
          comment.cuboid_position.rotation === null ||
          comment.cuboid_position.extent === null
            ? undefined
            : {
                masking_region_id: '',
                center: comment.cuboid_position.center,
                extent: comment.cuboid_position.extent,
                rotation: new Matrix3().fromArray(comment.cuboid_position.rotation),
              },
      }))
    )
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_COMMENTS, showErrorModal)
      return null
    })

  return comments
}

/**
 * Create a new comment
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {Comment}
 */
export const createComment = async (
  access_token: string,
  project_id: string,
  comment: Comment,
  showErrorModal: (message: string) => void
): Promise<Comment | null> => {
  const imageObject = comment.images?.length
    ? {
        images: comment.images,
      }
    : {}

  const result = await axios
    .post<Comment>(
      `${PROJECTS_API_URL}/${project_id}/threads`,
      {
        author_name: comment.author_name,
        thread_body: comment.thread_body,
        cartesian_position: comment.cartesian_position || {
          x: null,
          y: null,
          z: null,
        },
        cuboid_position: comment.cuboid_position
          ? {
              center: comment.cuboid_position.center,
              rotation: comment.cuboid_position.rotation.toArray(),
              extent: comment.cuboid_position.extent,
            }
          : {
              center: null,
              rotation: null,
              extent: null,
            },
        blueprint_position: comment.blueprint_position || {
          coordinate: {
            x: null,
            y: null,
          },
          extent: {
            width: null,
            height: null,
          },
        },
        blueprint_id: comment.blueprint_id || 'n/a',
        ...imageObject,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_COMMENT, showErrorModal)
      return null
    })

  return result
}

/**
 * Edit a comment
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {string} author_name author name
 * @param {string} thread_body comment thread body
 * @param {Position | undefined} cartesian_position
 * @param {Cuboid | undefined} cuboid_position
 * @param {RectangleInBlueprint | undefined} blueprint_position
 * @param {string} blueprint_id
 * @param {function} showErrorModal show error modal function
 * @returns {Comment}
 */
export const editComment = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  author_name: string,
  thread_body: string,
  cartesian_position: Position | undefined,
  cuboid_position: Omit<Cuboid, 'masking_region_id'> | undefined,
  blueprint_position: RectangleInBlueprint | undefined,
  blueprint_id: string | undefined,
  showErrorModal: (message: string) => void
): Promise<Comment | null> => {
  const result = await axios
    .patch<Comment>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}`,
      {
        author_name,
        thread_body,
        cartesian_position: cartesian_position || {
          x: null,
          y: null,
          z: null,
        },
        cuboid_position: cuboid_position
          ? {
              ...cuboid_position,
              masking_region_id: undefined,
              rotation: cuboid_position.rotation.toArray(),
            }
          : {
              center: null,
              rotation: null,
              extent: null,
            },
        blueprint_position: blueprint_position || {
          coordinate: { x: null, y: null },
          extent: {
            width: null,
            height: null,
          },
        },
        blueprint_id: blueprint_id || 'n/a',
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT, showErrorModal)
      return null
    })

  return result
}

/**
 * Delete a comment
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteComment = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete<Comment>(`${PROJECTS_API_URL}/${project_id}/threads/${thread_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_COMMENT, showErrorModal)
      return false
    })

  return result
}

/**
 * Get all replies
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {function} showErrorModal show error modal function
 * @returns {CommentReply[]}
 */
export const getReplies = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  showErrorModal: (message: string) => void
): Promise<CommentReply[] | null> => {
  const replies = await axios
    .get<{ results: CommentReply[] }>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}/replies?compress_size_url=true`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_COMMENT_REPLIES, showErrorModal)
      return null
    })

  return replies
}

/**
 * Create a new reply
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {function} showErrorModal show error modal function
 * @returns {CommentReply}
 */
export const createReply = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  reply: CommentReply,
  showErrorModal: (message: string) => void
): Promise<CommentReply | null> => {
  const imageObject = reply.images?.length
    ? {
        images: reply.images,
      }
    : {}
  const result = await axios
    .post<CommentReply>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}/replies`,
      {
        author_name: reply.author_name,
        reply_body: reply.reply_body,
        ...imageObject,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_COMMENT_REPLY, showErrorModal)
      return null
    })

  return result
}

/**
 * Edit a reply
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {string} reply_id comment reply id
 * @param {string} author_name author name
 * @param {string} reply_body comment thread body
 * @param {function} showErrorModal show error modal function
 * @returns {CommentReply}
 */
export const editReply = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  reply_id: string,
  author_name: string,
  reply_body: string,
  showErrorModal: (message: string) => void
): Promise<CommentReply | null> => {
  const result = await axios
    .patch<CommentReply>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}/replies/${reply_id}`,
      {
        author_name,
        reply_body,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT_REPLY, showErrorModal)
      return null
    })

  return result
}

/**
 * Delete a reply
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id comment thread id
 * @param {string} reply_id comment reply id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteReply = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  reply_id: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete<Comment>(`${PROJECTS_API_URL}/${project_id}/threads/${thread_id}/replies/${reply_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_COMMENT_REPLY, showErrorModal)
      return false
    })

  return result
}

/**
 * Get signed url for upload image
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} filename filename
 * @param {function} showErrorModal show error modal function
 * @returns {CommentUploadedImage}
 */
export const getSignedUrlForUploadImage = async (
  access_token: string,
  project_id: string,
  filename: string,
  showErrorModal: (message: string) => void
): Promise<CommentUploadedImage | null> => {
  const signedImage = await axios
    .post<CommentUploadedImage>(
      `${PROJECTS_API_URL}/${project_id}/image-files`,
      {
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_COMMENT, showErrorModal)
      return null
    })

  return signedImage
}

/**
 * Get an original image
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id thread id
 * @param {string} reply_id reply id
 * @param {string} image_id image id
 * @param {function} showErrorModal show error modal function
 * @returns {string}
 */
export const getOriginalImageUrl = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  reply_id: string | null,
  image_id: string,
  showErrorModal: (message: string) => void
): Promise<string | null> => {
  const replyUrl = reply_id ? `/replies/${reply_id}` : ''
  const image = await axios
    .get<{ original_size_url: string }>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}${replyUrl}/images/${image_id}?original_size_url=true`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.original_size_url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_COMMENTS, showErrorModal)
      return null
    })

  return image
}

/**
 * Update or insert an image metadata
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id thread id
 * @param {string} reply_id reply id
 * @param {string} image_id image id
 * @param {string} caption caption
 * @param {string} filename filename
 * @param {function} showErrorModal show error modal function
 * @returns {CommentUploadedImage}
 */
export const upsertImageMetaData = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  reply_id: string | null,
  image_id: string,
  caption: string,
  filename: string,
  showErrorModal: (message: string) => void
): Promise<CommentUploadedImage | null> => {
  const replyUrl = reply_id ? `/replies/${reply_id}` : ''
  const image = await axios
    .put<CommentUploadedImage>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}${replyUrl}/images/${image_id}`,
      {
        caption,
        filename,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(
        err,
        reply_id ? ERROR_PROCESS.UPDATE_COMMENT_REPLY : ERROR_PROCESS.UPDATE_COMMENT,
        showErrorModal
      )
      return null
    })

  return image
}

/**
 * Delete an image
 * @param {string} access_token access token
 * @param {string} project_id project id
 * @param {string} thread_id thread id
 * @param {string} reply_id reply id
 * @param {string} image_id image id
 * @param {function} showErrorModal show error modal function
 * @returns {boolean}
 */
export const deleteImage = async (
  access_token: string,
  project_id: string,
  thread_id: string,
  reply_id: string | null,
  image_id: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const replyUrl = reply_id ? `/replies/${reply_id}` : ''
  const result = await axios
    .delete<CommentUploadedImage>(
      `${PROJECTS_API_URL}/${project_id}/threads/${thread_id}${replyUrl}/images/${image_id}`,
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_COMMENT, showErrorModal)
      return false
    })

  return result
}
