import { FC, useContext, useEffect, useState } from 'react'

import { Flex, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { IFCFile } from 'interfaces/interfaces'

import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'

const IFCPanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const {
    IFCFiles,
    project,
    isLayerModifying,
    changeIFCFiles,
    IFCGeometries,
    selectedIFCGeometryIndex,
    changeSelectedIFCGeometryIndex,
  } = useContext(EditorContext)
  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.ifcFiles)
  )

  const [fileCollapses, setFileCollapses] = useState<boolean[]>([])

  useEffect(() => {
    setFileCollapses(new Array(IFCFiles.length).fill(false))
  }, [IFCFiles.length])

  const updateLayerVisibility = (visibility: boolean, ifcFile: IFCFile) => {
    changeIFCFiles(
      IFCFiles.map((file) =>
        file.ifc_file_id === ifcFile.ifc_file_id
          ? {
              ...file,
              invisible: visibility,
            }
          : file
      )
    )

    // track with mixpanel
    mixpanel.track('Change visibility of ifc files', {
      'Inspection area ID': project?.project_id,
      Granuarity: 'individual',
      'Visibility (new)': !visibility,
      'Visibility (old)': visibility,
    })
  }

  if (!IFCFiles?.filter((m) => !m.deleted).length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? 20 : 10}
      data-testid="ifc-files-panel"
    >
      <CollapsePanel
        title={t('main_canvas.panels.ifc.title', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.ifcFiles}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          {IFCFiles.map((file, index) => (
            <>
              <LayerItem
                disabled={isLayerModifying}
                invisible={file.invisible}
                key={`ifc-listitem-${file.ifc_file_id}}`}
                label={file.original_filename.replace('.ifc', '')}
                updateVisibility={(invisible) => updateLayerVisibility(invisible, file)}
                collapsible
                collapsed={fileCollapses[index]}
                updateExpansion={(expanded) =>
                  setFileCollapses(fileCollapses.map((c, i) => (i === index ? expanded : c)))
                }
              />
              {!fileCollapses[index] &&
                !file.invisible &&
                IFCFiles.length === IFCGeometries.length &&
                IFCGeometries[index].map((geo, geoIndex) => {
                  const selected =
                    selectedIFCGeometryIndex?.fileIndex === index && selectedIFCGeometryIndex?.index === geoIndex
                  return (
                    geo.name && (
                      <LayerItem
                        disabled={isLayerModifying}
                        selected={selected}
                        key={`ifc-listitem-${file.ifc_file_id}}-${geo.id}`}
                        label={geo.name}
                        childLevel={1}
                        onMouseOver={() => changeSelectedIFCGeometryIndex(index, geoIndex)}
                        onMouseOut={() => changeSelectedIFCGeometryIndex()}
                      />
                    )
                  )
                })}
            </>
          ))}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default IFCPanel
