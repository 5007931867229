import { FC, useContext, useRef, useState } from 'react'

import {
  Button,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Square,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddIcon, ChevronDownIcon, DeleteIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { Blueprint } from 'interfaces/interfaces'

import { uploadFile } from 'services/AWS'
import { deleteBlueprint, getSignedUrlForUploadBlueprint, upsertBlueprintMetaData } from 'services/Blueprint'

const DocListButton: FC<{
  blueprints: Blueprint[]
  selectedBlueprint: Blueprint | null
  setSelectedBlueprint: (blueprint: Blueprint | null) => void
  onFileChanged: () => void
  isAllowedModify: boolean
}> = ({ blueprints, selectedBlueprint, setSelectedBlueprint, onFileChanged, isAllowedModify }) => {
  const { t } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  const uploadFileRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(false)

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const file: File = e.target.files[0]

    await onBlueprintAdd(file)

    // clear the files list
    e.target.value = ''
  }

  const onBlueprintAdd = async (file: File) => {
    if (!project_id || !isAllowedModify) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      return false
    }
    const signedFile = await getSignedUrlForUploadBlueprint(token, project_id, file.name, showErrorModal)

    if (signedFile?.url) {
      const uploadResult = await uploadFile(
        signedFile.url,
        signedFile.content_type || file.type,
        file,
        null,
        showErrorModal
      )

      if (uploadResult) {
        if (await upsertBlueprintMetaData(token, project_id, signedFile, showErrorModal)) {
          onFileChanged()
        }
      }
    }

    setIsLoading(false)

    // track event to mixpanel
    mixpanel.track('Import blueprint', {
      'Inspection area ID': project_id,
      'Number of blueprints (after importing a new blueprint)': blueprints.length + 1,
    })

    return true
  }

  const onBlueprintDelete = async (blueprintId: string) => {
    if (!project_id || !isAllowedModify) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    if (await deleteBlueprint(token, project_id, blueprintId, showErrorModal)) {
      onFileChanged()
    }

    setIsLoading(false)

    // track event to mixpanel
    mixpanel.track('Delete blueprint', {
      'Inspection area ID': project_id,
      'Number of blueprints (after deleting one)': blueprints.length - 1,
    })

    return true
  }

  return (
    <HStack
      left={isTablet ? 'unset' : '50%'}
      right={isTablet ? 1 : 'unset'}
      transform={isTablet ? 'none' : 'translateX(-50%)'}
      position="absolute"
      spacing={1}
      top={1}
      h={8}
    >
      <Menu variant="toolbar">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          backgroundColor="gray.800"
          fontWeight="normal"
          color="white"
          size="sm"
          _hover={{ bg: 'gray.600' }}
          _expanded={{ bg: 'gray.600' }}
          _focus={{ bg: 'gray.600', boxShadow: 'outline' }}
          _active={{ bg: 'gray.600' }}
          h="100%"
          disabled={isLoading}
        >
          <Text isTruncated>{selectedBlueprint?.original_filename}</Text>
        </MenuButton>
        <MenuList minW={0}>
          {blueprints.map((blueprint) => (
            <MenuItem
              disabled={isLoading}
              key={blueprint.blueprint_id}
              backgroundColor={
                blueprint.blueprint_id === selectedBlueprint?.blueprint_id ? 'whiteAlpha.100' : 'gray.700'
              }
              onClick={() => {
                setSelectedBlueprint(blueprint)
                // track event to mixpanel
                mixpanel.track('Switch blueprint', {
                  'Inspection area ID': project_id,
                })
              }}
            >
              {blueprint.original_filename}
              {isAllowedModify && (
                <>
                  <Spacer />
                  <Square
                    position="relative"
                    color="white"
                    fontSize="md"
                    size={8}
                    ml={4}
                    backgroundColor="whiteAlpha.100"
                    _hover={{ bg: 'whiteAlpha.400' }}
                    borderRadius="md"
                    overflow="hidden"
                    cursor="pointer"
                    onClick={() => {
                      if (!isLoading) {
                        void onBlueprintDelete(blueprint.blueprint_id)
                      }
                    }}
                    aria-label="delete-blueprint"
                  >
                    <DeleteIcon />
                  </Square>
                </>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {isAllowedModify && (
        <Tooltip
          hasArrow
          placement="bottom"
          label={t('blueprint.doc_list_button.add_blueprint', { ns: 'projects' })}
          p={2}
          fontSize="xs"
          fontWeight="normal"
          isDisabled={isTablet}
        >
          <Flex>
            <Square
              position="relative"
              color="white"
              fontSize="md"
              size={8}
              backgroundColor="gray.800"
              _hover={{ bg: 'gray.600' }}
              borderRadius="md"
              overflow="hidden"
              cursor="pointer"
              onClick={() => {
                if (!isLoading) {
                  uploadFileRef.current?.click()
                }
              }}
              data-testid="add-blueprint"
            >
              <AddIcon />
            </Square>
          </Flex>
        </Tooltip>
      )}
      <Input
        data-testid="file-upload"
        hidden
        type="file"
        accept={isMobile ? '' : '.pdf'}
        onChange={onFileChange}
        ref={uploadFileRef}
      />
    </HStack>
  )
}

export default DocListButton
