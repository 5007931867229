import { FC } from 'react'

import { Box, Flex, Heading, Link, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

export const NotFoundPage: FC = () => {
  const { t } = useTranslation(['not_found'])

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <Navbar />
      <Flex w="100%" flex={1} justify="center" align="center">
        <Box maxW={840}>
          <Box textAlign="center">
            <Heading fontSize="lg">{t('page_not_found', { ns: 'not_found' })}</Heading>
          </Box>
          <Box textAlign="center" mt={8}>
            <Link as={RouterLink} to="/" fontSize="sm" variant="underline">
              {t('go_back_homepage', { ns: 'not_found' })}
            </Link>
          </Box>
        </Box>
      </Flex>
    </VStack>
  )
}
