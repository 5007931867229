import { createContext, useState } from 'react'

import { ServiceStatus, ServiceStatusContextProps } from 'interfaces/interfaces'

export const ServiceStatusContext = createContext<ServiceStatusContextProps>({
  serviceStatus: undefined,
  setServiceStatus: () => null,
})

export function useServiceStatusContext(): ServiceStatusContextProps {
  const [serviceStatus, setServiceStatus] = useState<ServiceStatus>()

  return {
    serviceStatus,
    setServiceStatus,
  }
}
