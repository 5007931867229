import { FC, RefObject, useContext, useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { BackSide, Camera, Matrix4, Mesh, MeshBasicMaterial, TorusGeometry } from 'three'
import { ArcballControls } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import useMeshOutline from 'hooks/MeshOutline'
import useShape from 'hooks/Shape'

import { EDITOR_LABEL_BACKGROUND } from 'config/styles'

import { Torus } from 'interfaces/interfaces'

import { findDiameterKeyByValue, meterToMillimeter } from 'services/Util'

//* 鉄筋マテリアル
const defaultMaterial = new MeshBasicMaterial({ color: '#00cc00' })
const hoveredMaterial = new MeshBasicMaterial({ color: 'yellow' })
const selectedMaterial = new MeshBasicMaterial({ color: 'white', side: BackSide })

export const TorusMesh: FC<{
  cameraRef: RefObject<Camera> | null
  torus: Torus
  invisible?: boolean
  arcballControls: ArcballControls | null
}> = ({ cameraRef, torus, invisible, arcballControls }) => {
  const { selectedShapeIds, shapeGroups } = useContext(EditorContext)
  const diameterLabelVisible = useSelector((state: RootState) => state.editor.diameterLabelVisible)
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut, onPointerMove, isFocus } = useShape(torus, cameraRef)
  const { outlineThickness } = useMeshOutline(arcballControls)
  const [mesh, setMesh] = useState<Mesh>(new Mesh())
  const [outlineMesh, setOutlineMesh] = useState<Mesh>(new Mesh())

  const shapeGroup = shapeGroups.find((group) => group.torus_ids?.includes(torus.shape_id))
  const standardMaterial = shapeGroup
    ? new MeshBasicMaterial({ color: shapeGroup.color_palette_code })
    : defaultMaterial

  useEffect(() => {
    const { minor_diameter, major_diameter, transformation } = torus
    if (transformation.length !== 16) {
      return
    }
    const matrix = new Matrix4().set(
      transformation[0],
      transformation[1],
      transformation[2],
      transformation[3],
      transformation[4],
      transformation[5],
      transformation[6],
      transformation[7],
      transformation[8],
      transformation[9],
      transformation[10],
      transformation[11],
      transformation[12],
      transformation[13],
      transformation[14],
      transformation[15]
    )

    const torusMesh = new Mesh()
    torusMesh.applyMatrix4(matrix)

    //* 形状設定
    //* divide by factor 2 as TorusGeometry takes radius as argument
    //* https://threejs.org/docs/#api/en/geometries/TorusGeometry
    const geo = new TorusGeometry(major_diameter / 2, minor_diameter / 2, 10, 60)
    torusMesh.geometry = geo

    //* state保存
    setMesh(torusMesh)

    // used for outline effect
    const outlineTorusMesh = torusMesh.clone()
    setOutlineMesh(outlineTorusMesh)
  }, [torus])

  useEffect(() => {
    if (!outlineMesh || !selectedShapeIds.includes(torus.shape_id)) {
      return
    }
    // used for outline effect
    const { minor_diameter, major_diameter } = torus
    const outlineGeo = new TorusGeometry(
      (major_diameter + outlineThickness) / 2,
      (minor_diameter + outlineThickness) / 2,
      10,
      60
    )
    outlineMesh.geometry = outlineGeo
  }, [torus, outlineMesh, outlineThickness, selectedShapeIds])

  const distanceLabel = () => {
    if (!diameterLabelVisible) {
      return null
    }
    return (
      <Html position={0} style={{ transform: 'translateX(-100%) translateY(-50%)' }} zIndexRange={[1, 9]}>
        <Box backgroundColor={EDITOR_LABEL_BACKGROUND.tori} px={2} fontSize="80%" fontWeight="bold" color="black">
          {findDiameterKeyByValue(meterToMillimeter(torus.minor_diameter))}
        </Box>
      </Html>
    )
  }

  if (!torus || invisible) return null

  return (
    <>
      <mesh
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerMove={onPointerMove}
        quaternion={mesh.quaternion}
        position={mesh.position}
        geometry={mesh.geometry}
        material={isFocus ? hoveredMaterial : standardMaterial}
      >
        <mesh position={[torus.major_diameter / 2, 0, 0]} quaternion={mesh.quaternion}>
          {distanceLabel()}
        </mesh>
      </mesh>
      {selectedShapeIds.includes(torus.shape_id) && (
        <mesh
          quaternion={outlineMesh.quaternion}
          position={outlineMesh.position}
          geometry={outlineMesh.geometry}
          material={selectedMaterial}
        />
      )}
    </>
  )
}

// Handle typing for props that is not required
TorusMesh.defaultProps = {
  invisible: undefined,
}
