import { FC } from 'react'

import { Document, Font, Page, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import NotoSansBold from 'assets/fonts/NotoSansJP-Bold.otf'
import NotoSansRegular from 'assets/fonts/NotoSansJP-Regular.otf'

import { INSPECTION_CONTRACTEE_ERROR_COLOR, INSPECTION_NORMAL_COLOR } from 'config/styles'

import { InspectionSheet, Project, SpacerInspectionItem } from 'interfaces/interfaces'

import { formatInspectionDifferentValue } from 'services/InspectionSheet'
import { evaluateInspectionItemValue } from 'services/SpacerInspectionSheet'

import { BORDER_COLOR, LABEL_COLOR, NORMAL_COLOR, styles } from './MainSheetPDF'

Font.register({
  family: 'NotoSansRegular',
  fonts: [
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src: NotoSansRegular,
      fontWeight: 'normal',
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src: NotoSansBold,
      fontWeight: 'bold',
    },
  ],
})

const NAME_COLUMN_WIDTH = 155
const VALUE_COLUMN_WIDTH = 218
const AREA_VALUE_COLUMN_WIDTH = 100
const RESULT_COLUMN_WIDTH = 110

const SpacerSheetPDF: FC<{
  project?: Project
  inspectionSheet: InspectionSheet
  inspectionItems: SpacerInspectionItem[]
  itemResults: boolean[]
}> = ({ project, inspectionSheet, inspectionItems, itemResults }) => {
  const { t } = useTranslation(['projects'])

  const renderProfileGroup = (label: string, value?: string) => (
    <View style={styles.profileGroup}>
      <Text style={styles.profileGroupLabel}>{label}</Text>
      <Text>{value}</Text>
    </View>
  )
  const renderGroupHeadersColumn = (header: string, COLUMN_WIDTH: number) => (
    <View style={{ ...styles.criterionColumn, minWidth: COLUMN_WIDTH }}>
      <View style={styles.criterionHeader}>
        <Text>{header}</Text>
      </View>
    </View>
  )

  const renderValueHeadersColumn = (headers: (string[] | undefined)[], COLUMN_WIDTH: number, unit: string) => (
    <View
      style={{
        ...styles.valueHeaderColumn,
        minWidth: COLUMN_WIDTH,
        borderRightWidth: 0,
      }}
    >
      <View style={styles.valueHeaderColumnContentWrapper}>
        <>
          {!!headers[0] && (
            <View style={styles.valueHeaderColumnContentLabel}>
              <Text>{headers[0]?.[0]}</Text>
              <Text>{headers[0]?.[1]}</Text>
              <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
            </View>
          )}
          {!!headers[1] && (
            <View style={styles.valueHeaderColumnContentLabel}>
              <Text>{headers[1]?.[0]}</Text>
              <Text>{headers[1]?.[1]}</Text>
              <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
            </View>
          )}
          {!!headers[2] && (
            <View style={styles.valueHeaderColumnContentLabel}>
              <Text>{headers[2]?.[0]}</Text>
              <Text>{headers[2]?.[1]}</Text>
              <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
            </View>
          )}
        </>
      </View>
    </View>
  )

  const renderResultHeadersColumn = (COLUMN_WIDTH: number) => (
    <View
      style={{
        ...styles.valueHeaderColumn,
        minWidth: COLUMN_WIDTH,
        borderRightWidth: 1,
      }}
    >
      <View style={styles.valueHeaderColumnContentWrapper}>
        <View style={styles.valueHeaderColumnContentLabelForResult}>
          <Text>{t('main_canvas.inspection_sheet.result.result', { ns: 'projects' })}</Text>
        </View>
      </View>
    </View>
  )

  const renderNameColumn = (name: string, isFirstRow?: boolean) => (
    <View
      style={{
        ...styles.nameColumn,
        borderTopWidth: isFirstRow ? 0 : 1,
        marginTop: isFirstRow ? 0 : -1,
        maxWidth: NAME_COLUMN_WIDTH,
        minWidth: NAME_COLUMN_WIDTH,
      }}
      wrap={false}
    >
      <Text style={{ padding: 4, textAlign: 'right' }}>{name}</Text>
    </View>
  )

  const renderValuesColumn = (
    COLUMN_WIDTH: number,
    values: [string, string, string],
    result?: {
      color: string
      description: string
    },
    isFirstRow?: boolean
  ) => (
    <View
      style={{
        ...styles.valuesColumn,
        borderTopWidth: isFirstRow ? 0 : 1,
        marginTop: isFirstRow ? 0 : -1,
        minWidth: COLUMN_WIDTH,
      }}
      wrap={false}
    >
      <Text style={styles.valuesColumnContent}>{values[0]}</Text>
      <Text style={styles.valuesColumnContent}>{values[1]}</Text>
      <Text
        style={{
          ...styles.valuesColumnContent,
          color: result?.color === INSPECTION_NORMAL_COLOR ? NORMAL_COLOR : result?.color || NORMAL_COLOR,
        }}
      >
        {values[2]}
      </Text>
    </View>
  )

  const renderResultsColumn = (result: boolean, isFirstRow?: boolean) => (
    <View
      style={{
        ...styles.resultColumn,
        borderTopWidth: isFirstRow ? 0 : 1,
        marginTop: isFirstRow ? 0 : -1,
        maxWidth: RESULT_COLUMN_WIDTH,
        minWidth: RESULT_COLUMN_WIDTH,
      }}
      wrap={false}
    >
      <Text
        style={{
          ...styles.resultColumnContent,
          color: result ? NORMAL_COLOR : INSPECTION_CONTRACTEE_ERROR_COLOR,
        }}
      >
        {result
          ? t('main_canvas.inspection_sheet.result.ok', { ns: 'projects' })
          : t('main_canvas.inspection_sheet.result.ng', { ns: 'projects' })}
      </Text>
    </View>
  )

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={{ ...styles.page, paddingHorizontal: 20 }}>
        <View style={{ paddingTop: 16, paddingBottom: 10 }}>
          <Text style={styles.heading}>
            {t('main_canvas.inspection_sheet.spacer_inspection_sheet', { ns: 'projects' })}
          </Text>
        </View>
        <View style={styles.profileGroupRow}>
          <View style={styles.profileGroupColumn}>
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.construction_project_name', { ns: 'projects' }),
              inspectionSheet.construction_properties.construction_project_name
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.construction_type_name', { ns: 'projects' }),
              inspectionSheet.construction_properties.construction_type
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.construction_detail', { ns: 'projects' }),
              inspectionSheet.construction_properties.construction_type_detailed
            )}
          </View>
          <View style={styles.profileGroupColumn}>
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.inspection_area_name', { ns: 'projects' }),
              project?.project_name
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.creator', { ns: 'projects' }),
              inspectionSheet.creator_name
            )}
            {renderProfileGroup(
              t('main_canvas.inspection_sheet.sheet_property.creation_day', { ns: 'projects' }),
              inspectionSheet.create_time_user_specified
                ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
                : ''
            )}
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'stretch' }}>
          <View style={{ minWidth: NAME_COLUMN_WIDTH }} />
          {renderGroupHeadersColumn(
            t('main_canvas.inspection_sheet.spacer_sheet_property.surface_density', { ns: 'projects' }),
            VALUE_COLUMN_WIDTH
          )}
          {renderGroupHeadersColumn(
            t('main_canvas.inspection_sheet.spacer_sheet_property.target_area', { ns: 'projects' }),
            AREA_VALUE_COLUMN_WIDTH
          )}
          {renderGroupHeadersColumn(
            t('main_canvas.inspection_sheet.spacer_sheet_property.spacer_number', { ns: 'projects' }),
            VALUE_COLUMN_WIDTH
          )}
          <View style={{ minWidth: RESULT_COLUMN_WIDTH }} />
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ minWidth: NAME_COLUMN_WIDTH, maxWidth: NAME_COLUMN_WIDTH }} />
          {renderValueHeadersColumn(
            [
              ['', t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' })],
              ['', t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' })],
              ['', t('main_canvas.inspection_sheet.values.difference', { ns: 'projects' })],
            ],
            VALUE_COLUMN_WIDTH,
            t('main_canvas.inspection_sheet.spacer_sheet_property.unit_surface_density', { ns: 'projects' })
          )}
          {renderValueHeadersColumn([undefined, ['', ''], undefined], AREA_VALUE_COLUMN_WIDTH, 'm^2')}
          {renderValueHeadersColumn(
            [
              ['', t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' })],
              [
                t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
                `(${t('main_canvas.inspection_sheet.spacer_sheet_property.estimate', { ns: 'projects' })})`,
              ],
              [
                t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
                `(${t('main_canvas.inspection_sheet.spacer_sheet_property.user_input', { ns: 'projects' })})`,
              ],
            ],
            VALUE_COLUMN_WIDTH,
            t('main_canvas.inspection_sheet.spacer_sheet_property.unit_number', { ns: 'projects' })
          )}
          {renderResultHeadersColumn(RESULT_COLUMN_WIDTH)}
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              minWidth: NAME_COLUMN_WIDTH,
              maxWidth: NAME_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          >
            <Text style={{ paddingHorizontal: 4, paddingTop: 4, color: LABEL_COLOR, textAlign: 'right' }}>
              {t('main_canvas.inspection_sheet.spacer_sheet_property.item', { ns: 'projects' })}
            </Text>
          </View>
          <View
            style={{
              minWidth: VALUE_COLUMN_WIDTH,
              maxWidth: VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: AREA_VALUE_COLUMN_WIDTH,
              maxWidth: AREA_VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: VALUE_COLUMN_WIDTH,
              maxWidth: VALUE_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
          <View
            style={{
              minWidth: RESULT_COLUMN_WIDTH,
              maxWidth: RESULT_COLUMN_WIDTH,
              borderLeftWidth: 1,
              borderTopWidth: 1,
              borderRightWidth: 1,
              borderColor: BORDER_COLOR,
            }}
          />
        </View>
        {inspectionItems.map((item, index) => (
          <View
            key={item.inspection_item_id}
            style={{
              flexDirection: 'row',
              width: '100%',
              alignItems: 'stretch',
            }}
          >
            {renderNameColumn(item.part_name, index === 0)}
            {renderValuesColumn(
              VALUE_COLUMN_WIDTH,
              [
                item.number_of_spacers_per_meter_square.specified_value?.toString() || '-',
                item.number_of_spacers_per_meter_square.estimated_value?.toString() || '-',
                formatInspectionDifferentValue(item.number_of_spacers_per_meter_square),
              ],
              evaluateInspectionItemValue(
                item.number_of_spacers_per_meter_square,
                t('main_canvas.inspection_sheet.values', { ns: 'projects', returnObjects: true })
              ),
              index === 0
            )}
            <View
              style={{
                ...styles.valuesColumn,
                borderTopWidth: index === 0 ? 0 : 1,
                marginTop: index === 0 ? 0 : -1,
                minWidth: AREA_VALUE_COLUMN_WIDTH,
              }}
              wrap={false}
            >
              <Text style={styles.valuesColumnContent}>{item.target_area ?? '-'}</Text>
            </View>
            {renderValuesColumn(
              VALUE_COLUMN_WIDTH,
              [
                item.number_of_deployed_spacers.specified_value?.toString() || '-',
                item.number_of_deployed_spacers.estimated_value_by_surface_density?.toString() || '-',
                item.number_of_deployed_spacers.estimated_value_by_user_input?.toString() || '-',
              ],
              undefined,
              index === 0
            )}
            {renderResultsColumn(itemResults[index], index === 0)}
          </View>
        ))}
      </Page>
    </Document>
  )
}

export default SpacerSheetPDF
