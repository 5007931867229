import React, { useContext, useEffect, useRef, useState } from 'react'

import { User } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Spinner,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import mixpanel from 'mixpanel-browser'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import { Vector3 } from 'three'

import {
  AddImageIcon,
  CheckCircleIcon,
  DeleteIcon,
  ErrorIcon,
  HDotsIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
  ResetIcon,
} from 'assets/icons'

import { UserContext } from 'contexts/Users'

import {
  Comment,
  CommentImage,
  CommentReply,
  CommentUploadedImage,
  Cuboid,
  Position,
  RectangleInBlueprint,
} from 'interfaces/interfaces'

import { uploadFile } from 'services/AWS'
import {
  createComment,
  createReply,
  deleteImage,
  deleteReply,
  editComment,
  editReply,
  getOriginalImageUrl,
  getReplies,
  getSignedUrlForUploadImage,
  upsertImageMetaData,
} from 'services/Comments'
import { scaleBlueprintRectangle } from 'services/Util'

// drei cannot load styles of chakra-ui
const BUTTON_STYLE = {
  width: 'auto',
  lineHeight: 1.2,
  height: '32px',
  fontWeight: 'bold',
  borderRadius: 'var(--chakra-radii-md)',
  paddingX: '12px',
}
const PRIMARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  backgroundColor: 'var(--chakra-colors-primary-500)',
  color: 'white',
  _hover: { backgroundColor: 'var(--chakra-colors-primary-600)' },
  _disabled: { backgroundColor: 'var(--chakra-colors-primary-300)' },
}
const SECONDARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  borderColor: 'var(--chakra-colors-gray-200)',
  borderWidth: 1,
  _hover: { backgroundColor: 'var(--chakra-colors-gray-100)' },
  _disabled: { backgroundColor: 'white', color: 'var(--chakra-colors-gray-400)' },
}
const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '6px',
  padding: '4px',
}

const CommentPopup: React.FC<{
  user?: User
  project_id?: string
  comment?: Comment
  cartesianPosition?: Position
  blueprintPosition?: RectangleInBlueprint
  cuboidPosition?: Cuboid
  blueprintId?: string
  blueprintScale?: number
  disabled: boolean
  commentBody: string
  commentImages: CommentImage[]
  commentName: string
  deletingImageIndexes: number[]
  editingCaptions: string[]
  openingImageIds: string[]
  handlingImageIndex: number
  uploadedImages: CommentUploadedImage[]
  forEditor: boolean
  updateCommentBody: (body: string) => void
  updateCommentImages: (images: CommentImage[]) => void
  updateCommentName: (name: string) => void
  toggleOpeningImageId: (id: string) => void
  updateDeletingImageIndexes: (indexes: number[]) => void
  updateEditingCaptions: (captions: string[]) => void
  updateUploadedImages: (images: CommentUploadedImage[]) => void
  updateHandlingImageIndex: (index: number) => void
  closePopup: () => void
  onCommentModified: () => void
  onMoveComment: () => void
  deleteComment: () => void
  showErrorModal: (message: string) => void
}> = ({
  user,
  project_id,
  comment,
  cartesianPosition,
  blueprintPosition,
  cuboidPosition,
  blueprintId,
  blueprintScale,
  disabled,
  commentBody,
  commentImages,
  commentName,
  deletingImageIndexes,
  editingCaptions,
  openingImageIds,
  handlingImageIndex,
  uploadedImages,
  forEditor,
  closePopup,
  onCommentModified,
  deleteComment,
  updateCommentBody,
  updateCommentImages,
  updateCommentName,
  toggleOpeningImageId,
  updateDeletingImageIndexes,
  updateEditingCaptions,
  updateUploadedImages,
  updateHandlingImageIndex,
  showErrorModal,
  onMoveComment,
}) => {
  const { t } = useTranslation(['projects'])
  const { getAccessToken } = useContext(UserContext)

  const [replies, setReplies] = useState<CommentReply[]>([])
  const uploadMultipleFilesRef = useRef<HTMLInputElement>(null)
  const uploadFileRef = useRef<HTMLInputElement>(null)

  const [showCommentEditInputs, setShowCommentEditInputs] = useState(false)
  const [showInputs, setShowInputs] = useState(false)
  const [editingReplyIndex, setEditingReplyIndex] = useState(-1)
  const [loading, setLoading] = useState(false)

  const fetchReplies = async () => {
    if (!project_id || !comment?.thread_id) {
      return false
    }

    setLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setLoading(false)
      return false
    }

    const retrievedReplies = await getReplies(token, project_id, comment.thread_id, showErrorModal)

    if (retrievedReplies) {
      setReplies(retrievedReplies)
    } else {
      setReplies([])
    }

    setLoading(false)
    return true
  }

  const openOriginalImage = async (thread_id?: string, reply_id?: string, image_id?: string) => {
    if (!openingImageIds || !toggleOpeningImageId) {
      return false
    }

    if (!project_id || !thread_id || !image_id || openingImageIds.includes(image_id)) {
      return false
    }

    // Show loading spinner
    toggleOpeningImageId(image_id)

    const token = await getAccessToken()
    if (!token) {
      setLoading(false)
      return false
    }

    const url = await getOriginalImageUrl(token, project_id, thread_id, reply_id || null, image_id, showErrorModal)

    // Hide loading spinner
    toggleOpeningImageId(image_id)

    if (url) {
      window.open(url, '_blank', 'noreferrer')
    }
    return true
  }

  const onMultipleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!commentImages || !updateCommentImages) {
      return
    }

    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const files: File[] = Array.from(e.target.files)
    const addingFiles = files.filter((file) => !commentImages.some((image) => image.file.name === file.name)) || []
    const addingImages = addingFiles.map((file) => ({ file, caption: '' }))
    updateCommentImages([...commentImages, ...addingImages])

    // clear the files list
    e.target.value = ''
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!handlingImageIndex || !commentImages || !updateCommentImages) {
      return
    }

    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const file: File = e.target.files[0]
    const newImages = [...commentImages]

    if (handlingImageIndex < newImages.length) {
      newImages[handlingImageIndex].file = file
      updateCommentImages(newImages)
    }

    // clear the files list
    e.target.value = ''
  }

  const handleAddingComment = async (statuses: CommentUploadedImage[]) => {
    if (!project_id || (!cartesianPosition && !blueprintPosition && !cuboidPosition) || disabled) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }
    setLoading(true)
    const newComment = {
      thread_body: commentBody.trim(),
      author_name: commentName.trim(),
      cartesian_position: cartesianPosition,
      blueprint_position: blueprintPosition,
      cuboid_position: cuboidPosition,
      blueprint_id: blueprintId,
      images: statuses.map((image) => ({
        image_id: image.image_id,
        filename: image.filename,
        caption: image.caption,
      })),
    }

    const commentCreated = await createComment(token, project_id, newComment, showErrorModal)
    if (commentCreated) {
      onCommentModified()
      closePopup()

      // track event to mixpanel
      let trackSituation = ''
      if (cartesianPosition) {
        trackSituation = '3D GUI (point)'
      } else if (cuboidPosition) {
        trackSituation = '3D GUI (cuboid)'
      } else if (blueprintPosition) {
        trackSituation = 'blueprint viewer'
      }

      mixpanel.track('Create comment', {
        'Inspection area ID': project_id,
        'Thread ID': commentCreated.thread_id,
        Situation: trackSituation,
        'Number of images': newComment.images.length,
        'Length of text': newComment.thread_body.length,
      })
    }

    setLoading(false)
    return true
  }

  const handleAddingCommentReply = async (statuses: CommentUploadedImage[]) => {
    if (!project_id || !comment?.thread_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const reply: CommentReply = {
      reply_body: commentBody.trim(),
      author_name: commentName.trim(),
      images: statuses.map((image) => ({
        image_id: image.image_id,
        filename: image.filename,
        caption: image.caption,
      })),
    }

    const replyCreated = await createReply(token, project_id, comment.thread_id, reply, showErrorModal)
    if (replyCreated) {
      setShowInputs(false)
      onCommentModified()
      await fetchReplies()

      // track event to mixpanel
      mixpanel.track('Create Reply', {
        'Inspection area ID': project_id,
        'Thread ID': comment.thread_id,
        'Reply ID': replyCreated.reply_id,
        'Number of images': reply.images?.length,
        'Length of text': reply.reply_body.length,
      })
    }

    setLoading(false)
    return true
  }

  const handleUploadImage = async (image: CommentImage, status: CommentUploadedImage) => {
    if (!project_id || disabled) {
      return status
    }

    const token = await getAccessToken()
    if (!token) {
      return status
    }
    const signedImage = await getSignedUrlForUploadImage(token, project_id, image.file.name, showErrorModal)

    if (signedImage?.url) {
      const uploadResult = await uploadFile(
        signedImage.url,
        signedImage.content_type || image.file.type,
        image.file,
        null,
        showErrorModal
      )

      if (uploadResult) {
        return {
          ...signedImage,
          caption: image.caption.trim(),
          status: 'done',
        }
      }
    }
    return status
  }

  const handleAddingImages = async () => {
    if (!uploadedImages || !commentImages || !updateUploadedImages) {
      return []
    }

    if (commentImages.length) {
      const statuses = uploadedImages.length
        ? [...uploadedImages]
        : commentImages.map(() => ({
            image_id: '',
            filename: '',
            status: 'loading',
            caption: '',
          }))
      updateUploadedImages(statuses)

      const newStatuses = await Promise.all(
        commentImages.map(async (image, index) => {
          // happens when reuploading an image, no need to reupload other done images
          if (statuses[index].status !== 'done') {
            return handleUploadImage(image, statuses[index])
          }
          return statuses[index]
        })
      )
      updateUploadedImages(newStatuses)
      return newStatuses
    }
    updateUploadedImages([])
    return []
  }

  const addComment = async () => {
    if (!project_id || (!cartesianPosition && !blueprintPosition && !cuboidPosition) || disabled) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    setLoading(true)

    // Upload images (if any) first
    const statuses = await handleAddingImages()

    // Pause if there is any image upload error
    if (statuses.some((image) => image.status !== 'done')) {
      setLoading(false)
      return false
    }

    // Then create a new comment
    await handleAddingComment(statuses)
    return true
  }

  const reuploadCommentImage = async (index: number) => {
    if (!uploadedImages || !commentImages || !updateUploadedImages) {
      return false
    }

    if (commentImages.length <= index) {
      return false
    }
    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const newStatus = await handleUploadImage(commentImages[index], uploadedImages[index])
    const statuses = [...uploadedImages]
    statuses[index] = newStatus
    updateUploadedImages(statuses)
    return true
  }

  const addCommentReply = async () => {
    if (!project_id || !comment?.thread_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    setLoading(true)

    // Upload images (if any) first
    const statuses = await handleAddingImages()

    // Pause if there is any image upload error
    if (statuses.some((image) => image.status !== 'done')) {
      setLoading(false)
      return false
    }

    // Then create a new comment reply
    await handleAddingCommentReply(statuses)
    return true
  }

  const deleteCommentReply = async (index: number) => {
    if (!project_id || !comment?.thread_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    setLoading(true)

    const reply_id = replies[index].reply_id || ''
    if (reply_id && (await deleteReply(token, project_id, comment.thread_id, reply_id, showErrorModal))) {
      setShowInputs(false)
      onCommentModified()
      await fetchReplies()

      // track with mixpanel
      mixpanel.track('Delete reply', {
        'Inspection area ID': project_id,
        'Thread ID': comment.thread_id,
        'Reply ID': reply_id,
      })
    }

    setLoading(false)
    return true
  }

  const editBody = async () => {
    if (!project_id || !comment?.thread_id) {
      return false
    }

    setLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setLoading(false)
      closeEditBody()
      return false
    }

    let needRefetchThread = false
    let needRefetchReply = false

    if (!!editingCaptions && !!deletingImageIndexes) {
      // Upload images (if any) first
      const statuses = await handleAddingImages()

      // Pause if there is any image upload error
      if (statuses.some((image) => image.status !== 'done')) {
        setLoading(false)
        return false
      }

      // Add new images to current thread / reply
      const addResults = await Promise.all(
        statuses.map(async (status) => {
          const reply =
            editingReplyIndex >= 0 && editingReplyIndex < replies.length ? replies[editingReplyIndex] : undefined

          if (showCommentEditInputs && comment.thread_id) {
            return upsertImageMetaData(
              token,
              project_id,
              comment.thread_id,
              null,
              status.image_id,
              status.caption || '',
              status.filename,
              showErrorModal
            )
          }
          if (comment.thread_id && reply?.reply_id) {
            return upsertImageMetaData(
              token,
              project_id,
              comment.thread_id,
              reply.reply_id,
              status.image_id,
              status.caption || '',
              status.filename,
              showErrorModal
            )
          }
          return false
        })
      )

      needRefetchThread = needRefetchThread || (showCommentEditInputs && addResults.some((result) => result))
      needRefetchReply = needRefetchReply || (editingReplyIndex >= 0 && addResults.some((result) => result))

      // Edit images
      const updateResults = await Promise.all(
        editingCaptions.map(async (caption, index) => {
          const reply =
            editingReplyIndex >= 0 && editingReplyIndex < replies.length ? replies[editingReplyIndex] : undefined

          if (
            showCommentEditInputs &&
            comment.thread_id &&
            comment.images?.length &&
            index < comment.images.length &&
            // Only update if there is a change
            comment.images[index].caption !== caption.trim()
          ) {
            return upsertImageMetaData(
              token,
              project_id,
              comment.thread_id,
              null,
              comment.images[index].image_id,
              caption.trim(),
              comment.images[index].filename,
              showErrorModal
            )
          }
          if (
            comment.thread_id &&
            reply?.reply_id &&
            reply.images?.length &&
            index < reply.images.length &&
            // Only update if there is a change
            reply.images[index].caption !== caption.trim()
          ) {
            return upsertImageMetaData(
              token,
              project_id,
              comment.thread_id,
              reply.reply_id,
              reply.images[index].image_id,
              caption.trim(),
              reply.images[index].filename,
              showErrorModal
            )
          }
          return false
        })
      )

      needRefetchThread = needRefetchThread || (showCommentEditInputs && updateResults.some((result) => result))
      needRefetchReply = needRefetchReply || (editingReplyIndex >= 0 && updateResults.some((result) => result))

      // Delete images
      const deleteResults = await Promise.all(
        deletingImageIndexes.map(async (index) => {
          const reply =
            editingReplyIndex >= 0 && editingReplyIndex < replies.length ? replies[editingReplyIndex] : undefined

          if (showCommentEditInputs && comment.thread_id && comment.images?.length && index < comment.images.length) {
            return deleteImage(
              token,
              project_id,
              comment.thread_id,
              null,
              comment.images[index].image_id,
              showErrorModal
            )
          }
          if (comment.thread_id && reply?.reply_id && reply.images?.length && index < reply.images.length) {
            return deleteImage(
              token,
              project_id,
              comment.thread_id,
              reply.reply_id,
              reply.images[index].image_id,
              showErrorModal
            )
          }
          return false
        })
      )

      needRefetchThread = needRefetchThread || (showCommentEditInputs && deleteResults.some((result) => result))
      needRefetchReply = needRefetchReply || (editingReplyIndex >= 0 && deleteResults.some((result) => result))
    }

    // Edit the body
    if (
      showCommentEditInputs &&
      // Only update if there is a change
      (comment.author_name !== commentName.trim() || comment.thread_body !== commentBody.trim())
    ) {
      needRefetchThread = !!(await editComment(
        token,
        project_id,
        comment.thread_id,
        commentName.trim(),
        commentBody.trim(),
        comment.cartesian_position,
        comment.cuboid_position,
        comment.blueprint_position,
        comment.blueprint_id,
        showErrorModal
      ))

      // track with mixpanel
      mixpanel.track('Update comment', { 'Inspection area ID': project_id, 'Thread ID': comment.thread_id })
    } else if (
      editingReplyIndex >= 0 &&
      editingReplyIndex < replies.length &&
      replies[editingReplyIndex].reply_id &&
      // Only update if there is a change
      (replies[editingReplyIndex].author_name !== commentName.trim() ||
        replies[editingReplyIndex].reply_body !== commentBody.trim())
    ) {
      needRefetchReply = !!(await editReply(
        token,
        project_id,
        comment.thread_id,
        replies[editingReplyIndex].reply_id || '',
        commentName.trim(),
        commentBody.trim(),
        showErrorModal
      ))

      // track with mixpanel
      mixpanel.track('Update reply', {
        'Inspection area ID': project_id,
        'Thread ID': comment.thread_id,
        'Reply ID': replies[editingReplyIndex].reply_id,
      })
    }

    if (needRefetchThread) {
      onCommentModified()
    }

    if (needRefetchReply) {
      await fetchReplies()
    }

    setLoading(false)
    closeEditBody()
    return true
  }

  const closeEditBody = () => {
    setShowCommentEditInputs(false)
    setEditingReplyIndex(-1)

    if (!!updateCommentImages && !!updateEditingCaptions && !!updateDeletingImageIndexes && !!updateUploadedImages) {
      updateCommentImages([])
      updateEditingCaptions([])
      updateDeletingImageIndexes([])
      updateUploadedImages([])
    }
  }

  useEffect(() => {
    updateCommentName(user?.nickname || '')
    updateCommentBody('')
    setLoading(false)
    setShowInputs(!comment && (!!cartesianPosition || !!blueprintPosition || !!cuboidPosition))
    setShowCommentEditInputs(false)
  }, [comment, blueprintPosition, cartesianPosition, updateCommentBody, updateCommentName, user, cuboidPosition])

  useEffect(() => {
    if (showCommentEditInputs && comment) {
      updateCommentName(comment.author_name)
      updateCommentBody(comment.thread_body)
    } else {
      updateCommentName(user?.nickname || '')
      updateCommentBody('')
    }
  }, [showCommentEditInputs, comment, user, updateCommentName, updateCommentBody])

  useEffect(() => {
    if (editingReplyIndex >= 0) {
      updateCommentName(replies[editingReplyIndex].author_name)
      updateCommentBody(replies[editingReplyIndex].reply_body)
    } else {
      updateCommentName(user?.nickname || '')
      updateCommentBody('')
    }
  }, [editingReplyIndex, replies, updateCommentBody, updateCommentName, user])

  // To prevent fade out animation from anchor (0, 0)
  if (!cartesianPosition && !blueprintPosition && !cuboidPosition) {
    return null
  }

  const renderBody = (
    id: string | undefined,
    nameString: string,
    emailString: string,
    bodyString: string,
    images: CommentUploadedImage[],
    author_id: string | undefined,
    updated_at: number | undefined,
    handleEdit: () => void,
    handleDelete: () => void,
    handleOpenOriginalImage: (image_id: string) => void,
    handleMove?: () => void
  ) => {
    const date = updated_at ? new Date(updated_at) : null
    const timestamp = date ? date.toLocaleString('en-ZA', { hour12: false }) : ''

    return (
      <Flex key={id} flexDirection="column" width="100%">
        <HStack alignItems="center">
          <HStack color="var(--chakra-colors-secondary-400)" fontWeight="bold" alignItems="baseline">
            <Text as="span" marginBottom={-0.5}>
              {nameString}
            </Text>
            <Text fontSize="80%">{timestamp}</Text>
          </HStack>
          <Spacer />
          {author_id && author_id === user?.sub && (
            <Menu autoSelect={false}>
              <MenuButton
                variant="ghost"
                as={IconButton}
                aria-label="Actions"
                fontSize="md"
                size="xs"
                icon={<HDotsIcon />}
                disabled={loading || disabled}
                _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                {...BUTTON_STYLE}
                paddingX="6px"
                height="24px"
              />
              <MenuList
                backgroundColor="white"
                paddingY="8px"
                boxShadow="var(--chakra-shadows-md)"
                borderRadius="6px"
                zIndex={1}
                minW="80px"
              >
                <MenuItem
                  onClick={handleEdit}
                  paddingX="12px"
                  paddingY="10px"
                  _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                >
                  {t('components.comment_popup.edit', { ns: 'projects' })}
                </MenuItem>
                {!!handleMove && (
                  <MenuItem
                    onClick={handleMove}
                    paddingX="12px"
                    paddingY="10px"
                    _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                  >
                    {t('components.comment_popup.move', { ns: 'projects' })}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleDelete}
                  paddingX="12px"
                  paddingY="10px"
                  _hover={{ backgroundColor: 'var(--chakra-colors-gray-100)' }}
                >
                  {t('components.comment_popup.delete', { ns: 'projects' })}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
        <HStack color="var(--chakra-colors-secondary-400)" alignItems="baseline" mt="-4px">
          <Text fontSize="80%">{emailString}</Text>
        </HStack>
        <Linkify
          // eslint-disable-next-line react/no-unstable-nested-components
          componentDecorator={(decoratedHref: string, _decoratedText: string, key: number) => (
            <Link isExternal variant="underline" key={key} href={decoratedHref}>
              {decoratedHref}
            </Link>
          )}
        >
          {bodyString}
        </Linkify>
        <Flex gap={2} flexWrap="wrap">
          {images.map((image) => (
            <VStack
              spacing={0}
              key={image.compressed_size_url}
              cursor="pointer"
              onClick={() => handleOpenOriginalImage(image.image_id)}
              position="relative"
            >
              <Image src={image.compressed_size_url} />
              {image.caption && (
                <Text
                  width="100%"
                  backgroundColor="var(--chakra-colors-secondary-50)"
                  color="var(--chakra-colors-secondary-500)"
                  p="1px"
                  fontSize="80%"
                  textAlign="center"
                >
                  {image.caption}
                </Text>
              )}
              {openingImageIds?.includes(image.image_id) && (
                <Center
                  position="absolute"
                  top={0}
                  left={0}
                  w="100%"
                  h="100%"
                  backgroundColor="var(--chakra-colors-blackAlpha-700)"
                >
                  <Spinner
                    width="50px"
                    height="50px"
                    thickness="2px"
                    speed="0.65s"
                    color="var(--chakra-colors-secondary-400)"
                  />
                </Center>
              )}
            </VStack>
          ))}
        </Flex>
      </Flex>
    )
  }

  const checkImageError = (index: number) =>
    uploadedImages && uploadedImages.length > index && uploadedImages?.[index]?.status === 'error'
  const checkImageDone = (index: number) =>
    uploadedImages && uploadedImages.length > index && uploadedImages?.[index]?.status === 'done'
  const getImageActionIcon = (index: number, onDelete: () => void) => {
    //  In case user adding new images into existing thread / reply, action icon index should be lifted up
    const addedUpIndex = index + (editingCaptions?.length || 0)

    // Show done icon when image is uploaded
    if (checkImageDone(addedUpIndex)) {
      return (
        <IconButton {...ICON_BUTTON_PROPS} aria-label="Done Image" color="green" icon={<CheckCircleIcon />} disabled />
      )
    }
    if (checkImageError(addedUpIndex)) {
      // Only show error icon when other images are being uploaded
      if (loading) {
        return <IconButton {...ICON_BUTTON_PROPS} aria-label="Error Image" color="red" icon={<ErrorIcon />} disabled />
      }

      // Allow to reupload when other images are uploaded
      return (
        <IconButton
          {...ICON_BUTTON_PROPS}
          aria-label="Reupload Image"
          icon={<ResetIcon />}
          onClick={async () => {
            await reuploadCommentImage(index)
          }}
          disabled={disabled}
        />
      )
    }
    // Show loading icon when image is being uploaded
    if (!checkImageError(addedUpIndex) && loading) {
      return <IconButton {...ICON_BUTTON_PROPS} aria-label="Loading Image" icon={<DeleteIcon />} disabled isLoading />
    }
    // Show delete icon when image is not being uploaded
    return (
      <IconButton
        {...ICON_BUTTON_PROPS}
        aria-label="Delete Image"
        icon={<DeleteIcon />}
        onClick={onDelete}
        disabled={disabled}
      />
    )
  }

  const renderImageInput = (
    key: string,
    src: string | undefined,
    name: string,
    caption: string | undefined,
    index: number,
    onUpdateCaption: (value: string) => void,
    onDelete: () => void,
    canChangeFile: boolean
  ) => (
    <HStack key={key} width="100%" py="3px">
      <Image src={src} width="20%" />
      <VStack flex={1} alignItems="flex-start">
        <Text
          fontSize="80%"
          mb="-6px"
          onClick={() => {
            if (canChangeFile && !!updateHandlingImageIndex) {
              updateHandlingImageIndex(index)
              uploadFileRef.current?.click()
            }
          }}
          color={canChangeFile ? 'var(--chakra-colors-primary-600)' : 'inherit'}
          cursor={canChangeFile ? 'pointer' : 'inherit'}
        >
          {name}
        </Text>
        <Input
          size="sm"
          fontSize="80%"
          placeholder={t('components.comment_popup.can_insert_caption', { ns: 'projects' })}
          value={caption}
          onChange={(e) => {
            onUpdateCaption(e.target.value)
          }}
          maxLength={30}
          disabled={loading}
          borderWidth="1px"
          borderRadius="6px"
          padding="4px"
          width="100%"
        />
      </VStack>
      {getImageActionIcon(index, onDelete)}
    </HStack>
  )

  const renderInputBody = (forEdit?: boolean, key?: string) => (
    <React.Fragment key={key}>
      {forEdit && (
        <Text color="secondary.400" fontWeight="bold" as="span" marginBottom={-0.5} alignSelf="flex-start">
          {commentName}
        </Text>
      )}
      {!forEdit && (
        <Input
          size="sm"
          placeholder={t('components.comment_popup.name', { ns: 'projects' })}
          value={commentName}
          onChange={(e) => updateCommentName(e.target.value)}
          maxLength={30}
          disabled={loading}
          borderWidth="1px"
          borderRadius="6px"
          padding="4px"
          width="100%"
        />
      )}
      <Textarea
        size="sm"
        placeholder={t('components.comment_popup.comment', { ns: 'projects' })}
        resize="vertical"
        value={commentBody}
        onChange={(e) => updateCommentBody(e.target.value)}
        maxLength={300}
        disabled={loading}
        borderWidth="1px"
        borderRadius="6px"
        padding="4px"
        width="100%"
        minHeight="50px"
      />
      <Input
        data-testid="multiple-file-upload"
        hidden
        ref={uploadMultipleFilesRef}
        type="file"
        accept={isMobile ? '' : '.jpg,.jpeg,.png,.svg'}
        onChange={onMultipleFilesChange}
        multiple
      />
      <Input
        data-testid="file-upload"
        hidden
        ref={uploadFileRef}
        type="file"
        accept={isMobile ? '' : '.jpg,.jpeg,.png,.svg'}
        onChange={onFileChange}
      />
      {forEdit &&
        showCommentEditInputs &&
        !!comment?.images &&
        !!editingCaptions &&
        !!deletingImageIndexes &&
        !!updateEditingCaptions &&
        !!updateDeletingImageIndexes &&
        editingCaptions.length === comment.images.length &&
        comment.images.map((image, index) => {
          if (deletingImageIndexes.includes(index)) {
            return null
          }
          return renderImageInput(
            image.image_id,
            image.compressed_size_url,
            image.filename,
            editingCaptions[index],
            index,
            (value: string) => {
              const newCaptions = [...editingCaptions]
              newCaptions[index] = value
              updateEditingCaptions(newCaptions)
            },
            () => {
              const newIndexes = [...deletingImageIndexes]
              newIndexes.push(index)
              updateDeletingImageIndexes(newIndexes)
            },
            false
          )
        })}
      {forEdit &&
        editingReplyIndex >= 0 &&
        editingReplyIndex < replies.length &&
        !!replies[editingReplyIndex].images &&
        !!editingCaptions &&
        !!deletingImageIndexes &&
        !!updateEditingCaptions &&
        !!updateDeletingImageIndexes &&
        editingCaptions.length === replies[editingReplyIndex].images?.length &&
        replies[editingReplyIndex].images?.map((image, index) => {
          if (deletingImageIndexes.includes(index)) {
            return null
          }
          return renderImageInput(
            image.image_id,
            image.compressed_size_url,
            image.filename,
            editingCaptions[index],
            index,
            (value: string) => {
              const newCaptions = [...editingCaptions]
              newCaptions[index] = value
              updateEditingCaptions(newCaptions)
            },
            () => {
              const newIndexes = [...deletingImageIndexes]
              newIndexes.push(index)
              updateDeletingImageIndexes(newIndexes)
            },
            false
          )
        })}
      {!!commentImages &&
        !!updateCommentImages &&
        commentImages.map((image, index) =>
          renderImageInput(
            image.file.name,
            URL.createObjectURL(image.file),
            image.file.name,
            image.caption,
            index,
            (value: string) => {
              const newCommentImages = [...commentImages]
              newCommentImages[index].caption = value
              updateCommentImages(newCommentImages)
            },
            () => {
              const newImages = [...commentImages]
              newImages.splice(index, 1)

              if (updateUploadedImages && uploadedImages?.length === commentImages.length) {
                const newStatuses = [...uploadedImages]
                newStatuses.splice(index, 1)
                updateUploadedImages(newStatuses)
              }

              updateCommentImages(newImages)
            },
            true
          )
        )}
      {!forEdit && (
        <IconButton
          {...ICON_BUTTON_PROPS}
          alignSelf="flex-start"
          aria-label="Add Image"
          icon={<AddImageIcon />}
          disabled={disabled || loading}
          onClick={() => {
            uploadMultipleFilesRef.current?.click()
          }}
        />
      )}
      {forEdit && (
        <HStack width="100%">
          <IconButton
            {...ICON_BUTTON_PROPS}
            aria-label="Add Image"
            icon={<AddImageIcon />}
            disabled={disabled || loading}
            onClick={() => {
              uploadMultipleFilesRef.current?.click()
            }}
          />
          <Spacer />
          <IconButton
            {...ICON_BUTTON_PROPS}
            aria-label="Cancel Edit"
            icon={<InputEditorCancelIcon />}
            disabled={disabled || loading}
            onClick={closeEditBody}
          />
          <IconButton
            {...ICON_BUTTON_PROPS}
            aria-label="Confirm Edit"
            icon={<InputEditorConfirmIcon />}
            disabled={disabled || loading}
            onClick={editBody}
          />
        </HStack>
      )}
    </React.Fragment>
  )

  const renderCommentBody = () => {
    if (!comment) {
      return null
    }
    if (showCommentEditInputs) {
      return renderInputBody(true)
    }

    return renderBody(
      comment.thread_id,
      comment.author_name,
      comment.author_email || '',
      comment.thread_body,
      comment.images || [],
      comment.author_id,
      comment.updated_at,
      () => {
        setShowCommentEditInputs(true)
        setEditingReplyIndex(-1)
        setShowInputs(false)
        if (updateEditingCaptions) {
          updateEditingCaptions(comment.images?.map((image) => image.caption || '') || [])
        }
      },
      () => {
        deleteComment()
      },
      (image_id: string) => {
        void openOriginalImage(comment.thread_id, undefined, image_id)
      },
      onMoveComment
    )
  }

  const renderReplyBody = (reply: CommentReply, index: number, thread_id: string) => {
    if (index === editingReplyIndex) {
      return renderInputBody(true, reply.reply_id)
    }
    return renderBody(
      reply.reply_id,
      reply.author_name,
      reply.author_email || '',
      reply.reply_body,
      reply.images || [],
      reply.author_id,
      reply.updated_at,
      () => {
        setEditingReplyIndex(index)
        setShowInputs(false)
        if (setShowCommentEditInputs) {
          setShowCommentEditInputs(false)
        }
        if (updateEditingCaptions) {
          updateEditingCaptions(reply.images?.map((image) => image.caption || '') || [])
        }
      },
      () => {
        void deleteCommentReply(index)
      },
      (image_id: string) => {
        void openOriginalImage(thread_id, reply.reply_id, image_id)
      }
    )
  }

  const popover = (
    <Popover placement={forEditor ? 'top-start' : 'bottom-start'} isOpen>
      {/* Just for positioning the popover, trigger event will be handled by toolbar */}
      <PopoverTrigger>
        <Box style={{ left: '-8px', right: 0, top: 0, position: 'absolute', width: 0, height: 0 }} />
      </PopoverTrigger>
      <PopoverContent backgroundColor="white" borderRadius="6px" maxWidth="320px">
        <PopoverArrow />
        <PopoverBody wordBreak="break-word" p={0}>
          <VStack>
            <VStack overflow="auto" maxHeight="360px" p="8px" w="100%">
              {renderCommentBody()}
              {!replies.length && comment?.reply_amount && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => fetchReplies()}
                  alignSelf="flex-start"
                  disabled={loading}
                  {...SECONDARY_BUTTON_STYLE}
                  py="6px"
                >
                  {t('components.comment_popup.show_reply', { ns: 'projects', count: comment.reply_amount })}
                </Button>
              )}
              {!!replies.length &&
                replies.map((reply, index) => renderReplyBody(reply, index, comment?.thread_id || ''))}
              {showInputs && renderInputBody()}
            </VStack>
            <HStack alignSelf="stretch" p="8px" pt={0} w="100%">
              {!!comment && !showInputs && (
                <Button
                  colorScheme="primary"
                  size="sm"
                  onClick={() => {
                    if (!disabled) {
                      setShowInputs(true)
                      closeEditBody()
                    }
                  }}
                  disabled={disabled || loading}
                  {...PRIMARY_BUTTON_STYLE}
                >
                  {t('components.comment_popup.reply', { ns: 'projects' })}
                </Button>
              )}
              {!!comment && showInputs && (
                <Button
                  colorScheme="primary"
                  size="sm"
                  onClick={() => addCommentReply()}
                  disabled={
                    disabled ||
                    loading ||
                    !commentName ||
                    (uploadedImages &&
                      deletingImageIndexes &&
                      uploadedImages.some(
                        (image, index) => !deletingImageIndexes.includes(index) && image.status !== 'done'
                      ))
                  }
                  {...PRIMARY_BUTTON_STYLE}
                >
                  {t('components.comment_popup.send_reply', { ns: 'projects' })}
                </Button>
              )}
              <Spacer />
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  if (!disabled) {
                    closePopup()
                  }
                }}
                disabled={disabled || loading}
                {...SECONDARY_BUTTON_STYLE}
              >
                {comment
                  ? t('components.comment_popup.close', { ns: 'projects' })
                  : t('components.comment_popup.cancel', { ns: 'projects' })}
              </Button>
              {!comment && (
                <Button
                  colorScheme="primary"
                  size="sm"
                  onClick={addComment}
                  disabled={
                    disabled ||
                    loading ||
                    !commentName ||
                    (uploadedImages &&
                      deletingImageIndexes &&
                      uploadedImages.some(
                        (image, index) => !deletingImageIndexes.includes(index) && image.status !== 'done'
                      ))
                  }
                  {...PRIMARY_BUTTON_STYLE}
                >
                  {t('components.comment_popup.add', { ns: 'projects' })}
                </Button>
              )}
            </HStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )

  const popupPosition = () => {
    if (comment?.cartesian_position) {
      return new Vector3(comment.cartesian_position.x, comment.cartesian_position.y, comment.cartesian_position.z)
    }
    if (cartesianPosition) {
      return new Vector3(cartesianPosition.x, cartesianPosition.y, cartesianPosition.z)
    }
    if (cuboidPosition) {
      return new Vector3(...cuboidPosition.center)
    }

    return new Vector3()
  }

  if (forEditor) {
    return (
      // zIndex should be less than --chakra-zIndices ~ 1000, for Global modal overlaying purposes
      <Html
        position={popupPosition()}
        zIndexRange={[0, 999]}
        style={{
          transform: comment?.unplacedIndex
            ? // icon size = 24px
              // padding left and right = 4px
              `translateX(${comment.unplacedIndex[0] * (24 + 4 * 2)}px) translateY(${
                comment.unplacedIndex[1] * (24 + 4 * 2)
              }px)`
            : '',
        }}
        className="unprintable"
      >
        {popover}
      </Html>
    )
  }

  const scaledPosition = scaleBlueprintRectangle(blueprintPosition, blueprintScale || 1)?.coordinate
  if (!scaledPosition) {
    return null
  }
  return (
    <Box position="absolute" left={`${scaledPosition.x}px`} top={`${scaledPosition.y}px`}>
      {popover}
    </Box>
  )
}

export default CommentPopup
