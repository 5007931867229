import 'react-day-picker/dist/style.css'

import { FC, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalFooter,
  NumberInput,
  NumberInputField,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { enUS, ja } from 'date-fns/locale'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import mixpanel from 'mixpanel-browser'
import { DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'

import {
  AlertFillIcon,
  ChevronDownIcon,
  DeleteIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
  InputEditorEditIcon,
  ResetIcon,
} from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import useInspectionSheetInputSuggestions from 'hooks/InspectionSheetInputSuggestions'

import { LANGUAGES, MODAL_TYPES } from 'config/constants'
import { INSPECTION_CONTRACTEE_ERROR_COLOR, INSPECTION_NORMAL_COLOR } from 'config/styles'

import { InspectionSheet, SpacerInspectionItem } from 'interfaces/interfaces'

import { deleteInspectionItem, formatInspectionDifferentValue, updateInspectionSheet } from 'services/InspectionSheet'
import { evaluateInspectionItemValue } from 'services/SpacerInspectionSheet'
import { sanitizeFilename } from 'services/Util'
import { generateXLSX } from 'services/exports/XLSX_Spacer'

import SpacerSheetPDF from './SpacerSheetPDF'

dayjs.extend(utc)

const ALERT_COLOR = 'orange'
const ALERT_BACKGROUND_COLOR = 'orange.50'
const LINE_COLOR = 'secondary.200'
const ICON_WIDTH = 6
const INPUT_EDITOR_ICON_PROPS = {
  backgroundColor: 'secondary.200',
  borderColor: 'secondary.00',
  borderWidth: 1,
  borderRadius: ICON_WIDTH * 4,
  minWidth: ICON_WIDTH,
  width: ICON_WIDTH,
  height: ICON_WIDTH,
  padding: 0,
}
const PROFILE_INPUT_WIDTH = 300
const NAME_COLUMN_WIDTH = 165
const VALUE_COLUMN_WIDTH = 270
const AREA_VALUE_COLUMN_WIDTH = 100
const RESULT_COLUMN_WIDTH = 90
const VALUE_HEADER_COLOR = 'secondary.500'

const ROW_WIDTH = NAME_COLUMN_WIDTH + VALUE_COLUMN_WIDTH * 2 + AREA_VALUE_COLUMN_WIDTH + RESULT_COLUMN_WIDTH
const MODAL_PADDING = 6
export const MODAL_WIDTH = MODAL_PADDING * 4 + ROW_WIDTH + 79

const ITEMS_ON_FIRST_PAGE = 5
const ITEMS_PER_PAGE = 20

const SpacerSheetModal: FC<{
  canClose: boolean
  inspectionSheet: InspectionSheet
  inspectionItems: SpacerInspectionItem[]
  isLoadingSheet: boolean
  isAllowedDownload: boolean
  isAllowedModify: boolean
  isLoading: boolean
  onOpenItem: (item: SpacerInspectionItem) => void
  onClose: () => void
  onSheetUpdated: () => void
  onItemSelected: (item: SpacerInspectionItem | null) => void
  onItemRefetch: (item: SpacerInspectionItem) => void
  openUpdateProjectModal: () => void
  setIsItemSelected: (state: boolean) => void
  setIsLoading: (state: boolean) => void
  onOpenBlackboard: () => void
}> = ({
  canClose,
  inspectionSheet,
  inspectionItems,
  isLoadingSheet,
  isAllowedDownload,
  isAllowedModify,
  isLoading,
  onOpenItem,
  onClose,
  onSheetUpdated,
  onItemSelected,
  onItemRefetch,
  openUpdateProjectModal,
  setIsItemSelected,
  setIsLoading,
  onOpenBlackboard,
}) => {
  const { t, i18n } = useTranslation(['projects'])

  const { project_id } = useParams<{ project_id: string }>()
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { project } = useContext(EditorContext)

  const inputSuggestions = useInspectionSheetInputSuggestions(project?.project_group_id)

  const [editingField, setEditingField] = useState('')
  const [editingValue, setEditingValue] = useState<string | Date>('')
  const [itemResults, setItemResults] = useState<boolean[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)

  useEffect(() => {
    setIsItemSelected(selectedItemIndex >= 0)
  }, [selectedItemIndex, setIsItemSelected])

  useEffect(() => {
    setItemResults(
      inspectionItems.map(
        (item) =>
          !!evaluateInspectionItemValue(
            item.number_of_spacers_per_meter_square,
            t('main_canvas.inspection_sheet.values', { ns: 'projects', returnObjects: true })
          ).result
      )
    )

    const itemsLeft = inspectionItems.length - ITEMS_ON_FIRST_PAGE
    if (itemsLeft <= 0) {
      setTotalPage(1)
    } else {
      const pageLeft = Math.ceil(itemsLeft / ITEMS_PER_PAGE)
      setTotalPage(pageLeft + 1)
    }
  }, [inspectionItems, inspectionSheet, t])

  const updateSheetValue = (key: string, value: string | number | null, parentKey?: 'construction_properties') => {
    if (!project_id) {
      return false
    }

    if (!inspectionSheet) {
      showErrorModal(t('main_canvas.inspection_sheet.message_no_sheet', { ns: 'projects' }))
      return false
    }

    setIsLoading(true)
    const newValues = parentKey
      ? {
          [parentKey]: { ...inspectionSheet[parentKey], [key]: value },
        }
      : { [key]: value }

    const newSheet = {
      ...inspectionSheet,
      ...newValues,
    }

    void (async () => {
      const token = await getAccessToken()
      if (!token) {
        setIsLoading(false)
        return false
      }

      if (await updateInspectionSheet(token, project_id, newSheet, showErrorModal)) {
        onSheetUpdated()
        setIsLoading(false)

        // track with mixpanel
        mixpanel.track('Update inspection sheet', {
          'Inspection area ID': project_id,
          'Updated part': parentKey,
          'Updated subpart': key,
          'New Value': value,
        })

        return true
      }
      setIsLoading(false)
      return false
    })()

    return true
  }

  const deleteItem = (item: SpacerInspectionItem) => {
    showModal({
      body: (
        <>
          <Text>{t('main_canvas.inspection_sheet.delete_item_modal.warning_text', { ns: 'projects' })}</Text>
          <Text mt="1" fontWeight="semibold">
            {item.part_name}
          </Text>
        </>
      ),
      confirmText: t('main_canvas.inspection_sheet.delete_item_modal.delete', { ns: 'projects' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          if (!item.inspection_item_id || !project_id || !inspectionSheet.inspection_sheet_id) {
            return false
          }

          setIsLoading(true)
          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            return false
          }

          if (
            await deleteInspectionItem(
              token,
              project_id,
              inspectionSheet.inspection_sheet_id,
              item.inspection_item_id,
              showErrorModal
            )
          ) {
            onSheetUpdated()
            setIsLoading(false)

            // track with mixpanel
            mixpanel.track('Delete spacer inspection item', {
              'Inspection area ID': project_id,
              'Inspection item ID': item.inspection_item_id,
            })

            return true
          }

          setIsLoading(false)
          return false
        })()
        return true
      },
      title: t('main_canvas.inspection_sheet.delete_item_modal.confirm', { ns: 'projects' }),
    })
  }

  const renderTextInput = (
    label: string,
    value: string,
    setter: (newValue: string | number) => void,
    readonly?: boolean,
    hideLabel?: boolean,
    displayValue?: string,
    forDateTime?: boolean,
    isNumber?: boolean,
    lowerConstraintValue?: number,
    lowerConstraintMessage?: string,
    upperConstraintValue?: number,
    upperConstraintMessage?: string
  ) => {
    const suggestions = inputSuggestions[label] || []

    return (
      <FormControl flex={1}>
        <HStack
          alignItems="baseline"
          borderBottomWidth={hideLabel ? 0 : 1}
          borderBottomColor={LINE_COLOR}
          paddingBottom="1px"
        >
          {!hideLabel && (
            <FormLabel htmlFor={label} minWidth={16} marginBottom={0} fontSize="sm" color={VALUE_HEADER_COLOR}>
              {label}
            </FormLabel>
          )}
          <Box position="relative" flex={1} zIndex={forDateTime ? 5 : 'auto'} mx={1} backgroundColor="white">
            {isAllowedModify && editingField === label && forDateTime && (
              <Box paddingLeft={2} paddingRight={2} flex={1} height={6}>
                <Box
                  fontSize="sm"
                  position="absolute"
                  boxShadow="md"
                  borderWidth={1}
                  borderColor="secondary.200"
                  backgroundColor="white"
                  rounded={8}
                  right={0}
                  top={0}
                  className="modely-date-picker"
                >
                  <DayPicker
                    mode="single"
                    locale={i18n.language === LANGUAGES.JA ? ja : enUS}
                    selected={editingValue as Date}
                    onSelect={(date) => setEditingValue(date || new Date())}
                  />
                </Box>
              </Box>
            )}
            {isAllowedModify && editingField === label && !forDateTime && !isNumber && (
              <Input
                id={label}
                value={editingValue as string}
                onChange={(e) => setEditingValue(e.target.value)}
                flex={1}
                paddingLeft={2}
                paddingRight={2}
                height={8}
                fontSize="sm"
                autoFocus
              />
            )}
            {isAllowedModify && editingField === label && !forDateTime && isNumber && (
              <>
                <NumberInput
                  min={lowerConstraintValue || 0}
                  max={upperConstraintValue || 9999}
                  flex={1}
                  value={(editingValue as string) || ''}
                  onChange={(valueAsString: string) => {
                    setEditingValue(valueAsString)
                  }}
                  isRequired
                  position="relative"
                  zIndex={2}
                >
                  <NumberInputField fontSize="sm" padding={2} height={8} />
                </NumberInput>
                <Text
                  position="absolute"
                  left={0}
                  fontSize="80%"
                  whiteSpace="nowrap"
                  color={VALUE_HEADER_COLOR}
                  backgroundColor="white"
                  p={1}
                >
                  {lowerConstraintMessage || upperConstraintMessage || ''}
                </Text>
              </>
            )}
            {editingField !== label && (
              <Text height={8} lineHeight={8} flex={1} color={value ? 'secondary' : 'transparent'}>
                {displayValue || value || '.'}
              </Text>
            )}
            <HStack
              position="absolute"
              right={0}
              top={0}
              transform="translateX(100%)"
              px={1.5}
              h="100%"
              backgroundColor="white"
              zIndex={2}
            >
              {isAllowedModify && editingField === label && (
                <>
                  <IconButton
                    {...INPUT_EDITOR_ICON_PROPS}
                    aria-label="cancel"
                    icon={<InputEditorCancelIcon />}
                    color="secondary.500"
                    onClick={() => {
                      setEditingField('')
                      setEditingValue('')
                    }}
                  />
                  <IconButton
                    {...INPUT_EDITOR_ICON_PROPS}
                    disabled={
                      isNumber &&
                      ((!!lowerConstraintValue && parseFloat(editingValue as string) < lowerConstraintValue) ||
                        (!!upperConstraintValue && parseFloat(editingValue as string) > upperConstraintValue))
                    }
                    aria-label="confirm"
                    icon={<InputEditorConfirmIcon />}
                    color="white"
                    backgroundColor="secondary.500"
                    colorScheme="secondary"
                    onClick={() => {
                      if (forDateTime) {
                        setter(
                          editingValue
                            ? dayjs(editingValue as Date)
                                .utc()
                                .toISOString()
                            : ''
                        )
                      } else if (isNumber) {
                        setter(parseFloat(editingValue as string))
                      } else {
                        setter(editingValue as string)
                      }
                      setEditingField('')
                      setEditingValue('')
                    }}
                  />
                </>
              )}
              {isAllowedModify && editingField !== label && !readonly && (
                <IconButton
                  {...INPUT_EDITOR_ICON_PROPS}
                  aria-label="edit"
                  icon={<InputEditorEditIcon />}
                  color="white"
                  borderColor="primary.500"
                  backgroundColor="primary.500"
                  colorScheme="primary"
                  onClick={() => {
                    setEditingField(label)
                    setEditingValue(forDateTime && value ? dayjs.utc(value).toDate() : value || '')
                  }}
                  disabled={isLoading || isLoadingSheet}
                />
              )}
            </HStack>
            {isAllowedModify && editingField === label && forDateTime && value && (
              <HStack
                position="absolute"
                right={0}
                top={9}
                transform="translateX(100%)"
                px={1.5}
                h="100%"
                backgroundColor="white"
                zIndex={2}
              >
                <IconButton
                  {...INPUT_EDITOR_ICON_PROPS}
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  color="white"
                  backgroundColor="secondary.500"
                  colorScheme="secondary"
                  onClick={() => {
                    setEditingField('')
                    setEditingValue('')
                    setter('')
                  }}
                />
              </HStack>
            )}
            {isAllowedModify && editingField === label && (
              <Menu isOpen={!editingValue && !!suggestions?.length}>
                {/* For positioning the menu */}
                <MenuButton left={0} bottom={2} position="absolute" />
                <MenuList zIndex={9}>
                  {suggestions?.map((suggestion) => (
                    <MenuItem key={suggestion} onClick={() => setEditingValue(suggestion)}>
                      {suggestion}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Box>
        </HStack>
      </FormControl>
    )
  }

  const renderCriterionHeadersColumn = (header: string, COLUMN_WIDTH: number) => (
    <VStack minWidth={COLUMN_WIDTH} alignItems="stretch">
      <Center p={4} backgroundColor={LINE_COLOR}>
        <Text>{header}</Text>
      </Center>
    </VStack>
  )

  const renderValueHeadersColumn = (headers: (string[] | undefined)[], COLUMN_WIDTH: number, unit: string) => (
    <VStack
      minWidth={COLUMN_WIDTH}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderTopWidth={1}
      borderRightWidth={0}
    >
      <HStack height={84} alignItems="flex-end" paddingBottom={1} spacing={0}>
        <>
          <VStack color={VALUE_HEADER_COLOR} flex={1} textAlign="center">
            <VStack spacing={0}>
              <Text>{headers[0]?.[0]}</Text>
              <Text>{headers[0]?.[1]}</Text>
            </VStack>
            {headers[0] && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </VStack>
          <VStack color={VALUE_HEADER_COLOR} flex={1} textAlign="center">
            <VStack spacing={0}>
              <Text>{headers[1]?.[0]}</Text>
              <Text>{headers[1]?.[1]}</Text>
            </VStack>
            {headers[1] && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </VStack>
          <VStack color={VALUE_HEADER_COLOR} flex={1} textAlign="center">
            <VStack spacing={0}>
              <Text>{headers[2]?.[0]}</Text>
              <Text>{headers[2]?.[1]}</Text>
            </VStack>
            {headers[2] && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </VStack>
        </>
      </HStack>
    </VStack>
  )

  const renderResultHeadersColumn = (COLUMN_WIDTH: number) => (
    <VStack
      minWidth={COLUMN_WIDTH}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderTopWidth={1}
      borderRightWidth={1}
    >
      <HStack height={84} alignItems="flex-end" paddingBottom={1} spacing={0}>
        <VStack color={VALUE_HEADER_COLOR} flex={1}>
          <Text>{t('main_canvas.inspection_sheet.result.result', { ns: 'projects' })}</Text>
        </VStack>
      </HStack>
    </VStack>
  )

  const renderNameColumn = (name: string, needUpdate: boolean) => (
    <HStack
      minWidth={NAME_COLUMN_WIDTH - 1}
      maxWidth={NAME_COLUMN_WIDTH - 1}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderBottomWidth={1}
      p={1}
      width="100%"
      backgroundColor={needUpdate ? ALERT_BACKGROUND_COLOR : 'transparent'}
    >
      {needUpdate && (
        <Tooltip
          hasArrow
          placement="right"
          label={t('main_canvas.inspection_sheet.spacer_sheet_property.message_spacer_deleted', { ns: 'projects' })}
          fontSize="xs"
          fontWeight="normal"
        >
          <Flex height="100%" alignItems="center" pb={1}>
            <AlertFillIcon color={ALERT_COLOR} />
          </Flex>
        </Tooltip>
      )}
      <Text px={1} flex={1} textAlign="right" title={name} isTruncated>
        {name}
      </Text>
    </HStack>
  )

  const renderValuesColumn = (
    COLUMN_WIDTH: number,
    values: [string, string, string],
    needUpdate: boolean,
    result?: {
      color: string
      description: string
    }
  ) => (
    <VStack
      minWidth={COLUMN_WIDTH}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderBottomWidth={1}
      py={1}
      backgroundColor={needUpdate ? ALERT_BACKGROUND_COLOR : 'transparent'}
    >
      <HStack alignItems="flex-end" paddingBottom={1}>
        <Text flex={1} textAlign="center">
          {values[0]}
        </Text>
        <Text flex={1} textAlign="center">
          {values[1]}
        </Text>
        <Text flex={1} textAlign="center" color={result?.color || ''} title={result?.description || ''}>
          {values[2]}
        </Text>
      </HStack>
    </VStack>
  )

  const renderResultsColumn = (result: boolean | undefined, needUpdate: boolean) => (
    <VStack
      minWidth={RESULT_COLUMN_WIDTH - 1}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderBottomWidth={1}
      p={1}
      backgroundColor={needUpdate ? ALERT_BACKGROUND_COLOR : 'transparent'}
    >
      <HStack alignItems="flex-end" paddingBottom={1} spacing={0}>
        <Text flex={1} textAlign="center" color={result ? INSPECTION_NORMAL_COLOR : INSPECTION_CONTRACTEE_ERROR_COLOR}>
          {result
            ? t('main_canvas.inspection_sheet.result.ok', { ns: 'projects' })
            : t('main_canvas.inspection_sheet.result.ng', { ns: 'projects' })}
        </Text>
      </HStack>
    </VStack>
  )

  const renderItemModifyButton = (
    item: SpacerInspectionItem,
    index: number,
    forDeleting?: boolean,
    forRefetching?: boolean
  ) => {
    let icon = <InputEditorEditIcon />
    let backgroundColor = 'primary'

    if (forDeleting) {
      icon = <InputEditorCancelIcon />
      backgroundColor = 'red'
    }
    if (forRefetching) {
      icon = <ResetIcon />
      backgroundColor = ALERT_COLOR
    }

    return (
      <Center
        position="relative"
        width={ICON_WIDTH}
        flex="1"
        key={item.inspection_item_id}
        transition="opacity 0.1s ease-in"
        opacity={selectedItemIndex === index || selectedItemIndex < 0 ? 1 : 0.2}
        pointerEvents="auto"
      >
        {isAllowedModify && (
          <IconButton
            {...INPUT_EDITOR_ICON_PROPS}
            aria-label={forDeleting ? 'delete' : 'edit'}
            icon={icon}
            color="white"
            backgroundColor={backgroundColor}
            colorScheme={backgroundColor}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              if (!item.inspection_item_id) {
                return
              }
              if (forDeleting) {
                deleteItem(item)
              } else if (forRefetching) {
                onItemRefetch(item)
              } else {
                onOpenItem(item)
              }
            }}
            disabled={isLoading || isLoadingSheet}
          />
        )}
      </Center>
    )
  }

  const getStartIndexOfCurrentPage = (pageIndex: number) => {
    if (pageIndex === 0) {
      return 0
    }
    return ITEMS_ON_FIRST_PAGE + (pageIndex - 1) * ITEMS_PER_PAGE
  }
  const filterPaginatedItems = (items: unknown[], pageIndex: number) => {
    if (pageIndex === 0) {
      return [...items].slice(0, ITEMS_ON_FIRST_PAGE)
    }

    const startIndex = getStartIndexOfCurrentPage(pageIndex)
    const endIndex = startIndex + ITEMS_PER_PAGE
    return [...items].slice(startIndex, endIndex)
  }

  const getPaginationButtonBorderColor = (index: number) => {
    if (currentPage === index) {
      return 'secondary.100'
    }
    const results = filterPaginatedItems(itemResults, index) as boolean[]

    if (results.some((result) => !result)) {
      return INSPECTION_CONTRACTEE_ERROR_COLOR
    }
    return 'secondary.100'
  }

  const getPaginationButtonAlertIcon = (index: number) => {
    const items = filterPaginatedItems(inspectionItems, index) as SpacerInspectionItem[]

    if (items.some((item) => item.need_update)) {
      return <AlertFillIcon color={ALERT_COLOR} />
    }
    return null
  }

  const getFileName = () =>
    project?.project_name || t('main_canvas.inspection_sheet.spacer_inspection_sheet', { ns: 'projects' })

  const onGenerateXLSX = async () => {
    if (!inspectionSheet) {
      return
    }

    const filename = sanitizeFilename(`${getFileName()}.xlsx`)
    await generateXLSX(
      filename,
      inspectionSheet,
      inspectionItems,
      itemResults,
      project,
      t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true })
    )
  }

  if (!inspectionSheet) {
    return null
  }

  return (
    <>
      <ModalBody pt={MODAL_PADDING} pr={MODAL_PADDING} pl={MODAL_PADDING}>
        <VStack
          borderColor={selectedItemIndex >= 0 ? 'transparent' : LINE_COLOR}
          transition="border-color 0.1s ease-in"
          borderWidth={1}
          p={MODAL_PADDING}
        >
          <Text
            fontSize={20}
            fontWeight="bold"
            letterSpacing={i18n.language === LANGUAGES.JA ? 16 : 'auto'}
            textTransform="uppercase"
            textAlign="center"
            pt={4}
            pb={6}
            transition="opacity 0.1s ease-in"
            opacity={selectedItemIndex >= 0 ? 0 : 1}
          >
            {t('main_canvas.inspection_sheet.spacer_inspection_sheet', { ns: 'projects' })}
          </Text>
          {currentPage === 0 && (
            <HStack
              width="100%"
              justifyContent="space-around"
              alignItems="flex-start"
              pb={8}
              transition="opacity 0.1s ease-in"
              opacity={selectedItemIndex >= 0 ? 0 : 1}
            >
              <VStack spacing={2} minWidth={PROFILE_INPUT_WIDTH}>
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.construction_project_name', { ns: 'projects' }),
                  inspectionSheet.construction_properties.construction_project_name || '',
                  (newValue: string | number) => {
                    updateSheetValue('construction_project_name', newValue, 'construction_properties')
                  }
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.construction_type_name', { ns: 'projects' }),
                  inspectionSheet.construction_properties.construction_type || '',
                  (newValue: string | number) => {
                    updateSheetValue('construction_type', newValue, 'construction_properties')
                  }
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.construction_detail', { ns: 'projects' }),
                  inspectionSheet.construction_properties.construction_type_detailed || '',
                  (newValue: string | number) => {
                    updateSheetValue('construction_type_detailed', newValue, 'construction_properties')
                  }
                )}
              </VStack>
              <VStack spacing={2} minWidth={PROFILE_INPUT_WIDTH}>
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.inspection_area_name', { ns: 'projects' }),
                  project?.project_name || '',
                  () => null,
                  true
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.creator', { ns: 'projects' }),
                  inspectionSheet.creator_name || '',
                  (newValue: string | number) => {
                    updateSheetValue('creator_name', newValue)
                  }
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.creation_day', { ns: 'projects' }),
                  inspectionSheet.create_time_user_specified || '',
                  (newValue: string | number) => {
                    updateSheetValue('create_time_user_specified', newValue || null)
                  },
                  false,
                  false,
                  inspectionSheet.create_time_user_specified
                    ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
                    : '',
                  true
                )}
              </VStack>
            </HStack>
          )}
          <Box position="relative" width={`calc(100% + ${MODAL_PADDING * 4 * 2}px)`} paddingX={MODAL_PADDING}>
            <Box width="100%" overflow="auto">
              <HStack
                minWidth={ROW_WIDTH}
                alignItems="flex-end"
                spacing={0}
                divider={<StackDivider borderColor={LINE_COLOR} />}
                transition="opacity 0.1s ease-in"
                opacity={selectedItemIndex >= 0 ? 0 : 1}
              >
                <Box minWidth={NAME_COLUMN_WIDTH} maxWidth={NAME_COLUMN_WIDTH} />
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.spacer_sheet_property.surface_density', { ns: 'projects' }),
                  VALUE_COLUMN_WIDTH
                )}
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.spacer_sheet_property.target_area', { ns: 'projects' }),
                  AREA_VALUE_COLUMN_WIDTH
                )}
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.spacer_sheet_property.spacer_number', { ns: 'projects' }),
                  VALUE_COLUMN_WIDTH
                )}
                <Box minWidth={RESULT_COLUMN_WIDTH} />
              </HStack>
              <HStack
                minWidth={ROW_WIDTH}
                alignItems="flex-end"
                spacing={0}
                divider={<StackDivider borderColor={LINE_COLOR} />}
                transition="opacity 0.1s ease-in"
                opacity={selectedItemIndex >= 0 ? 0 : 1}
              >
                <Box minWidth={NAME_COLUMN_WIDTH} maxWidth={NAME_COLUMN_WIDTH} />
                {renderValueHeadersColumn(
                  [
                    ['', t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' })],
                    ['', t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' })],
                    ['', t('main_canvas.inspection_sheet.values.difference', { ns: 'projects' })],
                  ],
                  VALUE_COLUMN_WIDTH,
                  t('main_canvas.inspection_sheet.spacer_sheet_property.unit_surface_density', { ns: 'projects' })
                )}
                {renderValueHeadersColumn([undefined, [' ', ''], undefined], AREA_VALUE_COLUMN_WIDTH, 'm^2')}
                {renderValueHeadersColumn(
                  [
                    ['', t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' })],
                    [
                      t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
                      `(${t('main_canvas.inspection_sheet.spacer_sheet_property.estimate', { ns: 'projects' })})`,
                    ],
                    [
                      t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
                      `(${t('main_canvas.inspection_sheet.spacer_sheet_property.user_input', { ns: 'projects' })})`,
                    ],
                  ],
                  VALUE_COLUMN_WIDTH,
                  t('main_canvas.inspection_sheet.spacer_sheet_property.unit_number', { ns: 'projects' })
                )}
                {renderResultHeadersColumn(RESULT_COLUMN_WIDTH)}
              </HStack>
              <HStack
                minWidth={ROW_WIDTH}
                spacing={0}
                divider={<StackDivider borderColor={LINE_COLOR} />}
                borderColor={LINE_COLOR}
                borderTopWidth={1}
                transition="opacity 0.1s ease-in"
                opacity={selectedItemIndex >= 0 ? 0 : 1}
              >
                <Box width={0} />
                <Box minWidth={NAME_COLUMN_WIDTH - 1} maxWidth={NAME_COLUMN_WIDTH - 1}>
                  <Text px={1} pt={1} color={VALUE_HEADER_COLOR} textAlign="right">
                    {t('main_canvas.inspection_sheet.spacer_sheet_property.item', { ns: 'projects' })}
                  </Text>
                </Box>
                <Box minWidth={VALUE_COLUMN_WIDTH} />
                <Box minWidth={AREA_VALUE_COLUMN_WIDTH} />
                <Box minWidth={VALUE_COLUMN_WIDTH} />
                <Box minWidth={RESULT_COLUMN_WIDTH - 1} />
                <Box width={0} />
              </HStack>
              {(filterPaginatedItems(inspectionItems, currentPage) as SpacerInspectionItem[]).map((item, index) => (
                <HStack
                  cursor="pointer"
                  minWidth={ROW_WIDTH}
                  alignItems="stretch"
                  spacing={0}
                  divider={<StackDivider borderColor={LINE_COLOR} />}
                  key={item.inspection_item_id}
                  backgroundColor="white"
                  onClick={() => {
                    if (selectedItemIndex === index) {
                      onItemSelected(null)
                      setSelectedItemIndex(-1)
                    } else {
                      onItemSelected(item)
                      setSelectedItemIndex(index)
                    }
                  }}
                  transition="opacity 0.1s ease-in"
                  opacity={selectedItemIndex === index || selectedItemIndex < 0 ? 1 : 0.2}
                  position="relative"
                  zIndex={3}
                  pointerEvents="auto"
                >
                  <Box />
                  {renderNameColumn(item.part_name, item.need_update)}
                  {renderValuesColumn(
                    VALUE_COLUMN_WIDTH,
                    [
                      item.number_of_spacers_per_meter_square.specified_value?.toString() || '-',
                      item.number_of_spacers_per_meter_square.estimated_value?.toString() || '-',
                      formatInspectionDifferentValue(item.number_of_spacers_per_meter_square),
                    ],
                    item.need_update,
                    evaluateInspectionItemValue(
                      item.number_of_spacers_per_meter_square,
                      t('main_canvas.inspection_sheet.values', { ns: 'projects', returnObjects: true })
                    )
                  )}
                  <VStack
                    minWidth={AREA_VALUE_COLUMN_WIDTH}
                    alignItems="stretch"
                    borderColor={LINE_COLOR}
                    borderBottomWidth={1}
                    py={1}
                    backgroundColor={item.need_update ? ALERT_BACKGROUND_COLOR : 'transparent'}
                  >
                    <HStack alignItems="flex-end" paddingBottom={1}>
                      <Text flex={1} textAlign="center">
                        {item.target_area ?? '-'}
                      </Text>
                    </HStack>
                  </VStack>
                  {renderValuesColumn(
                    VALUE_COLUMN_WIDTH,
                    [
                      item.number_of_deployed_spacers.specified_value?.toString() || '-',
                      item.number_of_deployed_spacers.estimated_value_by_surface_density?.toString() || '-',
                      item.number_of_deployed_spacers.estimated_value_by_user_input?.toString() || '-',
                    ],
                    item.need_update
                  )}
                  {renderResultsColumn(itemResults[getStartIndexOfCurrentPage(currentPage) + index], item.need_update)}
                  <Box />
                </HStack>
              ))}
              {!filterPaginatedItems(inspectionItems, currentPage).length && (
                <Box w="100%" borderTopWidth={1} borderColor={LINE_COLOR} />
              )}
            </Box>
            {/* Delete buttons */}
            <VStack position="absolute" left={0} top={160} bottom={0} spacing={0} width={MODAL_PADDING} zIndex={3}>
              {(filterPaginatedItems(inspectionItems, currentPage) as SpacerInspectionItem[]).map((item, index) =>
                renderItemModifyButton(item, index, true)
              )}
            </VStack>
            {/* Edit buttons */}
            <VStack position="absolute" right={0} top={160} bottom={0} spacing={0} width={MODAL_PADDING} zIndex={3}>
              {(filterPaginatedItems(inspectionItems, currentPage) as SpacerInspectionItem[]).map((item, index) =>
                renderItemModifyButton(item, index, false, item.need_update)
              )}
            </VStack>
          </Box>
        </VStack>
        <HStack
          spacing={1}
          mt={1}
          justifyContent="flex-end"
          transition="opacity 0.1s ease-in"
          opacity={selectedItemIndex >= 0 ? 0 : 1}
        >
          {Array(totalPage)
            .fill(null)
            .map((emptyValue, index) => ({ id: `item-${index}` }))
            .map((item, index) => (
              <Button
                key={item.id}
                size="xs"
                backgroundColor={currentPage === index ? 'secondary.100' : 'transparent'}
                borderColor={getPaginationButtonBorderColor(index)}
                borderWidth={currentPage === index ? 0 : 1}
                onClick={() => setCurrentPage(index)}
                lineHeight={4}
                borderRadius={0}
                position="relative"
              >
                <Box position="absolute" opacity={0.5} pb={0.5} fontSize="md">
                  {getPaginationButtonAlertIcon(index)}
                </Box>
                <Text position="relative">{index + 1}</Text>
              </Button>
            ))}
        </HStack>
      </ModalBody>

      <ModalFooter justifyContent="center" transition="opacity 0.1s ease-in" opacity={selectedItemIndex >= 0 ? 0 : 1}>
        {canClose && (
          <>
            <Button disabled={isLoading || isLoadingSheet} me={3} py={2} minW="100px" onClick={() => onClose()}>
              {t('main_canvas.inspection_sheet.menu_bottom.close', { ns: 'projects' })}
            </Button>
            <Button
              disabled={isLoading || isLoadingSheet}
              me={3}
              py={2}
              minW="100px"
              onClick={() => onOpenBlackboard()}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.show_blackboard', { ns: 'projects' })}
            </Button>
          </>
        )}
        {!canClose && (
          <>
            <Button
              as={RouterLink}
              to="/dashboard"
              disabled={isLoading || isLoadingSheet}
              me={3}
              py={2}
              minW="100px"
              onClick={() => onClose()}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.back', { ns: 'projects' })}
            </Button>
            <Button
              as={RouterLink}
              to={`/projects/${project_id || ''}/blueprint`}
              disabled={isLoading || isLoadingSheet}
              me={3}
              py={2}
              minW="100px"
              onClick={() => onClose()}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.move_to_blueprint', { ns: 'projects' })}
            </Button>
          </>
        )}
        {isAllowedModify && !canClose && (
          <Button
            disabled={isLoading || isLoadingSheet}
            me={3}
            py={2}
            minW="100px"
            onClick={() => openUpdateProjectModal()}
          >
            {t('main_canvas.inspection_sheet.menu_bottom.upload_pcd', { ns: 'projects' })}
          </Button>
        )}
        {isAllowedDownload && (
          <Menu autoSelect={false}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              minW="100px"
              colorScheme="primary"
              disabled={isLoading || isLoadingSheet}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.download', { ns: 'projects' })}
            </MenuButton>
            <MenuList zIndex={4}>
              <MenuItem
                as={PDFDownloadLink}
                document={
                  <SpacerSheetPDF
                    project={project}
                    inspectionSheet={inspectionSheet}
                    inspectionItems={inspectionItems}
                    itemResults={itemResults}
                  />
                }
                fileName={`${getFileName()}.pdf`}
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => {
                  // track event to mixpanel
                  mixpanel.track('Export spacer inspection sheet', {
                    'Inspection area ID': project_id,
                    'File format': 'pdf',
                  })
                }}
              >
                <Text color="gray.600">PDF</Text>
              </MenuItem>
              <MenuItem onClick={onGenerateXLSX}>XLSX</MenuItem>
            </MenuList>
          </Menu>
        )}
      </ModalFooter>
    </>
  )
}

export default SpacerSheetModal
